import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

export interface FACTORY_CHECK_CONDITION {
  jitanFlag: boolean,
  goodExampleFlag: boolean,
  more90PercentFlag: boolean,
  factoryId: string,
  factoryFullName: string,
  areaCode: string,
  areaName: string,
  qcMailAddress: string,
  qcName: string,
  createDateFrom: any,
  createDateTo: any,
}

const initialFactoryCheckCondition: FACTORY_CHECK_CONDITION = {
  jitanFlag: false,
  goodExampleFlag: false,
  more90PercentFlag: false,
  factoryId: "",
  factoryFullName: "",
  areaCode: "",
  areaName: "",
  qcMailAddress: "",
  qcName: "",
  createDateFrom: null,
  createDateTo: null,
};

export const factoryCheckSlice = createSlice({
  name: "factoryCheckCondition",
  initialState: {
    factoryCheckCondition: initialFactoryCheckCondition,
    checkDataList: [],
    checkFilingMonthList: [],
    checkFilingDataList: [],
    factoryCheckManagerTab: "",
    factoryCheckManagerMap: {},
    locationKey: {},
  },
  reducers: {
    replaceFactoryCheckCondition: (state, action) => {
      state.factoryCheckCondition = Object.assign({}, state, action.payload.replaceData);
    },
    updateJitanFlag: (state, action) => {
      state.factoryCheckCondition.jitanFlag = action.payload.jitanFlag;
    },
    updateGoodExampleFlag: (state, action) => {
      state.factoryCheckCondition.goodExampleFlag = action.payload.goodExampleFlag;
    },
    updateMore90PercentFlag: (state, action) => {
      state.factoryCheckCondition.more90PercentFlag =
        action.payload.more90PercentFlag;
    },
    updateFactoryId: (state, action) => {
      state.factoryCheckCondition.factoryId =
        action.payload.factoryId;
    },
    updateFactoryFullName: (state, action) => {
      state.factoryCheckCondition.factoryFullName =
        action.payload.factoryFullName;
    },
    updateAreaCode: (state, action) => {
      state.factoryCheckCondition.areaCode =
        action.payload.zoCode;
    },
    updateAreaName: (state, action) => {
      state.factoryCheckCondition.areaName =
        action.payload.areaName;
    },
    updateQCMailAddress: (state, action) => {
      state.factoryCheckCondition.qcMailAddress =
        action.payload.qcMailAddress;
    },
    updateQCName: (state, action) => {
      state.factoryCheckCondition.qcName =
        action.payload.qcName;
    },
    updateCreateDateFrom: (state, action) => {
      state.factoryCheckCondition.createDateFrom =
        action.payload.createDateFrom;
    },
    updateCreateDateTo: (state, action) => {
      state.factoryCheckCondition.createDateTo =
        action.payload.createDateTo;
    },
    updateCheckDataList: (state, action) => {
      state.checkDataList = action.payload.checkDataList;
    },
    updateCheckFilingMonthList: (state, action) => {
      state.checkFilingMonthList = action.payload.checkFilingMonthList;
    },
    updateCheckFilingDataList: (state, action) => {
      state.checkFilingDataList = action.payload.checkFilingDataList;
    },
    updateFactoryCheckManagerTab: (state, action) => {
      state.factoryCheckManagerTab =
        action.payload.factoryCheckManagerTab;
    },
    updateFactoryCheckManagerMap: (state, action) => {
      state.factoryCheckManagerMap = action.payload.factoryCheckManagerMap;
    },
    updateLocationKey: (state, action) => {
      state.locationKey = { ...state.locationKey, ...action.payload.locationKey };
    },
    clearFactoryCheckCondition: (state) => {
      state.factoryCheckCondition = initialFactoryCheckCondition;
      state.checkDataList = [];
      state.checkFilingMonthList = [];
      state.checkFilingDataList = [];
      state.factoryCheckManagerTab = "";
      state.factoryCheckManagerMap = {};
    },
  },
});

export const selectFactoryCheckCondition = (state: RootState) => state.factoryCheckCondition;
export const selectJitanFlag = (state: RootState) =>
  state.factoryCheckCondition.factoryCheckCondition.jitanFlag;
export const selectGoodExampleFlag = (state: RootState) =>
  state.factoryCheckCondition.factoryCheckCondition.goodExampleFlag;
export const selectMore90PercentFlag = (state: RootState) =>
  state.factoryCheckCondition.factoryCheckCondition.more90PercentFlag;
export const selectFactoryId = (state: RootState) =>
  state.factoryCheckCondition.factoryCheckCondition.factoryId;
export const selectFactoryFullName = (state: RootState) =>
  state.factoryCheckCondition.factoryCheckCondition.factoryFullName;
export const selectAreaCode = (state: RootState) =>
  state.factoryCheckCondition.factoryCheckCondition.areaCode;
export const selectAreaName = (state: RootState) =>
  state.factoryCheckCondition.factoryCheckCondition.areaName;
export const selectQCMailAddress = (state: RootState) =>
  state.factoryCheckCondition.factoryCheckCondition.qcMailAddress;
export const selectQCName = (state: RootState) =>
  state.factoryCheckCondition.factoryCheckCondition.qcName;
export const selectCreateDateFrom = (state: RootState) =>
  state.factoryCheckCondition.factoryCheckCondition.createDateFrom;
export const selectCreateDateTo = (state: RootState) =>
  state.factoryCheckCondition.factoryCheckCondition.createDateTo;
export const selectCheckDataList = (state: RootState) =>
  state.factoryCheckCondition.checkDataList;
export const selectCheckFilingMonthList = (state: RootState) =>
  state.factoryCheckCondition.checkFilingMonthList;
export const selectCheckFilingDataList = (state: RootState) =>
  state.factoryCheckCondition.checkFilingDataList;
export const selectFactoryCheckManagerTab= (state: RootState) =>
  state.factoryCheckCondition.factoryCheckManagerTab;
export const selectFactoryCheckManagerMap = (state: RootState) =>
  state.factoryCheckCondition.factoryCheckManagerMap;
export const selectLocationKey = (state: RootState) =>
  state.factoryCheckCondition.locationKey;
export default factoryCheckSlice.reducer;
export const {
  replaceFactoryCheckCondition,
  updateJitanFlag,
  updateGoodExampleFlag,
  updateMore90PercentFlag,
  updateFactoryId,
  updateFactoryFullName,
  updateAreaCode,
  updateAreaName,
  updateQCMailAddress,
  updateQCName,
  updateCreateDateFrom,
  updateCreateDateTo,
  updateCheckDataList,
  updateCheckFilingMonthList,
  updateCheckFilingDataList,
  updateFactoryCheckManagerTab,
  updateFactoryCheckManagerMap,
  updateLocationKey,
  clearFactoryCheckCondition,
} = factoryCheckSlice.actions;

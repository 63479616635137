import Grid from '@mui/material/Unstable_Grid2';
import {
  styled,
  Button,
  AccordionSummary,
  Accordion,
  Typography,
  IconButton,
  CircularProgress,
  Modal,
} from "@mui/material";

export const OverLayer = styled(Grid)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: "0",
  left: "0",
  backgroundColor: "#CCC",
  opacity: "0.5",
  zIndex: 100000,
}));

// 
export const OverFlowGrid = styled(Grid)(({ theme }) => ({
  overflowY: "auto",
  height: "68vh",
  // iPad縦
  "@media (min-width: 830px)": {
    height: "78vh",
  },
  // iPad横
  "@media (min-width: 1100px)": {
    height: "72vh",
  },
  // GWPC
  "@media (min-width: 1300px)": {
    height: "65vh",
  },
}));

// 
export const ViewOverFlowGrid = styled(Grid)(({ theme }) => ({
  overflowY: "auto",
  paddingTop: "20px",
  paddingLeft: "30px",
  paddingRight: "30px",
  // その他
  "@media (min-width: 300px)": {
    height: "88vh",
  },
  // iPad Air縦
  "@media (min-width: 820px)": {
    height: "88vh",
  },
  // iPad Pro縦, iPad Air横
  "@media (min-width: 1024px)": {
    height: "82vh",
  },
  // 統合OA, iPad Pro横
  "@media (min-width: 1300px)": {
    height: "75vh",
  },
}));

export const BaseGrid = styled(Grid)(({ theme }) => ({
  borderColor: "lightgray",
  borderTopStyle: "solid",
  borderTopWidth: "4px",
  borderLeftStyle: "solid",
  borderLeftWidth: "4px",
}));

export const SeparatorGrid = styled(Grid)(({ theme }) => ({
  paddingTop: "20px",
}));

export const SeparatorLeftGrid = styled(Grid)(({ theme }) => ({
  paddingLeft: "20px",
}));

export const HeaderGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: "#00CC99",
  color: "white",
  [theme.breakpoints.up('md')]: {
    paddingLeft: "16px",
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: "6px",
  },
  borderColor: "lightgray",
  borderRightStyle: "solid",
  borderRightWidth: "4px",
  borderBottomStyle: "solid",
  borderBottomWidth: "4px",
}));

export const MiddleHeaderGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: "#00CC99",
  color: "white",
  [theme.breakpoints.up('md')]: {
    paddingLeft: "16px",
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: "6px",
  },
  borderColor: "lightgray",
  borderBottomStyle: "solid",
  borderBottomWidth: "4px",
}));

export const ContentsGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: "#d8e8e6",
  [theme.breakpoints.up('md')]: {
    paddingLeft: "16px",
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: "6px",
  },
  paddingRight: "16px",
  borderColor: "lightgray",
  borderRightStyle: "solid",
  borderRightWidth: "4px",
  borderBottomStyle: "solid",
  borderBottomWidth: "4px",
}));
//xs: 7, sm: 7, md: 7, lg: 7, xl: 8
export const ContentsBasicGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: "#fff176",
  [theme.breakpoints.up('md')]: {
    paddingLeft: "16px",
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: "6px",
  },
  paddingRight: "16px",
  borderColor: "lightgray",
  borderRightStyle: "solid",
  borderRightWidth: "4px",
  borderBottomStyle: "solid",
  borderBottomWidth: "4px",
}));

export const MiddleContentsGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: "#d8e8e6",
  [theme.breakpoints.up('md')]: {
    paddingLeft: "16px",
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: "6px",
  },
  borderColor: "lightgray",
  borderRightStyle: "solid",
  borderRightWidth: "4px",
}));

export const InputGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    paddingLeft: "16px",
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: "6px",
  },
  borderColor: "lightgray",
  borderRightStyle: "solid",
  borderRightWidth: "4px",
  borderBottomStyle: "solid",
  borderBottomWidth: "4px",
}));


export const DataHeaderGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: "#00CC99",
  color: "white",
  borderColor: "lightgray",
  borderRightStyle: "solid",
  borderRightWidth: "4px",
  borderBottomStyle: "solid",
  borderBottomWidth: "4px",
  minHeight: "50px",
}));

export const DataContentsGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: "#d8e8e6",
  borderColor: "lightgray",
  borderRightStyle: "solid",
  borderRightWidth: "4px",
  borderBottomStyle: "solid",
  borderBottomWidth: "4px",
  minHeight: "50px",
}));

export const DataInputGrid = styled(Grid)(({ theme }) => ({
  borderColor: "lightgray",
  borderRightStyle: "solid",
  borderRightWidth: "4px",
  borderBottomStyle: "solid",
  borderBottomWidth: "4px",
  minHeight: "50px",
}));

export const DataMiddleGrid = styled(Grid)(({ theme }) => ({
  borderColor: "lightgray",
  borderRightStyle: "solid",
  borderRightWidth: "4px",
}));

export const TopContentsGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    paddingLeft: "16px",
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: "6px",
  },
  borderColor: "lightgray",
  borderTopStyle: "solid",
  borderTopWidth: "4px",
  borderRightStyle: "solid",
  borderRightWidth: "4px",
}));

export const PrintMiddleContentsGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    paddingLeft: "16px",
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: "6px",
  },
  borderColor: "lightgray",
  borderRightStyle: "solid",
  borderRightWidth: "4px",
}));

export const PrintContentsGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    paddingLeft: "16px",
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: "6px",
  },
  borderColor: "lightgray",
  borderRightStyle: "solid",
  borderRightWidth: "4px",
  borderBottomStyle: "solid",
  borderBottomWidth: "4px",
}));

export const PrintDataContentsGrid = styled(Grid)(({ theme }) => ({
  borderColor: "lightgray",
  borderRightStyle: "solid",
  borderRightWidth: "4px",
  borderBottomStyle: "solid",
  borderBottomWidth: "4px",
  minHeight: "50px",
}));

export const PrintDataGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    paddingLeft: "16px",
    minHeight: "60px",
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: "6px",
    minHeight: "60px",
  },
  borderColor: "lightgray",
  borderRightStyle: "solid",
  borderRightWidth: "4px",
  borderBottomStyle: "solid",
  borderBottomWidth: "4px",
}));

export const PrintObjectGrid = styled(Grid)(({ theme }) => ({
  borderColor: "lightgray",
  borderRightStyle: "solid",
  borderRightWidth: "4px",
  borderBottomStyle: "solid",
  borderBottomWidth: "4px",
  [theme.breakpoints.up('md')]: {
    minHeight: "60px",
  },
  [theme.breakpoints.down('md')]: {
    minHeight: "60px",
  },
}));

export const PrintReviewAdviceGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    paddingLeft: "16px",
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: "6px",
  },
  borderColor: "lightgray",
  borderRightStyle: "solid",
  borderRightWidth: "4px",
  borderBottomStyle: "solid",
  borderBottomWidth: "4px",
  minHeight: "120px",
}));

export const RegistrationButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#ef942a",
  fontSize: "20px",
  minWidth: "30vw",
  height: "5vh",
  "&:hover": {
    background: "#ef942a",
  },
}));

export const FactoryAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  // width: "230px",
  height: "54px",
  backgroundColor: "#00CC99",
  color: "white",
  // borderRadius: "10px",
}));

export const FactoryAccordion = styled(Accordion)(({ theme }) => ({
  width: "100%",
  boxShadow: theme.shadows[3],
  // margin: theme.spacing(1),
  // borderRadius: "10px",
}));

export const AccordionSummaryGrid = styled(Grid)(({ theme }) => ({
  // width: "230px",
  height: "54px",
}));

export const FactoryButtonTypography = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize:"17px",
  fontWeight: "bold",
}));

export const SaveButton = styled(Button)(({ theme }) => ({
  fontSize: "20px",
  width: "30vw",
  minWidth: "200px",
  height: "5vh",
  padding: "10px",
}));

export const UpDownButton = styled(IconButton)(({ theme }) => ({
  color: "white",
  backgroundColor: "#00CC5B",
  "&:hover": {
    backgroundColor: "#00B250",
  },
}));

export const LoadingCircule = styled(CircularProgress)(({ theme }) => ({
  color: "#ccc",
  size: "40vh",
  top: "30%",
  position: "fixed",
}));

export const ModalPaneModal = styled(Modal)(({ theme }) => ({
  position: "absolute",
  paddingTop: "2%",
  paddingLeft: "15%",
  width: "640px",
  overflowY: "auto",
  overflowX: "hidden",
}));
  
// 全体枠
export const SearchAreaGrid = styled(Grid)(({ theme }) => ({
  border: "solid",
  flex: 1,
  margin: "auto",
  alignItems: "center",
  width: "640px",
  // height: "55vh",
  backgroundColor: "#fff",
  paddingBottom: "15px",
}));

// ヘッダー
export const SearchHeaderGrid = styled(Grid)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: "#37AB9D",
  fontWeight: "bolder",
  textAlign: "center",
  height: "40px",
}));

// ヘッダー
export const SearchSectionGrid = styled(Grid)(({ theme }) => ({
  paddingTop: "15px",
  paddingLeft: "15px",
  fontWeight: "bolder",
}));

export const SearchItemGrid = styled(Grid)(({ theme }) => ({
  paddingTop: "5px",
  paddingLeft: "10px",
  paddingBottom: "10px",
}));

export const SearchButton = styled(Button)(({ theme }) => ({
  color: "#ffffff",
  backgroundColor: "#4dc0b2",
  fontSize: "20px",
  "&:hover": {
    backgroundColor: "#4ab5a1",
  },
}));

export const TableBottomGrid = styled(Grid)(({ theme }) => ({
  marginTop: "20px",
  marginBottom: "20px",
  padding: "1px",
  width: "80vw",
}));
 
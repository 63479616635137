import React, { useEffect, useRef, FC } from "react";
import { DateTime } from "luxon";
import Grid from '@mui/material/Unstable_Grid2';
import DownloadIcon from "@mui/icons-material/CloudDownload";
import {
  VerticalAlignTop,
  VerticalAlignBottom,
} from "@mui/icons-material";

import { openWebSite } from "../../../apis/util";
import { 
  getFirstFlg,
  getFixedFlg
} from "../../Header/Header";
import FactoryCheckDialog from "../FactoryCheckDialog";
import {
  getFactoryCheckData,
  getFactoryCheckRecordsFirestoreData,
  updateFactoryCheckFirestore,
  FACTORY_CHECK_RECORD,
  FACTORY_CHECK_DATA,
  FACTORY_CHECK_BIG_RECORD,
  REQUEST_FOR_CORRECTION,
  FACTORY_CHECK_ADD_BIG_RECORD,
} from "./FactoryCheckDataOperation";
import {
  getCheckRecord
} from "../../organisms/table/FactoryCheckDataAllTable";
import {
  getCorrectionsTable
} from "../../organisms/table/FactoryCheckCorrectionsTable";

import { userLogging } from "../../../apis/userLog";
import { useSelector } from "react-redux";
import { 
  selectImageToken,
  selectUser
} from "../../../features/userSlice";
import { postRequest } from "../../../apis/axiosAction";
import { 
  EXISTING_FACTORY_RECORD,
  getAllExistingFactory
} from "../../../data/existingFactory";
import { db } from "../../../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import {
  OverLayer,
  OverFlowGrid,
  SeparatorGrid,
  SeparatorLeftGrid,
  RegistrationButton,
  UpDownButton,
  LoadingCircule,
} from "../Styled/FactoryCheckStyled";
import {
  getPrintImage,
} from "../../organisms/photo/FactoryCheckPrintImage";

let excelFlg = false;
export { excelFlg };
let updateFlg = 0;

const buttonNamePrint = "印刷（Ｅｘｃｅｌダウンロード）";
const buttonNameExcute = "登録・Ｅｘｃｅｌファイル作成";
const OTHER_VALUE: string = "99";

export function commentReason(row: FACTORY_CHECK_RECORD) {
  let comment = "";
  const addComment = (
    row: FACTORY_CHECK_RECORD,
    target: string,
    comment: string,
    targetFlg: boolean
  ) => {
    let workComment = "";
    if (row.reason_target_list && target && targetFlg) {
      for (let i = 0; i < row.reason_target_list.length; i++) {
        if (target === row.reason_target_list[i].value) {
          workComment += row.reason_target_list[i].name + " ";
        }
      }
    }
    if (OTHER_VALUE === target) {
      workComment += "その他 ";
    }
    if (
      row.reason_comment_map_list &&
      row.reason_comment_map_list.length > 0 &&
      comment
    ) {
      for (let i = 0; i < row.reason_comment_map_list.length; i++) {
        if (target === row.reason_comment_map_list[i].value) {
          let commentList = row.reason_comment_map_list[i].comment_list;
          for (let j = 0; j < commentList.length; j++) {
            if (comment === commentList[j].value) {
              workComment += commentList[j].name + " ";
            }
          }
        }
      }
    }
    return workComment;
  };

  comment += addComment(
    row,
    row.user_radio_target,
    row.user_radio_comment,
    true
  );
  if (row.user_ng_list) {
    let targetNo = "";
    for (let i = 0; i < row.user_ng_list.length; i++) {
      let targetFlg = false;
      if (row.user_ng_list[i].user_radio_target !== targetNo) {
        targetNo = row.user_ng_list[i].user_radio_target;
        targetFlg = true;
      }
      comment += addComment(
        row,
        row.user_ng_list[i].user_radio_target,
        row.user_ng_list[i].user_radio_comment,
        targetFlg
      );
    }
  }
  return comment;
}

// 作成日付(旧承認日)
let isApprove: number = 0;
export function isCheckDate() {
  return isApprove;
}
function checkCheckDate(dayStr: string | null, lastCheckDate: string) {
  isApprove =
    lastCheckDate !== ""
      ? dayStr && DateTime.fromFormat(dayStr, "yyyyMMdd").isValid
        ? dayStr >= lastCheckDate
          ? 1
          : 0
        : -1
      : dayStr && DateTime.fromFormat(dayStr, "yyyyMMdd").isValid
      ? 1
      : -1;
}
// 最初の読み込み時に追い越しが走らないようにするためのフラグ
let initailizeFlag: boolean;
// 合計スコア（保存処理用）
let scoreArray: number[] = [];

const FactoryCheckResultPrint: FC<{
  factoryId: string;
  factoryCheckId: string;
  lastFactoryCheckId: string;
  setUpdateFlg: any;
}> = ({ factoryId, factoryCheckId, lastFactoryCheckId, setUpdateFlg }) => {
  // const classes = stylesFactoryCheckTable();

  // 監査日時
  const [checkDate, setCheckDate] = React.useState<DateTime | null>(null);
  // 前回監査日時
  const [lastCheckDate, setLastCheckDate] = React.useState("");
  const [lastDateTitle, setLastDateTitle] = React.useState("");
  // フォーマット作成日
  const [formatCreateDate, setFormatCreateDate] = React.useState<DateTime | null>(null);
  // フォーマット更新日
  const [formatUpdateDate, setFormatUpdateDate] = React.useState<DateTime | null>(null);
  // 回数
  const [reportCount, setReportCount] = React.useState("");
  // 立会人
  const [witness, setWitness] = React.useState("");
  // 製造カテゴリーチェックボックス
  const [categoryCheckValue, setCategoryCheckValue] = React.useState([false, false, false, false, false, false, false, false, false]);
  // 製造カテゴリーその他
  const [manufacturCategory, setManufacturCategory] = React.useState("");
  // 改善報告書提出期限
  const [submissionDeadline, setSubmissionDeadline] = React.useState<DateTime | null>(null);
  // 改善報告書提出期限
  const [auditorDepartmentName, setAuditorDepartmentName] = React.useState("");
  // 改善報告書提出期限
  const [auditorName, setAuditorName] = React.useState("");

  const user = useSelector(selectUser);

  const [rowItemList, setRowItemList] = React.useState<
    FACTORY_CHECK_BIG_RECORD[]
  >([]);
  const [addRowItemList, setAddRowItemList] = React.useState<
    FACTORY_CHECK_ADD_BIG_RECORD[]
  >([]);
  const [selectedFactoryId, setSelectedFactoryId] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [snapshotloading, setSnapshotLoading] = React.useState(false);
  const [userPrivilege, setUserPrivilege] = React.useState("");

  const [inputHour, setInputHour] = React.useState("");
  const [checkHour, setCheckHour] = React.useState("");

  const [factoryCheckSelect, setFactoryCheckSelect] = React.useState([false, false, false, false, false]);
  // 是正要請
  const [corrections, setCorrections] = React.useState<REQUEST_FOR_CORRECTION[]>([]);
  // 総評・助言
  const [reviewAdvice, setReviewAdvice] = React.useState("");
  // 編集可否
  const [isFixed, setIsFixed] = React.useState(false);
  const [excelURL, setExcelURL] = React.useState("");
  const [excelNewButtonName, setExcelNewButtonName] =
    React.useState(buttonNameExcute);

  const [creatWait, setCreatWait] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [alertFlg, setAlertFlg] = React.useState(false);
  const [dialogFlg, setDialogFlg] = React.useState(false);

  type RecordsType =
  firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
  const [snapshot, setSnapshot] = React.useState<RecordsType>();
  const factoryCheckRecords = async () => {
    return new Promise((resolve) => {
      db.collection("factory_check_records")
      .doc(factoryCheckId)
      .onSnapshot(docSnapshot => {
          setSnapshot(docSnapshot);
        resolve(docSnapshot.data());
      })
    });
  };

  useEffect(() => {
    setSnapshotLoading(true);
    getFactoryCheckData(
      factoryId,
      factoryCheckId,
      lastFactoryCheckId,
      getFixedFlg(),
      user.jobCategory,
      snapshot?.data()
    ).then((res: FACTORY_CHECK_DATA) => {
      setCheckDate(
        res.check_date
          ? DateTime.fromFormat(res.check_date, "yyyyMMdd")
          : DateTime.local()
      );
      setFormatCreateDate(
        res.format_create_date
          ? DateTime.fromFormat(res.format_create_date, "yyyy/MM/dd")
          : DateTime.local()
      );
      setFormatUpdateDate(
        res.format_update_date
          ? DateTime.fromFormat(res.format_update_date, "yyyy/MM/dd")
          : DateTime.local()
      );
      checkCheckDate(
        res.check_date ? res.check_date : "",
        res.last_check_date
      );
      if (res.check_hour) {
        setInputHour(res.check_hour);
        setCheckHour(res.check_hour);
      } else {
        setInputHour("");
        setCheckHour("");
      }
      setLastCheckDate(res.last_check_date);
      setLastDateTitle(
        res.last_check_date
          ? DateTime.fromFormat(res.last_check_date, "yyyyMMdd").toFormat(
              "MM月dd日"
            )
          : "-"
      );
      setFactoryCheckSelect(res.factory_check_select);
      setManufacturCategory(res.manufactur_category);
      setReportCount(res.report_count);
      setWitness(res.witness);
      setAuditorDepartmentName(res.auditor_department_name);
      setAuditorName(res.auditor_name);
      setCorrections(res.corrections);
      setReviewAdvice(res.review_advice);
      setCategoryCheckValue(res.category_check_value);
      setSubmissionDeadline(
        res.submission_deadline
          ? DateTime.fromFormat(res.submission_deadline, "yyyyMMdd")
          : DateTime.local()
      );
      setRowItemList(res.base_value);
      setAddRowItemList(res.add_value);
      setIsFixed(res.is_fixed);
      // setSnapshotLoading(false);
      if (saving) {
        setSaving(false);
      }
      else {
        setSnapshotLoading(false);
        setLoading(false);
      }
    });
  }, [snapshot]);

  // useEffect(() => {
  //   setRowItemList([]);
  //   setCheckDate(null);
  //   setCheckHour("");
  //   setSubmissionDeadline(null);
  //   initailizeFlag = false;
  //   if (getFirstFlg()) {
  //     setSelectedFactoryId("");
  //   } else {
  //     setSelectedFactoryId(factoryId);
  //   }
  // }, [factoryId, getFirstFlg()]);

  useEffect(() => {
    setLoading(true);
    userLogging("工場チェック", "提出#初期表示", factoryCheckId);
    setUserPrivilege(user.jobCategory);

    setRowItemList([]);
    initailizeFlag = false;
    setSelectedFactoryId(factoryId);
    if (!factoryCheckId) return;
    factoryCheckRecords();
    // factoryCheckRecords().then((docData: any) => {
    //   getFactoryCheckData(
    //     factoryId,
    //     factoryCheckId,
    //     lastFactoryCheckId,
    //     getFixedFlg(),
    //     user.jobCategory,
    //     docData
    //   ).then((res: FACTORY_CHECK_DATA) => {
    //     setCheckDate(
    //       res.check_date
    //         ? DateTime.fromFormat(res.check_date, "yyyyMMdd")
    //         : DateTime.local()
    //     );
    //     setFormatCreateDate(
    //       res.format_create_date
    //         ? DateTime.fromFormat(res.format_create_date, "yyyy/MM/dd")
    //         : DateTime.local()
    //     );
    //     setFormatUpdateDate(
    //       res.format_update_date
    //         ? DateTime.fromFormat(res.format_update_date, "yyyy/MM/dd")
    //         : DateTime.local()
    //     );
    //     checkCheckDate(
    //       res.check_date ? res.check_date : "",
    //       res.last_check_date
    //     );
    //     if (res.check_hour) {
    //       setInputHour(res.check_hour);
    //       setCheckHour(res.check_hour);
    //     } else {
    //       setInputHour("");
    //       setCheckHour("");
    //     }
    //     setLastCheckDate(res.last_check_date);
    //     setLastDateTitle(
    //       res.last_check_date
    //         ? DateTime.fromFormat(res.last_check_date, "yyyyMMdd").toFormat(
    //             "MM月dd日"
    //           )
    //         : "-"
    //     );
    //     setFactoryCheckSelect(res.factory_check_select);
    //     setManufacturCategory(res.manufactur_category);
    //     setReportCount(res.report_count);
    //     setWitness(res.witness);
    //     setAuditorDepartmentName(res.auditor_department_name);
    //     setAuditorName(res.auditor_name);
    //     setCorrections(res.corrections);
    //     setReviewAdvice(res.review_advice);
    //     setCategoryCheckValue(res.category_check_value);
    //     setSubmissionDeadline(
    //       res.submission_deadline
    //         ? DateTime.fromFormat(res.submission_deadline, "yyyyMMdd")
    //         : DateTime.local()
    //     );
    //     setRowItemList(res.base_value);
    //     initailizeFlag = true;
    //     setIsFixed(res.is_fixed);
    //     if (res.complete_date) {
    //       setExcelURL(generateExcelURL());
    //       setExcelNewButtonName(buttonNamePrint);
    //       setAlertFlg(true);
    //       setDialogMessage(["表示されているデータは提出済みです。"]);
    //       setDialogFlg(true);
    //     }
    //     setLoading(false);
    //   });
    // });
  }, [factoryCheckId]);

  // ダイアログ表示
  const dialogCancel = () => {
    // if (dialogNum === 2) {
      setExcelNewButtonName(buttonNameExcute);
      setCreatWait(false);
      // setLoading(false);
      setSaving(false);
    // }
    setDialogFlg(false);
  };
  const dialogOK = () => {
    // if (!alertFlg) {
    //   if (dialogNum === 1) {
    //     setExcelNewButtonName("PDF作成中…");
    //     setCreatWait(true);
    //     handleExcelPrint();
    //   } else if (dialogNum === 2) {
    //     excelPrintUpdate();
    //   }
    // }
    setDialogFlg(false);
  };

  function generateExcelURL() {
    let gcsUrl = process.env.REACT_APP_CLOUD_STORAGE_URL ? process.env.REACT_APP_CLOUD_STORAGE_URL : "";
    let gcsDir = process.env.REACT_APP_GCS_DIR_PDF ? process.env.REACT_APP_GCS_DIR_PDF : "";
    return (
      gcsUrl + gcsDir + "/" + factoryCheckId +".xlsx" +"?access_token=" + imageToken
    );
  }

  /**
   * Excelボタン押下時のイベント
   */
  function clickExcelPrint() {
    if (excelURL) {
      openWebSite(excelURL);
      return;
    }
    setCreatWait(true);
    // setLoading(true);
    setSaving(true);
    handleExcelPrint();
  }
  const handleExcelPrint = async () => {
    // 既に完了している場合は更新しない
    const factoryCheckData: any = await getFactoryCheckRecordsFirestoreData(factoryCheckId);
    if (factoryCheckData && factoryCheckData.attribution.complete_date) {
      setCreatWait(false);
      // setLoading(false);
      setAlertFlg(true);
      //TODO ボタン名の変更とリンク先設定
      setExcelURL(generateExcelURL());
      setExcelNewButtonName(buttonNamePrint);
      setDialogMessage(["既に登録されています。",
      "新たにデータ登録する場合は左上のメニューから「新規作成」を、",
      "作成されたデータを見る場合は左上のメニューからデータ検索してください。"]);
      setDialogFlg(true);
      setSaving(false);
      return;
    }
    sendAPISubmissionFactoryCheck(
      rowItemList,
      addRowItemList,
      factoryCheckId,
      scoreArray[scoreArray.length-1],
      lastFactoryCheckId,
      selectedFactoryId,
      checkDate,
      lastCheckDate ? lastCheckDate : "",
      userPrivilege,
      checkHour,
      factoryCheckSelect,
      reportCount,
      witness,
      categoryCheckValue,
      manufacturCategory,
      submissionDeadline,
      corrections,
      reviewAdvice,
      user.hqDepartmentNameOA,
      user.employeeName,
      isFixed,
    );
  };
  /**
   * 工場チェックの値を受け取り、Firestoreの更新を行う
   * @param basicItemList 基本項目の配列
   * @param addRowItemList 追加項目の配列
   *
   */
  const sendAPISubmissionFactoryCheck = async (
    rowItemList: Array<FACTORY_CHECK_BIG_RECORD>,
    addRowItemList: Array<FACTORY_CHECK_ADD_BIG_RECORD>,
    factoryCheckId: string,
    totalScore: number,
    factoryCheckLastTimeId: string,
    selectedfactoryId: string,
    checkDate: DateTime | null,
    lastCheckDate: string,
    userPrivilege: string,
    checkHour: string,
    factoryCheckSelect: boolean[],
    reportCount: string,
    witness: string,
    categoryCheckValue: boolean[],
    manufacturCategory: string,
    submissionDeadline: DateTime | null,
    corrections: Array<REQUEST_FOR_CORRECTION>,
    reviewAdvice: string,
    auditorDepartmentName: string,
    auditorName: string,
    isFixed: boolean,
  ) => {
    if (
      (Array.isArray(rowItemList) && rowItemList.length === 0) ||
      factoryCheckId === ""
    ) {
      // 初回読み込みで呼ばれてしまうので空であれば終了する
      return;
    }
    // 登録済みフラグを設定
    const objects: any = {};
    
    objects["attribution"] = {
      conditions: ["ALL"],
      last_time_id: factoryCheckLastTimeId,
      factory_id: selectedfactoryId,
      total_score: String(totalScore),
      check_date: checkDate ? checkDate.toFormat("yyyy/MM/dd") : "",
      last_check_date: lastCheckDate,
      user_privilege: userPrivilege,
      check_hour: checkHour,
      factoryCheckSelect: factoryCheckSelect,
      reportCount: reportCount,
      witness: witness,
      categoryCheckValue: categoryCheckValue,
      manufacturCategory: manufacturCategory,
      submissionDeadline: submissionDeadline ? submissionDeadline.toFormat("yyyy/MM/dd") : "",
      corrections: corrections,
      reviewAdvice: reviewAdvice,
      auditorDepartmentName: auditorDepartmentName,
      auditorName: auditorName,
      isFixed: isFixed,
    };
    objects["id"] = factoryCheckId;
    objects["docId"] = factoryCheckId;
    objects["update_date"] = {
      // update_date: firestore.FieldValue.serverTimestamp(),
      // update_user: getFirebaseUserInfo().uid,
    };
    let records: any = {};
    // let scoreArray = [];
    let valuesRecord: any = {};
    let values = [];
    for (const bigRecord of rowItemList) {
      for (const smallRecord of bigRecord.rows) {
        const keyValue: string = "records-" + smallRecord.key;
        records[keyValue] = smallRecord;
        valuesRecord = smallRecord;
        valuesRecord["createUser"] = user.mailAddress;
        valuesRecord["updateUser"] = user.mailAddress;
        values.push(valuesRecord);
      }
    }
    // for (const bigRecord of addRowItemList) {
    //   for (const smallRecord of bigRecord.rows) {
    //     const keyValue: string = "records-" + smallRecord.key;
    //     records[keyValue] = smallRecord;
    //   }
    // }
    objects["records"] = records;
    // 次回の工場チェックのデータを作成{user.hqDepartmentNameOA} {user.employeeName}
    const apiParams = 
    {
      "query": 'mutation {' +
        'factoryCheckRegister(' +
        'input: {' +
          'id: "' + factoryCheckId + '", ' +
          'updateUser: "' + user.mailAddress +'", ' +
          'updateEmployeeNo: "' + user.employeeNo + '"}' +
        ')' +
      '}'
    }
    const url = process.env.REACT_APP_API_URL + "/graphql";
    postRequest(url, apiParams)
      .then(async (res) => {
        // 完了済みのレコードの場合
        if (res.data.data.factoryCheckRegister === "already completed") {
          setCreatWait(false);
          // setLoading(false);
          setAlertFlg(true);
          //TODO ボタン名の変更とリンク先設定
          setExcelURL(generateExcelURL());
          setExcelNewButtonName(buttonNamePrint);
          setDialogMessage(["既に登録されています。",
          "新たにデータ登録する場合は左上のメニューから「新規作成」を、",
          "作成されたデータを見る場合は左上のメニューからデータ検索してください。"]);
          setDialogFlg(true);
          setSaving(false);
          return;
        }
        else if (res.data.data.factoryCheckRegister === "error") {
          setCreatWait(false);
          // setLoading(false);
          setAlertFlg(true);
          setDialogMessage([
            "正常保存されませんでした。",
            "お手数ですが工場選択をして（もしくはブラウザを開きなおしてから）、再度提出してください。",
          ]);
          setDialogFlg(true);
          setSaving(false);
          return;
          //TODO 登録済みフラグを解除？
        }
        else {
          updateFactoryCheckFirestore(
            rowItemList,
            addRowItemList,
            factoryCheckId,
            totalScore,
            lastFactoryCheckId,
            selectedFactoryId,
            checkDate ? checkDate.toFormat("yyyyMMdd") : "",
            lastCheckDate,
            userPrivilege,
            checkHour,
            factoryCheckSelect,
            reportCount,
            witness,
            categoryCheckValue,
            manufacturCategory,
            submissionDeadline ? submissionDeadline.toFormat("yyyyMMdd") : "",
            corrections,
            reviewAdvice,
            user.hqDepartmentNameOA,
            user.employeeName,
            isFixed,
            DateTime.local().toFormat("yyyyMMdd"),
          );
          //TODO ボタン名の変更とリンク先設定
          setExcelURL(generateExcelURL());
          setExcelNewButtonName(buttonNamePrint);
          setCreatWait(false);
          // setLoading(false);
        }
        // // Firestoreへの入力内容に不備がある場合
        // else if (res.data.dataList[0] === "InvalidFirestoreSave") {
        //   return [
        //     "入力内容が正常に保存されていません。",
        //     "チェックタブに戻り、再度入力内容を保存してください。",
        //   ];
        // }
      })
      .catch((r) => {
        setCreatWait(false);
        // setLoading(false);
        setAlertFlg(true);
        setDialogMessage(["提出データ送信中にエラーが発生しました。", String(r)]);
        setDialogFlg(true);
        setSaving(false);
    });
  };

  // 画面スクロール
  const moveScroll = (fieldName: string, bottomFlg?: boolean) => {
    let element = document?.getElementById(fieldName);
    if (element !== null) {
      if (bottomFlg) {
        element?.scrollIntoView(false);
      } else {
        element.scrollIntoView();
      }
    }
  };

  const factoryMaster: Map<String, EXISTING_FACTORY_RECORD> = getAllExistingFactory().factory_data;
  let factoryRecordSearch = factoryMaster.get(selectedFactoryId);

  const imageToken = useSelector(selectImageToken);
  let currentIndex = -1;
  function isHeaderCheckImage(index: number) {
    let headerFlag = false;
    if (currentIndex !== index) {
      headerFlag = true;
      currentIndex = index;
    }
    return headerFlag;
  }

  return (
    <>
    {(loading || snapshotloading || saving) ?
    (
      <>
        <OverLayer/>
        <Grid
          container
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          justifyContent="center"
          alignItems="center"
        >
          <LoadingCircule
            size={"40vh"}
          />
        </Grid>
      </>
    ) : (
      <>
    {/* メインコンテンツ↓ここから */}
    <OverFlowGrid>
      <Grid id="topField" />
      <Grid container
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
      >
        {/* 通常項目 */}
        {getCheckRecord(
            factoryCheckSelect, 
            rowItemList,
            addRowItemList,
            formatCreateDate ? formatCreateDate.toFormat("yyyy/MM/dd") : "",
            formatUpdateDate ? formatUpdateDate.toFormat("yyyy/MM/dd") : "",
            factoryRecordSearch,
            checkDate,
            inputHour,
            witness,
            categoryCheckValue,
            reportCount,
            manufacturCategory,
            submissionDeadline,
            imageToken,
            auditorDepartmentName,
            auditorName,
            // isHeaderCheckImage(index),
        )}
        {getCorrectionsTable(corrections, reviewAdvice)}
        {getPrintImage(
          factoryRecordSearch,
          witness,
          submissionDeadline,
          auditorDepartmentName,
          auditorName,
          rowItemList,
          addRowItemList,
          imageToken
        )}
      </Grid>
      <Grid id="bottomField" />
    </OverFlowGrid>
    {/* メインコンテンツ↑ここまで */}
    {/* コンテンツフッター↓ここから */}
    <Grid container>
      <Grid
       {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
      >
      </Grid>
      <Grid
       {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
      >
        <SeparatorGrid
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            container
            justifyContent={"center"}
            alignItems={"center"}
        >
        {isFixed ? (
          <RegistrationButton
            variant="contained"
            // className={classes.button}
            onClick={() => {
              userLogging("工場チェック", "印刷#帳票出力", "");
              clickExcelPrint();
            }}
          >
            {excelNewButtonName}
            {excelURL ? <DownloadIcon /> : ""}
          </RegistrationButton>
        ) : ("")}
        </SeparatorGrid>
      </Grid>
      <Grid
       {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
      >
        <SeparatorGrid
          container
          justifyContent={"center"}
          alignItems={"center"}
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <SeparatorLeftGrid>
            <UpDownButton
              onClick={() => moveScroll("topField")}
            >
              <VerticalAlignTop></VerticalAlignTop>
            </UpDownButton>
          </SeparatorLeftGrid>
          <SeparatorLeftGrid>
            <UpDownButton
              onClick={() => moveScroll("bottomField")}
            >
              <VerticalAlignBottom></VerticalAlignBottom>
            </UpDownButton>
          </SeparatorLeftGrid>
        </SeparatorGrid>
      </Grid>
    </Grid>
    {/* コンテンツフッター↑ここまで */}
    {/* ダイアログ */}
    <FactoryCheckDialog
      msg={dialogMessage}
      isOpen={dialogFlg}
      doOK={dialogOK}
      doNo={dialogCancel}
      isAlert={alertFlg}
      boldList={dialogMessage}
    />
    </>
    )}
    </>
  );
};
export default FactoryCheckResultPrint;

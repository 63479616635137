import React, { useState } from "react";
// import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { auth, analytics } from "../../firebase";
import Typography from "@mui/material/Typography";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       height: "100vh",
//       width: "100vw",
//       backgroundColor: "#D7FFF1",
//     },
//     cardRoot: { height: "80vh", margin: "auto" },
//     title: {
//       color: "#fff",
//       backgroundColor: "rgb(71, 179, 118)",
//       borderColor: "rgb(71, 179, 118)",
//     },
//     buttonGreen: {
//       color: "#fff",
//       backgroundColor: "#1ba649",
//       margin: "100px 0 0 auto",
//       "&:hover": {
//         background: "#1ba649",
//       },
//     },
//     errorMessage: {
//       color: "red",
//       marginTop: "10px",
//       fontSize: "13px",
//       display: "block",
//       textAlign: "center",
//     },
//   })
// );

const Auth: React.FC = (props: any) => {
  // const classes = useStyles();
  const [userid, setUserid] = useState("");
  const [password, setPassword] = useState("");
  const [authFlg, setAuthFlg] = useState(true);
  const [loginMessage, setLoginMessage] = useState("");
  const signIn = async () => {
    let inputUserId: string = userid;
    if (inputUserId.indexOf("@") === -1) {
      // 「@」が含まれない場合は「@opview.7view.jp」を付与する
      inputUserId = userid + "@opview.7view.jp";
    }
    await auth
      .signInWithEmailAndPassword(inputUserId, password)
      .then(() => {
        setLoginMessage("ログインに成功しました");
        props.history.push("/");
        analytics.logEvent("login");
      })
      .catch((error: any) => {
        setAuthFlg(false);
        if (error.code === "auth/wrong-password") {
          setLoginMessage("パスワードが違います");
        } else if (error.code === "auth/invalid-email") {
          setLoginMessage("登録されていないIDです");
        } else {
          setLoginMessage("ログインに失敗しました");
        }
        console.log(error.code);
        console.log(error.message);
      });
  };

  // 開発画面以外ではログイン画面を非表示化する(本番環境はSSO共通基盤認証よりログイン)
  const isAuthDisplay: boolean = process.env.REACT_APP_MODE === "develop";

  return (
    // <Grid container className={classes.root} justify="center">
    //   <Grid container item xs={12} md={10} lg={8} xl={6} justify="center">
    <Grid container>
      <Grid item xs={12} md={10} lg={8} xl={6}>
        {isAuthDisplay ? (
          // <Card className={classes.cardRoot}>
          <Card>
            <CardHeader
              // className={classes.title}
              title="工場チェック"
            />
            {/* <Grid container justify="center"> */}
            <Grid container>
              <img
                src={`${window.location.origin}/image/sejlogo.png`}
                alt="SEJアイコン"
                style={{ height: "15vh" }}
              />
            </Grid>
            <CardContent>
              {/* <Grid container justify="center"> */}
              <Grid container>
                <TextField
                  margin="dense"
                  required
                  label="ユーザーID"
                  variant="outlined"
                  style={{ width: "72vw" }}
                  id="userid"
                  name="userid"
                  autoComplete="userid"
                  value={userid}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUserid(e.target.value);
                  }}
                />
                <TextField
                  margin="dense"
                  required
                  label="パスワード"
                  type="password"
                  variant="outlined"
                  style={{ width: "72vw" }}
                  name="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(e.target.value);
                  }}
                />
              </Grid>
              {/* <Typography className={!authFlg ? classes.errorMessage : ""}> */}
              <Typography>
                {loginMessage}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="large"
                variant="contained"
                // className={classes.buttonGreen}
                onClick={async () => {
                  try {
                    await signIn();
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                ログイン
              </Button>
            </CardActions>
          </Card>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};
export default Auth;

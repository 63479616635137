import React, { FC, useEffect } from "react";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {
  IconButton,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

import { userLogging } from "../../../apis/userLog";
import { selectUser } from "../../../features/userSlice";
import { postRequest } from "../../../apis/axiosAction";
import {
  BaseGrid,
  SeparatorGrid,
  DataHeaderGrid,
  DataInputGrid,
} from "../../FactoryCheck/Styled/FactoryCheckStyled";
import { isExecuteLogicalDelete } from "../../../apis/privilege";

const FactoryCheckSearchResultTable: FC<{
  resultList: any;
  updateResultList: any;
  setFactoryCheckId: any;
  setSearchResultRecord: any;
  setSearchFlg: any;
  isReference: boolean;
}> = ({ resultList, updateResultList, setFactoryCheckId, setSearchResultRecord, setSearchFlg, isReference }) => {

  const user = useSelector(selectUser);
  const [sortingKey, setSortingKey] = React.useState<String>("");
  const [thumbUpClick, setThumbUpClick] = React.useState<boolean>(false);
  const [thumbUpColor, setThumbUpColor] = React.useState<String[]>([]);
  const [deletedFlagClick, setDeletedFlagClick] = React.useState<boolean>(false);
  const [deletedFlagColor, setDeletedFlagColor] = React.useState<String[]>([]);
  useEffect(() => {
    if (resultList && resultList.length > 0) {
      resultList.map((row: any, index: number) => {
        thumbUpColor.push(row.thumbUp);
      })
    }
  }, [thumbUpClick]);

  useEffect(() => {
    if (resultList && resultList.length > 0) {
      resultList.map((row: any, index: number) => {
        deletedFlagColor.push(row.deletedFlag);
      })
    }
  }, [deletedFlagClick]);

  // ボタン押下処理
  const clickDeletedFlagButton = (row: any, index: number) => {
    let deletedFlag = row.deletedFlag === "1" ? "0" : "1";
    deletedFlagColor[index] = deletedFlag;
    updateResultList(row.docId, row.thumbUp, deletedFlag);
    setDeletedFlagColor(deletedFlagColor);
    setDeletedFlagClick(!deletedFlagClick);
    let apiStrings = 
    'mutation { ' + 
      'factoryCheckDelete (' +
        'input: {' +
            'id: "' + row.docId + '", ' +
            'deletedFlag: "' + deletedFlag + '", ' +
            'updateUser: "' + user.mailAddress + '"' +
        '}' +
      ')' +
    '}';
    const apiParams = 
    {
      "query": apiStrings
    };
    userLogging("ＱＣ工場チェック", "データ検索#削除", JSON.stringify(apiParams));
    const url = process.env.REACT_APP_API_URL + "/graphql";
    postRequest(url, apiParams);
  };
  // ボタン押下処理
  const clickThumbUpButton = (row: any, index: number) => {
    let thumbUp = row.thumbUp === "1" ? "0" : "1";
    thumbUpColor[index] = thumbUp;
    updateResultList(row.docId, thumbUp, row.deletedFlag);
    setThumbUpColor(thumbUpColor);
    setThumbUpClick(!thumbUpClick);
    let apiStrings = 
    'mutation { ' + 
      'factoryCheckUpdateThumbUp (' +
        'input: {' +
            'id: "' + row.docId + '", ' +
            'thumbUp: "' + thumbUp + '", ' +
            'updateUser: "' + user.mailAddress + '"' +
        '}' +
      ')' +
    '}';
    const apiParams = 
    {
      "query": apiStrings
    };
    userLogging("ＱＣ工場チェック", "データ検索#好事例更新", JSON.stringify(apiParams));
    const url = process.env.REACT_APP_API_URL + "/graphql";
    postRequest(url, apiParams);
  };
  // ソート
  const clickSort = (sortKey: string) => {
    if (isReference) return;
    let mapList: any[] = [];
    for (let i = 0; i < resultList.length; i++) {
      mapList.push(resultList[i]);
    }

    let asc = true;
    if (sortingKey === sortKey) {
      asc = false;
    }
    resultList = mapList.sort(function (first, second) {
      if (first[sortKey] > second[sortKey]) {
        return asc ? 1 : -1;
      } else if (first[sortKey] < second[sortKey]) {
        return asc ? -1 : 1;
      } else {
        return 0;
      }
    });
    if (asc) {
      setSortingKey(sortKey);
    } else {
      setSortingKey("");
    }
  };

  const clickReference = (
    row: any,
  ) => {
    if (isReference) return;
    userLogging(
      "ＱＣ工場チェック", "データ検索結果#詳細表示",
      JSON.stringify(row)
    );
    setFactoryCheckId(row.docId);
    setSearchResultRecord(row);
    setSearchFlg("3");
  };

  if (resultList && resultList.length > 0) {
    return (
      <>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"flex-start"}
        id="topField"
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
      >
        <SeparatorGrid
          container
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <BaseGrid
            container
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            {/* ヘッダー */}
            <Grid
              container 
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              {isExecuteLogicalDelete(user) ?
              <>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 0.3, sm: 0.3, md: 0.3, lg: 0.3 }}
                onClick={() => clickSort("deletedFlag")}
              >
                削除
              </DataHeaderGrid>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 0.7, sm: 0.7, md: 0.7, lg: 0.7 }}
                onClick={() => clickSort("thumbUp")}
              >
                好事例
              </DataHeaderGrid>
              </>
              :
              <>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
                onClick={() => clickSort("thumbUp")}
              >
                好事例
              </DataHeaderGrid>
              </>}
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
                onClick={() => clickSort("areaName")}
              >
                地区
              </DataHeaderGrid>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                onClick={() => clickSort("companyName")}
              >
                メーカー名
              </DataHeaderGrid>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                onClick={() => clickSort("factoryName")}
              >
                工場名
              </DataHeaderGrid>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                onClick={() => clickSort("qcName")}
              >
                QC
              </DataHeaderGrid>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                onClick={() => clickSort("managerName")}
              >
                M
              </DataHeaderGrid>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                onClick={() => clickSort("filingDate")}
              >
                作成日
              </DataHeaderGrid>
            </Grid>
            {/* データ部 */}
            {resultList.map((row: any, index: number) => (
              <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <Grid
                  container 
                  {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                >
                  {isExecuteLogicalDelete(user) ?
                  <>
                  <DataInputGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 0.3, sm: 0.3, md: 0.3, lg: 0.3 }}
                  >
                    <IconButton size="large" onClick={() => clickDeletedFlagButton(row, index)}>
                      {row.deletedFlag !== "1" ? 
                        <DeleteIcon color={"primary"} />
                       : 
                        <RestoreFromTrashIcon/>
                      }
                    </IconButton>
                  </DataInputGrid>
                  <DataInputGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 0.7, sm: 0.7, md: 0.7, lg: 0.7 }}
                  >
                    <IconButton size="large" onClick={() => clickThumbUpButton(row, index)}>
                    <ThumbUpAltIcon color={(row.thumbUp === "1" ? "primary" : "disabled")} />
                    </IconButton>
                  </DataInputGrid>
                  </>
                  :
                  <>
                  <DataInputGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
                  >
                    <IconButton size="large" onClick={() => clickThumbUpButton(row, index)}>
                    <ThumbUpAltIcon color={(row.thumbUp === "1" ? "primary" : "disabled")} />
                    </IconButton>
                  </DataInputGrid>
                  </>}
                  <DataInputGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
                    onClick={() => {
                      clickReference(row);
                    }}
                  >
                    {row.areaName}
                  </DataInputGrid>
                  <DataInputGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    onClick={() => {
                      clickReference(row);
                    }}
                  >
                    {row.companyName}
                  </DataInputGrid>
                  <DataInputGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    onClick={() => {
                      clickReference(row);
                    }}
                  >
                    {row.factoryName}
                  </DataInputGrid>
                  <DataInputGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    onClick={() => {
                      clickReference(row);
                    }}
                  >
                    {row.qcName}
                  </DataInputGrid>
                  <DataInputGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    onClick={() => {
                      clickReference(row);
                    }}
                  >
                    {row.managerName}
                  </DataInputGrid>
                  <DataInputGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    onClick={() => {
                      clickReference(row);
                    }}
                  >
                    {DateTime.fromFormat(row.filingDate, "yyyy/MM/dd hh:mm:ss").plus({ hour: 9 }).toFormat("yyyy年MM月dd日")}
                  </DataInputGrid>
                </Grid>
              </Grid>
            ))}
          </BaseGrid>
        </SeparatorGrid>
      </Grid>
      <Grid id="bottomField" />
      </>
    );
  } else {
    return (
      <>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
      >
        <SeparatorGrid>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          右上の虫眼鏡アイコンから検索条件を指定してください。
        </Grid>
        </SeparatorGrid>
      </Grid>
      </>
    );
  }
};
export default FactoryCheckSearchResultTable;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
import {
  CircularProgress,
  Tooltip,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import InfoIcon from "@mui/icons-material/Info";

import SejDatePicker from "../../common/SejDatePicker";
import FactoryCheckFilingStatusConfirmResult from "./FactoryCheckFilingStatusConfirmResult";
import { selectUser } from "../../../features/userSlice";
import Header from "../../Header/Header";
import SearchFactoryArea from "../../organisms/search/searchFactory/SearchFactoryArea";
import {
  getAuthFactoryCheck,
  onlyArea,
} from "../../organisms/search/specification/UsageAuthority/AuthFactoryCheck";
import {
  updateAreaCode,
  updateAreaName,
  updateCheckFilingMonthList,
  updateCheckFilingDataList,
  selectCheckFilingMonthList,
  selectCheckFilingDataList,
} from "../../../components/organisms/search/FactoryCheckSearchSlice";
import { userLogging } from "../../../apis/userLog";
import FactoryCheckDialog from "../FactoryCheckDialog";
import { Redirect } from "react-router-dom";
import { isFirebaseAuth } from "../../../firebase";
import { isOpenStatusConfirmPage } from "../../../apis/privilege";

import {
  OverLayer,
  LoadingCircule,
  SeparatorGrid,
  ModalPaneModal,
  SearchAreaGrid,
  SearchHeaderGrid,
  SearchSectionGrid,
  SearchItemGrid,
  SearchButton,
} from "../Styled/FactoryCheckStyled";

// const useStyles = makeStyles((theme: Theme) => ({
//   root: {
//     height: "100vh",
//   },
//   modalPane: {
//     position: "absolute",
//     paddingTop: "2%",
//     paddingLeft: "15%",
//     width: "60vw",
//     minWidth: "600px",
//     height: "80vh",
//   },
//   contentFrame: {
//     marginTop: "10px",
//     height: "85vh",
//     overflowY: "auto",
//   },
//   searchArea: {
//     border: "solid",
//     flex: 1,
//     margin: "auto",
//     alignItems: "center",
//     width: "60vw",
//     backgroundColor: "#fff",
//     paddingBottom: "15px",
//   },
//   searchItem: {
//     marginTop: "5px",
//     marginLeft: "10px",
//     marginBottom: "10px",
//   },
//   unCheckButton: {
//     width: "100px",
//     color: "black",
//     backgroundColor: "#F2F2F2",
//     "&:hover": {},
//   },
//   checkedButton: {
//     width: "100px",
//     color: "#ffffff",
//     outlineColor: "#3e3e3e",
//     backgroundColor: "#4dc0b2",
//     "&:hover": {
//       backgroundColor: "#4ab5a1",
//     },
//   },
//   excuteButton: {
//     color: "#ffffff",
//     backgroundColor: "#4dc0b2",
//     fontSize: "20px",
//     marginLeft: "4vw",
//     "&:hover": {
//       backgroundColor: "#4ab5a1",
//     },
//   },
//   tableBottom: {
//     marginTop: "20px",
//     marginBottom: "20px",
//     padding: "1px",
//     width: "80vw",
//   },
// }));

function setSelectItem(code: string, name: string) {
  return { code, name };
}

let aggregation = false;

const FilingStatusConfirm: React.FC = (props: any) => {
  // const classes = useStyles();
  const user = useSelector(selectUser);
  const stateMonthList = useSelector(selectCheckFilingMonthList);
  const stateDataList = useSelector(selectCheckFilingDataList);
  const [click, setClick] = React.useState(false);
  const [aggregationType, setAggregationType] = React.useState(true);
  const [executeFlg, setExecuteFlg] = React.useState("");
  const [selectedBaseDate, setSelectedBaseDate] =
    React.useState<DateTime | null>(DateTime.local());
  const [convertedFirstValue, setConvertedFirstValue] = React.useState<
    string | null
  >(DateTime.local().toFormat("yyyy/MM/dd HH:mm:ss"));
  const [searchButtonFlg, setsearchButtonFlg] = React.useState(true);
  const [areaName, setAreaName] = React.useState("");
  const dispatch = useDispatch();
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);
  const [csvColumnsList, setCsvColumnsList] = React.useState<any[]>([]);

  const dialogClose = () => {
    setDialogFlg(false);
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    // matelial-tableの表示を更新
    setOpen(false);
  };
  const handleBaseDate = (date: DateTime | null) => {
    setSelectedBaseDate(date);
    date !== null &&
      setConvertedFirstValue(date.toFormat("yyyy/MM/dd HH:mm:ss"));
  };

  const aggregationCheck = (flag: boolean) => {
    setAggregationType(flag);
  };

  const handleClick = () => {
    setClick((prev) => !click);
  };

  // 初期表示処理
  useEffect(() => {
    userLogging("ＱＣ工場チェック", "提出状況確認#初期表示", "");
    dispatch(
      updateAreaName({
        areaName: "",
      })
    );
    if (stateDataList.length === 0) {
      // 初期検索
      if (user.jobCategory === "dm") {
        doExecute(0);
      } else if (user.jobCategory === "zm") {
        doExecute(1);
      }
    } else {
      setExecuteFlg("1");
    }
  }, [dispatch]);

  // 検索ボタンの制御
  // useEffect(() => {
  //   if (areaName !== "") {
  //     setsearchButtonFlg(true);
  //   } else {
  //     setsearchButtonFlg(false);
  //   }
  // }, [areaName]);  
  
  // 画面表示可否
  if (!isFirebaseAuth()) {
    return <></>;
  }
  if (!isOpenStatusConfirmPage(user)) {
    return <Redirect to="/home" />;
  }

  // コード順に並べ変え
  function sortCode(dataMapList: any[]) {
    let mapList: any[] = dataMapList;
    return mapList.sort(function (first, second) {
      if (first.docode > second.docode) {
        return 1;
      } else if (first.docode < second.docode) {
        return -1;
      } else {
        if (first.code > second.code) {
          return 1;
        } else if (first.code < second.code) {
          return -1;
        } else {
          return 0;
        }
      }
    });
  }

  function setTargetDateList(MonthList: any[]) {
    let columns: any[] = [];
    let csvColumns: any[] = [];
    csvColumns = [
      {
        title: "DO",
        field: "doname",
        type: "numeric",
      },
    ];
    columns = [
      {
        title: MonthList[0],
        field: "name",
        cellStyle: {
          width: "300px",
        },
      },
      {
        title: MonthList[1],
        field: "tenpo_count",
        type: "numeric",
        align: "center",
      },
    ];

    for (let i = 2; i < MonthList.length; i++) {
      let no = MonthList.length - i + 1;
      columns.push({
        title: MonthList[no],
        field: "result" + Number(no - 1),
        align: "center",
        type: "numeric",
        sorting: false,
      });
    }

    for (let i = 0; i < columns.length; i++) {
      csvColumns.push(columns[i]);
    }
    setCsvColumnsList(csvColumns);

    return columns;
  }
  
  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter" && searchButtonFlg) {
      doExecute();
    }
  }

  // 実行ボタン押下時処理
  const doExecute = (initType?: number) => {
    if (initType && user.zoCode.length > 1) return;
    aggregation = aggregationType;
    const apiParams = {
      "api-name": "submit-check",
      aggregation_type: aggregationType ? "1" : "0",
      target_date: convertedFirstValue,
      areaName: areaName
    };
    // 初期検索
    let initAggregationType = true;
    const url = process.env.REACT_APP_API_URL + "tenpocheck/submit-check";

    userLogging("ＱＣ工場チェック", "提出状況確認#検索", JSON.stringify(apiParams));
    setExecuteFlg("2");
    // postRequest(url, apiParams)
    //   .then((res) => {
        // let dataList: any[] = res.data.dataList;
        let dataList = 
          {
            // "link_list": [
            //   {
            // "resultid1": ["123123", "4526456"], 没・予実イメージ
            //   }
            // ],
            "month_list": [
                {
                    "month1": "2023/01/01",
                    "month2": "2023/02/01",
                    "month3": "2023/03/01",
                    "month4": "2023/04/01",
                    "month5": "2023/05/01",
                    "month6": "2023/06/01",
                    "month7": "2023/07/01",
                    "month8": "2023/08/01",
                    "month9": "2023/09/01",
                    "month10": "2023/10/01",
                    "month11": "2023/11/01",
                    "month12": "2023/12/01",
                }
            ],
            "result_list": [
              {
                "id":"13876001",
                "qc_area_name": "北海道",
                "qc_area_code": "01",
                "vendor_name": "㈱菊水",
                "vendor_code": "13876",
                "factory_name": "サッポロファクトリー",
                "factory_code": "001",
                // "result1": "0/3",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"10790002",
                "qc_area_name": "北海道",
                "qc_area_code": "01",
                "vendor_name": "北海道ローズデリカ㈱",
                "vendor_code": "10790",
                "factory_name": "本社工場",
                "factory_code": "002",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"13994036",
                "qc_area_name": "北海道",
                "qc_area_code": "01",
                "vendor_name": "わらべや日洋食品㈱",
                "vendor_code": "13994",
                "factory_name": "札幌工場",
                "factory_code": "036",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"13758001",
                "qc_area_name": "北海道",
                "qc_area_code": "01",
                "vendor_name": "㈱ピックルスコーポレーション札幌",
                "vendor_code": "13758",
                "factory_name": "本社工場",
                "factory_code": "001",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"13787001",
                "qc_area_name": "北海道",
                "qc_area_code": "01",
                "vendor_name": "㈱弁釜",
                "vendor_code": "13787",
                "factory_name": "札幌工場",
                "factory_code": "001",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"13787011",
                "qc_area_name": "北海道",
                "qc_area_code": "01",
                "vendor_name": "㈱弁釜",
                "vendor_code": "13787",
                "factory_name": "札幌八軒工場",
                "factory_code": "011",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"11216007",
                "qc_area_name": "北海道",
                "qc_area_code": "01",
                "vendor_name": "㈱エフビーエス",
                "vendor_code": "11216",
                "factory_name": "札幌工場",
                "factory_code": "007",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"13787007",
                "qc_area_name": "北海道",
                "qc_area_code": "01",
                "vendor_name": "㈱弁釜",
                "vendor_code": "13787",
                "factory_name": "函館工場",
                "factory_code": "007",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"12718001",
                "qc_area_name": "北海道",
                "qc_area_code": "01",
                "vendor_name": "ライラック・フーズ㈱",
                "vendor_code": "12718",
                "factory_name": "本社工場",
                "factory_code": "001",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"13994044",
                "qc_area_name": "北海道",
                "qc_area_code": "01",
                "vendor_name": "わらべや日洋食品㈱",
                "vendor_code": "13994",
                "factory_name": "釧路工場",
                "factory_code": "044",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"13787012",
                "qc_area_name": "北海道",
                "qc_area_code": "01",
                "vendor_name": "㈱弁釜",
                "vendor_code": "13787",
                "factory_name": "旭川工場",
                "factory_code": "012",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"13994051",
                "qc_area_name": "東北",
                "qc_area_code": "02",
                "vendor_name": "わらべや日洋食品㈱",
                "vendor_code": "13994",
                "factory_name": "岩手工場",
                "factory_code": "051",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"12977031",
                "qc_area_name": "東北",
                "qc_area_code": "02",
                "vendor_name": "フジフーズ㈱",
                "vendor_code": "12977",
                "factory_name": "岩手工場",
                "factory_code": "031",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"15013020",
                "qc_area_name": "東北",
                "qc_area_code": "02",
                "vendor_name": "㈱武蔵野",
                "vendor_code": "15013",
                "factory_name": "仙台工場",
                "factory_code": "020",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"12977016",
                "qc_area_name": "東北",
                "qc_area_code": "02",
                "vendor_name": "フジフーズ㈱",
                "vendor_code": "12977",
                "factory_name": "ベーカリー仙台工場",
                "factory_code": "016",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"12977006",
                "qc_area_name": "東北",
                "qc_area_code": "02",
                "vendor_name": "フジフーズ㈱",
                "vendor_code": "12977",
                "factory_name": "仙台工場",
                "factory_code": "006",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"13706007",
                "qc_area_name": "東北",
                "qc_area_code": "02",
                "vendor_name": "㈱ピックルスコーポレーション",
                "vendor_code": "13706",
                "factory_name": "宮城ファクトリー",
                "factory_code": "007",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"10458003",
                "qc_area_name": "東北",
                "qc_area_code": "02",
                "vendor_name": "㈱ニッセーデリカ",
                "vendor_code": "10458",
                "factory_name": "福島工場",
                "factory_code": "003",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"13592001",
                "qc_area_name": "東北",
                "qc_area_code": "02",
                "vendor_name": "㈱デイリー開発福島",
                "vendor_code": "13592",
                "factory_name": "本社工場",
                "factory_code": "001",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"12977013",
                "qc_area_name": "東北",
                "qc_area_code": "02",
                "vendor_name": "フジフーズ㈱",
                "vendor_code": "12977",
                "factory_name": "福島工場",
                "factory_code": "013",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"15013016",
                "qc_area_name": "東北",
                "qc_area_code": "02",
                "vendor_name": "㈱武蔵野",
                "vendor_code": "15013",
                "factory_name": "福島工場",
                "factory_code": "016",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"13994030",
                "qc_area_name": "東北",
                "qc_area_code": "02",
                "vendor_name": "わらべや日洋食品㈱",
                "vendor_code": "13994",
                "factory_name": "福島工場",
                "factory_code": "030",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
              {
                "id":"11096002",
                "qc_area_name": "東北",
                "qc_area_code": "02",
                "vendor_name": "㈱リバティーフーズ",
                "vendor_code": "11096",
                "factory_name": "福島工場",
                "factory_code": "002",
                "result1": "0",
                "result2": "0",
                "result3": "0",
                "result4": "0",
                "result5": "0",
                "result6": "0",
                "result7": "0",
                "result8": "0",
                "result9": "0",
                "result10": "0",
                "result11": "0",
                "result12": "0",
                "qc_name": "小野 佑輔",
                "qc_mailaddress": "ono-yuusuke@sej.7andi.co.jp",
                "manager_name": "原",
                "manager_mailaddress": "hara@sej.7andi.co.jp",
              },
            ]
          };
        if (dataList) {
          // for (let i = 0; i < dataList.length; i++) {
          //   let datajsList: any = dataList[i];
          //   let resWeeks: any = datajsList.week_list[0];
          //   let resResultList: any[] = datajsList.result_list;
          //   let resultMonthList: any[] = [];
          //   let resultDataList: any[] = [];
          //   if (initType) {
          //     if (initAggregationType) {
          //       resultMonthList.push("提出者");
          //     } else {
          //       resultMonthList.push("店舗名");
          //     }
          //   } else {
          //     if (aggregationType) {
          //       resultMonthList.push("提出者");
          //     } else {
          //       resultMonthList.push("店舗名");
          //     }
          //   }
          //   resultMonthList.push("店舗数");
          //   resultMonthList.push(resWeeks.week1);
          //   resultMonthList.push(resWeeks.week2);
          //   resultMonthList.push(resWeeks.week3);
          //   resultMonthList.push(resWeeks.week4);
          //   resultMonthList.push(resWeeks.week5);
          //   resultMonthList.push(resWeeks.week6);
          //   resultMonthList.push(resWeeks.week7);
          //   for (let j = 0; j < resResultList.length; j++) {
          //     resultDataList.push({
          //       zoname: resResultList[j].zoname || "",
          //       doname: resResultList[j].doname || "",
          //       name: resResultList[j].name,
          //       tenpo_count: resResultList[j].tenpo_count,
          //       result7: resResultList[j].result7,
          //       result6: resResultList[j].result6,
          //       result5: resResultList[j].result5,
          //       result4: resResultList[j].result4,
          //       result3: resResultList[j].result3,
          //       result2: resResultList[j].result2,
          //       result1: resResultList[j].result1,
          //       code: resResultList[j].code,
          //       docode: resResultList[j].docode,
          //       ofc_mailaddress: resResultList[j].ofc_mailaddress,
          //     });
          //   }
          //   sortCode(resultDataList);

            // let checkFilingMonthList = setTargetDateList(resultMonthList);
            dispatch(
              updateCheckFilingMonthList({
                checkFilingMonthList: dataList.month_list,
              })
            );
            dispatch(
              updateCheckFilingDataList({ checkFilingDataList: dataList.result_list })
            );
          // }
        }
        setExecuteFlg("1");
        handleClick();
      // })
      // .catch((r) => {
      //   setDialogMessage([String(r)]);
      //   setDialogFlg(true);
      //   setExecuteFlg("0");
      // })
      // .finally(() => {
      //   handleClick();
      // });

    handleClose();
    handleClick();
  };

  // 検索ボタン押下時処理
  function saveStatusBottun(
    argAreaCode: string,
    argAreaName: string,
  ) {
    dispatch(
      updateAreaCode({
        areaCode: argAreaCode,
      })
    );
    dispatch(
      updateAreaName({
        areaName: argAreaName,
      })
    );
    setAreaName(argAreaName);
  }

  function clickSearch(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    handleOpen();
  }
  return (
    <>
      <ModalPaneModal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        // className={classes.modalPane}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyUp(e)}
      >
        <SearchAreaGrid
          container 
          alignItems="flex-start"
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        // className={classes.searchArea}
        >
          {/*検索欄*/}
          <SearchHeaderGrid
            container
            justifyContent="center"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            // justify="center"
            // alignItems="center"
            // style={{
            //   color: "#FFFFFF",
            //   backgroundColor: "#37AB9D",
            //   fontWeight: "bolder",
            //   textAlign: "center",
            //   height: "40px",
            // }}
          >
            条件変更
          </SearchHeaderGrid>
          {/*L1*/}
          <SearchSectionGrid
            container
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                // style={{
                //   paddingTop: "15px",
                //   paddingLeft: "15px",
                //   fontWeight: "bolder",
                // }}
              >
                地区名を選択
          </SearchSectionGrid>
          <SearchFactoryArea
            // useStyles={stylesSearchDO}
            disabled={false}
            firstView={false}
            initialDisplay={true}
            onClickFunc={(areaCode: string, areaName: string) => {
              // saveStatusBottun(factoryId, tenpoName, "", "", "", "", "", "");
              saveStatusBottun("", areaName);
            }}
          />
          <SearchSectionGrid
            container
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                // style={{
                //   paddingTop: "15px",
                //   paddingLeft: "15px",
                //   fontWeight: "bolder",
                // }}
          >
            基準日を選択
            <Tooltip title="※基準日の週より過去7週分を表示します">
              <InfoIcon fontSize="small"></InfoIcon>
            </Tooltip>
          </SearchSectionGrid>
          <SearchItemGrid
            container
            // className={classes.searchItem}
            justifyContent="space-evenly"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <SejDatePicker
              selectedDate={selectedBaseDate}
              dateFunc={(date: DateTime | null) => {
                handleBaseDate(date);
              }}
              maxDate={DateTime.local()}
              // minDate={DateTime.local().minus(0)}
              mainColor={"#4dc0b2"}
            />
          </SearchItemGrid>
          <SearchItemGrid
            container
            // className={classes.searchItem}
            justifyContent="center"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <SearchButton
              // className={classes.excuteButton}
              variant="contained"
              color="primary"
              disabled={!searchButtonFlg}
              onClick={() => doExecute()}
            >
              検索
            </SearchButton>
          </SearchItemGrid>
        </SearchAreaGrid>
      </ModalPaneModal>

      <Grid container 
      // className={classes.root} justify="center"
      >
        <Grid
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Header
            titleName={"工場チェック提出状況確認"}
            modalCondition={(e) => clickSearch(e)}
          />
          {/* メインコンテンツ↓ここから */}
          <Grid
            container
            justifyContent="center"
            alignItems="space-evenly"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            // className={classes.contentFrame}
          >
            {executeFlg === "1" ? (
              <>
              <FactoryCheckFilingStatusConfirmResult
                record={JSON.parse(JSON.stringify(stateDataList))}
              />
              </>
            ) : // 実行中の表示
            executeFlg === "2" ? (
              <>
              <OverLayer/>
              <LoadingCircule
                size={"40vh"}
              />
              </>
            ) : (
              <SeparatorGrid>
                右上の虫眼鏡アイコンから検索条件を指定してください。
              </SeparatorGrid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* ダイアログ */}
      <FactoryCheckDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogClose}
        doNo={dialogClose}
        isAlert={true}
      />
    </>
  );
};

export default FilingStatusConfirm;

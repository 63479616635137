import React, { useEffect, useRef, FC } from "react";

import {
  Modal,
  Button,
  TextareaAutosize,
  Typography,
  Select,
  MenuItem,
  TextField,
  IconButton,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

import { getFixedFlg } from "../../Header/Header";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from '@mui/icons-material/Add';
import {
  deleteFactoryCheckTemplate,
  getTemplateData,
  TEMPLATE_ITEM,
  FACTORY_CHECK_ITEM_TEMPLATE,
  updateFactoryCheckTemplate,
  FACTORY_CHECK_RECORD,
  FACTORY_CHECK_ADD_NUMBER,
  FACTORY_CHECK_COMMENTS,
  defaultComments,
} from "./FactoryCheckDataOperation";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";
import { v4 as uuidv4 } from "uuid";
import FactoryCheckDialog from "../FactoryCheckDialog";
import { userLogging } from "../../../apis/userLog";
import FactoryCheckModalButton from "./FactoryCheckModalButton";
import {
  SeparatorGrid,
  SeparatorLeftGrid,
} from "../Styled/FactoryCheckStyled";

const ModalPaneMain = styled(Modal)(({ theme }) => ({
  position: "absolute",
  paddingTop: "2%",
  paddingLeft: "5%",
  width: "60%",
  //xl, extra-large: 1536px
  [theme.breakpoints.up('xl')]: {
    height: "65%",
  },
  [theme.breakpoints.down('xl')]: {
    height: "60%",
  },
  //lg, large: 1200px
  [theme.breakpoints.down('lg')]: {
    height: "75%",
  },
  //md, medium: 900px
  [theme.breakpoints.down('md')]: {
    height: "55%",
  },
}));

const ModalPaneSub = styled(Modal)(({ theme }) => ({
  position: "absolute",
  paddingTop: "2%",
  paddingLeft: "5%",
  width: "60%",
  height: "60%",
}));

const PaperGrid = styled(Grid)(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
}));

const OverFlowGrid = styled(Grid)(({ theme }) => ({
  overflowY: "auto",
  height: "50%",
}));

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     modalPane: {
//       position: "absolute",
//       paddingTop: "2%",
//       paddingLeft: "20%",
//       width: "60%",
//       height: "60%",
//       overflowX: "hidden",
//     },
//     modalPane2: {
//       position: "absolute",
//       paddingTop: "2%",
//       paddingLeft: "20%",
//       width: "60%",
//       height: "100%",
//       overflowX: "hidden",
//     },
//     paper: {
//       height: "100%",
//       backgroundColor: theme.palette.background.paper,
//       border: "2px solid #000",
//       boxShadow: theme.shadows[5],
//       padding: theme.spacing(2, 4, 3),
//     },
//     paper2: {
//       height: "62%",
//       minHeight: "60%",
//       backgroundColor: theme.palette.background.paper,
//       border: "2px solid #000",
//       boxShadow: theme.shadows[5],
//       padding: theme.spacing(2, 4, 3),
//     },
//     titleGrid: {
//       height: "7vh",
//       color: "white",
//       backgroundColor: "#00AC4C",
//     },
//     titleTypography: {
//       fontWeight: "bold",
//     },
//     buttonSpace: {
//       paddingRight: "15px",
//     },
//     greenButton: {
//       backgroundColor: "#00CC5B",
//       color: "#fff",
//       "&:hover": {
//         backgroundColor: "#00CC5B",
//       },
//     },
//     redButton: {
//       backgroundColor: "#E60012",
//       color: "#fff",
//       "&:hover": {
//         backgroundColor: "#E60012",
//       },
//     },
//   })
// );
const FactoryCheckTemplateModalButton: FC<{
  onClickOK?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    recordList: any[]
  ) => void;
}> = ({ onClickOK }) => {
  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [itemText, setItemText] = React.useState("");
  const [editFlg, setEditFlg] = React.useState(false);

  const user = useSelector(selectUser);
  const [targetTemplateTitle, setTargetTemplateTitle] = React.useState("");
  const [templateList, setTemplateList] = React.useState<
    FACTORY_CHECK_ITEM_TEMPLATE[]
  >([]);
  const [targetTemplate, setTargetTemplate] =
    React.useState<FACTORY_CHECK_ITEM_TEMPLATE>();
  const [tmpTargetTemplate, setTmpTargetTemplate] =
    React.useState<FACTORY_CHECK_ITEM_TEMPLATE>();
  const [registedTemplate, setRegistedTemplate] =
    React.useState<FACTORY_CHECK_ITEM_TEMPLATE>();

  const [click, setClick] = React.useState(false);
  const [limited, isLimited] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [reload, setReload] = React.useState(false);

  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);
  const [dialogFlg2, setDialogFlg2] = React.useState(false);

  // テンプレート削除
  const deleteTemplate = () => {
    if (targetTemplate) {
      deleteFactoryCheckTemplate(targetTemplate.doc_id).then(() => {
        setReload(true);
        setTargetTemplateTitle("");
        setTmpTargetTemplate(undefined);
        setLoad(!load);
        refreshItemString();
        setItemText("");
        setTargetTemplate(undefined);
      });
    }
    setDialogFlg2(false);
  };

  const dialogClose = () => {
    setDialogFlg(false);
  };
  const dialogClose2 = () => {
    setDialogFlg2(false);
  };

  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const [scrollSwitch, setScrollSwitch] = React.useState(false);

  useEffect(() => {
    refreshItemString();
    setClick(!click);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetTemplate]);

  // データ取得
  useEffect(() => {
    let conditions: any[] = ["all"];
    if (user.doCode) {
      for (const code of user.doCode) {
        conditions.push(code);
      }
    }

    getTemplateData(conditions).then(
      (response: FACTORY_CHECK_ITEM_TEMPLATE[]) => {
        let tmplateList: any[] = [];

        for (const res of response) {
          tmplateList.push(res);
          // 登録時のリロード処理
          if (targetTemplate && targetTemplate.doc_id === res.doc_id) {
            setTargetTemplate(res);
            if (reload && registedTemplate) {
              let ckResult = true;
              if (registedTemplate.template_name !== res.template_name) {
                ckResult = false;
              }
              if (
                registedTemplate.small_items.length !== res.small_items.length
              ) {
                ckResult = false;
              } else {
                for (let i = 0; i < registedTemplate.small_items.length; i++) {
                  if (
                    registedTemplate.small_items[i].name !==
                    res.small_items[i].name
                  ) {
                    ckResult = false;
                    break;
                  }
                }
              }

              if (ckResult) {
                setRegistedTemplate(undefined);
                setReload(false);
              } else {
                setLoad(!load);
              }
            } else {
              setReload(false);
            }
          }
        }
        setTemplateList(tmplateList);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);

  useEffect(() => {
    scrollBottomRef?.current?.scrollIntoView(false);
  }, [scrollSwitch]);

  const handleOpen = () => {
    setLoad(!load);
    setTargetTemplateTitle("");
    setTargetTemplate(undefined);
    setItemText("");
    setOpen(true);
  };

  const onClickPhotoButton = (
    row: TEMPLATE_ITEM,
    tempComments: Array<FACTORY_CHECK_COMMENTS>,
  ) => {
    row.comments = tempComments;
    // setChangeFlg(true);
    // handleClick();
    setClick(!click);
  };

  // テンプレート名編集
  const changeTemplateTitle = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    let comment = event.target.value as string;
    if (comment && Number(comment.length) > 100) {
      comment = comment.substring(0, 100);
    }
    setTargetTemplateTitle(comment);
    setClick(!click);
  };

  // テンプレート項目名編集
  const changeTemplateItem = (
    event: React.ChangeEvent<{ value: unknown }>,
    row: TEMPLATE_ITEM
  ) => {
    let comment = event.target.value as string;
    if (comment && Number(comment.length) > 100) {
      comment = comment.substring(0, 100);
    }
    row.name = comment;
    setClick(!click);
  };

  // テンプレート登録・編集入力内容チェック
  const checkInputTemplate = () => {
    if (!targetTemplateTitle) {
      return false;
    }
    if (tmpTargetTemplate) {
      if (tmpTargetTemplate.small_items.length < 1) {
        return false;
      }

      for (const item of tmpTargetTemplate.small_items) {
        if (!item.name) {
          return false;
        }
      }
    } else {
      return false;
    }
    return true;
  };
  // 登録・更新ボタン
  const handleRegist = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    userLogging("店舗確認表", "新規作成#テンプレート登録・更新ボタン", "");
    if (!checkInputTemplate()) {
      setDialogMessage(["未入力項目があります。入力内容を確認してください"]);
      setDialogFlg(true);
      return;
    }
    if (tmpTargetTemplate) {
      sortIdTmp();
      let docId = "";
      let inputId = "";
      let inputConditions: string[] = ["all"];
      let inputBigItemId = FACTORY_CHECK_ADD_NUMBER;
      let newFlg = false;
      // 更新の場合
      if (editFlg) {
        inputId = tmpTargetTemplate.id;
        docId = tmpTargetTemplate.doc_id;
        inputConditions = tmpTargetTemplate.conditions;
        inputBigItemId = Number(tmpTargetTemplate.big_item_id);
      } else {
        // 登録の場合
        newFlg = true;
        let firstDoCode = "";
        if (user.doCode) {
          for (const code of user.doCode) {
            firstDoCode = code;
            inputConditions.push(code);
          }
        }
        let uuid = uuidv4();
        inputId = firstDoCode + "-" + uuid;
        docId = firstDoCode + "-" + uuid;
      }
      const registTargetTemplate: FACTORY_CHECK_ITEM_TEMPLATE = {
        id: inputId,
        doc_id: docId,
        template_name: targetTemplateTitle,
        big_item_id: inputBigItemId,
        big_item_name: "その他",
        conditions: inputConditions,
        small_items: tmpTargetTemplate.small_items,
        info: tmpTargetTemplate.info,
      };
      setRegistedTemplate(registTargetTemplate);
      updateFactoryCheckTemplate(registTargetTemplate, newFlg).then(() => {
        setReload(true);
        setTargetTemplate(registTargetTemplate);
        setTmpTargetTemplate(registTargetTemplate);
        setLoad(!load);
        refreshItemString();
        setOpen2(false);
      });
    }
  };

  // 反映ボタン
  const handleSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    userLogging("店舗確認表", "新規作成#テンプレート項目反映ボタン", "");
    let addRowItemList: any[] = [];
    if (targetTemplate) {
      for (const item of targetTemplate.small_items) {
        addRowItemList.push({
          name: item.name,
          uuid: item.uuid,
          comments: item.comments,
          // photo_filepath_gcs: item.photo_filepath_gcs,
          // user_input_comment: item.user_input_comment,
          // comment_filepath_gcs: item.comment_filepath_gcs,
          // comment_handwrite_flag: item.comment_handwrite_flag,
        });
      }
      if (onClickOK) {
        onClickOK(e, addRowItemList);
      }
      setOpen(false);
      setTargetTemplate(initTemplate());
    }
  };
  // キャンセル処理
  const handleClose = () => {
    userLogging("店舗確認表", "新規作成#テンプレートキャンセルボタン", "");
    if (open2) {
      if (targetTemplate) {
        setTmpTargetTemplate(targetTemplate);
        setTargetTemplateTitle(targetTemplate.template_name);
      }
      setOpen2(false);
    } else if (open) {
      setOpen(false);
      setTargetTemplate(initTemplate());
    }
  };

  // テンプレート選択
  const doSelChange = (event: SelectChangeEvent<string>) => {
    userLogging("店舗確認表", "新規作成#テンプレート選択ボタン", "");
    let value = event.target.value;
    for (let i = 0; i < templateList.length; i++) {
      if (templateList[i].id === value) {
        setTargetTemplateTitle(templateList[i].template_name);
        setTargetTemplate(templateList[i]);
        break;
      }
    }
  };

  const refreshItemString = () => {
    let itemString = "";
    if (targetTemplate) {
      for (let i = 0; i < targetTemplate.small_items.length; i++) {
        if (itemString) {
          itemString += "\n";
        }
        itemString += "・" + targetTemplate.small_items[i].name;
      }
    }
    setItemText(itemString);
  };

  // 追加
  const clickAdd = () => {
    userLogging("店舗確認表", "新規作成#テンプレート追加ボタン", "");
    if (tmpTargetTemplate) {
      tmpTargetTemplate.small_items.push(
        {
          id: 0,
          name: "",
          uuid: uuidv4(),
          comments: [defaultComments],
          // photo_filepath_gcs: [],
          // user_input_comment: "",
          // comment_filepath_gcs: [],
          // comment_handwrite_flag: ""
        }
      );
      if (tmpTargetTemplate.small_items.length > 19) {
        isLimited(true);
      }
    }
    sortIdTmp();
    setClick(!click);
    setScrollSwitch(!scrollSwitch);
  };
  // 削除
  const clickDelete = (row: TEMPLATE_ITEM) => {
    userLogging("店舗確認表", "新規作成#テンプレート削除ボタン", "");
    if (tmpTargetTemplate) {
      let smallItem = [];
      for (let i = 0; i < tmpTargetTemplate.small_items.length; i++) {
        if (tmpTargetTemplate.small_items[i].id !== row.id) {
          smallItem.push(tmpTargetTemplate.small_items[i]);
        }
      }
      tmpTargetTemplate.small_items = smallItem;
      if (tmpTargetTemplate.small_items.length < 20) {
        isLimited(false);
      }
    }
    sortIdTmp();
    setClick(!click);
  };

  function sortIdTmp() {
    const newUuid = () => {
      return String(Math.random);
    };
    if (tmpTargetTemplate) {
      let smallItems: TEMPLATE_ITEM[] = [];
      for (let i = 0; i < tmpTargetTemplate.small_items.length; i++) {
        smallItems.push({
          id: i + 1,
          name: tmpTargetTemplate.small_items[i].name,
          uuid: tmpTargetTemplate.small_items[i].uuid || newUuid(),
          comments: [defaultComments],
          // photo_filepath_gcs: [],
          // user_input_comment: "",
          // comment_filepath_gcs: [],
          // comment_handwrite_flag: "",
        });
        tmpTargetTemplate.small_items[i].id = i + 1;
      }
    }
  }

  const initTemplate = () => {
    let template: FACTORY_CHECK_ITEM_TEMPLATE = {
      id: "",
      doc_id: "",
      template_name: "",
      conditions: [],
      big_item_id: 0,
      big_item_name: "",
      small_items: [],
      info: {
        create_date: null,
        create_user: "",
        update_date: null,
        update_user: "",
      },
    };
    return template;
  };
  // 新規登録ボタン
  const clickNew = () => {
    userLogging("店舗確認表", "新規作成#テンプレート新規登録ボタン", "");
    let initTemp = initTemplate();
    let itemList: TEMPLATE_ITEM[] = [
      {
        id: 0,
        name: "",
        uuid: uuidv4(),
        comments: [defaultComments],
      }
    ];
    initTemp.small_items = itemList;
    setTargetTemplateTitle("");
    setTmpTargetTemplate(initTemp);
    setEditFlg(false);
    setOpen2(true);
    isLimited(false);
  };
  // 編集ボタン
  const clickEdit = () => {
    userLogging("店舗確認表", "新規作成#テンプレート編集ボタン", "");
    if (targetTemplate) {
      let tmpSmallItems: TEMPLATE_ITEM[] = [];

      for (const item of targetTemplate.small_items) {
        tmpSmallItems.push(item);
      }
      let tmpTemp: FACTORY_CHECK_ITEM_TEMPLATE = {
        id: targetTemplate.id,
        doc_id: targetTemplate.doc_id,
        template_name: targetTemplate.template_name,
        conditions: targetTemplate.conditions,
        big_item_id: targetTemplate.big_item_id,
        big_item_name: targetTemplate.big_item_name,
        small_items: tmpSmallItems,
        info: targetTemplate.info,
      };
      setTmpTargetTemplate(tmpTemp);
      setEditFlg(true);
      setOpen2(true);
      isLimited(tmpSmallItems.length >= 20);
    }
  };
  // テンプレート削除ボタン
  const clickDeleteTemplate = () => {
    userLogging("店舗確認表", "新規作成#テンプレート削除ボタン", "");
    if (targetTemplate) {
      setDialogMessage([
        "選択中のテンプレートを削除します。",
        "よろしいですか？",
      ]);
      setDialogFlg2(true);
      return;
    }
  };

    // const onPhotoClick = (
    //   row: TEMPLATE_ITEM,
    //   e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    //   comment: string,
    //   filePath: string[],
    //   commentFilepathGcs: string[],
    //   commentHandwriteFlag: string,
    // ) => {
    //   userLogging("店舗確認表", "テンプレート#写真ダイアログ保存ボタン", "");
    //   row.photo_filepath_gcs = filePath;
    //   row.user_input_comment = comment;
    //   row.comment_filepath_gcs = commentFilepathGcs;
    //   row.comment_handwrite_flag = commentHandwriteFlag;
    // };

  return (
    <>
      {/*  ボタンここから↓*/}
      <Button variant="contained" onClick={handleOpen}>
        テンプレート
      </Button>
      {/*  ボタンここまで↑*/}

      {/*  登録・更新モーダルここから↓*/}
      <ModalPaneSub
        open={open2}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        // className={classes.modalPane2}
      >
        <PaperGrid 
          container 
          // className={classes.paper2}
        >
          {/* 登録画面エラーダイアログ */}
          <FactoryCheckDialog
            msg={dialogMessage}
            isOpen={dialogFlg}
            doOK={dialogClose}
            doNo={dialogClose}
            isAlert={true}
          />
          {/*  ヘッダータイトルここから↓*/}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            // className={classes.titleGrid}
          >
            <Typography variant="h6" 
              // className={classes.titleTypography}
            >
              {editFlg ? "テンプレート編集" : "テンプレート新規登録"}
            </Typography>
          </Grid>
          {/*  ヘッダータイトルここまで↑*/}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            // style={{
            //   display: "flex",
            //   alignSelf: "flex-start",
            //   paddingTop: "10px",
            // }}
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <TextField
              placeholder="テンプレート名を入力"
              size="small"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "300px" }}
              value={targetTemplateTitle}
              onChange={(e) => changeTemplateTitle(e)}
            />
          </Grid>
          <OverFlowGrid
            // style={{
            //   overflowY: "auto",
            //   overflowX: "hidden",
            //   width: "100%",
            //   height: "50%",
            // }}
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            {tmpTargetTemplate
              ? tmpTargetTemplate.small_items.map((row) => (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      display: "flex",
                      alignSelf: "flex-start",
                      paddingTop: "10px",
                    }}
                  >
                    <Grid
                      {...{ xs: 10, sm: 10, md: 10, lg: 10 }}
                    >
                      <TextField
                        placeholder="項目名を入力"
                        size="small"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ width: "35vw" }}
                        value={row.name}
                        onChange={(e) => changeTemplateItem(e, row)}
                      />
                    </Grid>
                    <Grid
                      {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
                      key={row.id + "_upload"}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        {/* <FactoryCheckModalButton
                          title={row.name}
                          comments={row.comments}
                          onClickSave={(tempComments) => {
                            onClickPhotoButton(row, tempComments);
                          }}
                        /> */}
                      </Grid>
                    </Grid>
                    <Grid
                      {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
                    >
                      {tmpTargetTemplate && tmpTargetTemplate.small_items && tmpTargetTemplate.small_items.length > 1 ? (
                      <IconButton onClick={() => clickDelete(row)}>
                        <CloseIcon />
                      </IconButton>
                      ) : <></>}
                    </Grid>
                  </Grid>
                ))
              : ""}
            <Grid
              container
              justifyContent="center"
              ref={scrollBottomRef}
            >
            </Grid>
          </OverFlowGrid>
          <Grid
            container
            // style={{
            //   display: "flex",
            //   alignSelf: "flex-start",
            //   paddingTop: "10px",
            // }}
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
            >
              {tmpTargetTemplate && tmpTargetTemplate.small_items && tmpTargetTemplate.small_items.length < 20 ? (
              <IconButton
                onClick={clickAdd}
                // disabled={limited}
                // className={classes.greenButton}
                size={"small"}
                // style={limited ? { color: "#fff", backgroundColor: "#C4C4C4" } : {}}
              >
                <AddIcon/>枠を追加
              </IconButton>
              ) : <></>}
            </Grid>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
            >
              <Typography variant="caption" display="block" gutterBottom>
                ※最大20項目まで追加可能です。
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <Grid
              {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
            >
              <Grid
                container
                justifyContent="center"
              >
                <Button
                  style={{ width: "20vw" }}
                  variant="contained"
                  fullWidth={true}
                  onClick={handleClose}
                >
                  キャンセル
                </Button>
              </Grid>
            </Grid>
            <Grid
              {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
            >
              <Grid
                container
                justifyContent="center"
              >
                {getFixedFlg() ? (
                  ""
                ) : (
                  <Button
                    style={{ width: "20vw" }}
                    variant="contained"
                    fullWidth={true}
                    onClick={(e) => handleRegist(e)}
                    color="primary"
                    // className={classes.greenButton}
                  >
                    {editFlg ? "更新" : "登録"}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </PaperGrid>
      </ModalPaneSub>
      {/*  登録・更新モーダル↑ここまで*/}

      {/*  テンプレート選択モーダルここから↓*/}
      <ModalPaneMain
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        // className={classes.modalPane}
      >
        <PaperGrid 
          container 
          justifyContent="center"
          alignItems="flex-start"
        // className={classes.paper}
        >
          {/* 削除確認ダイアログ */}
          <FactoryCheckDialog
            msg={dialogMessage}
            isOpen={dialogFlg2}
            doOK={deleteTemplate}
            doNo={dialogClose2}
            isAlert={false}
          />
          {/*  ヘッダータイトルここから↓*/}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            // className={classes.titleGrid}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              // className={classes.titleGrid}
            >
              <Typography 
                variant="h6" 
                // className={classes.titleTypography}
              >
                テンプレート選択
              </Typography>
            </Grid>
            {/*  ヘッダータイトルここまで↑*/}

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                display: "flex",
                alignSelf: "flex-start",
                paddingTop: "10px",
              }}
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <Select
                  style={{
                    width: "93%",
                    minWidth: 320,
                  }}
                  onChange={(event) => doSelChange(event)}
                  value={targetTemplate ? targetTemplate.id : ""}
                  disabled={templateList.length !== 0 ? false : true}
                >
                  {templateList.map((rowItem) => (
                    <MenuItem value={rowItem.id}>
                      {rowItem.template_name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <SeparatorGrid
                container
                justifyContent="center"
                alignItems="center"
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ paddingTop: "5px" }}
                  {...{ xs: 11, sm: 11, md: 11, lg: 11 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    // className={classes.buttonSpace + " " + classes.greenButton}
                    onClick={() => clickEdit()}
                    disabled={targetTemplate ? false : true}
                  >
                    編集
                  </Button>
                  <SeparatorLeftGrid/>
                  <Button
                    variant="contained"
                    color="primary"
                    // className={classes.buttonSpace + " " + classes.greenButton}
                    onClick={() => clickNew()}
                  >
                    新規登録
                  </Button>
                  <SeparatorLeftGrid/>
                  <Button
                    variant="contained"
                    // className={classes.buttonSpace + " " + classes.redButton}
                    onClick={() => clickDeleteTemplate()}
                    disabled={targetTemplate ? false : true}
                  >
                    削除
                  </Button>
                </Grid>
              </SeparatorGrid>
            </Grid>

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <TextareaAutosize
                value={itemText}
                aria-label="store-input-comment-area"
                minRows={20}
                style={{
                  width: "93%",
                  fontSize: "16px",
                  borderRadius: "8px",
                  margin: "10px",
                }}
              />
            </Grid>
            <SeparatorGrid/>
            <Grid 
              container 
              justifyContent="center"
              alignItems="center"
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <Grid
                {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
              >
                <Grid
                  container
                  justifyContent="center"
                >
                  <Button
                    variant="contained"
                    fullWidth={true}
                    style={{ width: "20vw" }}
                    onClick={handleClose}
                  >
                    キャンセル
                  </Button>
                </Grid>
              </Grid>
              <Grid
                {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
              >
                <Grid
                  container
                  justifyContent="center"
                >
                  {getFixedFlg() ? (
                    ""
                  ) : (
                  <Button
                    // className={classes.greenButton}
                    variant="contained"
                    fullWidth={true}
                    style={{ width: "20vw" }}
                    onClick={(e) => handleSave(e)}
                    disabled={targetTemplate ? false : true}
                  >
                    項目反映
                  </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </PaperGrid>
      </ModalPaneMain>
      {/*  テンプレート選択モーダル↑ここまで*/}
    </>
  );
};
export default FactoryCheckTemplateModalButton;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "./firebase";
import {
  login,
  setIsDeviceType,
  updateImageToken,
  selectIsIos,
} from "./features/userSlice";
import { useDeviceType } from "./apis/util";
import { postCommonRequest } from "./apis/axiosAction";
import {
  CircularProgress,
  styled,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { userLogging } from "./apis/userLog";
import { initializeExistingFactory } from "./data/existingFactory";
import firebase from "firebase/app";
import { useLocation } from "react-router-dom";
import { generateKeyPairRsaOaep, exportPublicKey, arrayBufferToBase64, decryptRsaOaepAndConcatenate } from './apis/cryptoUtils';

export const OverLayer = styled(Grid)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: "0",
  left: "0",
  backgroundColor: "#CCC",
  opacity: "0.5",
  zIndex: 100000,
}));

export const LoadingCircule = styled(CircularProgress)(({ theme }) => ({
  color: "#ccc",
  size: "40vh",
  top: "30%",
  position: "fixed",
}));

interface pathState {
  urlPath: string;
  state: string;
}

// URLのパス及びパラメータの調整を行う
const setUrlPath = (): pathState => {
  const url: URL = new URL(window.location.href);
  // URLパス対応(ログイン時とトップの場合はhomeへ進める)
  const path =
    url.pathname === "/login" || url.pathname === "/" ? "home" : url.pathname;
  const initial = "initial";
  const val: pathState = { urlPath: path, state: initial };
  return val;
};

const startMenu: pathState = setUrlPath();

const App: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const [isAnotherLoginLoading, setIsAnotherLoginLoading] =
    React.useState(false);
  // デバイスタイプの取得
  const isiOS = useSelector(selectIsIos);
  // デバイス・OSの判定を呼び出し
  useDeviceType();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const tokenPatamertername = "usertoken";
  const token = query.get(tokenPatamertername);

  useEffect(() => {
    const unSub = auth.onAuthStateChanged((authUser: any) => {
      if (authUser) {
        // 初期処理
        initialOperation(authUser);
      } else {
        if (process.env.REACT_APP_MODE === "develop" && token === null) {
          // 開発モードのみログイン画面へ遷移可能とする
          props.history.push("login");
        } else if (token !== null) {
          callCustomFirebaseAuthentication(token);
        } else {
          // Firebase認証されず、かつデジタルツールから遷移せずにトークンがない場合はCookie経由で認証にトライする
          callUserAuthAfterInitialLogin(true, false, false, false);
        }
      }
    });
    return () => {
      unSub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, props.history]);

  useEffect(() => {
    // 定期的にCookie更新とユーザー 情報更新処理を行う
    const intervalTime = 5 * 60 * 1000;
    setInterval(() => callUserAuthAfterInitialLogin(false, true, true, true), intervalTime);
  }, []);

  // 初期処理
  const initialOperation = async (authUser: any): Promise<void> => {
    // 初期処理の終了フロー
    const setFirstOperationFinish = () => {
      //  ウェイト表示解除
      setIsAnotherLoginLoading(false);
      //  メニュー表示
      props.history.push({
        pathname: startMenu.urlPath,
        state: startMenu.state,
      });
    };

    // ユーザーデータの取得
    setIsAnotherLoginLoading(true);
    userLogging("共通", "ログイン処理", "");
    dispatch(
      setIsDeviceType({
        deviceType: true,
        ios: true,
        iphone: true,
      })
    );
    await callUserAuthAfterInitialLogin(false, true, true, true);
    setFirstOperationFinish();
  };

  /**
   * Firebaseカスタム認証及びユーザー情報取得
   * トークン情報の再利用を防ぐために公開鍵方式を用いる
   * @param token デジタルツールにて生成されたワンタイムトークン
   */
  const callCustomFirebaseAuthentication = async (token: string): Promise<void> => {
    try {
      const url: string = process.env.REACT_APP_API_URL + "/users/user-auth";
      // Crypto Web APIでの公開鍵方式を用いて暗号化/複合化を行う
      const keyPair = await generateKeyPairRsaOaep();
      const publicKey = await exportPublicKey(keyPair);
      const publicKeyBase64 = arrayBufferToBase64(publicKey);
      const param = {
        "user_token": token,
        "public_key": publicKeyBase64
      }
      const response = await postCommonRequest(url, param);
      const obj = JSON.parse(response.data.data);
      const storageToken = await decryptRsaOaepAndConcatenate(obj["storage_token"], keyPair);
      const customToken = await decryptRsaOaepAndConcatenate(obj["custom_token"], keyPair);
      // Firebaseカスタム認証を行う
      firebase
        .auth()
        .signInWithCustomToken(customToken)
        .then(() => {
          console.log("signInWithCustomToken custom");
        })
        .catch((e: any) => {
          console.log(e);
        });
        initializeExistingFactory(
          storageToken,
        );
      // storagetoken設定
      dispatch(
        updateImageToken({
          imageToken: storageToken,
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * 2回目ログイン以降Cookie処理及びアプリケーション情報やユーザー情報取得
   * 一部トークンでは情報の再利用を防ぐために公開鍵方式を用いる
   * @param token デジタルツールにて生成されたワンタイムトークン
   */
  const callUserAuthAfterInitialLogin = async (isCookieAuth: boolean = false, isCookieUpdate: boolean = false, isUserInfo: boolean = false, isStorageToken: boolean = false): Promise<void> => {
    try {
      const url: string = process.env.REACT_APP_API_URL + "/users/user-auth-after-initial-login";
      // Crypto Web APIでの公開鍵方式を用いて暗号化/複合化を行う
      const keyPair = await generateKeyPairRsaOaep();
      const publicKey = await exportPublicKey(keyPair);
      const publicKeyBase64 = arrayBufferToBase64(publicKey);
      const param = {
        "public_key": publicKeyBase64,
        "is_cookie_auth": isCookieAuth,
        "is_cookie_update": isCookieUpdate,
        "is_user_info": isUserInfo,
        "is_storage_token": isStorageToken,
      }
      const response = await postCommonRequest(url, param);
      const obj = JSON.parse(response.data.data);
      if (isCookieAuth) {
        const customToken = await decryptRsaOaepAndConcatenate(obj["custom_token"], keyPair);
        // Firebaseカスタム認証を行う
        firebase
          .auth()
          .signInWithCustomToken(customToken)
          .then(() => {
            console.log("signInWith Cookie Custom Token");
          })
          .catch((e: any) => {
            console.log(e);
            // ログイン失敗時はデジタルツールに遷移する
            console.log("no token");
            window.location.href = process.env.REACT_APP_DIGITAL_TOOLS_URL!;
          });
      }
      if (isUserInfo) {
        const json = obj["userCheck"];
        let hqDepartmentNameOA = json.hq_department_name_OA?.replace("ＱＣ室", "ＱＣ部");
        dispatch(
          login({
            employeeNo: json.employee_no,
            employeeName: json.employee_name,
            jobCategory: json.job_category_code_OA,
            jobCategoryCodeOA: json.job_category_code_OA,
            departmentCodeOA: json.department_code_OA,
            departmentNameOA: json.department_name_OA,
            hqDepartmentCodeOA: json.hq_department_code_OA,
            hqDepartmentNameOA: hqDepartmentNameOA,
            mailAddress: json.mail_address,
            zoCode: json.zo_code,
            doCode: json.do_code,
            factoryId: json.tenpo_code,
          })
        );
      }
      if (isStorageToken) {
        const storageToken = await decryptRsaOaepAndConcatenate(obj["storage_token"], keyPair);
        initializeExistingFactory(
          storageToken,
        );
        // storagetoken設定
        dispatch(
          updateImageToken({
            imageToken: storageToken,
          })
        );
      }
    } catch (err) {
      console.error(err);
      userLogging("共通", "認証失敗", "");
      throw err;
    }
  };

  return (
    <>
      {isAnotherLoginLoading ? (
        <Grid
          container 
          justifyContent="center"
          alignItems="center"
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
        <OverLayer/>
        <LoadingCircule
          size={"40vh"}
        />
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default App;

import {
  Typography,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import {
  HeaderGrid,
  PrintContentsGrid,
} from "../../FactoryCheck/Styled/FactoryCheckStyled";
import {
  FACTORY_CHECK_SCORES,
} from "../../FactoryCheck/Register/FactoryCheckCalculation";
  
  /**
   * 項目・評価（点数）の一覧
   * @param rowItem 
   * @param rowItemIndex 
   * @returns 
   */
export function getCategoryScoreTable(scoresList: FACTORY_CHECK_SCORES[]) {
  return (
    <>
    {Object.keys(scoresList).length !== 0 ? (
      <>
      {scoresList.map((scores: FACTORY_CHECK_SCORES, scoresIndex: number) => (
        <>
        <Grid
          container 
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
        <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }} minHeight={37}>
            <Typography variant="h6">{scores.category}</Typography>
          </HeaderGrid>
          <PrintContentsGrid {...{ xs: 5, sm: 5, md: 5, lg: 5 }} minHeight={37}>
            <Typography align="center" variant="h6">{scores.score} / {scores.total_score}</Typography>
          </PrintContentsGrid>
          <PrintContentsGrid {...{ xs: 3, sm: 3, md: 3, lg: 3 }} minHeight={37}>
            <Typography align="center" variant="h6">{scores.percent < 0 ? "-" : scores.percent}%</Typography>
          </PrintContentsGrid>
        </Grid>
        </>
      ))}
      </>
    ) : (
      ""
    )}
    </>
  )
}

/**
 * Firestore関連の処理です。
 * @class firestoreAction.ts
 * @constructor
 */

import { db } from "../firebase";

/**
 * Firestoreより第一引数（コレクション）の検索項目から
 * 第二引数（key）をキーに検索して一致するドキュメントのデータ全量を取得します(上位一件)
 * 非同期処理の為、Promiseオブジェクトを返します。
 * @param searchCollection 検索するコレクション（文字列）
 * @param searchItem 検索キー
 * @param searchId 検索ID
 * @return
 */
export function getFirestoreData(
  searchCollection: string,
  searchItem: string,
  searchId: string
) {
  return new Promise((resolve) => {
    // 検索キーを検索して先頭の一件のみ取得
    const docRef = db
      .collection(searchCollection)
      .where(searchItem, "==", searchId)
      .limit(1);
    docRef
      .get()
      .then(function (doc) {
        doc.forEach((res) => {
          // ドキュメントからデータ全量を返す
          resolve(res.data());
        });
        // 結果が空の場合はエラーで返す
        resolve({});
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
        resolve(error);
      });
  });
}

/**
 * Firestoreより第一引数（コレクション）の第二引数（ドキュメント）項目から
 * 第三引数（配列）をキーにIN検索して一致するドキュメントのデータ全量を取得します。
 * 非同期処理の為、Promiseオブジェクトを返します。
 * @param searchCollection 検索するコレクション（文字列）
 * @param searchItem 検索するドキュメント（文字列）
 * @param searchArray 検索キー
 * @return
 */
export function getFirestoreDataWhereIn(
  searchCollection: string,
  searchItem: string,
  searchArray: Array<string>
) {
  return new Promise((resolve) => {
    // 検索キーを検索して取得
    const docRef = db
      .collection(searchCollection)
      .where(searchItem, "in", searchArray);
    docRef
      .get()
      .then((doc) => {
        // ドキュメントからデータ全量を返す
        resolve(doc);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
        resolve(error);
      });
  });
}
/**
 * Firestoreより第一引数（コレクション）の第二引数（ドキュメント）項目から
 * 第三引数（配列）をキーにIN検索して一致するドキュメントのデータ全量を取得します。
 * 非同期処理の為、Promiseオブジェクトを返します。
 * また、Firestoreの制限により検索キーは10件までです
 * @param searchCollection 検索するコレクション（文字列）
 * @param searchItem 検索するドキュメント（文字列）
 * @param searchArray 検索キー
 * @return
 */
 export const getFirestoreDataWhereArrayAny = async(
  searchCollection: string,
  searchItem: string,
  searchArray: Array<string>
) => {
  return await new Promise((resolve) => {
    // 検索キーを検索して取得
    const docRef = db
      .collection(searchCollection)
      .where(searchItem, "array-contains-any", searchArray);
    docRef
      .get()
      .then((doc) => {
        // ドキュメントからデータ全量を返す
        resolve(doc);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
        resolve(error);
      });
  });
}

/**
 * Firestoreより第一引数（コレクション）の検索項目から
 * 第二引数（key）をキーに検索して一致するドキュメントのデータ全量を取得します(上位一件)
 * 非同期処理の為、Promiseオブジェクトを返します。
 * @param searchCollection 検索するコレクション（文字列）
 * @param searchItem 検索キー
 * @return
 */
export function getFirestoreDocData(
  searchCollection: string,
  searchItem: string
) {
  return new Promise((resolve) => {
    // 検索キーを検索して先頭の一件のみ取得
    const docRef = db.collection(searchCollection).doc(searchItem);
    docRef
      .get()
      .then((doc) => {
        // ドキュメントからデータ全量を返す
        resolve(doc.data());
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
        resolve(error);
      });
  });
}

/**
 * Firestoreよりドキュメントのデータ全てを取得します
 * 非同期処理の為、Promiseオブジェクトを返します。
 * @param searchCollection 検索するコレクション（文字列）
 * @return
 */
export function getFirestoreAllData(searchCollection: string) {
  return new Promise((resolve) => {
    // 検索キーを検索して先頭の一件のみ取得
    const docRef = db.collection(searchCollection);
    docRef
      .get()
      .then((doc) => {
        // ドキュメントからデータ全量を返す
        resolve(doc);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
        resolve(error);
      });
  });
}

/**
 * Firestoreのドキュメントのデータを追加/更新します(全量更新)
 * 非同期処理の為、Promiseオブジェクトを返します。
 * @param collectionTable Firestoreのコレクションテーブル
 * @param documentId FirestoreのドキュメントID
 * @param data 更新対象のデータ
 * @return
 */
export async function writeFirestoreDoc(
  collectionTable: string,
  documentId: string,
  data: any
) {
  try {
    if (data === undefined) return;
    // return new Promise(() => {
      // 検索キーを検索して先頭の一件のみ取得
      db.collection(collectionTable)
        .doc(documentId)
        .set(data)
        .then(() => {
          console.log("Document successfully written!");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    // });
    } catch(r) {
      throw r;
    };
}

/**
 * Firestoreのドキュメントのデータについて
 * リスナーにて受け取った関数を実行します
 * @param collectionName Firestoreのコレクションテーブル
 * @param documentId FirestoreのドキュメントID
 * @param paramFunction リスナーで受け取った後に実行する関数
 * @return
 */
export function snapshotFirestoreDocData(
  collectionName: string,
  documentId: string,
  paramFunction: any
) {
  return new Promise((resolve) => {
    // ドキュメントIDの一件のみ取得
    const docRef = db.collection(collectionName).doc(documentId);
    docRef.onSnapshot((doc) => {
      // ドキュメントからデータ全量を返す
      resolve(paramFunction(doc));
    });
  });
}

/**
 * Firestoreのドキュメントのデータを削除します
 * 非同期処理の為、Promiseオブジェクトを返します。
 * @param collectionTable Firestoreのコレクションテーブル
 * @param documentId FirestoreのドキュメントID
 * @return
 */
 export function deleteFirestoreDoc(
  collectionTable: string,
  documentId: string
) {
  if (documentId === undefined) return;
  return new Promise(() => {
    db.collection(collectionTable)
      .doc(documentId)
      .delete()
      .then(() => {
        console.log("Document successfully delete!");
      })
      .catch((error) => {
        console.error("Error deleting document: ", error);
        throw error;
      });
  });
}
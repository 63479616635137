import { FC } from "react";
import { TextField, } from "@mui/material";
import { 
  LocalizationProvider,
  MobileDatePicker
} from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from "luxon";

const SejDatePicker: FC<{
  selectedDate: DateTime | null;
  dateFunc: any;
  format?: any;
  maxDate?: any;
  minDate?: any;
  backgroundColor?: string;
  mainColor?: string;
  width?: any
}> = ({
  selectedDate,
  dateFunc,
  format,
  maxDate,
  minDate,
  backgroundColor,
  mainColor,
  width
}) => {

  if (!format) {
    format = "yyyy/MM/dd";
  }
  if (!minDate) {
    minDate = DateTime.fromFormat(DateTime.local().toString(), "yyyyMMdd");
  }
  if (!maxDate) {
    maxDate = DateTime.fromFormat("20991231", "yyyyMMdd");
  }

  return (
    <div>
      <LocalizationProvider
        dateAdapter={AdapterLuxon}
        // adapterLocale={'Asia/Tokyo'}
        dateFormats={{ monthAndYear: 'yyyy年 MM月' }}
        localeText={{
          previousMonth: "前月を表示", // < のツールチップ
          nextMonth: "次月を表示", // > のツールチップ
          cancelButtonLabel: "キャンセル", // スマホ画面のCANCELボタン
          okButtonLabel: "選択", // スマホ画面のOKボタン
        }}
      >
          <MobileDatePicker
            // label="監査日時"
            minDate={minDate} //未来の日付無効
            maxDate={maxDate} //未来の日付無効
            value={selectedDate}
            onChange={(date: DateTime | null) => dateFunc(date)}
            inputFormat='yyyy年MM月dd日' //選択済みの日付の表示
            mask='____年__月__日'
            toolbarTitle="日付選択"
            toolbarFormat="yyyy年MM月dd日" // スマホ画面の左上 選択中日付表示
            renderInput={(params) => (
            <TextField
              variant="standard"
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "****年**月**日", //プレースホルダー（フォーカスを合わせたときに薄く表示される入力例）
              }}
            />
            )}
          />
      </LocalizationProvider>
    </div>
  );
};

export default SejDatePicker;

import React, { useEffect, FC } from "react";
import { useSelector } from "react-redux";
import { selectImageToken } from "../../../features/userSlice";
import { getFixedFlg } from "../../Header/Header";
import {
  Grid,
  Button,
  TextareaAutosize,
  styled,
  Switch,
  FormControlLabel,
} from "@mui/material";

const ToolAreaGrid = styled(Grid)(({ theme }) => ({
  paddingLeft: "20px",
}));

const CommentTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: "200px",
  // iPad縦
  "@media (min-width: 830px)": {
    width: "200px",
  },
  // iPad横
  "@media (min-width: 1100px)": {
    width: "200px",
  },
  // GWPC
  "@media (min-width: 1200px)": {
    width: "200px",
  },
}));

export const HandwriteUpdate = { // 手書き入力の更新区分
  Off: "0",
  Clear: "1",
}

const SwitchTextArea: FC<{
  comment: string;
  commentFilepathGcs: string;
  commentFileData: string;
  commentHandwriteFlag: string;
  changeComment: (e: React.ChangeEvent<HTMLTextAreaElement>) => void; // テキストコメント変更関数
  updateCavasContext: (context: CanvasRenderingContext2D) => void; // canvas更新関数
  updateHandwriteUpdateClass: (val: string) => void; // 手書き入力更新区分更新関数
}> = ({ comment, commentFilepathGcs, commentFileData, commentHandwriteFlag, changeComment, updateCavasContext, updateHandwriteUpdateClass }) => {

  const defaultLineWidth: number = 2; // デフォルトの線の太さ
  const defaultLineColor: string = "black"; // デフォルトの線の色
  // 座標用
  interface Point {
    x: number;
    y: number;
  }

  const canvasRef = React.useRef<HTMLCanvasElement>(null); // 手入力用
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null); // テキストエリア
  const [canvasContext, setCanvasContext] = React.useState<CanvasRenderingContext2D | null>(null); // 手入力用canvasのcontext
  const [moving, setMoving] = React.useState(false); // 移動中であること
  const [lastPoint, setLastPoint] = React.useState<Point>({ x: 0, y: 0 }); // クリック、ドラッグ時のマウスポイント。タッチ、タッチした状態での移動時の座標。線を引くときに使用。
  const [isHandwrite, setIsHandwrite] = React.useState<boolean>(false);
  const [editing, setEditing] = React.useState<boolean>(false);

  const ClearButton = styled(Button)(({ theme }) => (
    isHandwrite ?
    {
      borderRadius: "100vh",
      fontWeight: "bold",
      backgroundColor: "#FFFFFF",
      color: "#e60012",
      border: "#e60012 solid",
      "&:hover": {
        backgroundColor: "#3333331a",
      }
    }
    :
    {
      borderRadius: "100vh",
      fontWeight: "bold",
      backgroundColor: "transparent",
      color: "#3333331a",
      border: "rgba(51,51,51,.1019607843) solid"
    }
  ));

  const ManualSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  useEffect(() => {
    setIsHandwrite(commentHandwriteFlag === "1");
    // canvas
    const canvas: HTMLCanvasElement | null = canvasRef.current;
    if (!canvas) return;
    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;
    const context: CanvasRenderingContext2D | null = canvas.getContext("2d");
    if (!context) return;
    refreshCanvas(context);
    context.lineWidth = defaultLineWidth;
    context.strokeStyle = defaultLineColor;
    setCanvasContext(context);
  }, [commentFilepathGcs, commentFileData]);

  useEffect(() => {
    if (isHandwrite) {
      // canvas
      const canvas: HTMLCanvasElement | null = canvasRef.current;
      if (!canvas) return;
      canvas.width = canvas.clientWidth;
      canvas.height = canvas.clientHeight;
      const context: CanvasRenderingContext2D | null = canvas.getContext("2d");
      if (!context) return;
      refreshCanvas(context);
      context.lineWidth = defaultLineWidth;
      context.strokeStyle = defaultLineColor;
      setCanvasContext(context);
  
      // 設定初期化
      setMoving(false);
      setEditing(false);
    }
  }, [isHandwrite]);

  // canvas更新
  const refreshCanvas = (canvasContext: CanvasRenderingContext2D): void => {
    if (!commentFilepathGcs && !commentFileData) return;
    const img = new Image();
    if (commentFileData) {
      img.src = commentFileData;
    }
    else {
      img.src = getImageFile(commentFilepathGcs);
    }
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      const {width, height} = canvasContext.canvas;
      canvasContext.drawImage(img, 0, 0, width, height);
      setEditing(true);
    };
  };
  
  const imageToken = useSelector(selectImageToken);
  function getImageFile(imagePath: string) {
    if (imagePath === undefined || imagePath === "") {
      return `${window.location.origin}/image/noimage.png`;
    }
    return (
      process.env.REACT_APP_CLOUD_STORAGE_URL +
      imagePath +
      "?access_token=" +
      imageToken
    );
  }

  // トグルボタン：テキスト押下時
  const switchManual = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (isHandwrite) {
      commentHandwriteFlag = "";
      clearHandwrite(HandwriteUpdate.Off);
    }
    else {
      if (comment) {
        const dummyEvent = { target: { value: "" } };
        changeComment(dummyEvent as React.ChangeEvent<HTMLTextAreaElement>);
      }
      commentHandwriteFlag = "1";
    }
    setIsHandwrite(!isHandwrite);
    return;
  };

  // クリアボタン押下
  const clearHandwrite = (val: string): void => {
    updateHandwriteUpdateClass(val);
    setEditing(false);
    setMoving(false);
    if (!canvasContext || !canvasContext.canvas) return;
    canvasContext.globalCompositeOperation = "destination-out";
    const {width, height} = canvasContext.canvas;
    canvasContext.fillRect(0, 0, width, height);
    canvasContext.globalCompositeOperation = "source-over";
  };
  const clickClear = (): void => {
    clearHandwrite(HandwriteUpdate.Clear);
  };
  

  // タッチの始め
  const handleTouchStart = (e: React.TouchEvent<HTMLCanvasElement>) => {
    if (!canvasContext) return;
    const offset = canvasContext.canvas.getBoundingClientRect();
    const offsetX = e.touches[0].clientX - offset.left;
    const offsetY = e.touches[0].clientY - offset.top;
    startOperation(offsetX, offsetY);
  };

  // タッチの終わり
  const handleTouchEnd = (e: React.TouchEvent<HTMLCanvasElement>) => {
    endOperation();
    e.preventDefault();
  };

  // タッチで線を引く
  const handleTouchMove = (e: React.TouchEvent<HTMLCanvasElement>) => {
    // 座標位置取得が必要なモードになっていないとき
    if (!moving) {
      return;
    }

    if (!canvasContext) return;
    const canvas: HTMLCanvasElement = canvasContext.canvas;
    const offset = canvas.getBoundingClientRect();
    let offsetX = e.changedTouches[0].clientX - offset.left;
    let offsetY = e.changedTouches[0].clientY - offset.top;
    moveOperation(offsetX, offsetY);
  };

  // ドラッグの始め
  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const { offsetX, offsetY } = e.nativeEvent;
    startOperation(offsetX, offsetY);
  };

  // ドラッグの終わり
  const handleMouseUp = (e: React.MouseEvent<HTMLCanvasElement>) => {
    endOperation();
  };

  // ドラッグ中。線を引く、クラップ範囲の指定等を行う。
  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    // 座標位置取得が必要なモードになっていないとき
    if (!moving) {
      return;
    }

    moveOperation(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
  };

  // 操作開始時（マウスクリック、タッチ）
  const startOperation = (offsetX: number, offsetY: number): void => {
    setLastPoint({ x: offsetX, y: offsetY });
    setMoving(true);
    setEditing(true);
  };

  // 操作終了時（マウスクリック、タッチ）
  const endOperation = (): void => {
    setMoving(false);
    if (canvasContext) {
      updateCavasContext(canvasContext);
    }
  };

  // 操作時（マウスクリック、タッチ）
  const moveOperation = (offsetX: number, offsetY: number): void => {
    setEditing(true);

    if (!canvasContext) return;
    const canvas: HTMLCanvasElement = canvasContext.canvas;

    // 座標補正
    if (offsetX < 0) {
      offsetX = 0;
    } else if (canvas.width < offsetX) {
      offsetX = canvas.width;
    }
    if (offsetY < 0) {
      offsetY = 0;
    } else if (canvas.height < offsetY) {
      offsetY = canvas.height;
    }

    const currentPoint = {
      x: offsetX,
      y: offsetY,
    };
    // 線を引く
    canvasContext.beginPath();
    canvasContext.moveTo(lastPoint.x, lastPoint.y);
    canvasContext.lineTo(currentPoint.x, currentPoint.y);
    canvasContext.stroke();
    setLastPoint(currentPoint);
  };

  return (
    <>
      <Grid>
        {isHandwrite ? (
          <canvas
            style={{
              width: "200px",
              pointerEvents: getFixedFlg() ? "none" : "auto",
              userSelect: "none",
              WebkitTouchCallout: "none",
              WebkitUserSelect: "none",
              border: "1px solid",
              borderColor: "#00cc5b",
              height: "194px",
              borderRadius: "8px",
              margin: "10px",
            }}
            ref={canvasRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onTouchMove={handleTouchMove}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          />
        ) : (
          <CommentTextarea
            aria-label="store-input-comment-area"
            maxLength={4000}
            placeholder={
                getFixedFlg()
                ? "その他コメント"
                : "その他コメントがあればご入力ください"
            }
            value={comment}
            onChange={(e) => {
                changeComment(e);
            }}
            ref={textAreaRef}
            style={{
              height: "190px",
              fontSize: "16px",
              borderRadius: "8px",
              margin: "10px",
            }}
          />
        )}
        <ToolAreaGrid>
          <FormControlLabel
            control={
              <ManualSwitch
                checked={isHandwrite}
                onChange={switchManual}
              />}
            label="手書き"
          />
          <ClearButton 
            variant="contained"
            disabled={!(isHandwrite && editing && !getFixedFlg())}
            size="small" 
            onClick={clickClear}
          >
            クリア
          </ClearButton>
        </ToolAreaGrid>
      </Grid>
    </>
  )
}
export default SwitchTextArea;
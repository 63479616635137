import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebaseApp.firestore().enablePersistence({synchronizeTabs: true});

export const db = firebaseApp.firestore();

export const firestore = firebase.firestore;
export const auth = firebase.auth();
export const storage = firebase.storage();
export const analytics = firebase.analytics();
// export const provider = new firebase.auth.GoogleAuthProvider();

// Firebase Authentication API Token取得
export const getFirebaseAuthToken = async (isRefresh: boolean = true) => {
  // APIの向き先がWorkstationではない場合
  if (process.env.REACT_APP_API_URL?.indexOf("cloudworkstations") === -1) {
    return await auth.currentUser?.getIdToken(/* forceRefresh */ isRefresh);
  }
  // 以下のURLからworkstations環境用のトークンを取得して編集してください。
  // https://cloud.google.com/workstations/docs/authentication?hl=ja#http
  // パラメーター：projects/sej-cloud-workstations/locations/asia-northeast1/workstationClusters/cluster-tokyo/workstationConfigs/apps-stable/workstations/workstation-[固有名]
  // curlで取得する場合
  // curl --request POST "https://workstations.googleapis.com/v1/projects/sej-cloud-workstations/locations/asia-northeast1/workstationClusters/cluster-tokyo/workstationConfigs/apps-stable/workstations/w-kudo:generateAccessToken" --header "Authorization: Bearer $(gcloud auth print-access-token)" --header "Accept: application/json"  --header "Content-Type: application/json"  --data "{}" --compressed | jq
  return "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2Nsb3VkLmdvb2dsZS5jb20vd29ya3N0YXRpb25zIiwiYXVkIjoidy1rdWRvLmNsdXN0ZXItbzd2c242NzZ0bmdhd3J3eHZ1bG5mb2M3eTYuY2xvdWR3b3Jrc3RhdGlvbnMuZGV2IiwiaWF0IjoxNzE2NzY3MjY3LCJleHAiOjE3MTY3NzA4Njd9.X96UgUbjPwkJTdep0AtCMNgKz2-9CqBeg5RhU5b3ukTp1IzVKJCVSJyID4W40Svln5QjzYdKtKw-MmKWgEBjxIavvqNLHf5dT35K1qqOLYJlxVwAbBz2V-K1yzjE90iooht0tGbExQJ_hagU6Y9ctKW8x8FHvv3_vzW5gin_qcTeno8cSEQ_Srh4WsKOL_rLuEOO8JxHtiZODlWOCPylikw35seksFaZy0MHVzempY7c31tN1vkw92wjoeN2HOdZxlgIHmmy4gRjhTiHC4Q5HB_q7Xo_Rdd-iKh14f12MhOBvniNX4V3EmfQ0cGpxrP1q6FG8gxbMkHbqpKkOli6Ww"
};

// Firebase Authentication API Token取得
export const getAPIToken = async (isRefresh: boolean = true) => {
  return await auth.currentUser?.getIdToken(/* forceRefresh */ isRefresh);
};

// Firebaseユーザー情報取得
export const getFirebaseUserInfo = (): any => {
  return auth.currentUser !== null ? auth.currentUser : {};
};

// Firebaseログイン判定
export const isFirebaseAuth = (): any => {
  return auth.currentUser !== null;
};
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectZoName,
} from "../../../../components/organisms/search/TenpoCheckSearchSlice";

import { selectUser } from "../../../../features/userSlice";
import {
  getAllZone
} from "../../../../data/existingSejStores";
import {
  getAuthFactoryCheck, onlyAll
} from "../specification/UsageAuthority/AuthFactoryCheck";

import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";

const SearchZO: React.FC<{
  // useStyles: any;
  disabled: boolean;
  firstView: boolean;
  toggleStatus: boolean;
  searchMode: number;
  onClickFunc: any;
}> = ({
  // useStyles,
  disabled,
  firstView,
  toggleStatus,
  searchMode,
  onClickFunc,
}) => {
  // const classes = useStyles({});
  const [open, setOpen] = React.useState(false);
  // 検索文字列
  const [searchWord, setSearchWord] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const user = useSelector(selectUser);
  const stateZoName = useSelector(selectZoName);
  
  const MAX_SEARCH_LIST = 150;
  const zoMaster = getAllZone().zone_data;
  const [zoList, setZoList] = React.useState([""]);
  // 検索文字列が更新された際に動く
  useEffect(() => {
    // ボタンのリストを更新
    if (getAuthFactoryCheck(user.jobCategory).search < onlyAll) {
      setZoList(userZoButton());
    }
    else {
      setZoList(allZoButton());
    }
  }, [searchWord]);

  function userZoButton() {
    let zoList: any[] = [""];
    let zoCode: any = "";
    let zoName: any = "";
    let hitCount: number = 0;

    for (let i = 0; i < user.zoCode.length; i++) {
      zoCode = user.zoCode[i];
      if (zoCode === "") return zoList;
      zoName = zoMaster[user.zoCode[i]].zone_name;
      // 検索文字列にマッチしたものだけリストに入れる
      if (zoCode.match(searchWord) || zoName.match(searchWord)) {
        zoList.push(
          <Button
            key={i}
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={() => {
              onClickFunc(
                user.zoCode[i],
                zoMaster[user.zoCode[i]].zone_name
              );
              setOpen(false);
            }}
          >
            <div  
            // className={classes.selectButton}
            >{user.zoCode[i]}：{zoMaster[user.zoCode[i]].zone_name}</div>
          </Button>
        );
        hitCount++;
      }
      if (hitCount > MAX_SEARCH_LIST) break;
    }
    return zoList;
  }

  function allZoButton() {
    let zoList: any[] = [""];
    let hitCount: number = 0;
    for (let key in zoMaster) {
      if (key === "undefined" || searchWord === "") continue;
      // 検索文字列にマッチしたものだけリストに入れる
      if (zoMaster[key].zone_code.match(searchWord) || zoMaster[key].zone_name.match(searchWord)) {
        zoList.push(
          <Button
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={() => {
              onClickFunc(
                zoMaster[key].zone_code,
                zoMaster[key].zone_name
              );
              setOpen(false);
            }}
          >
            <div 
            // className={classes.selectButton}
            >{zoMaster[key].zone_code}：{zoMaster[key].zone_name}</div>
          </Button>
        );
        hitCount++;
      }
      if (hitCount > MAX_SEARCH_LIST) break;
    }
    return zoList;
  }

  return (
    <>
      <button
        // className={classes.searchButton}
        onClick={() => {
          handleOpen();
          onClickFunc("", "");
        }}
        disabled = {disabled}
        style={{ WebkitAppearance: "none" }}
      >
        <div 
        // className={classes.selectButton}
        >{ stateZoName === "" ? "ＺＯ名" : stateZoName}</div>
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div 
        // className={classes.paper}
        >
          <TextField
            // className={classes.searchTextField}
            value={searchWord}
            label="ＺＯコード、ＺＯ名で探す"
            variant="outlined"
            size="medium"
            fullWidth={true}
            disabled={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchWord(e.target.value);
            }}
          />
          <div 
          // className={classes.listPaper}
          >
            {zoList}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SearchZO;
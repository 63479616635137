import {
  FACTORY_CHECK_BIG_RECORD,
  FACTORY_CHECK_RECORD,
} from "./FactoryCheckDataOperation";

/**
 * 工場チェックの大項目単位の値の型
 */
export interface FACTORY_CHECK_SCORES {
  //  カテゴリー
  category: string;
  //  スコア
  score: number;
  //  カテゴリスコア
  secondary_score: number[];
  //  総合スコア
  total_score: number;
  //  パーセントスコア
  percent: number;
  //  ランク評価 @see calclulationRank
  rank: string;
}

/**
 * 新規作成チェックタブのスコアリスト作成及びデータリストの更新
 * 
 * @param rowItemList 
 * @param factoryCheckSelect 
 * @returns 
 */
export const updateScore = (
  rowItemList: FACTORY_CHECK_BIG_RECORD[],
  factoryCheckSelect: boolean[]
) => {
  let scoresList: Array<FACTORY_CHECK_SCORES> = [];
  let totalScore = 0;
  let totalCount = 0;
  let secondaryScores: number[] = [];
  let secondaryScore = 0;
  // ボタンのカウント
  for (let i = 0; i < rowItemList.length; i++) {
    let categoryScore = 0;
    let categoryCount = 0;
    for (let j = 0; j < rowItemList[i].rows.length; j++) {
      // 「○」選択時のスコア計算
      if (rowItemList[i].rows[j].button_value === "1") {
        categoryScore = categoryScore + 4;
        secondaryScore = secondaryScore + 4;
        rowItemList[i].rows[j].score = "4";
        // カテゴリ選択有無を判定
        if (factoryCheckSelect[i]) {
          totalCount = totalCount + 4;
        }
        categoryCount = categoryCount + 4;
      }
      // 「×」選択時のスコア計算
      if (rowItemList[i].rows[j].button_value === "2") {
        rowItemList[i].rows[j].score = "0";
        // カテゴリ選択有無を判定
        if (factoryCheckSelect[i]) {
          totalCount = totalCount + 4;
        }
        categoryCount = categoryCount + 4;
      }
      // 「△」選択時のスコア計算
      if (rowItemList[i].rows[j].button_value === "3") {
        categoryScore = categoryScore + 2;
        secondaryScore = secondaryScore + 2;
        rowItemList[i].rows[j].score = "2";
        // カテゴリ選択有無を判定
        if (factoryCheckSelect[i]) {
          totalCount = totalCount + 4;
        }
        categoryCount = categoryCount + 4;
      }
      if (rowItemList[i].rows[j].secondary_end) {
        secondaryScores.push(secondaryScore);
        secondaryScore = 0;
      }
    }
    // カテゴリ選択有無を判定
    if (factoryCheckSelect[i]) {
      totalScore = totalScore + categoryScore;
    }
    // パーセントスコア算出
    let percent = calclulationScore(categoryScore, categoryCount);
    // カテゴリのスコアセット
    let scores: FACTORY_CHECK_SCORES = {
      category: rowItemList[i].primary_category,
      score: categoryScore,
      secondary_score: secondaryScores,
      total_score: categoryCount,
      percent: percent,
      rank: calclulationRank(percent)
    }
    scoresList.push(scores);
  }
  // 総評パーセントスコア算出
  let totalPercent = calclulationScore(totalScore, totalCount);
  // 総評のスコアセット
  let totalScores: FACTORY_CHECK_SCORES = {
    category: "総評",
    score: totalScore,
    secondary_score: secondaryScores,
    total_score: totalCount,
    percent: totalPercent,
    rank: calclulationRank(totalPercent)
  }
  scoresList.push(totalScores);
  return scoresList;
};

/**
 * 新規作成提出タブ及びデータ検索結果単票のスコアリスト作成
 * @param rows 
 * @returns 
 */
export const calculationUnitScore = (
  rows: Array<FACTORY_CHECK_RECORD>
) => {
  let scoresList: Array<FACTORY_CHECK_SCORES> = [];
  let totalScore = 0;
  let totalCount = 0;
  let secondaryScores: number[] = [];
  let currentCategory = "";
  // ボタンのカウント
  let categoryScore = 0;
  let categoryCount = 0;
  for (let j = 0; j < rows.length; j++) {
    // 現在のカテゴリに最初のカテゴリを格納
    if (j === 0) {
      currentCategory = rows[j].secondary_category;
    }
    // 現在のカテゴリと今回のカテゴリが異なる場合はスコアをリセットする
    if (rows[j].secondary_category !== currentCategory) {
      // パーセントスコア算出
      let percent = calclulationScore(categoryScore, categoryCount);
      // カテゴリのスコアセット
      let scores: FACTORY_CHECK_SCORES = {
        category: currentCategory,
        score: categoryScore,
        secondary_score: secondaryScores,
        total_score: categoryCount,
        percent: percent,
        rank: calclulationRank(percent)
      }
      scoresList.push(scores);
      currentCategory = rows[j].secondary_category;
      totalScore = totalScore + categoryScore;
      categoryScore = 0;
      categoryCount = 0;
    }
    // 「○」選択時のスコア計算
    if (rows[j].button_value === "1") {
      categoryScore = categoryScore + 4;
      rows[j].score = "4";
      totalCount = totalCount + 4;
      categoryCount = categoryCount + 4;
    }
    // 「×」選択時のスコア計算
    if (rows[j].button_value === "2") {
      rows[j].score = "0";
      totalCount = totalCount + 4;
      categoryCount = categoryCount + 4;
    }
    // 「△」選択時のスコア計算
    if (rows[j].button_value === "3") {
      categoryScore = categoryScore + 2;
      rows[j].score = "2";
      totalCount = totalCount + 4;
      categoryCount = categoryCount + 4;
    }
  }
  // パーセントスコア算出
  let percent = calclulationScore(categoryScore, categoryCount);
  // カテゴリのスコアセット
  let scores: FACTORY_CHECK_SCORES = {
    category: currentCategory,
    score: categoryScore,
    secondary_score: secondaryScores,
    total_score: categoryCount,
    percent: percent,
    rank: ""
  }
  scoresList.push(scores);
  totalScore = totalScore + categoryScore;

  // パーセントスコア算出
  let totalPercent = calclulationScore(totalScore, totalCount);
  // カテゴリのスコアセット
  let totalScores: FACTORY_CHECK_SCORES = {
    category: "総評",
    score: totalScore,
    secondary_score: secondaryScores,
    total_score: totalCount,
    percent: totalPercent,
    rank: calclulationRank(totalPercent)
  }
  scoresList.push(totalScores);
  return scoresList;
};

/**
 * 単票の合計得点計算
 * @param rows 
 * @returns 
 */
export const calculationUnitTotalScore = (
  rows: Array<FACTORY_CHECK_RECORD>
) => {
  let totalScore = 0;
  let totalCount = 0;
  // ボタンのカウント
  for (let i = 0; i < rows.length; i++) {
    // 「○」選択時のスコア計算
    if (rows[i].button_value === "1") {
      totalScore = totalScore + 4;
      totalCount = totalCount + 4;
    }
    // 「×」選択時のスコア計算
    if (rows[i].button_value === "2") {
      totalCount = totalCount + 4;
    }
    // 「△」選択時のスコア計算
    if (rows[i].button_value === "3") {
      totalScore = totalScore + 2;
      totalCount = totalCount + 4;
    }
  }
 
  return calclulationScore(totalScore, totalCount);
};

/**
 * パーセントスコア取得
 * @param totalScore 
 * @param totalCount 
 * @returns 
 */
export const calclulationScore = (
  totalScore: number,
  totalCount: number
) => {
  if (totalCount === 0) return -1; 
    // 1項目の得点
    const base = 0.001; // 小数点第三位で四捨五入
    let percentScore =
      Math.round((totalScore * (100 / totalCount)) / base) * base;
    // 小数点第三位以下が出ることがあるので以下を切り捨てる
    return Math.floor(percentScore * Math.pow(10, 0)) / Math.pow(10, 0);
}

/**
 * ランク評価取得
 * @param score 
 * @returns 
 */
export const calclulationRank = (score: number) => {
  if (score >= 90) {
    return "A";
  }
  else if (score >= 80) {
    return "B";
  }
  else if (score >= 70) {
    return "C";
  }
  return "D"
}

/**
 * 合計得点の計算
 * TODO グラフで利用されているためグラフの実装時に見直しが必要
 * 
 * @param rowItemList 
 * @returns 
 */
export const calculationTotalScore = (
  rowItemList: FACTORY_CHECK_BIG_RECORD[],
) => {
  let circleCount = 0;
  let totalCount = 0;
  let totalScoreFew = 0;
  // ボタンのカウント
  for (let i = 0; i < rowItemList.length; i++) {
    for (let j = 0; j < rowItemList[i].rows.length; j++) {
      // 「○」選択時のスコア計算
      if (rowItemList[i].rows[j].button_value === "1") {
        totalCount = totalCount + 4;
        ++circleCount;
      }
      // 「×」選択時のスコア計算
      if (rowItemList[i].rows[j].button_value === "3") {
        ++circleCount;
      }
      // 「△」選択時のスコア計算
      if (rowItemList[i].rows[j].button_value === "2") {
        totalCount = totalCount + 2;
        ++circleCount;
      }

    }
  }

  // 計算
  if (totalCount !== 0) {
    const base = 0.1; // 小数点第二位で四捨五入
    let totalScore =
      Math.round((circleCount * (100 / totalCount)) / base) * base;
    // 小数点第二位以下が出ることがあるので以下を切り捨てる
    totalScoreFew = Math.floor(totalScore * Math.pow(10, 3)) / Math.pow(10, 3);
  }
  // 合計得点を返す
  return totalScoreFew;
};


import React from "react";
import { ClickAwayListener, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Feedback from "@mui/icons-material/Feedback";

const InfoTip: React.FC<{
  iconType: string;
  infoText: string;
  placement?:
    | "left"
    | "right"
    | "top"
    | "bottom"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
  noTitleFlg?: boolean;
}> = ({ iconType, infoText, placement , noTitleFlg}) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        title={
          noTitleFlg ? (
            ""
          ) : (
            <span style={{ whiteSpace: "pre-line" }}>{infoText}</span>
          )
        }
        placement={placement}
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        {iconType === "info" ? (
          <InfoIcon
            fontSize="small"
            onClick={handleTooltipOpen}
            style={{ color: "#333333" }}
          ></InfoIcon>
        ) : (
          <Feedback
            fontSize="small"
            onClick={handleTooltipOpen}
            style={{ color: "#333333" }}
          ></Feedback>
        )}
      </Tooltip>
    </ClickAwayListener>
  );
};

export default InfoTip;

import React, { useEffect, FC } from "react";
import { DateTime } from "luxon";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Modal,
  styled,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import {
  VerticalAlignTop,
  VerticalAlignBottom,
} from "@mui/icons-material";
import { openWebSite } from "../../../apis/util";
import {
  FACTORY_CHECK_BIG_RECORD,
  FACTORY_CHECK_ADD_BIG_RECORD,
  REQUEST_FOR_CORRECTION,
} from "../Register/FactoryCheckDataOperation";
import {
  getCheckRecord
} from "../../organisms/table/FactoryCheckDataAllTable";
import {
  getCorrectionsTable
} from "../../organisms/table/FactoryCheckCorrectionsTable";

import { userLogging } from "../../../apis/userLog";
import { useSelector } from "react-redux";
import { selectImageToken, selectUser } from "../../../features/userSlice";
import { postRequest } from "../../../apis/axiosAction";
import { 
  EXISTING_FACTORY_RECORD,
  getAllExistingFactory
} from "../../../data/existingFactory";
import {
  OverLayer,
  ViewOverFlowGrid,
  SeparatorLeftGrid,
  UpDownButton,
  LoadingCircule,
  RegistrationButton,
} from "../Styled/FactoryCheckStyled";
import {
  getPrintImage,
} from "../../organisms/photo/FactoryCheckPrintImage";
import FactoryCheckSearchResultTable from "../../organisms/table/FactoryCheckSearchResultTable"

export const ResultPaneModal = styled(Modal)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  minWidth: "100%",
  overflowY: "auto",
  overflowX: "hidden",
}));
  
// 全体枠
export const ResultAreaGrid = styled(Grid)(({ theme }) => ({
  border: "solid",
  flex: 1,
  alignItems: "center",
  paddingBottom: "20px",
  width: "100%",
  height: "100%",
  // height: "55vh",
  backgroundColor: "#fff",
}));

const moveScroll = (fieldName: string, bottomFlg?: boolean) => {
  let element = document?.getElementById(fieldName);
  if (element !== null) {
    if (bottomFlg) {
      element?.scrollIntoView(false);
    } else {
      element.scrollIntoView();
    }
  }
};

// 作成日付(旧承認日)
let isApprove: number = 0;
export function isCheckDate() {
  return isApprove;
}
function checkCheckDate(dayStr: string | null, lastCheckDate: string) {
  isApprove =
    lastCheckDate !== ""
      ? dayStr && DateTime.fromFormat(dayStr, "yyyyMMdd").isValid
        ? dayStr >= lastCheckDate
          ? 1
          : 0
        : -1
      : dayStr && DateTime.fromFormat(dayStr, "yyyyMMdd").isValid
      ? 1
      : -1;
}
// 作成時間
let isCheckHourInput: number = 0;
export function isCheckHour() {
  return isCheckHourInput;
}
function checkCheckHour(hourStr: string) {
  let hourNum: number = Number(hourStr);
  if (hourNum || (hourStr && hourNum === 0)) {
    isCheckHourInput = hourNum;
  } else {
    isCheckHourInput = -1;
  }
}
const FactoryCheckSearchData: FC<{
  factoryCheckId: string;
  updateResultList: any;
  searchResultRecord: any;
  setSearchFlg: any;
}> = ({ factoryCheckId, updateResultList, searchResultRecord, setSearchFlg }) => {

  const [checkDate, setCheckDate] = React.useState<DateTime | null>(null);
  const [lastCheckDate, setLastCheckDate] = React.useState("");
  const [lastDateTitle, setLastDateTitle] = React.useState("");
  const [formatCreateDate, setFormatCreateDate] = React.useState<DateTime | null>(null);
  const [formatUpdateDate, setFormatUpdateDate] = React.useState<DateTime | null>(null);
  const [reportCount, setReportCount] = React.useState("");
  const [witness, setWitness] = React.useState("");
  const [categoryCheckValue, setCategoryCheckValue] = React.useState([false, false, false, false, false, false, false, false, false]);
  const [manufacturCategory, setManufacturCategory] = React.useState("");
  const [submissionDeadline, setSubmissionDeadline] = React.useState<DateTime | null>(null);

  const [auditorDepartmentName, setAuditorDepartmentName] = React.useState("");
  const [auditorName, setAuditorName] = React.useState("");
        
  const user = useSelector(selectUser);

  const [rowItemList, setRowItemList] = React.useState<
    FACTORY_CHECK_BIG_RECORD[]
  >([]);
  const [addRowItemList, setAddRowItemList] = React.useState<
    FACTORY_CHECK_ADD_BIG_RECORD[]
  >([]);
  const [selectedFactoryId, setSelectedFactoryId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [userPrivilege, setUserPrivilege] = React.useState("");

  const [inputHour, setInputHour] = React.useState("");
  const [checkHour, setCheckHour] = React.useState("");

  const [factoryCheckSelect, setFactoryCheckSelect] = React.useState([false, false, false, false, false]);
  // == 是正要請 ==
  // 重篤性判断 judgementOfSeverity
  // 基準 standard
  // 記録 evaluation
  // 連単 followUp
  // コメント・助言 commentAdvice
  const [corrections, setCorrections] = React.useState<REQUEST_FOR_CORRECTION[]>([]);
  // 総評・助言
  const [reviewAdvice, setReviewAdvice] = React.useState("");

  const [open, setOpen] = React.useState(true);

  const [dummy, setDummy] = React.useState();

  useEffect(() => {
    setLoading(true);
    userLogging("ＱＣ工場チェック", "新規作成#初期表示", factoryCheckId);
    setUserPrivilege(user.jobCategory);

    setRowItemList([]);
    if (!factoryCheckId) return;
    let apiParams = 
    {
      "query":
        'query { ' + 
          'factoryCheckSearchById (' + 
            'id: "' + factoryCheckId + '"' +
          ')' +
          '{' +
            'id ' +
            'docLastId ' +
            'createDate ' +
            'createUser ' + 
            'updateDate ' +
            'updateUser ' +
            'score ' +
            'completeFlag ' + 
            'userPrivilege ' + 
            'score1 ' + 
            'score2 ' + 
            'score3 ' + 
            'score4 ' + 
            'score5 ' + 
            'score6 ' + 
            'score7 ' + 
            'score8 ' + 
            'jsonValue ' + 
            'createEmployeeNo ' + 
            'updateEmployeeNo ' + 
            'formatCreateDate ' +
            'formatUpdateDate ' +
            'submissionDeadline ' +
            'checkDate ' + 
            'vendorCode ' + 
            'vendorName ' + 
            'factoryCode ' + 
            'factoryName ' + 
            'qcAreaCode ' + 
            'qcAreaName ' + 
            'witness ' + 
            'categoryCheckValue ' + 
            'factoryCheckSelect ' +
            'manufacturCategory ' + 
            'reportCount ' +
            'auditorDepartmentName ' + 
            'auditorName ' + 
            'rank ' + 
            'factoryWitness ' + 
            'chargeQcEmployeeNo ' + 
            'chargeQcDate ' + 
            'chargeMgrEmployeeNo ' + 
            'chargeMgrDate ' + 
            'chargeAgmEmployeeNo ' + 
            'chargeAgmDate ' + 
            'reviewAdvice ' + 
            'corrections {' + 
              'correction_no ' + 
              'judgement_of_severity ' + 
              'standard ' + 
              'evaluation ' + 
              'follow_up ' + 
              'comment_advice' +
            '}' +
            'base_value ' +
            'add_value ' +
          '}' +
        '}'
    };
    const url = process.env.REACT_APP_API_URL + "/graphql";
    postRequest(url, apiParams)
    .then((response) => {
      let tempRes = response.data.data.factoryCheckSearchById;
      tempRes.base_value = JSON.parse(response.data.data.factoryCheckSearchById.base_value);
      tempRes.add_value = JSON.parse(response.data.data.factoryCheckSearchById.add_value);
      let res = tempRes;
      setSelectedFactoryId(res.vendorCode + res.factoryCode);
      setCheckDate(
        res.checkDate
          ? DateTime.fromFormat(res.checkDate, "yyyy/MM/dd hh:mm:ss").plus({ hour: 9 })
          : DateTime.local()
      );
      setFormatCreateDate(
        res.formatCreateDate
          ? DateTime.fromFormat(res.formatCreateDate, "yyyy/MM/dd")
          : DateTime.local()
      );
      setFormatUpdateDate(
        res.formatUpdateDate
          ? DateTime.fromFormat(res.formatUpdateDate, "yyyy/MM/dd")
          : DateTime.local()
      );
      checkCheckDate(
        res.checkDate ? res.checkDate : "",
        res.last_check_date
      );
      checkCheckHour(res.checkDate);
      if (res.checkDate) {
        setInputHour(
          res.checkDate
          ? DateTime.fromFormat(res.checkDate, "yyyy/MM/dd hh:mm:ss").plus({ hour: 9 }).hour.toString()
          : ""
        );
        setCheckHour(
          res.checkDate
          ? DateTime.fromFormat(res.checkDate, "yyyy/MM/dd hh:mm:ss").plus({ hour: 9 }).day.toString()
          : ""
        );
      } else {
        setInputHour("");
        setCheckHour("");
      }
      setLastCheckDate(res.lastCheckDate);
      setLastDateTitle(
        res.lastCheckDate
          ? DateTime.fromFormat(res.lastCheckDate, "yyyyMMdd").toFormat(
              "MM月dd日"
            )
          : "-"
      );
      setFactoryCheckSelect(res.factoryCheckSelect);
      setManufacturCategory(res.manufacturCategory);
      setReportCount(res.reportCount);
      setWitness(res.witness);
      setAuditorDepartmentName(res.auditorDepartmentName);
      setAuditorName(res.auditorName);
      setCorrections(res.corrections)
      setReviewAdvice(res.reviewAdvice);
      setCategoryCheckValue(res.categoryCheckValue);
      setSubmissionDeadline(
        res.submissionDeadline
          ? DateTime.fromFormat(res.submissionDeadline, "yyyy/MM/dd")
          : DateTime.local()
      );
      setSecondaryStartEnd(res.base_value);
      setRowItemList(res.base_value);
      setAddRowItemList(res.add_value);
      setLoading(false);
    })
    .catch((error) => {
      console.error("Error API: ", error);
    });

  }, [factoryCheckId]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setSearchFlg("1");
    setOpen(false);
  };
  function setSecondaryStartEnd(bigRecord:FACTORY_CHECK_BIG_RECORD[]){
    bigRecord.map((rowItem: any, indexRowItem: number) => {
      if(Object.keys(rowItem.rows).length === 0) return;
      let currentSecondaryCategory = "";
      rowItem.rows.map((row: any, indexRow: number) => {
        if (row.secondary_start !== undefined) return;
        row.secondary_start = false;
        row.secondary_end = true;
        if (currentSecondaryCategory !== row.secondary_category) {
          currentSecondaryCategory = row.secondary_category;
          row.secondary_start = true;
        }
        else if (indexRow !== 0) {
          rowItem.rows[indexRow - 1].secondary_end = false;
        }
      });
    });
  }
  const factoryMaster: Map<String, EXISTING_FACTORY_RECORD> = getAllExistingFactory().factory_data;
  let factoryRecordSearch = factoryMaster.get(selectedFactoryId);

  const imageToken = useSelector(selectImageToken);
  let currentIndex = -1;
  function isHeaderCheckImage(index: number) {
    let headerFlag = false;
    if (currentIndex !== index) {
      headerFlag = true;
      currentIndex = index;
    }
    return headerFlag;
  }

  function generateExcelURL() {
    let gcsUrl = process.env.REACT_APP_CLOUD_STORAGE_URL ? process.env.REACT_APP_CLOUD_STORAGE_URL : "";
    let gcsDir = process.env.REACT_APP_GCS_DIR_PDF ? process.env.REACT_APP_GCS_DIR_PDF : "";
    return (
      gcsUrl + gcsDir + "/" + factoryCheckId +".xlsx" +"?access_token=" + imageToken
    );
  }

  return (
    <>
    <ResultPaneModal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      // className={classes.modalPane}
    >
      <ResultAreaGrid
        container 
        alignItems="center"
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
      // className={classes.searchArea}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            {...{ xs: 11.5, sm: 11.5, md: 11.5, lg: 11.5 }}
          >
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              {...{ xs: 11.5, sm: 11.5, md: 11.5, lg: 11.5 }}
            >
              <FactoryCheckSearchResultTable
                resultList={[searchResultRecord]}
                updateResultList={updateResultList}
                setFactoryCheckId={setDummy}
                setSearchResultRecord={setDummy}
                setSearchFlg={setDummy}
                isReference={true}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            {...{ xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5 }}
          >
            <IconButton
              size="large"
              onClick={(e) => {
                handleClose();
              }}
            >
              <CloseIcon/>
            </IconButton>
          </Grid>
        </Grid>
        {/* メインコンテンツ↓ここから */}
        <ViewOverFlowGrid>
          <Grid id="topField" />
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            {/* 通常項目 */}
            {getCheckRecord(
                factoryCheckSelect, 
                rowItemList,
                addRowItemList,
                formatCreateDate ? formatCreateDate.toFormat("yyyy/MM/dd") : "",
                formatUpdateDate ? formatUpdateDate.toFormat("yyyy/MM/dd") : "",
                factoryRecordSearch,
                checkDate,
                inputHour,
                witness,
                categoryCheckValue,
                reportCount,
                manufacturCategory,
                submissionDeadline,
                imageToken,
                auditorDepartmentName,
                auditorName,
                // isHeaderCheckImage(index),
            )}
            {getCorrectionsTable(corrections, reviewAdvice)}
            {getPrintImage(
              factoryRecordSearch,
              witness,
              submissionDeadline,
              auditorDepartmentName,
              auditorName,
              rowItemList,
              addRowItemList,
              imageToken
            )}
            <Grid id="bottomField" />
          </Grid>
        </ViewOverFlowGrid>
        {/* メインコンテンツ↑ここまで */}
        {/* コンテンツフッター↓ここから */}
        <Grid
          paddingTop={"10px"}
          container
          justifyContent={"center"}
          alignItems={"center"}
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Grid
          {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
          </Grid>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
          >
            {loading ? (
              <>
              <OverLayer/>
              <LoadingCircule
                size={"40vh"}
              />
              </>
            ) : ""}
            <RegistrationButton
              variant="contained"
              // className={classes.button}
              onClick={() => {
                userLogging("工場チェック", "印刷#帳票出力", "");
                openWebSite(generateExcelURL());
              }}
            >
              印刷（Ｅｘｃｅｌダウンロード）<DownloadIcon />
            </RegistrationButton>
          </Grid>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <SeparatorLeftGrid>
              <UpDownButton
                onClick={() => moveScroll("topField")}
              >
                <VerticalAlignTop/>
              </UpDownButton>
            </SeparatorLeftGrid>
            <SeparatorLeftGrid>
              <UpDownButton
                onClick={() => moveScroll("bottomField")}
              >
                <VerticalAlignBottom/>
              </UpDownButton>
            </SeparatorLeftGrid>
          </Grid>
        </Grid>
        {/* コンテンツフッター↑ここまで */}
      </ResultAreaGrid>
    </ResultPaneModal>
    </>
  );
};
export default FactoryCheckSearchData;

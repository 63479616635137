import React, { FC, useLayoutEffect, useRef } from "react";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Search from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../../../src/features/userSlice";
import {
  SwipTempDrawerHandles,
  SwipeableTemporaryDrawer,
} from "../common/SwipeableTemporaryDrawer";
import { EXISTING_FACTORY_RECORD, getAllExistingFactory } from "../../data/existingFactory";
import { userLogging } from "../../apis/userLog";
import { styled } from '@mui/material/styles'
import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material/Select';
import SearchFactory from "../organisms/search/searchFactory/SearchFactory";
import { getAuthFactoryCheck, } from "../organisms/search/specification/UsageAuthority/AuthFactoryCheck";
import {
  updateFactoryId,
  updateFactoryFullName,
} from "../organisms/search/FactoryCheckSearchSlice";

const FactoryTypography = styled(Typography)(({ theme }) => ({
  color: "#3e3e3e",
}));

const FactoryAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: theme.shadows[4],
  width: "100%",
  height: "62px",
  color: "#FFFFFF",
  backgroundColor: "#FFFFFF",
  fontSize:"17px",
  fontWeight: "bold",
  padding: "0"
}));

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     title: {
//       color: "#3e3e3e",
//     },
//     headerTitle: {
//       backgroundColor: "#ffffff",
//     },
//     logoutButton: {
//       color: "white",
//       backgroundColor: "#1ba649",
//     },
//     toolbar: {
//       // ヘッダーの縦の調整はminHeight を再設定する
//       [theme.breakpoints.down("sm")]: {
//         minHeight: "36px",
//       },
//       [theme.breakpoints.up("md")]: {
//         minHeight: "48px",
//       },
//     },
//     rightItems: {
//       marginLeft: "auto",
//     },
//   })
// );

// 初期表示店舗コード
let initFactoryId = "";
export function setInitFactoryId(code: any) {
  initFactoryId = code;
}
export function getInitFactoryId() {
  return initFactoryId;
}
// 編集不可フラグ
let fixedFlg: boolean = false;
export function getFixedFlg() {
  return fixedFlg;
}
export function setFixedFlg(flg: boolean) {
  fixedFlg = flg;
}

// 初期表示フラグ
let firstFlg: boolean = false;
export function getFirstFlg() {
  return firstFlg;
}
export function setFirstFlg(flg: boolean) {
  firstFlg = flg;
}

function setSelectItem(code: string, name: string) {
  return { code, name };
}

const ButtonAppBar: FC<{
  titleName: string;
  factoryChange?: (argFactoryId: string) => void;
  modalCondition?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}> = ({ titleName, factoryChange, modalCondition }) => {
  // const classes = useStyles();
  const user: any = useSelector(selectUser);
  // const storeMaster: Map<String, EXISTING_SEJ_STORES_RECORD> = getAllExistingSejStores().store_data;
  const factoryMaster: Map<String, EXISTING_FACTORY_RECORD> = getAllExistingFactory().factory_data;
  const [factoryId, setFactoryId] = React.useState(initFactoryId);
  const swipTempDrawerRef = useRef<SwipTempDrawerHandles>(null);
  const dispatch = useDispatch();
  // 検索ボタン押下時処理
  function saveStatusBottun(
    argFactoryId: string,
    argFactoryFullName: string
  ) {
    dispatch(
      updateFactoryId({
        factoryId: argFactoryId,
      })
    );
    dispatch(
      updateFactoryFullName({
        factoryFullName: argFactoryFullName,
      })
    );
  }
  let factoryList = [setSelectItem("", "")];
  // for (let i = 0; i < user.factoryId.length; i++) {
  //   // 店舗レコードを内部データから取得
  //   let factoryRecord = factoryMaster.get(user.factoryId[i]);
  //   if (factoryRecord) {
  //     factoryList.push(
  //       setSelectItem(
  //         user.factoryId[i],
  //         storeRecord.factory_name
  //       )
  //     );
  //   }
  // }
  let factoryRecordSearch = factoryMaster.get(factoryId);
  // データ検索時に担当店以外の店舗名が表示できるようにする
  if (factoryRecordSearch) {
  //   if (!user.factoryId.includes(factoryId)) {
      factoryList.push(
        setSelectItem(factoryId, factoryRecordSearch.factory_full_name)
      );
  //   }
  }

  function openSwipTempDrawer() {
    swipTempDrawerRef.current?.openDrawer();
  }
  const modalConditionDo = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (modalCondition) {
      modalCondition(event);
    }
  };

  // 店番変更時のタブリセット
  useLayoutEffect(() => {
    if (!firstFlg) return;
    setFactoryId("");
    dispatch(
      updateFactoryFullName({
        factoryFullName: "",
      })
    );
  }, [firstFlg]);

  const factoryChangeDo = (arg: string) => {
    setFactoryId(arg);
    if (factoryChange) {
      factoryChange(arg);
    }
  };

  return (
    <FactoryAppBar position="static" 
    // className={classes.headerTitle}
    >
      <Toolbar 
      // className={classes.toolbar}
      >
        <Grid
          container 
          justifyContent="flex-start"
          alignItems="center"
          direction="row"
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Grid
            container 
            justifyContent="flex-start"
            alignItems="center"
            direction="row"
            {...{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
            // sx={{
            //   '--Grid-borderWidth': '4px',
            //   borderTop: 'var(--Grid-borderWidth) solid',
            //   borderLeft: 'var(--Grid-borderWidth) solid',
            //   borderColor: 'divider',
            //   '& > div': {
            //     borderRight: 'var(--Grid-borderWidth) solid',
            //     borderBottom: 'var(--Grid-borderWidth) solid',
            //     borderColor: 'divider',
            //   },
            // }}
          >
            <Grid>
              <SwipeableTemporaryDrawer ref={swipTempDrawerRef} />
            </Grid>
            <Grid>
              <FactoryTypography variant="h6" 
              // className={classes.title}
              >
                {titleName}
              </FactoryTypography>
            </Grid>
          </Grid>
          {factoryChange ? (
          <>
          <Grid
            container 
            justifyContent="flex-end"
            alignItems="center"
            direction="row"
            {...{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
          >
            <SearchFactory
              disabled={false}
              firstView={false}
              initialDisplay={true}
              onClickFunc={(factoryId: string, factoryFullName: string) => {
                factoryChangeDo(factoryId);
                saveStatusBottun(factoryId, factoryFullName);
              }}
            />
          </Grid>
          </>
          ) : modalCondition ? (
          <>
          <Grid
            container 
            justifyContent="flex-end"
            alignItems="center"
            direction="row"
            {...{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
          >
            <Button onClick={(e: any) => modalConditionDo(e)}>
              <Search style={{ color: "#4DC0B2" }}></Search>
            </Button>
          </Grid>
          </>
          ) : (
            ""
          )}
        </Grid>
      </Toolbar>
    </FactoryAppBar>
  );
};
export default ButtonAppBar;

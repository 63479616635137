import React, { useEffect, FC } from "react";

import {
  Modal,
  Button,
  Grid,
  Typography,
  IconButton,
  styled,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import PhotoCamera from "@mui/icons-material/PhotoCamera";

import { getFixedFlg } from "../../Header/Header";
import FactoryCheckDialog from "../FactoryCheckDialog";
import PhotoAndComment from "../../organisms/photo/PhotoAndComment";
import {
  FACTORY_CHECK_COMMENTS,
} from "./FactoryCheckDataOperation";

const UploadGrid = styled(Grid)(({ theme }) => ({
  height: "7vh",
}));

const ModalPane = styled(Modal)(({ theme }) => ({
  position: "absolute",
  paddingTop: "2%",
  paddingLeft: "5%",
  width: "84%",
  height: "98%",
}));

const PaperGrid = styled(Grid)(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
}));

const TitleGrid = styled(Grid)(({ theme }) => ({
  height: "7vh",
  color: "white",
  backgroundColor: "#4dc0b2",
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
}));

const FactoryCheckModalButton: FC<{
  // ダイアログのタイトル
  title: string;
  // コメント
  comments: Array<FACTORY_CHECK_COMMENTS>;
  onClickSave?: (
    comments: Array<FACTORY_CHECK_COMMENTS>,
  ) => void;
  factoryId?: string;
}> = ({ title, comments, onClickSave, factoryId }) => {
  const [tempComments, setTempComments] = React.useState<Array<FACTORY_CHECK_COMMENTS>>([]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);

  const dialogClose = () => {
    setDialogFlg(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (Array.isArray(comments) && comments.length === 0) return;
    let commentArray:Array<FACTORY_CHECK_COMMENTS> = [];
    comments.map((comment: FACTORY_CHECK_COMMENTS, index: number) => {
      commentArray.push({
        user_input_comment: comment.user_input_comment,
        photo_filepath_gcs: comment.photo_filepath_gcs,
        photo_file_data: comment.photo_file_data,
        comment_filepath_gcs: comment.comment_filepath_gcs,
        comment_file_data: comment.comment_file_data,
        comment_handwrite_flag: comment.comment_handwrite_flag,
      });
    });
    setTempComments(commentArray);
  }, [open]);

  // 保存ボタン押下
  const handleSave = async () => {
    // 閲覧モード判定
    if (getFixedFlg()) {
      setOpen(false);
      return;
    }
    // 保存用ファンクション判定
    if (onClickSave) {
      let commentArray:Array<FACTORY_CHECK_COMMENTS> = [];
      tempComments.map((comment: FACTORY_CHECK_COMMENTS, index: number) => {
        if (comment.photo_filepath_gcs !== "" ||
          comment.photo_file_data !== "" ||
          comment.user_input_comment !== "" ||
          comment.comment_filepath_gcs !== "" ||
          comment.comment_file_data !== ""
        ) {
          commentArray.push(comment);
        }
      });
      if (commentArray.length === 0) {
        commentArray.push({
          user_input_comment: "",
          photo_filepath_gcs: "",
          photo_file_data: "",
          comment_filepath_gcs: "",
          comment_file_data: "",
          comment_handwrite_flag: "",
        });
      }
      setTempComments(commentArray);
      onClickSave(commentArray);
    }
    // ダイアログを閉じる
    setOpen(false);
  };

  // ダイアログクローズ処理
  const handleClose = () => {
    if (loading) {
      return;
    }
    setOpen(false);
  };

  // 
  const handleAdd = () => {
    if (loading) {
      return;
    }
    if (tempComments.length > 2) {
      setDialogMessage(["これ以上追加出来ません。"]);
      setDialogFlg(true);
      return;
    }
    let commentArray:Array<FACTORY_CHECK_COMMENTS> = [];
    tempComments.map((comment: FACTORY_CHECK_COMMENTS, index: number) => {
      commentArray.push({
        user_input_comment: comment.user_input_comment,
        photo_filepath_gcs: comment.photo_filepath_gcs,
        photo_file_data: comment.photo_file_data,
        comment_filepath_gcs: comment.comment_filepath_gcs,
        comment_file_data: comment.comment_file_data,
        comment_handwrite_flag: comment.comment_handwrite_flag,
      });
    });
    commentArray.push({
      user_input_comment: "",
      photo_filepath_gcs: "",
      photo_file_data: "",
      comment_filepath_gcs: "",
      comment_file_data: "",
      comment_handwrite_flag: "",
    });
    setTempComments(commentArray);
  };


  // 
  const handleDelete = (argIndex: number) => {
    if (loading) {
      return;
    }
    if (tempComments.length === 0) {
      setDialogMessage(["削除出来ません。"]);
      setDialogFlg(true);
      return;
    }
    let commentArray:Array<FACTORY_CHECK_COMMENTS> = [];
    tempComments.map((comment: FACTORY_CHECK_COMMENTS, index: number) => {
      if (argIndex !== index) {
        commentArray.push(comment);
      }
    });
    setTempComments(commentArray);
  };

  function isUploaded(comments: Array<FACTORY_CHECK_COMMENTS>): any {
    let camera_button_color = {color: '#f2f2f2'};
    comments.map((comment: FACTORY_CHECK_COMMENTS, index: number) => {
      if (
        comment.user_input_comment !== "" ||
        comment.photo_filepath_gcs !== "" ||
        comment.photo_file_data !== "" ||
        comment.comment_filepath_gcs !== "" ||
        comment.comment_file_data !== ""
      ) {
        camera_button_color = {color: '#37ab9d'};
        return;
      }
    });
    return camera_button_color;
  }

  return (
    <>
      {
      // カメラボタンここから↓
      }
      <UploadGrid
        container
        justifyContent={"center"}
        alignItems={"center"} 
      >
        <IconButton
          component="span"
          onClick={handleOpen}
          sx={isUploaded(tempComments)}
        >
          <PhotoCamera fontSize="large" />
        </IconButton>
      </UploadGrid>
      {
      // カメラボタンここまで↑
      }
      {
      // モーダルここから↓
      }
      <ModalPane
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <PaperGrid container 
          style={{ overflowY: "auto" }}
        >
          {
          // タイトルここから↓
          }
          <TitleGrid
            container
            justifyContent={"center"}
            alignItems={"center"} 
          >
            <TitleTypography variant="h6" 
            >
              {title}
            </TitleTypography>
          </TitleGrid>
          {
          // タイトルここまで↑
          }
          {tempComments.map((comment: FACTORY_CHECK_COMMENTS, index: number) => (
            <PhotoAndComment
              comment={comment}
              handwriteFlag={comment.comment_handwrite_flag}
              index={index}
              length={tempComments.length}
              factoryId={factoryId}
              handleDelete={handleDelete}
            />
          ))}
          {
          // ボタン↓ここから
          }
          <Grid container 
            justifyContent={"center"}
            alignItems={"center"} 
          >
            <Grid item xs={6}>
              {tempComments.length < 3 ? (
              <Grid container 
                justifyContent={"flex-start"}
                alignItems={"flex-start"} 
              >
                <IconButton
                  onClick={(e) => {
                    handleAdd();
                  }}
                  size={"small"}
                  style={{ marginLeft: "8px" }}
                >
                  <AddIcon/>枠を追加
                </IconButton>
              </Grid>
              ) : <></>}
            </Grid>
            <Grid item xs={6}>
            </Grid>
          </Grid>
          <Grid container 
            justifyContent={"center"}
            alignItems={"center"} 
          >
            <Grid item xs={6}>
              <Grid container 
                justifyContent={"center"}
                alignItems={"center"} 
              >
                <Button
                  style={{ width: "30vw" }}
                  variant="contained"
                  fullWidth={true}
                  onClick={handleClose}
                  disabled={loading}
                >
                  キャンセル
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container 
                justifyContent={"center"}
                alignItems={"center"} 
              >
                {getFixedFlg() ? (
                  ""
                ) : (
                  <Button
                    style={{ width: "30vw" }}
                    variant="contained"
                    fullWidth={true}
                    onClick={(e) => handleSave()}
                    color="primary"
                    disabled={loading}
                  >
                    保存
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          {
          // ボタン↑ここまで
          }
          {
          // ダイアログ
          }
          <FactoryCheckDialog
            msg={dialogMessage}
            isOpen={dialogFlg}
            doOK={dialogClose}
            doNo={dialogClose}
            isAlert={true}
          />
        </PaperGrid>
      </ModalPane>
      {
      // モーダル↑ここまで
      }
    </>
  );
};
export default FactoryCheckModalButton;

import React, { useEffect, useLayoutEffect, useRef, FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import {
  TextField,
  Checkbox,
  Typography,
  Snackbar,
  SnackbarContent,
  IconButton,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  Stack,
  Box,
  Button,
  Radio,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import {
  VerticalAlignTop,
  VerticalAlignBottom,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { yellow, green, grey } from '@mui/material/colors';

import { limitLengthAndLine } from "../../../apis/util"
import { getFixedFlg, setFixedFlg } from "../../Header/Header";
import SejDatePicker from "../../common/SejDatePicker";
import FactoryCheckModalButton from "./FactoryCheckModalButton";
import FactoryCheckModalManual from "./FactoryCheckModalManual";
import { getDayOfWeekText } from "../../../apis/util";
import FactoryCheckSelect from "./FactoryCheckSelect";
import {
  getFactoryCheckData,
  FACTORY_CHECK_RECORD,
  updateFactoryCheck,
  updateFactoryCheckFirestore,
  FACTORY_CHECK_DATA,
  FACTORY_CHECK_BIG_RECORD,
  REQUEST_FOR_CORRECTION,
  getFactoryCheckRecordsFirestoreData,
  FACTORY_CHECK_COMMENTS,
  FACTORY_CHECK_ADD_BIG_RECORD,
  FACTORY_CHECK_ADD_RECORD,
  sortAddItems,
  updateFactoryCheckAddValue,
  updateFactoryCheckAddItem,
  createNewRecord,
  deleteAddItems,
  FACTORY_CHECK_ADD_NUMBER,
  defaultComments,
} from "./FactoryCheckDataOperation";
import { FACTORY_CHECK_SCORES, updateScore } from "./FactoryCheckCalculation";
import FactoryCheckDialog from "../FactoryCheckDialog";
import FactoryCheckTemplateModalButton from "./FactoryCheckTemplateModalButton";

import { userLogging } from "../../../apis/userLog";
import { selectUser } from "../../../features/userSlice";
import FactoryCheckModalLastScore from "./FactoryCheckModalLastScore";
import { postRequest } from "../../../apis/axiosAction";
import SejTimePicker from "../../common/SejTimePicker";
import {
  OverLayer,
  OverFlowGrid,
  BaseGrid,
  SeparatorGrid,
  SeparatorLeftGrid,
  HeaderGrid,
  MiddleHeaderGrid,
  ContentsGrid,
  MiddleContentsGrid,
  InputGrid,
  DataHeaderGrid,
  DataContentsGrid,
  DataInputGrid,
  ContentsBasicGrid,
  FactoryAccordionSummary,
  FactoryAccordion,
  AccordionSummaryGrid,
  FactoryButtonTypography,
  SaveButton,
  UpDownButton,
  LoadingCircule,
} from "../Styled/FactoryCheckStyled";
import { EXISTING_FACTORY_RECORD, getAllExistingFactory } from "../../../data/existingFactory";
import { db } from "../../../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import { uploadGoogleCloudStorage, convertToFile } from "../../../apis/photoAction";
import { v4 as uuidv4 } from "uuid";

// 画面項目の変更フラグ
let changeFlg = false;
export function getChangeFlg() {
  return changeFlg;
}

export function setChangeFlg(flg: boolean) {
  changeFlg = flg;
}

// チェック項目の変更フラグ
let selectedCategoryFlg = false;
export function isSelectedCategory() {
  return selectedCategoryFlg;
}

function setSelectedCategory(flg: boolean) {
  selectedCategoryFlg = flg;
}

let i = 0; 
// 全角数字半角変換
function zenkakuReplace(str: string) {
  return str.replace(/[０-９．]/g, function (s: string) {
    let result = String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    return result;
  });
}

// 監査日の入力チェック
let isChecked: number = 0;
export function isCheckDate() {
  return isChecked;
}

function checkCheckDate(dayStr: string | null, lastCheckDate: string) {
    if (dayStr && DateTime.fromFormat(dayStr, "yyyyMMdd").isValid) {
      if (lastCheckDate !== "") {
        if (dayStr && dayStr >= lastCheckDate) {
          isChecked = 1;
        }
        else {
          isChecked = 0;
        }
      }
      else {
        isChecked = 1;
      }
    }
    else {
      isChecked = -1;
    }
}

// 監査時間帯の入力チェック
let isCheckHourInput: number = 0;
export function isCheckHour() {
  return isCheckHourInput;
}

function checkCheckHour(hourStr: string) {
  let hourNum: number = Number(hourStr);
  if (hourNum || (hourStr && hourNum === 0)) {
    isCheckHourInput = hourNum;
  } else {
    isCheckHourInput = -1;
  }
}

// 最初の読み込み時に追い越しが走らないようにするためのフラグ
let initailizeFlag: boolean;

// クリックされたことを判定するカウント
let clickCount = 0;

// 合計スコア（保存処理用）
let scoresList: Array<FACTORY_CHECK_SCORES> = [];

// 作成日（保存処理用）
let saveCheckDate: DateTime | null;

// 作成日（保存処理用）
let saveCheckHour: string;

//  改善報告書提出期限（保存処理用）
let saveSubmissionDeadline: DateTime | null;

const FactoryCheckTable: FC<{
  factoryId: string;
  factoryCheckId: string;
  lastFactoryCheckId: string;
}> = ({ factoryId, factoryCheckId, lastFactoryCheckId }) => {

  // const classes = stylesFactoryCheckTable();

  // 監査日時
  const [checkDate, setCheckDate] = React.useState<DateTime | null>(null);
  // 前回監査日時
  const [lastCheckDate, setLastCheckDate] = React.useState("");
  const [lastDateTitle, setLastDateTitle] = React.useState("");
  // フォーマット作成日
  const [formatCreateDate, setFormatCreateDate] = React.useState<DateTime | null>(null);
  // フォーマット更新日
  const [formatUpdateDate, setFormatUpdateDate] = React.useState<DateTime | null>(null);
  // カテゴリ選択
  const [factoryCheckSelect, setFactoryCheckSelect] = React.useState([false, false, false, false, false]);
  // 回数
  const [reportCount, setReportCount] = React.useState("");
  // 立会人
  const [witness, setWitness] = React.useState("");
  // 製造カテゴリーチェックボックス
  const [categoryCheckValue, setCategoryCheckValue] = React.useState([false, false, false, false, false, false, false, false, false]);
  // 製造カテゴリーその他
  const [manufacturCategory, setManufacturCategory] = React.useState("");
  // 改善報告書提出期限
  const [submissionDeadline, setSubmissionDeadline] = React.useState<DateTime | null>(null);

  // == 是正要請 ==
  // 重篤性判断 judgementOfSeverity
  // 基準 standard
  // 記録 evaluation
  // 連単 followUp
  // コメント・助言 commentAdvice
  const [corrections, setCorrections] = React.useState<REQUEST_FOR_CORRECTION[]>([]);
  // 総評・助言
  const [reviewAdvice, setReviewAdvice] = React.useState("");
  // == ====== ==

  // 編集可否
  const [isFixed, setIsFixed] = React.useState(false);

  const [click, setClick] = React.useState(false);
  const [addMode, setAddMode] = React.useState(false);
  const [addValue, setAddValue] = React.useState("");
  const [delCkList, setDelCkList] = React.useState([""]);
  const [deleteMode, setDeleteMode] = React.useState(false);
  const [sortMode, setSortMode] = React.useState(false);
  const [checkedId, setCheckedId] = React.useState("");
  const [openTemplateEditAlert, setOpenTemplateEditAlert] = React.useState(false);
  const [scrollSwitch, setScrollSwitch] = React.useState(false);
  const user = useSelector(selectUser);

  const [rowItemList, setRowItemList] = React.useState<
    FACTORY_CHECK_BIG_RECORD[]
  >([]);
  const [addRowItemList, setAddRowItemList] = React.useState<
    FACTORY_CHECK_ADD_BIG_RECORD[]
  >([]);
  const [selectedFactoryId, setSelectedFactoryId] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [snapshotLoading, setSnapshotLoading] = React.useState(false);
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const [userPrivilege, setUserPrivilege] = React.useState("");
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);

  const [dialogFlg, setDialogFlg] = React.useState(false);
  const dialogClose = () => {
    setDialogFlg(false);
  };
  const [openAlert, setOpenAlert] = React.useState(false);
  //  監査時間帯
  const [checkHour, setCheckHour] = React.useState("");

  const blurHour = (hour: string) => {
    setCheckHour(String(hour));
    checkCheckHour(hour);
    setChangeFlg(true);
  };

  const alertClick = () => {
    setOpenAlert(true);
  };

  const alertClose = () => {
    setOpenAlert(false);
  };

  type RecordsType =
  firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
  const [snapshot, setSnapshot] = React.useState<RecordsType>();
  const factoryCheckRecords = async () => {
    // return new Promise((resolve) => {
      await db.collection("factory_check_records")
      .doc(factoryCheckId)
      .onSnapshot(docSnapshot => {
          setSnapshot(docSnapshot);
        // resolve(docSnapshot.data());
        return docSnapshot.data();
      })
    // });
  };

  const onReload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = "";
  };

  useEffect(() => {
    // リロード時の警告ダイアログ用
    window.addEventListener('beforeunload', onReload);
    return () => {
      window.removeEventListener('beforeunload', onReload);
    };
  }, []);

  useLayoutEffect(() => {
    if (snapshot === undefined) return;
    setSnapshotLoading(true);
    initailizeFlag = false;
    getFactoryCheckData(
      factoryId,
      factoryCheckId,
      lastFactoryCheckId,
      getFixedFlg(),
      user.jobCategory,
      snapshot?.data()
    ).then((res: FACTORY_CHECK_DATA) => {
      if (res.complete_date || (res.is_fixed === true && getFixedFlg() === false)) {
        setDialogMessage(["表示されているデータは提出中または提出済みです。", "工場選択からやりなおしてください。"]);
        setDialogFlg(true);
        setFixedFlg(true);
        // setLoading(false);
        setSnapshotLoading(false);
      }
      else if (res.is_fixed === false) {
        setFixedFlg(false);
      }
      setCheckDate(
        res.check_date
          ? DateTime.fromFormat(res.check_date, "yyyyMMdd")
          : DateTime.local()
      );
      setFormatCreateDate(
        res.format_create_date
          ? DateTime.fromFormat(res.format_create_date, "yyyy/MM/dd")
          : DateTime.local()
      );
      setFormatUpdateDate(
        res.format_update_date
          ? DateTime.fromFormat(res.format_update_date, "yyyy/MM/dd")
          : DateTime.local()
      );
      saveCheckDate = res.check_date
        ? DateTime.fromFormat(res.check_date, "yyyyMMdd")
        : DateTime.local();
      checkCheckDate(
        res.check_date ? res.check_date : "",
        res.last_check_date
      );
      if (res.check_date) {
        setChangeFlg(false);
      } else {
        setChangeFlg(true);
      }
      checkCheckHour(res.check_hour);
      if (res.check_hour) {
        saveCheckHour = res.check_hour
        setCheckHour(res.check_hour);
      } else {
        saveCheckHour = "00";
        setCheckHour("00");
      }
      setLastCheckDate(res.last_check_date);
      setLastDateTitle(
        res.last_check_date
          ? DateTime.fromFormat(res.last_check_date, "yyyyMMdd").toFormat(
              "MM月dd日"
            )
          : "-"
      );
      setFactoryCheckSelect(res.factory_check_select);
      setManufacturCategory(res.manufactur_category);
      setReportCount(res.report_count);
      setWitness(res.witness);
      setCorrections(res.corrections)
      setReviewAdvice(res.review_advice);
      setIsFixed(res.is_fixed);
      setCategoryCheckValue(res.category_check_value);
      setSelectedCategory(false);
      for (const checkFlg of res.factory_check_select) {
        if (checkFlg === true) {
          setSelectedCategory(checkFlg);
          break;
        }
      }
      setSubmissionDeadline(
        res.submission_deadline
          ? DateTime.fromFormat(res.submission_deadline, "yyyyMMdd")
          : DateTime.local()
      );
      saveSubmissionDeadline = res.submission_deadline
        ? DateTime.fromFormat(res.submission_deadline, "yyyyMMdd")
        : DateTime.local();
      scoresList = updateScore(res.base_value, res.factory_check_select);
      setRowItemList(res.base_value);
      setAddRowItemList(res.add_value);
      initailizeFlag = true;
      if (saving) {
        setSaving(false);
      }
      else {
        setSnapshotLoading(false);
        setLoading(false);
      }
    });
  }, [snapshot]);

  useLayoutEffect(() => {
    setLoading(true);
    // setSnapshotLoading(true);
    userLogging("ＱＣ工場チェック", "新規作成#初期表示", factoryCheckId);
    setUserPrivilege(user.jobCategory);

    setRowItemList([]);
    initailizeFlag = false;
    setSelectedFactoryId(factoryId);
    if (!factoryCheckId) return;
    factoryCheckRecords();
  }, [factoryCheckId]);

  const handleClick = () => {
    setClick((prev) => !click);
  };

  const handleCheckDate = (date: DateTime | null) => {
    if (!initailizeFlag) return date;
    setChangeFlg(true);
    checkCheckDate(date ? date.toFormat("yyyyMMdd") : "", lastCheckDate);
    setCheckDate(date);
    saveCheckDate = date;
    return date;
  };

  const initCheckDate = () => {
    checkCheckDate(
      checkDate ? checkDate.toFormat("yyyyMMdd") : "",
      lastCheckDate
    );
    if (checkDate !== null) return checkDate;
    let date = DateTime.local();
    setCheckDate(date);
    saveCheckDate = date;
    return date;
  };

  const handleCheckHour = (hour: string) => {
    if (!initailizeFlag) return hour;
    setChangeFlg(true);
    checkCheckHour(hour);
    setCheckHour(hour);
    saveCheckHour = hour;
    return hour;
  };

  const initCheckHour = () => {
    checkCheckHour(
      checkHour ? checkHour : ""
    );
    let date = DateTime.local();
    if (checkHour !== null) {
      date = date.set({ hour: Number(checkHour)});
      return date;
    }
    let hour = date.toFormat("HH");
    setCheckHour(hour);
    saveCheckHour = hour;
    return date;
  };

  //  改善報告書提出期限 submissionDeadline
  const handleSubmissionDeadline = (date: DateTime | null) => {
    if (!initailizeFlag) return date;
    setChangeFlg(true);
    setSubmissionDeadline(date);
    saveSubmissionDeadline = date;
    return date;
  };

  const initSubmissionDeadline = () => {
    if (submissionDeadline !== null) return submissionDeadline;
    let date = DateTime.local();
    setSubmissionDeadline(date);
    saveSubmissionDeadline = date;
    return date;
  };

  // ボタン押下処理
  const clickButton = (row: any, n: number) => {
    if (getFixedFlg()) return;

    setChangeFlg(true);
    row.button_value = "0";
    row.user_radio_comment = "";
    row.user_radio_target = "";
    row.user_ng_list = [];
    if (n === 1) {
      if (row.button_value !== "1") {
        row.button_value = "1";
        row.user_radio_comment = "";
        row.user_radio_target = "";
        row.user_ng_list = [];
      }
    }
    if (n === 2) {
      if (row.button_value !== "2") {
        row.button_value = "2";
      }
    }
    if (n === 3) {
      if (row.button_value !== "3") {
        row.button_value = "3";
        row.user_radio_comment = "";
        row.user_radio_target = "";
        row.user_ng_list = [];
      }
    }
    handleClick();
    ++clickCount;
    if (row.big_item_id < FACTORY_CHECK_ADD_NUMBER) {
      setRowItemList(updateFactoryCheck(rowItemList, row));
    } else {
      setAddRowItemList(updateFactoryCheckAddValue(addRowItemList, row));
    }
    // updateFactoryCheckAddItem(addRowItemList, selectedFactoryId, userPrivilege);
    scoresList = updateScore(rowItemList, factoryCheckSelect);
  };

  // ×ボタン押下処理
  const clickNgButton = (row: any) => {
    if (getFixedFlg()) return;

    setChangeFlg(true);
    if (row.button_value !== "2") {
      row.button_value = "2";
    } else if (!row.user_ng_list || row.user_ng_list.length === 0) {
      row.button_value = "0";
    }
    handleClick();
    ++clickCount;
    if (row.big_item_id < FACTORY_CHECK_ADD_NUMBER) {
      setRowItemList(updateFactoryCheck(rowItemList, row));
    } else {
      setAddRowItemList(updateFactoryCheckAddValue(addRowItemList, row));
    }
    scoresList = updateScore(rowItemList, factoryCheckSelect);
  };

  
  /**
   * 新規作成チェックタブのスコアリスト作成及びデータリストの更新
   * 
   * @param rowItemList 
   * @param factoryCheckSelect 
   * @returns 
   */
  const updateFactoryCheckSelect = (
    factoryCheckSelect: boolean[]
  ) => {
    // ボタンのカウント
    for (let i = 0; i < rowItemList.length; i++) {
      // カテゴリ選択有無を判定
      if (!factoryCheckSelect[i]) {
        for (let j = 0; j < rowItemList[i].rows.length; j++) {
          rowItemList[i].rows[j].button_value = "0";
          rowItemList[i].rows[j].score = "0";
          rowItemList[i].rows[j].comments = [defaultComments]
        }
      }
    }
  };

  // ボタン押下処理
  const selectfactoryCheck = (n: number) => {
    // 閲覧モードは編集不可
    if (getFixedFlg()) return;
    // チェック対象判定
    factoryCheckSelect[n] = !factoryCheckSelect[n];
    // 
    setFactoryCheckSelect(factoryCheckSelect);
    setChangeFlg(true);
    updateFactoryCheckSelect(factoryCheckSelect);
    scoresList = updateScore(rowItemList, factoryCheckSelect);
    // saveFactoryCheck();
    handleClick();
    ++clickCount;
  };

  // ボタン押下処理
  const manufacturCategoryCheck = (n: number) => {
    // 閲覧モードは編集不可
    if (getFixedFlg()) return;
    // 変更フラグ設定
    setChangeFlg(true);
    // チェック対象判定
    categoryCheckValue[n] = !categoryCheckValue[n];
    // 
    setCategoryCheckValue(categoryCheckValue);
    handleClick();
    // ++clickCount;
  };

  // 保存ボタンクリック処理
  const clickConfirmButton = () => {
    if (getFixedFlg()) return;
    if (!navigator.onLine) {
      setDialogMessage([
        "サーバーに接続できませんでした。",
        "通信環境の良い場所に移動してから再度お試しください。"
      ]);
      setDialogFlg(true);
      return;
    }
    if (!initailizeFlag) return;
    userLogging("ＱＣ工場チェック", "新規作成#保存ボタン", "");
    //保存処理
    setChangeFlg(false);
    saveAddItems(addRowItemList, selectedFactoryId, userPrivilege);
    saveFactoryCheck();
    handleClick();
    alertClick();
  };

  const onClickPhotoButton = (
    row: FACTORY_CHECK_RECORD,
    tempComments: Array<FACTORY_CHECK_COMMENTS>,
  ) => {
    row.comments = tempComments;
    setChangeFlg(true);
    handleClick();
  };

  // 追加項目保存処理
  const saveAddItems = async (
    addRowItemList: FACTORY_CHECK_ADD_BIG_RECORD[],
    factoryCheckId: string,
    userPrivilege: string
  ) => {
    // 既に完了している場合は更新しない
    const factoryCheckData: any = await getFactoryCheckRecordsFirestoreData(factoryCheckId);
    if (factoryCheckData && factoryCheckData.attribution.complete_date) return;

    updateFactoryCheckAddItem(addRowItemList, factoryCheckId, userPrivilege);
  };

  // 追加項目の行番号をソート
  function sortAddRow() {
    if (!addRowItemList[0].rows) return;
    let rowNum: number = 0;
    for (let i = 0; i < addRowItemList[0].rows.length; i++) {
      addRowItemList[0].rows[i].small_item_id = rowNum;
      rowNum++;
    }
    return;
  }
  // 削除対象全チェック判定
  function ckAllDelCheck() {
    for (let i = 0; i < addRowItemList[0].rows.length; i++) {
      let targetRow = addRowItemList[0].rows[i];
      if (targetRow.big_item_id === FACTORY_CHECK_ADD_NUMBER && delCkList.indexOf(targetRow.key) === -1) {
        return false;
      }
    }
    return true;
  }

  // 削除モード削除対象全チェック
  const clickAllDelCheck = () => {
    let dcList = [];
    if (!ckAllDelCheck()) {
      for (let i = 0; i < addRowItemList[0].rows.length; i++) {
        if (addRowItemList[0].rows[i].big_item_id === 10 || 
          addRowItemList[0].rows[i].big_item_id === 11) {
            continue;
        }
        dcList.push(addRowItemList[0].rows[i].key);
      }
    }
    setDelCkList(dcList);
    handleClick();
  };
  // 削除モード削除対象チェック
  const clickDelCheck = (key: string) => {
    let dcList = delCkList;
    if (delCkList.includes(key)) {
      dcList = [];
      for (let i = 0; i < delCkList.length; i++) {
        if (delCkList[i] !== key) {
          dcList.push(delCkList[i]);
        }
      }
    } else {
      dcList.push(key);
    }
    setDelCkList(dcList);
    handleClick();
  };

  // 上移動
  const upClick = () => {
    setChangeFlg(true);
    if (checkedId) {
      userLogging("店舗確認表", "新規作成#並び替え上移動", checkedId);
      const obj = sortAddItems(addRowItemList, true, checkedId);
      setCheckedId(obj.nextKey);
      // saveFactoryCheck();
      // saveAddItems(
      //   obj.addRowItemList,
      //   selectedFactoryId,
      //   userPrivilege
      // );
      handleClick();
    }
  };
  // 下移動
  const lowClick = () => {
    setChangeFlg(true);
    if (checkedId) {
      userLogging("店舗確認表", "新規作成#並び替え下移動", checkedId);
      const obj = sortAddItems(addRowItemList, false, checkedId);
      setCheckedId(obj.nextKey);
      // saveFactoryCheck();
      // saveAddItems(
      //   obj.addRowItemList,
      //   selectedFactoryId,
      //   userPrivilege
      // );
      handleClick();
    }
  };

  // 追加ボタンクリック
  const addClick = () => {
    if (addRowItemList[0] && addRowItemList[0].rows.length > 19) {
      setDialogMessage(["20項目を超えるため追加できません。"]);
      setDialogFlg(true);
      return;
    }
    if (addValue !== "") {
      userLogging("店舗確認表", "新規作成#追加ボタン処理", addValue);
      const newRecord: FACTORY_CHECK_ADD_RECORD = createNewRecord(
        addValue,
        addRowItemList[0],
        [defaultComments]
      );
      const newAddRecord: FACTORY_CHECK_ADD_BIG_RECORD[] =
        updateFactoryCheckAddValue(addRowItemList, newRecord, true);
      setAddRowItemList(newAddRecord);
      // saveAddItems(addRowItemList, selectedFactoryId, userPrivilege);
      // saveFactoryCheck();
      setAddValue("");
      setChangeFlg(true);
    }
    sortAddRow();
    handleClick();
    setScrollSwitch(!scrollSwitch);
  };

  // テンプレートによる項目追加
  const onTemplateClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    templeteItems: any[]
  ) => {
    if (addRowItemList[0].rows.length + templeteItems.length > 20) {
      setDialogMessage(["20項目を超えるため追加できません。"]);
      setDialogFlg(true);
      return;
    }
    let addRowItem: any[] = [];
    for (let i = 0; i < templeteItems.length; i++) {
      let addFlg = true;
      if (addRowItemList[0]) {
        for (let j = 0; j < addRowItemList[0].rows.length; j++) {
          if (templeteItems[i].name === addRowItemList[0].rows[j].small_title) {
            addFlg = false;
            break;
          }
        }
      }
      if (addFlg) {
        addRowItem.push(templeteItems[i]);
      }
    }
    for (let i = 0; i < addRowItem.length; i++) {
      const newRecord: FACTORY_CHECK_ADD_RECORD = createNewRecord(
        addRowItem[i].name,
        addRowItemList[0],
        addRowItem[i].comments
      );
      const newAddRecord: FACTORY_CHECK_ADD_BIG_RECORD[] =
        updateFactoryCheckAddValue(addRowItemList, newRecord, true);
      setAddRowItemList(newAddRecord);
    }
    // saveAddItems(addRowItemList, selectedFactoryId, userPrivilege);
    // saveFactoryCheck();
    setChangeFlg(true);

    sortAddRow();
    handleClick();
    setScrollSwitch(!scrollSwitch);
  };

  //削除ボタン処理
  const deleteClick = () => {
    if (delCkList.length > 0) {
      setChangeFlg(true);
    }
    userLogging(
      "店舗確認表",
      "新規作成#削除ボタン処理",
      JSON.stringify(delCkList)
    );
    const result: FACTORY_CHECK_ADD_BIG_RECORD[] = deleteAddItems(
      delCkList,
      addRowItemList
    );
    setAddRowItemList(result);
    // saveFactoryCheck();
    // saveAddItems(addRowItemList, selectedFactoryId, userPrivilege);

    if (addRowItemList && addRowItemList[0].rows.length === 0) {
      setDeleteMode(false);
    }
    setDelCkList([]);
    sortAddRow();
    handleClick();
    ++clickCount;
  };
  // 追加モードへ
  const addModeClick = () => {
    userLogging("店舗確認表", "新規作成#項目追加", "");
    if (getFixedFlg()) return;

    setAddMode((prev) => !prev);
    setDeleteMode(false);
    setSortMode(false);
  };
  // 削除モードへ
  const deleteModeClick = () => {
    userLogging("店舗確認表", "新規作成#項目削除", "");
    if (getFixedFlg()) return;

    setDelCkList([]);
    setDeleteMode((prev) => !prev);
    setAddMode(false);
    setSortMode(false);
    if (!deleteMode) {
      setOpenTemplateEditAlert(true);
    }
  };
  // 並べ替えモードへ
  const sortModeClick = () => {
    userLogging("店舗確認表", "新規作成#並び替え", "");
    if (getFixedFlg()) return;

    setCheckedId("");
    setSortMode((prev) => !prev);
    setAddMode(false);
    setDeleteMode(false);
    if (!sortMode) {
      setOpenTemplateEditAlert(true);
    }
  };

  // 項目追加入力フィールド
  const InputAddItemField: React.FC<{
    comment: string;
    blurEvent: (comment: string) => void;
  }> = ({ comment, blurEvent }) => {
    const [inputComment, setInputComment] = React.useState(String(comment));
    const changeComment = (event: React.ChangeEvent<{ value: unknown }>) => {
      setInputComment(event.target.value as string);
    };
    const blurComment = () => {
      blurEvent(String(inputComment));
    };

    return (
      <TextField
        label="追加したい項目"
        placeholder="項目名を入力"
        size="small"
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth={true}
        // className={classes.addText}
        value={inputComment}
        onBlur={() => blurComment()}
        onChange={(event) => changeComment(event)}
      />
    );
  };

  // 画面スクロール
  const moveScroll = (fieldName: string, bottomFlg?: boolean) => {
    let element = document?.getElementById(fieldName);
    if (element !== null) {
      if (bottomFlg) {
        element?.scrollIntoView(false);
      } else {
        element.scrollIntoView();
      }
    }
  };

  async function dataURL2File(url: string, fileName: string){
    const blob = await (await fetch(url)).blob()
    return new File([blob], fileName, {type: blob.type})
  }
  // 保存処理
  const saveFactoryCheck = async () => {
    try {
      if (getFixedFlg()) return;
      // setSnapshotLoading(true);
      setSaving(true);

      // 既に完了している場合は更新しない
      const factoryCheckData: any = await getFactoryCheckRecordsFirestoreData(factoryCheckId);
      if (factoryCheckData && factoryCheckData.attribution.complete_date) {
        setDialogMessage([
          "既に登録されています。",
          "新たにデータ登録する場合は左上のメニューから「新規作成」を、",
          "作成されたデータを見る場合は左上のメニューからデータ検索してください。"
        ]);
        setDialogFlg(true);
        setFixedFlg(true);
        // setLoading(false);
        // setSnapshotLoading(false);
        setSaving(false);
        return;
      }

      // 画像アップロード
      let tempRowItemList: FACTORY_CHECK_BIG_RECORD[] = [];
      let itemIndex = 0;
      for (const bigRecord of rowItemList) {
        let tempbigRecord: FACTORY_CHECK_BIG_RECORD = bigRecord;
        let rowIndex = 0;
        for (const smallRecord of bigRecord.rows) {
          for (const comment of smallRecord.comments) {
            if (comment.photo_file_data !== "") {
              const directory = "factory-check-photo/";
              const fileName = factoryId + "-" + uuidv4();
              let filedata: any = await dataURL2File(comment.photo_file_data, fileName);
              uploadGoogleCloudStorage(filedata, directory, fileName);
              comment.photo_filepath_gcs = directory + fileName;
              comment.photo_file_data = "";
            }
            if (comment.comment_file_data !== "") {
              const directory = "factory-check-comment/";
              const fileName = factoryId + "-" + uuidv4();
              let filedata: any = await dataURL2File(comment.comment_file_data, fileName);
              uploadGoogleCloudStorage(filedata, directory, fileName);
              comment.comment_filepath_gcs = directory + fileName;
              comment.comment_file_data = "";
            }
          }
          tempbigRecord.rows[rowIndex] = smallRecord;
          rowIndex++;
        }
        tempRowItemList[itemIndex] = tempbigRecord;
        itemIndex++;
      }
      setRowItemList(rowItemList);

      for (const bigRecord of addRowItemList) {
        for (const smallRecord of bigRecord.rows) {
          for (const comment of smallRecord.comments) {
            if (comment.photo_file_data !== "") {
              const directory = "factory-check-photo/";
              const fileName = factoryId + "-" + uuidv4();
              let filedata: any = await dataURL2File(comment.photo_file_data, fileName);
              uploadGoogleCloudStorage(filedata, directory, fileName);
              comment.photo_filepath_gcs = directory + fileName;
              comment.photo_file_data = "";
            }
            if (comment.comment_file_data !== "") {
              const directory = "factory-check-comment/";
              const fileName = factoryId + "-" + uuidv4();
              let filedata: any = await dataURL2File(comment.comment_file_data, fileName);
              uploadGoogleCloudStorage(filedata, directory, fileName);
              comment.comment_filepath_gcs = directory + fileName;
              comment.comment_file_data = "";
            }
          }
        }
      }
      setRowItemList(rowItemList);
      sendAPIFactoryCheck(
        rowItemList,
        addRowItemList,
        factoryCheckId,
        scoresList[scoresList.length-1].percent,
        lastFactoryCheckId,
        selectedFactoryId,
        (saveCheckDate ? saveCheckDate.toFormat("yyyy/MM/dd") : "") + " " + saveCheckHour + ":00:00",
        lastCheckDate,
        userPrivilege,
        factoryCheckSelect,
        reportCount,
        witness,
        categoryCheckValue,
        manufacturCategory,
        saveSubmissionDeadline ? saveSubmissionDeadline.toFormat("yyyyMMdd") : "",
        corrections,
        reviewAdvice,
        user.hqDepartmentNameOA,
        user.employeeName,
        isFixed,
      );
    } catch(r) {
      setDialogMessage(["ERROR: postRequest: ",String(r)]);
      setDialogFlg(true);
      setSaving(false);
      throw r;
    };
  };
  /**
   * 工場チェックの値を受け取り、Firestoreの更新を行う
   * @param basicItemList 基本項目の配列
   * @param addRowItemList 追加項目の配列
   *
   */
  const sendAPIFactoryCheck = async (
    rowItemList: Array<FACTORY_CHECK_BIG_RECORD>,
    addRowItemList: Array<FACTORY_CHECK_ADD_BIG_RECORD>,
    factoryCheckId: string,
    totalScore: number,
    factoryCheckLastTimeId: string,
    selectedfactoryId: string,
    checkDate: string,
    lastCheckDate: string,
    userPrivilege: string,
    factoryCheckSelect: boolean[],
    reportCount: string,
    witness: string,
    categoryCheckValue: boolean[],
    manufacturCategory: string,
    saveSubmissionDeadline: string,
    corrections: Array<REQUEST_FOR_CORRECTION>,
    reviewAdvice: string,
    auditorDepartmentName: string,
    auditorName: string,
    isFixed: boolean,
    // displayRenkeiList: Array<any>
  ) => {
    if (
      (Array.isArray(rowItemList) && rowItemList.length === 0) ||
      factoryCheckId === ""
    ) {
      // 初回読み込みで呼ばれてしまうので空であれば終了する
      return;
    }
    const objects: any = {};
    
    objects["attribution"] = {
      conditions: ["ALL"],
      last_time_id: factoryCheckLastTimeId,
      factory_id: selectedfactoryId,
      total_score: String(totalScore),
      check_date: checkDate,
      last_check_date: lastCheckDate,
      user_privilege: userPrivilege,
      factoryCheckSelect: factoryCheckSelect,
      reportCount: reportCount,
      witness: witness,
      categoryCheckValue: categoryCheckValue,
      manufacturCategory: manufacturCategory,
      submissionDeadline: saveSubmissionDeadline,
      corrections: corrections,
      reviewAdvice: reviewAdvice,
      auditorDepartmentName: auditorDepartmentName,
      auditorName: auditorName,
      isFixed: isFixed,
    };
    objects["id"] = factoryCheckId;
    objects["docId"] = factoryCheckId;
    objects["update_date"] = {
    };
    let records: any = {};
    let scoresList: Array<FACTORY_CHECK_SCORES> = updateScore(rowItemList, factoryCheckSelect);
    objects["records"] = records;
    // 次回の工場チェックのデータを作成{user.hqDepartmentNameOA} {user.employeeName}
    const apiParams = 
    {
      "query": 'mutation {' +
        'factoryCheckSave (' +
          'input: {' +
            'id: "' + factoryCheckId + '",' +
            'docLastId: "' + factoryCheckLastTimeId + '",' +
            'createUser: "' + user.mailAddress + '",' +
            'updateUser: "' + user.mailAddress + '",' +
            'score: ' + scoresList[scoresList.length-1].percent + ',' +
            'userPrivilege: "' + user.jobCategoryCodeOA + '",' +
            'score1: ' + scoresList[0].percent + ',' +
            'score2: ' + scoresList[1].percent + ',' +
            'score3: ' + scoresList[2].percent + ',' +
            'score4: ' + scoresList[3].percent + ',' +
            'score5: ' + scoresList[4].percent + ',' +
            'createEmployeeNo: "' + user.employeeNo + '",' +
            'updateEmployeeNo: "' + user.employeeNo + '",' +
            'formatCreateDate: "' + formatCreateDate?.toFormat("yyyy/MM/dd") + '",' +
            'formatUpdateDate: "' + formatUpdateDate?.toFormat("yyyy/MM/dd") + '",' +
            'checkDate: "' + checkDate + '",' +
            'vendorCode: "' + factoryRecordSearch?.vender_code + '",' +
            'vendorName: "' + factoryRecordSearch?.company_name + '",' +
            'factoryCode: "' + factoryRecordSearch?.factory_code + '",' +
            'factoryName: "' + factoryRecordSearch?.factory_name + '",' +
            'qcAreaCode: "' + factoryRecordSearch?.factory_area_code + '",' +
            'qcAreaName: "' + factoryRecordSearch?.factory_area + '",' +
            'witness: "' + witness + '",' +
            'categoryCheckValue: ' + JSON.stringify(categoryCheckValue).replace(/"([^"]+)":/g, '$1:') + ',' +
            'factoryCheckSelect: ' + JSON.stringify(factoryCheckSelect).replace(/"([^"]+)":/g, '$1:') + ',' +
            'manufacturCategory: "' + manufacturCategory + '",' +
            'reportCount: "' + reportCount + '",' +
            'auditorDepartmentName: "' + user.hqDepartmentNameOA + '",' +
            'auditorName: "' + user.employeeName + '",' +
            'submissionDeadline: "' + submissionDeadline?.toFormat("yyyy/MM/dd") + '",' +
            'rank: "' + scoresList[scoresList.length-1].rank + '",' +
            'factoryWitness: "' + "" + '",' +
            'chargeQcEmployeeNo: "' + "" + '",' +
            'chrgeQcDate: "' + "" + '",' +
            'chargeMgrEmployeeNo: "' + "" + '",' +
            'chargeMgrDate: "' + "" + '",' +
            'chargeAgmEmployeeNo: "' + "" + '",' +
            'chargeAgmDate: "' + "" + '",' +
            //.replace('correction_no', 'correctionNo').replace('comment_advice', 'commentAdvice').replace('judgement_of_severity', 'judgementOfSeverity').replace('follow_up', 'followUp')
            'reviewAdvice: "' + reviewAdvice.replaceAll(/\r\n|\r|\n/g, '\\n') + '",' +
            'corrections: ' + JSON.stringify(corrections).replace(/"([^"]+)":/g, '$1:').replaceAll(/\r\n|\r|\n/g, '\\n') + ',' +
            'base_value: "' + JSON.stringify(rowItemList).replace(/"([^"]+)":/g, '$1:').replace(/"/g, '\\\"').replaceAll(/\r\n|\r|\n/g, '\\n').replaceAll("\\n", '\\\\n') + '",' + 
            'add_value: "' + JSON.stringify(addRowItemList).replace(/"([^"]+)":/g, '$1:').replace(/"/g, '\\\"').replaceAll(/\r\n|\r|\n/g, '\\n').replaceAll("\\n", '\\\\n') + '"' + 
          '}' +
        ')' +
      '}'
    };
    const url = process.env.REACT_APP_API_URL + "/graphql";
    postRequest(url, apiParams)
      .then(async (res) => {
        // 完了済みのレコードの場合
        if (res.data.data.factoryCheckSave === "already completed") {
          setDialogMessage([
            "既に登録されています。",
            "新たにデータ登録する場合は左上のメニューから「新規作成」を、",
            "作成されたデータを見る場合は左上のメニューからデータ検索してください。"
          ]);
          setDialogFlg(true);
          setFixedFlg(true);
          setSaving(false);
          return;
        }
        // Firestoreへの入力内容に不備がある場合
        else if (res.data.data.factoryCheckSave === "error") {
          setDialogMessage([
            "入力内容が正常に保存されていません。",
            "左上のメニューから「新規作成」を開きなおしてから操作してください。",
          ]);
          setDialogFlg(true);
          setFixedFlg(true);
          setSaving(false);
          return;
        }
        else {
          updateFactoryCheckFirestore(
            rowItemList,
            addRowItemList,
            factoryCheckId,
            scoresList[scoresList.length-1].percent,
            lastFactoryCheckId,
            selectedFactoryId,
            saveCheckDate ? saveCheckDate.toFormat("yyyyMMdd") : "",
            lastCheckDate ? lastCheckDate : "",
            userPrivilege,
            saveCheckHour,
            factoryCheckSelect,
            reportCount,
            witness,
            categoryCheckValue,
            manufacturCategory,
            saveSubmissionDeadline,
            corrections,
            reviewAdvice,
            user.hqDepartmentNameOA,
            user.employeeName,
            isFixed,
          ).then(() => {
            setSaving(false);
          });
        }
      })
      .catch((r) => {
        setDialogMessage(["ERROR: postRequest: ",String(r)]);
        setDialogFlg(true);
        setSaving(false);
        throw r;
    });
  };

  // テキスト入力フィールド
  const InputTextField: React.FC<{
    comment: string;
    blurEvent: (comment: string, changeFlg: boolean, n?: number) => void;
    fixedFlg: boolean;
    multi: boolean;
    row: number;
    maxLength: number;
  }> = ({ comment, blurEvent, fixedFlg, multi, row, maxLength }) => {
    const [inputComment, setInputComment] = React.useState(String(comment));
    const [inputChangeFlg, setInputChangeFlg] = React.useState(false);
    const changeComment = (event: React.ChangeEvent<{ value: unknown }>) => {
      let comment = event.target.value as string;
      comment = limitLengthAndLine(comment, maxLength, 12);
      setInputChangeFlg(true);
      setInputComment(comment);
    };

    const blurComment = () => {
      blurEvent(String(inputComment), inputChangeFlg);
    };

    return (
      <TextField
        multiline={multi}
        rows={row}
        variant="outlined"
        fullWidth={true}
        onChange={(e: any) => changeComment(e)}
        onBlur={() => blurComment()}
        value={inputComment}
        disabled={fixedFlg}
      />
    );
  };

  // テキスト入力フィールド
  const InputListTextField: React.FC<{
    comment: string;
    blurEvent: (comment: string, changeFlg: boolean, n: number) => void;
    fixedFlg: boolean;
    multi: boolean;
    row: number;
    maxLength: number;
    index: number;
  }> = ({ comment, blurEvent, fixedFlg, multi, row, maxLength, index }) => {
    const [inputComment, setInputComment] = React.useState(String(comment));
    const [inputChangeFlg, setInputChangeFlg] = React.useState(false);
    const changeComment = (event: React.ChangeEvent<{ value: unknown }>) => {
      let comment = event.target.value as string;
      comment = limitLengthAndLine(comment, maxLength, 12);
      setInputChangeFlg(true);
      setInputComment(comment);
    };

    const blurComment = () => {
      blurEvent(String(inputComment), inputChangeFlg, index);
    };

    return (
      <TextField
        multiline={multi}
        rows={row}
        variant="outlined"
        fullWidth={true}
        onChange={(e: any) => changeComment(e)}
        onBlur={() => blurComment()}
        value={inputComment}
        disabled={fixedFlg}
      />
    );
  };

  function checkNumber(str: string) {
    if (Number(str)) {
      return true;
    } else if (Number(str) === 0) {
      return true;
    }
    return false;
  }

  const doSelChangeStandard = (
    event: any,
    num: number
  ) => {
    corrections[num]["standard"] = event.target.value;
    setChangeFlg(true);
    handleClick();
  };

  const doSelChangeEvaluation = (
    event: any,
    num: number
  ) => {
    setChangeFlg(true);
    corrections[num]["evaluation"] = event.target.value;
    handleClick();
  };

  const doSelChangeFollowUp = (
    event: any,
    num: number
  ) => {
    corrections[num]["follow_up"] = event.target.value;
    setChangeFlg(true);
    handleClick();
  };

  // 回数テキスト入力フィールド
  const InputReportCount: React.FC<{
    comment: string;
    blurEvent: (comment: string, changeFlg: boolean) => void;
    fixedFlg: boolean;
  }> = ({ comment, blurEvent, fixedFlg }) => {
    const [inputComment, setInputComment] = React.useState(String(comment));
    const [inputChangeFlg, setInputChangeFlg] = React.useState(false);
    const changeComment = (event: React.ChangeEvent<{ value: unknown }>) => {
      let comment = event.target.value as string;
      if (comment && Number(comment.length) > 2) {
        comment = comment.substring(0, 2);
      }
      setInputChangeFlg(true);
      setInputComment(comment);
    };

    const blurComment = () => {
      blurEvent(String(inputComment), inputChangeFlg);
    };

    return (
      <TextField
        variant="standard"
        inputProps={{ maxLength: 2, pattern: "^[0-9]+$" }}
        onChange={(e: any) => changeComment(e)}
        onBlur={() => blurComment()}
        value={inputComment}
        disabled={fixedFlg}
      />
    );
  };

  // 回数Blur時
  const reportCountBlur = (comment: string, inputChangeFlg: boolean) => {
    if (inputChangeFlg) {
      setChangeFlg(true);
    }
    setReportCount(comment);
    handleClick();
  };

  // 立会者テキスト入力フィールド
  const InputWitness: React.FC<{
    comment: string;
    blurEvent: (comment: string, changeFlg: boolean) => void;
    fixedFlg: boolean;
  }> = ({ comment, blurEvent, fixedFlg }) => {
    const [inputComment, setInputComment] = React.useState(String(comment));
    const [inputChangeFlg, setInputChangeFlg] = React.useState(false);
    const changeComment = (event: React.ChangeEvent<{ value: unknown }>) => {
      let comment = event.target.value as string;
      if (comment && Number(comment.length) > 40) {
        comment = comment.substring(0, 40);
      }
      setInputChangeFlg(true);
      setInputComment(comment);
    };

    const blurComment = () => {
      blurEvent(String(inputComment), inputChangeFlg);
    };

    return (
      <TextField
        variant="standard"
        onChange={(e: any) => changeComment(e)}
        onBlur={() => blurComment()}
        value={inputComment}
        disabled={fixedFlg}
      />
    );
  };

  // 立会者Blur時
  const witnessBlur = (comment: string, inputChangeFlg: boolean) => {
    if (inputChangeFlg) {
      setChangeFlg(true);
    }
    setWitness(comment);
    handleClick();
  };

  // テキスト入力フィールド
  const InputManufacturCategory: React.FC<{
    comment: string;
    blurEvent: (comment: string, changeFlg: boolean) => void;
    fixedFlg: boolean;
  }> = ({ comment, blurEvent, fixedFlg }) => {
    const [inputComment, setInputComment] = React.useState(String(comment));
    const [inputChangeFlg, setInputChangeFlg] = React.useState(false);
    const changeComment = (event: React.ChangeEvent<{ value: unknown }>) => {
      let comment = event.target.value as string;
      comment = limitLengthAndLine(comment, 100, 12);
      setInputChangeFlg(true);
      setInputComment(comment);
    };

    const blurComment = () => {
      blurEvent(String(inputComment), inputChangeFlg);
    };

    return (
      <TextField
        variant="standard"
        onChange={(e: any) => changeComment(e)}
        onBlur={() => blurComment()}
        value={inputComment}
        disabled={fixedFlg}
      />
    );
  };

  // 製造カテゴリーテキストボックスBlur時
  const manufacturCategoryBlur = (comment: string, inputChangeFlg: boolean) => {
    if (inputChangeFlg) {
      setChangeFlg(true);
    }
    setManufacturCategory(comment);
    handleClick();
  };

  // 総評・助言Blur時
  const blurReviewAdvice = (comment: string, inputChangeFlg: boolean) => {
    if (inputChangeFlg) {
      setChangeFlg(true);
    }
    setReviewAdvice(comment);
    handleClick();
  };

  // 重篤性判断Blur時
  const blurJudgementOfSeverity = (comment: string, inputChangeFlg: boolean, n: number) => {
    if (inputChangeFlg) {
      setChangeFlg(true);
    }
    corrections[n]["judgement_of_severity"] = comment;
    handleClick();
  };

  // コメント・助言Blur時
  const blurCommentAdvice = (comment: string, inputChangeFlg: boolean, n: number) => {
    if (inputChangeFlg) {
      setChangeFlg(true);
    }
    corrections[n]["comment_advice"] = comment;
    handleClick();
  };

  const isEnd = (currentSubCategory: string, rows: any, index: number) => {
    if (rows.length === index + 1 || rows[index + 1].tertiary_category !== currentSubCategory) {
      return true;
    }
    return false;
  }
  const isTop = (currentSubCategory: string, rows: any, index: number) => {
    if (index === 0 || rows[index - 1].tertiary_category !== currentSubCategory) {
      return true;
    }
    return false;
  }
  const factoryMaster: Map<String, EXISTING_FACTORY_RECORD> = getAllExistingFactory().factory_data;
  let factoryRecordSearch = factoryMaster.get(selectedFactoryId);

  function getDataRecord(isAddRow: boolean, rowItem: any, index: number) {
    if (Object.keys(rowItem.rows).length === 0) return "";
    return (
            <>
            {/* データ行↓ここから */}
            <Grid container {...{ xs: 12, sm: 12, md: 12, lg: 12 }}>
              {rowItem.rows.map((row: any, indexRowItem: number) => (
                <>
                <Grid
                  container
                  direction="row" 
                  {...{ xs: 7, sm: 7, md: 7, lg: 7, xl: 8 }}
                >
                  {/* 項目↓ここから */}
                  {isAddRow ? (
                  <>
                    {sortMode ? (
                    <>
                      <DataContentsGrid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        {...{ xs: 2, sm: 2, md: 2, lg: 1 }}
                      >
                        <Radio
                          id="sort"
                          name="sort"
                          onClick={() => setCheckedId(row.key)}
                          checked={
                            row.key + "" === checkedId ? true : false
                          }
                        />
                      </DataContentsGrid>
                    </>
                    ) : deleteMode ? (
                    <>
                      <DataContentsGrid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        {...{ xs: 2, sm: 2, md: 2, lg: 1 }}
                      >
                        <Checkbox
                          onChange={() => clickDelCheck(row.key)}
                          checked={
                            delCkList.includes(row.key) ? true : false
                          }
                        />
                      </DataContentsGrid>
                    </>
                    ) : ""}
                    {sortMode || deleteMode ? (
                    <>
                      {/* 確認内容↓ここから */}
                      {row.basic_flag ? (
                        <ContentsBasicGrid
                          container
                          alignItems={"center"}
                          {...{ xs: 10, sm: 10, md: 10, lg: 11 }}
                          // key={row.key + "_caption"}
                        >
                          <FactoryCheckModalManual
                            title={row.small_title}
                            filePath={row.manual}
                            titleLinkList={row.title_link_list}
                          />
                        </ContentsBasicGrid>
                      ) : (
                        <ContentsGrid
                          container
                          alignItems={"center"}
                          {...{ xs: 10, sm: 10, md: 10, lg: 11 }}
                          // key={row.key + "_caption"}
                        >
                          <FactoryCheckModalManual
                            title={row.small_title}
                            filePath={row.manual}
                            titleLinkList={row.title_link_list}
                          />
                        </ContentsGrid>
                      )}
                    {/* 確認内容↑ここまで */}
                    </>
                    ) : (
                    <>
                    {/* 確認内容↓ここから */}
                    {row.basic_flag ? (
                      <ContentsBasicGrid
                        container
                        alignItems={"center"}
                        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        // key={row.key + "_caption"}
                      >
                        <FactoryCheckModalManual
                          title={row.small_title}
                          filePath={row.manual}
                          titleLinkList={row.title_link_list}
                        />
                      </ContentsBasicGrid>
                    ) : (
                      <ContentsGrid
                        container
                        alignItems={"center"}
                        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        // key={row.key + "_caption"}
                      >
                        <FactoryCheckModalManual
                          title={row.small_title}
                          filePath={row.manual}
                          titleLinkList={row.title_link_list}
                        />
                      </ContentsGrid>
                    )}
                    {/* 確認内容↑ここまで */}
                    </>
                    )}
                  </>
                  ) : (
                  <>
                    {/* セカンダリカテゴリ↓ここから */}
                    {isEnd(row.tertiary_category, rowItem.rows, indexRowItem) ? 
                    (
                      <ContentsGrid
                        container
                        {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
                      >
                        <Typography>{isTop(row.tertiary_category, rowItem.rows, indexRowItem) ? (row.secondary_category) : ("")}</Typography>
                      </ContentsGrid>
                    )
                    :
                    isTop(row.tertiary_category, rowItem.rows, indexRowItem) ?
                    (
                      <MiddleContentsGrid
                        container
                        {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
                      >
                        <Typography>{row.secondary_category}</Typography>
                      </MiddleContentsGrid>
                    )
                    :
                    (
                      <MiddleContentsGrid
                        container
                        {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
                      >
                        <Typography>　</Typography>
                      </MiddleContentsGrid>
                    )
                    }
                    {/* セカンダリカテゴリ↑ここまで */}
                    {/* ターシャリカテゴリ↓ここから */}
                    {isEnd(row.tertiary_category, rowItem.rows, indexRowItem) ? 
                    (
                      <ContentsGrid
                        container
                        {...{ xs: 1, sm: 1, md: 2, lg: 2 }}
                      >
                        <Typography>{isTop(row.tertiary_category, rowItem.rows, indexRowItem) ? (row.tertiary_category) : ("")}</Typography>
                      </ContentsGrid>
                    )
                    :
                    isTop(row.tertiary_category, rowItem.rows, indexRowItem) ?
                    (
                      <MiddleContentsGrid
                        container
                        {...{ xs: 1, sm: 1, md: 2, lg: 2 }}
                      >
                        <Typography>{row.tertiary_category}</Typography>
                      </MiddleContentsGrid>
                    )
                    :
                    (
                      <MiddleContentsGrid
                        container
                        {...{ xs: 1, sm: 1, md: 2, lg: 2 }}
                      >
                        <Typography>　</Typography>
                      </MiddleContentsGrid>
                    )}
                    {/* ターシャリカテゴリ↑ここまで */}
                    {/* 項目↑ここまで */}
                    {/* 確認内容↓ここから */}
                    {row.basic_flag ? (
                      <ContentsBasicGrid
                        container
                        {...{ xs: 10, sm: 10, md: 9, lg: 9 }}
                        // key={row.key + "_caption"}
                      >
                        <FactoryCheckModalManual
                          title={row.small_title}
                          filePath={row.manual}
                          titleLinkList={row.title_link_list}
                        />
                      </ContentsBasicGrid>
                      ) : (
                      <ContentsGrid
                        container
                        {...{ xs: 10, sm: 10, md: 9, lg: 9 }}
                        // key={row.key + "_caption"}
                      >
                        <FactoryCheckModalManual
                          title={row.small_title}
                          filePath={row.manual}
                          titleLinkList={row.title_link_list}
                        />
                      </ContentsGrid>
                    )}
                    {/* 確認内容↑ここまで */}
                  </>
                  )}
                </Grid>
                <Grid
                  container
                  {...{ xs: 5, sm: 5, md: 5, lg: 5, xl: 4 }}
                >
                  {/* 前回↓ここから */}
                  <DataContentsGrid
                    container
                    {...{ xs: 2, sm: 2, md: 2, lg: 2, xl: 3 }}
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        {row.last_button_value === "1" ? (
                          <RadioButtonUnchecked />
                        ) : row.last_button_value === "2" ? (
                            <FactoryCheckModalLastScore
                              title={row.small_title}
                              comments={row.last_comments || []}
                            />
                        ) : row.last_button_value === "3" ? (
                            <ChangeHistoryIcon />
                        ) : row.last_button_value === "0" ? (
                            <RemoveIcon />
                        ) : (
                          ""
                        )}
                    </Stack>
                  </DataContentsGrid>
                  {/* 前回↑ここまで */}
                  {/* 今回↓ここから */}
                  <DataInputGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 8, sm: 8, md: 8, lg: 8, xl: 6 }}
                    // key={row.key + "_check"}
                  >
                    <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}>
                      <Avatar
                        sx={row.button_value === "1" ? 
                          { 
                            color: "#fff",
                            bgcolor: green[500],
                            boxShadow: "0px 0px 0px 3px #000 inset"
                          }
                          :
                          {
                            color: "#c0c0c0",
                            backgroundColor: "#e6e6e6"
                          }
                        }
                        onClick={() => console.log("a1")}
                      >
                        <IconButton onClick={() => clickButton(row, 1)}>
                          <RadioButtonUnchecked />
                        </IconButton>
                      </Avatar>
                      <Avatar
                        sx={row.button_value === "3" ? 
                          { 
                            color: "#fff",
                            bgcolor: yellow[500],
                            boxShadow: "0px 0px 0px 3px #000 inset"
                          }
                          :
                          {
                            color: "#c0c0c0",
                            backgroundColor: "#e6e6e6"
                          }
                        }
                      >
                        <IconButton size="large" onClick={() => clickButton(row, 3)}>
                          <ChangeHistoryIcon />
                        </IconButton>
                      </Avatar>
                      <FactoryCheckSelect
                        record={row}
                        reasonChange={(row) => clickNgButton(row)}
                        factoryId={selectedFactoryId}
                      />
                      <Avatar
                        sx={row.button_value !== "1" && row.button_value !== "2" && row.button_value !== "3" ? 
                          { 
                            color: "#fff",
                            bgcolor: grey[500],
                            boxShadow: "0px 0px 0px 3px #000 inset"
                          }
                          :
                          {
                            color: "#c0c0c0",
                            backgroundColor: "#e6e6e6"
                          }
                        }
                      >
                        <IconButton size="large" onClick={() => clickButton(row, 0)}>
                          <RemoveIcon />
                        </IconButton>
                      </Avatar>
                    </Stack>
                  </DataInputGrid>
                  {/* 今回↑ここまで */}
                  {/* 添付↓ここから */}
                  <DataInputGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 2, sm: 2, md: 2, lg: 2, xl: 3 }}
                  >
                    <FactoryCheckModalButton
                      title={row.small_title}
                      comments={row.comments}
                      onClickSave={(tempComments) => {
                        onClickPhotoButton(row, tempComments);
                      }}
                      factoryId={selectedFactoryId}
                    />
                  </DataInputGrid>
                  {/* 添付↑ここまで */}
                </Grid>
                </>
              ))}
            </Grid>
            {/* データ行↑ここまで */}
            </>
    )
  }

  const getCheckRecord = () => {
    if ((Array.isArray(rowItemList) && rowItemList.length === 0)) {
      return "";
    }
    return (
      <>
      {rowItemList.map((rowItem, index) => (
      <>
      {/* {isNext ? ( */}
          {/* <> */}
          <SeparatorGrid/>
          <FactoryAccordion
            expanded={factoryCheckSelect[index]}
            disabled={!factoryCheckSelect[index]}
          >
              <FactoryAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                // aria-controls={"panel" + index + "a-content"}
                // id={"panel" + index + "a-header"}
              >
                <AccordionSummaryGrid
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <FactoryButtonTypography>{rowItem.primary_category}</FactoryButtonTypography>
                </AccordionSummaryGrid>
              </FactoryAccordionSummary>
            {/* </> */}
          {/* ) : (
            ""
          )} */}
          {/* チェック表↓ここから */}
          <Grid
            container
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <BaseGrid
              container
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              {/* タイトル行↓ここから */}
              <Grid
                container
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <Grid
                container
                {...{ xs: 7, sm: 7, md: 7, lg: 7, xl: 8 }}
                >
                  <DataHeaderGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 2, sm: 2, md: 3, lg: 3 }}
                  >
                    <Typography variant="h6">項目</Typography>
                  </DataHeaderGrid>
                  <DataHeaderGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 10, sm: 10, md: 9, lg: 9 }}
                  >
                    <Typography variant="h6">確認内容</Typography>
                  </DataHeaderGrid>
                </Grid>
                <Grid
                  container
                  {...{ xs: 5, sm: 5, md: 5, lg: 5, xl: 4 }}
                >
                  <DataHeaderGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 2, sm: 2, md: 2, lg: 2, xl: 3 }}
                  >
                    <Typography variant="h6">前回</Typography>
                  </DataHeaderGrid>
                  <DataHeaderGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 8, sm: 8, md: 8, lg: 8, xl: 6 }}
                  >
                    <Typography variant="h6">今回</Typography>
                  </DataHeaderGrid>
                  <DataHeaderGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 2, sm: 2, md: 2, lg: 2, xl: 3 }}
                  >
                    <Typography variant="h6">添付</Typography>
                  </DataHeaderGrid>
                </Grid>
              </Grid>
              {/* ) : (
                ""
              )} */}
              {/* タイトル行↑ここまで */}
              {getDataRecord(false, rowItem, index)}
                </BaseGrid>
          </Grid>
          </FactoryAccordion>
          </>
      ))}
      </>
    );
  };

  const getCheckAddRecord = () => {
    if ((Array.isArray(addRowItemList) && addRowItemList.length === 0)) {
      return "";
    }
    if ((Array.isArray(addRowItemList[0].rows) && addRowItemList[0].rows.length === 0)) {
      return "";
    }
    return (
      <>
      {addRowItemList.map((rowItem, index) => (
      <>
      {/* {isNext ? ( */}
          {/* <> */}
        <SeparatorGrid/>
        {getFixedFlg() ? (
          ""
        ) : (
          <>
          <Grid
            container
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <Grid
              container 
              justifyContent="flex-start"
              {...{ xs: 5.9, sm: 5.9, md: 8.9, lg: 8.9 }}
            >
              <Typography variant="h4">
                {rowItem.big_title}
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              {...{ xs: 6, sm: 6, md: 3, lg: 3 }}
            >
              <Grid
                container
                // className={classes.buttonSpace}
              >
                <Button
                  variant={deleteMode ? "outlined" : "contained"}
                  onClick={() => deleteModeClick()}
                >
                  項目削除
                </Button>
              </Grid>
              <SeparatorLeftGrid/>
              <Grid
                container
                // className={classes.buttonSpace}
              >
                <Button
                  variant={sortMode ? "outlined" : "contained"}
                  onClick={() => sortModeClick()}
                >
                  並べ替え
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              {...{ xs: 0.1, sm: 0.1, md: 0.1, lg: 0.1 }}
            >
            </Grid>
          </Grid>
          </>
        )}
        <FactoryAccordion
          expanded={true}
          disabled={false}
          // disabled={!factoryCheckSelect[index]}
        >
          <FactoryAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            // aria-controls={"panel" + index + "a-content"}
            // id={"panel" + index + "a-header"}
          >
            <AccordionSummaryGrid
              container
              justifyContent="center"
              alignItems="center"
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <FactoryButtonTypography>{rowItem.primary_category}</FactoryButtonTypography>
            </AccordionSummaryGrid>
          </FactoryAccordionSummary>
          {/* </> */}
        {/* ) : (
          ""
        )} */}
        {/* チェック表↓ここから */}
          <Grid
            container
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <BaseGrid
              container
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              {/* タイトル行↓ここから */}
              <Grid
                container
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <Grid
                container
                {...{ xs: 7, sm: 7, md: 7, lg: 7, xl: 8 }}
                >
                    {sortMode || deleteMode ? (
                    <>
                  <DataHeaderGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 2, sm: 2, md: 2, lg: 1 }}
                  >
                    {deleteMode ? (
                    <Checkbox
                      onChange={() => clickAllDelCheck()}
                      checked={ckAllDelCheck()}
                    />
                    ) : ""}
                  </DataHeaderGrid>
                  <DataHeaderGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 10, sm: 10, md: 10, lg: 11 }}
                  >
                    <Typography variant="h6">確認内容</Typography>
                  </DataHeaderGrid>
                    </>
                    ) : (
                    <>
                  <DataHeaderGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  >
                    <Typography variant="h6">確認内容</Typography>
                  </DataHeaderGrid>
                  </>
                    )}
                </Grid>
                <Grid
                  container
                  {...{ xs: 5, sm: 5, md: 5, lg: 5, xl: 4 }}
                >
                  <DataHeaderGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 2, sm: 2, md: 2, lg: 2, xl: 3 }}
                  >
                    <Typography variant="h6">前回</Typography>
                  </DataHeaderGrid>
                  <DataHeaderGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 8, sm: 8, md: 8, lg: 8, xl: 6 }}
                  >
                    <Typography variant="h6">今回</Typography>
                  </DataHeaderGrid>
                  <DataHeaderGrid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    {...{ xs: 2, sm: 2, md: 2, lg: 2, xl: 3 }}
                  >
                    <Typography variant="h6">添付</Typography>
                  </DataHeaderGrid>
                </Grid>
              </Grid>
              {/* ) : (
                ""
              )} */}
              {/* タイトル行↑ここまで */}
              {getDataRecord(true, rowItem, index)}
                </BaseGrid>
          </Grid>
        </FactoryAccordion>
        </>
      ))}
      </>
    );
  };

  const getCorrections = () => {
    if (Array.isArray(corrections) && corrections.length === 0) {
      return "";
    }
    return (
      <SeparatorGrid
      container
      justifyContent={"flex-start"}
      {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
    >
      <Typography variant="h4">是正要請</Typography>
      <BaseGrid
        container
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
      >
        {/* 是正要請/総評・助言 */}
        {/* ヘッダー部 */}
        <Grid
          container
          {...{ xs: 6, sm: 6, md: 4, lg: 4 }}
        >
          <Grid
            container
            {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
          >
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              {/* 重篤性判断 */}
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <Typography variant="h6">重篤性判断</Typography>
              </DataHeaderGrid>
            </Grid>
          </Grid>
          <Grid
            container
            {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
          >
            {/* 基準 */}
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
            >
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <Typography variant="h6">基準</Typography>
              </DataHeaderGrid>
            </Grid>
            {/* 記録 */}
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
            >
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <Typography variant="h6">記録</Typography>
              </DataHeaderGrid>
            </Grid>

          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
        >
          {/* 連単 */}
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <DataHeaderGrid
              container
              justifyContent={"center"}
              alignItems={"center"}
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <Typography variant="h6">連単</Typography>
            </DataHeaderGrid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          {...{ xs: 5, sm: 5, md: 7, lg: 7 }}
        >
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            {/* コメント・助言 */}
            <DataHeaderGrid
              container
              justifyContent={"center"}
              alignItems={"center"}
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <Typography variant="h6">コメント・助言</Typography>
            </DataHeaderGrid>
          </Grid>
        </Grid>
        {/* データ部 */}
        {corrections.map((correctionRecord, index) => (
          <>
          <Grid
            container
            {...{ xs: 6, sm: 6, md: 4, lg: 4 }}
          >
            <Grid
              container
              {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
            >
              <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                {/* 重篤性判断 */}
                <DataInputGrid
                  {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                >
                  <InputListTextField
                    comment={correctionRecord["judgement_of_severity"]}
                    blurEvent={blurJudgementOfSeverity}
                    fixedFlg={getFixedFlg()}
                    multi={true}
                    row={2}
                    maxLength={50}
                    index={index}
                  />
                </DataInputGrid>
              </Grid>
            </Grid>
            <Grid
              container
              {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
            >
              {/* 基準 */}
              <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
              >
                <DataInputGrid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                >
                  <FormControl variant="standard" sx={{ pt: 1, minHeight: 71 }}>
                    <Select
                      autoWidth
                      value={correctionRecord["standard"]}
                      onChange={(event) => doSelChangeStandard(event, index)}
                      readOnly={getFixedFlg()}
                    >
                      <MenuItem value={""}><Typography variant="h4">　</Typography></MenuItem>
                      <MenuItem value={"1"}><Typography variant="h4">○</Typography></MenuItem>
                      <MenuItem value={"0"}><Typography variant="h4">×</Typography></MenuItem>
                    </Select>
                  </FormControl>
                </DataInputGrid>
              </Grid>
              {/* 記録 */}
              <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
              >
                <DataInputGrid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                >
                  <FormControl variant="standard" sx={{ pt: 1, minHeight: 71 }}>
                    <Select
                      autoWidth
                      value={correctionRecord["evaluation"]}
                      onChange={(event) => doSelChangeEvaluation(event, index)}
                      readOnly={getFixedFlg()}
                    >
                      <MenuItem value={""}><Typography variant="h4">　</Typography></MenuItem>
                      <MenuItem value={"1"}><Typography variant="h4">○</Typography></MenuItem>
                      <MenuItem value={"0"}><Typography variant="h4">×</Typography></MenuItem>
                    </Select>
                  </FormControl>
                </DataInputGrid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
          >
            {/* 連単 */}
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <DataInputGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <FormControl variant="standard" sx={{ pt: 2, minHeight: 63, minWidth: 60 }}>
                  <Select
                    autoWidth
                    value={correctionRecord["follow_up"]}
                    onChange={(event) => doSelChangeFollowUp(event, index)}
                    readOnly={getFixedFlg()}
                  >
                    <MenuItem value={""}><Typography variant="h6">　</Typography></MenuItem>
                    <MenuItem value={"1"}><Typography variant="h6">継続</Typography></MenuItem>
                    <MenuItem value={"0"}><Typography variant="h6">単発</Typography></MenuItem>
                  </Select>
                </FormControl>
              </DataInputGrid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 5, sm: 5, md: 7, lg: 7 }}
          >
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              {/* コメント・助言 */}
              <DataInputGrid
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <InputListTextField
                  comment={correctionRecord["comment_advice"]}
                  blurEvent={blurCommentAdvice}
                  fixedFlg={getFixedFlg()}
                  multi={true}
                  row={2}
                  maxLength={100}
                  index={index}
                />
              </DataInputGrid>
            </Grid>
          </Grid>
          </>
        ))}
      </BaseGrid>
      <Grid
        container
        justifyContent={"flex-start"}
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
      >
        <IconButton
          size="small"
          disabled={(corrections.length >= 20 || getFixedFlg())}
          onClick={() => {
            let tempCorrections = corrections;
            let tempCorrection = 
              {
                correction_no: corrections.length + 1,
                judgement_of_severity: "",
                standard: "",
                evaluation: "",
                follow_up: "",
                comment_advice: "",
              }
            tempCorrections.push(tempCorrection);
            setCorrections(tempCorrections);
            setChangeFlg(true);
            handleClick();
          }}
        >
          <AddCircleOutlineIcon />是正要請の行を追加する。
        </IconButton>
      </Grid>
      <BaseGrid
        container
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
      >
        {/* 総評 ・ 助言 */}
        <Grid
          container
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Grid
            container
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <DataHeaderGrid
              container
              justifyContent={"center"}
              alignItems={"center"}
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <Grid>
                <Typography variant="h6">総評 ・ 助言</Typography>
              </Grid>
            </DataHeaderGrid>
            <DataInputGrid
              container
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <InputTextField
                comment={reviewAdvice}
                blurEvent={blurReviewAdvice}
                fixedFlg={getFixedFlg()}
                multi={true}
                row={6}
                maxLength={400}
              />
            </DataInputGrid>
          </Grid>
        </Grid>
      </BaseGrid>
    </SeparatorGrid>
    );
  };

  return (
    <>
    {(loading || snapshotLoading || saving) ?
    (
      <>
        <OverLayer/>
        <Grid
          container
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          justifyContent="center"
          alignItems="center"
        >
          <LoadingCircule
            size={"40vh"}
          />
        </Grid>
      </>
    ) : (
      <>
    <OverFlowGrid>
      {/* メインコンテンツ↓ここから */}
      <Grid container
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
      >
        {rowItemList && rowItemList.length > 0 ? (
        <>
        {/* ヘッダー部 START */}
        <Grid
          id="topField"
          container 
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid {...{ xs: 6, sm: 6, md: 6, lg: 6 }}>
            <Typography variant="h4">デイリーメーカー 工場確認報告書</Typography>
          </Grid>
          <Grid {...{ xs: 3, sm: 3, md: 3, lg: 3 }}>
            <Typography variant="h4">マスタ項目</Typography>
          </Grid>    
          <Grid {...{ xs: 3, sm: 3, md: 3, lg: 3 }}>
            <Grid container 
            >
              <Grid {...{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                【作成】{formatCreateDate?.toFormat("yyyy年MM月dd日")}
              </Grid>
            </Grid>
            <Grid container 
            >
              <Grid {...{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                【改訂】{formatUpdateDate?.toFormat("yyyy年MM月dd日")}
              </Grid>
            </Grid>
          </Grid>    
        </Grid>
        {/* ヘッダー部 END */}
        <SeparatorGrid
          container 
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          {/* 情報部 START */}
          <BaseGrid
            container 
            {...{ xs: 5, sm: 5, md: 5, lg: 5 }}
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid
              container
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
                <Typography variant="h6">地区名</Typography>
              </HeaderGrid>
              <ContentsGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }}>
                <Typography variant="h6">{factoryRecordSearch ? factoryRecordSearch.factory_area : ""}</Typography>
              </ContentsGrid>
            </Grid>
            <Grid
              container
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
                <Typography variant="h6">工場名</Typography>
              </HeaderGrid>
              <ContentsGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }}>
                <Typography variant="h6">{factoryRecordSearch ? factoryRecordSearch.factory_full_name : ""}</Typography>
              </ContentsGrid>
            </Grid>
            <Grid
              container
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
                <Typography variant="h6">監査日時</Typography>
              </HeaderGrid>
              <InputGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }}>
                <Grid
                container
                >
                {getFixedFlg() ? (
                  checkDate ? (
                    <Typography variant="h6">
                      {checkDate.toFormat("yyyy年MM月dd日") +
                        "(" +
                        getDayOfWeekText(checkDate) +
                        ")"}
                    </Typography>
                  ) : (
                    ""
                  )
                ) : (
                  <SejDatePicker
                    selectedDate={initCheckDate()}
                    dateFunc={(date: DateTime | null) => {
                      handleCheckDate(date);
                    }}
                    format={
                      "yyyy年MM月dd日" +
                      "(" +
                      getDayOfWeekText(checkDate) +
                      ")"
                    }
                    maxDate={DateTime.local().plus({days: 0})}
                    minDate={DateTime.local().minus({days: 7})}
                    mainColor={"#4dc0b2"}
                  />
                )}
                </Grid>
              </InputGrid>
            </Grid>
            <Grid
              container
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
                <Typography variant="h6">監査時間帯</Typography>
              </HeaderGrid>
              <InputGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }}>
              {getFixedFlg() ? (
                  checkHour ? (
                    <Typography variant="h6">
                      {saveCheckHour}時頃
                    </Typography>
                  ) : (
                    ""
                  )
                ) : (
                  <SejTimePicker
                    selectedTime={initCheckHour()}
                    timeFunc={(date: any | null) => {
                      handleCheckHour(date ? DateTime.fromMillis(Number(date)).toFormat("HH") : "");
                    }}
                    format={"HH"}
                  />
                )}
              </InputGrid>
            </Grid>
            <Grid
              container
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
                <Typography variant="h6">回数</Typography>
              </HeaderGrid>
              <InputGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      width: 30,
                      maxWidth: '100%',
                    }}
                  >
                    <InputReportCount
                      comment={reportCount}
                      blurEvent={reportCountBlur}
                      fixedFlg={getFixedFlg()}
                    />
                  </Box>
                    回目
                </Grid>
              </InputGrid>
            </Grid>
            <Grid
              container
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
                <Typography variant="h6">立会い者</Typography>
              </HeaderGrid>
              <InputGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }}>
                <InputWitness
                  comment={witness}
                  blurEvent={witnessBlur}
                  fixedFlg={getFixedFlg()}
                />
              </InputGrid>
            </Grid>
            <Grid
              container
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
                <Typography variant="h6">製造カテゴリー</Typography>
              </HeaderGrid>
              <InputGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }}>
                <Grid container>
                  <Grid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
                    <Grid>
                      <Checkbox
                        style={{ color: "black" }}
                        checked={categoryCheckValue[0]}
                        onClick={() => {manufacturCategoryCheck(0)}}
                      />米飯
                    </Grid>
                    <Grid>
                      <Checkbox
                        style={{ color: "black" }}
                        checked={categoryCheckValue[1]}
                        onClick={() => {manufacturCategoryCheck(1)}}
                      />麺類
                    </Grid>
                    <Grid>
                      <Checkbox
                        style={{ color: "black" }}
                        checked={categoryCheckValue[2]}
                        onClick={() => {manufacturCategoryCheck(2)}}
                      />焼成パン
                    </Grid>
                  </Grid>
                  <Grid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
                    <Grid>
                      <Checkbox
                        style={{ color: "black" }}
                        checked={categoryCheckValue[3]}
                        onClick={() => {manufacturCategoryCheck(3)}}
                      />チルド米飯
                    </Grid>
                    <Grid>
                      <Checkbox
                        style={{ color: "black" }}
                        checked={categoryCheckValue[4]}
                        onClick={() => {manufacturCategoryCheck(4)}}
                      />デリカ
                    </Grid>
                    <Grid>
                      <Checkbox
                        style={{ color: "black" }}
                        checked={categoryCheckValue[5]}
                        onClick={() => {manufacturCategoryCheck(5)}}
                      />７Ｐ
                    </Grid>
                  </Grid>
                  <Grid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
                    <Grid>
                      <Checkbox
                        style={{ color: "black" }}
                        checked={categoryCheckValue[6]}
                        onClick={() => {manufacturCategoryCheck(6)}}
                      />調理パン
                    </Grid>
                    <Grid>
                      <Checkbox
                        style={{ color: "black" }}
                        checked={categoryCheckValue[7]}
                        onClick={() => {manufacturCategoryCheck(7)}}
                      />スイーツ
                    </Grid>
                  </Grid>
                  <Grid
                    {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
                  >
                    <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Checkbox
                        style={{ color: "black" }}
                        checked={categoryCheckValue[8]}
                        onClick={() => {manufacturCategoryCheck(8)}}
                      />(
                      <Box
                        sx={{
                          width: 130,
                          maxWidth: '100%',
                        }}
                      >
                        <InputManufacturCategory
                          comment={manufacturCategory}
                          blurEvent={manufacturCategoryBlur}
                          fixedFlg={getFixedFlg()}
                        />
                      </Box>
                      )
                    </Grid>
                  </Grid>
                </Grid>
              </InputGrid>
            </Grid>
            <Grid
              container
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }} minHeight={37}>
                <Typography variant="h6">監査者</Typography>
              </HeaderGrid>
              <ContentsGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }} minHeight={37}>
                <Typography variant="h6">{user.hqDepartmentNameOA} {user.employeeName}</Typography>
              </ContentsGrid>
            </Grid>
            <Grid
              container
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
                <Typography variant="h6">改善報告書提出期限</Typography>
              </HeaderGrid>
              <InputGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }}>
                <Grid
                container
                >
                {getFixedFlg() ? (
                  submissionDeadline ? (
                    <Typography variant="h6">
                      {submissionDeadline.toFormat("yyyy年MM月dd日") +
                        "(" +
                        getDayOfWeekText(submissionDeadline) +
                        ")"}
                    </Typography>
                  ) : (
                    ""
                  )
                ) : (
                  <SejDatePicker
                    selectedDate={initSubmissionDeadline()}
                    dateFunc={(date: DateTime | null) => {
                      handleSubmissionDeadline(date);
                    }}
                    format={
                      "yyyy年MM月dd日" +
                      "(" +
                      getDayOfWeekText(submissionDeadline) +
                      ")"
                    }
                    maxDate={DateTime.local().plus({days: 8})}
                    minDate={DateTime.local().minus({days: 7})}
                    mainColor={"#4dc0b2"}
                  />
                )}
                </Grid>
              </InputGrid>
            </Grid>
          </BaseGrid>
          {/* 情報部 END */}
          <Grid {...{ xs: 1, sm: 1, md: 1, lg: 1 }}></Grid>
          {/* 評価部 START */}
          <BaseGrid
            container 
            maxHeight={"40vh"}
            {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
          >
            <HeaderGrid {...{ xs: 5, sm: 5, md: 5, lg: 5 }} minHeight={37}>
              <Typography variant="h6">項目</Typography>
            </HeaderGrid>
            <MiddleHeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }} minHeight={37}>
              <Typography variant="h6">評価</Typography>
            </MiddleHeaderGrid>
            <HeaderGrid {...{ xs: 3, sm: 3, md: 3, lg: 3 }} minHeight={37}>
              
            </HeaderGrid>
            <HeaderGrid {...{ xs: 5, sm: 5, md: 5, lg: 5 }} minHeight={37}>
              <Typography variant="h6">
                <Checkbox
                  style={{ color: "black" }}
                  checked={factoryCheckSelect[0]}
                  onClick={() => {selectfactoryCheck(0)}}
                />
                {scoresList[0].category}
              </Typography>
            </HeaderGrid>
            <ContentsGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }} minHeight={37}>
              <Typography align="center" variant="h6">{scoresList[0].score} / {scoresList[0].total_score}</Typography>
            </ContentsGrid>
            <ContentsGrid {...{ xs: 3, sm: 3, md: 3, lg: 3 }} minHeight={37}>
              <Typography align="center" variant="h6">{scoresList[0].percent < 0 ? "-" : scoresList[0].percent}%</Typography>
            </ContentsGrid>
            <HeaderGrid {...{ xs: 5, sm: 5, md: 5, lg: 5 }} minHeight={37}>
              <Typography variant="h6">
                <Checkbox
                  style={{ color: "black" }}
                  checked={factoryCheckSelect[1]}
                  onClick={() => {selectfactoryCheck(1)}}
                />
                {scoresList[1].category}
              </Typography>
            </HeaderGrid>
            <ContentsGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }} minHeight={37}>
              <Typography align="center" variant="h6">{scoresList[1].score} / {scoresList[1].total_score}</Typography>
            </ContentsGrid>
            <ContentsGrid {...{ xs: 3, sm: 3, md: 3, lg: 3 }} minHeight={37}>
              <Typography align="center" variant="h6">{scoresList[1].percent < 0 ? "-" : scoresList[1].percent}%</Typography>
            </ContentsGrid>
            <HeaderGrid {...{ xs: 5, sm: 5, md: 5, lg: 5 }} minHeight={37}>
              <Typography variant="h6">
                <Checkbox
                  style={{ color: "black" }}
                  checked={factoryCheckSelect[2]}
                  onClick={() => {selectfactoryCheck(2)}}
                />
                {scoresList[2].category}
              </Typography>
            </HeaderGrid>
            <ContentsGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }} minHeight={37}>
              <Typography align="center" variant="h6">{scoresList[2].score} / {scoresList[2].total_score}</Typography>
            </ContentsGrid>
            <ContentsGrid {...{ xs: 3, sm: 3, md: 3, lg: 3 }} minHeight={37}>
              <Typography align="center" variant="h6">{scoresList[2].percent < 0 ? "-" : scoresList[2].percent}%</Typography>
            </ContentsGrid>
            <HeaderGrid {...{ xs: 5, sm: 5, md: 5, lg: 5 }} minHeight={37}>
              <Typography variant="h6">
                <Checkbox
                  style={{ color: "black" }}
                  checked={factoryCheckSelect[3]}
                  onClick={() => {selectfactoryCheck(3)}}
                />
                {scoresList[3].category}
              </Typography>
            </HeaderGrid>
            <ContentsGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }} minHeight={37}>
              <Typography align="center" variant="h6">{scoresList[3].score} / {scoresList[3].total_score}</Typography>
            </ContentsGrid>
            <ContentsGrid {...{ xs: 3, sm: 3, md: 3, lg: 3 }} minHeight={37}>
              <Typography align="center" variant="h6">{scoresList[3].percent < 0 ? "-" : scoresList[3].percent}%</Typography>
            </ContentsGrid>
            <HeaderGrid {...{ xs: 5, sm: 5, md: 5, lg: 5 }} minHeight={37}>
              <Typography variant="h6">
                <Checkbox
                  style={{ color: "black" }}
                  checked={factoryCheckSelect[4]}
                  onClick={() => {selectfactoryCheck(4)}}
                />
                {scoresList[4].category}
              </Typography>
            </HeaderGrid>
            <ContentsGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }} minHeight={37}>
              <Typography align="center" variant="h6">{scoresList[4].score} / {scoresList[4].total_score}</Typography>
            </ContentsGrid>
            <ContentsGrid {...{ xs: 3, sm: 3, md: 3, lg: 3 }} minHeight={37}>
              <Typography align="center" variant="h6">{scoresList[4].percent < 0 ? "-" : scoresList[4].percent}%</Typography>
            </ContentsGrid>
            <HeaderGrid {...{ xs: 5, sm: 5, md: 5, lg: 5 }} minHeight={37}>
              <Typography variant="h6">{scoresList[scoresList.length-1].category}</Typography>
            </HeaderGrid>
            <ContentsGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }} minHeight={37}>
              <Typography align="center" variant="h6">{scoresList[scoresList.length-1].score} / {scoresList[scoresList.length-1].total_score}</Typography>
            </ContentsGrid>
            <ContentsGrid {...{ xs: 3, sm: 3, md: 3, lg: 3 }} minHeight={37}>
              <Typography align="center" variant="h6">{scoresList[scoresList.length-1].percent < 0 ? "-" : scoresList[scoresList.length-1].percent}%</Typography>
            </ContentsGrid>
          </BaseGrid>
          {/* 評価部 END */}
          <SeparatorGrid container {...{ xs: 5, sm: 5, md: 5, lg: 5 }}>
            <Grid
              container
              {...{ xs: 12, sm: 12, md: 5, lg: 5 }}
            >
              <BaseGrid
                container
                maxHeight={"16vh"}
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <DataHeaderGrid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  {...{ xs: 7, sm: 7, md: 7, lg: 7 }}
                >
                  <Typography variant="h6">ランク評価</Typography>
                </DataHeaderGrid>
                <DataContentsGrid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  {...{ xs: 5, sm: 5, md: 5, lg: 5 }}
                >
                  <Typography variant="h3">{scoresList[scoresList.length-1].rank}</Typography>
                </DataContentsGrid>
              </BaseGrid>
            </Grid>
            <Grid
              {...{ xs: 12, sm: 12, md: 1, lg: 1 }}
            />
            <Grid
              {...{ xs: 12, sm: 12, md: 6, lg: 6 }}
            >
              <BaseGrid
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <ContentsGrid
                  {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                >
                  <Grid
                    container
                    {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  >
                    <Grid
                      {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
                    >
                      Ａ：９０％以上
                    </Grid>
                    <Grid
                      {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
                    >
                      　
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  >
                    <Grid
                      {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
                    >
                      Ｂ：８０％以上
                    </Grid>
                    <Grid
                      {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
                    >
                      　
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  >
                    <Grid
                      {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
                    >
                      Ｃ：７０％以上
                    </Grid>
                    <Grid
                      {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
                    >
                      　※再監査要
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  >
                    <Grid
                      {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
                    >
                      Ｄ：７０％未満
                    </Grid>
                    <Grid
                      {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
                    >
                      　※再監査＋面談
                    </Grid>
                  </Grid>
                </ContentsGrid>
              </BaseGrid>
            </Grid>
          </SeparatorGrid>
          <Grid {...{ xs: 7, sm: 7, md: 7, lg: 7 }}></Grid>
            {/* 評価部 END */}
        </SeparatorGrid>
        {/* 情報部 END */}
        </>
        ) : (
          ""
        )}
          <SeparatorGrid/>
          {/* 通常項目 */}
          {getCheckRecord()}
          {/* 追加項目 */}
          {getCheckAddRecord()}
          {getFixedFlg() ? (
            ""
          ) : (
            <>
            <SeparatorGrid/>
            <Grid
              container
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              // className={classes.addbuttonSpace}
              ref={scrollBottomRef}
            >
              <Grid
                container
                {...{ xs: 0.1, sm: 0.1, md: 0.1, lg: 0.1 }}
              >
              </Grid>
              <Grid
                container
                justifyContent="flex-start"
                {...{ xs: 7.8, sm: 7.8, md: 8.8, lg: 8.8, xl: 9.8 }}
              >
                {!addMode ? (
                  ""
                ) : (
                  <>
                  <Grid
                    container
                    justifyContent="flex-start"
                    {...{ xs: 10, sm: 10, md: 10, lg: 11 }}
                  >
                    <InputAddItemField
                      comment={addValue}
                      blurEvent={setAddValue}
                    />
                    <SeparatorLeftGrid/>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    {...{ xs: 2, sm: 2, md: 2, lg: 1 }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => addClick()}
                      // className={classes.editButton}
                    >
                      追加
                    </Button>
                  </Grid>
                  </>
                )}
                {!deleteMode ? (
                  ""
                ) : (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => deleteClick()}
                      // className={classes.editButton}
                    >
                      削除
                    </Button>
                  </>
                )}
                {!sortMode ? (
                  ""
                ) : (
                  <>
                    <IconButton
                      // className={classes.upDownButton}
                      onClick={() => upClick()}
                    >
                      <ArrowCircleUpIcon  color="primary" fontSize="large"/>
                    </IconButton>
                    <IconButton
                      // className={classes.upDownButton}
                      onClick={() => lowClick()}
                    >
                      <ArrowCircleDownIcon  color="primary" fontSize="large"/>
                    </IconButton>
                  </>
                )}
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                {...{ xs: 4, sm: 4, md: 3, lg: 3, xl: 2 }}
              >
                <FactoryCheckTemplateModalButton
                  onClickOK={(e, recordList) =>
                    onTemplateClick(e, recordList)
                  }
                />
                <SeparatorLeftGrid/>
                <Button
                  variant={addMode ? "outlined" : "contained"}
                  onClick={() => addModeClick()}
                >
                  項目追加
                </Button>
              </Grid>
              <Grid
                container
                {...{ xs: 0.1, sm: 0.1, md: 0.1, lg: 0.1 }}
              >
              </Grid>
            </Grid>
            </>
          )}
          {getCorrections()}
        {/* チェック表↑ここまで */}
      </Grid>
      <Grid id="bottomField" />
    </OverFlowGrid>
    {/* メインコンテンツ↑ここまで */}
    {/* コンテンツフッター↓ここから */}
    <Grid container>
      <Grid
       {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
      >
      </Grid>
      <Grid
       {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
      >
        <SeparatorGrid
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            container
            justifyContent={"center"}
            alignItems={"center"}
        >
            {getFixedFlg() ? (
              ""
            ) : (
            <>
            <SaveButton
              variant={"contained"}
              size="small"
              color={changeFlg ? "error" : "primary"}
              onClick={() => {
                clickConfirmButton();
              }}
            >
              一時保存
            </SaveButton>
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={openAlert}
              autoHideDuration={2000}
              onClose={alertClose}
            >
              <SnackbarContent
                style={{ backgroundColor: "#333333", color: "white" }}
                message={
                  <span style={{ fontSize: "16px" }}>
                    {
                      "提出（印刷）タブをクリックして工場確認表を提出してください。"
                    }
                  </span>
                }
              />
            </Snackbar>
            </>
            )}
        </SeparatorGrid>
      </Grid>
      <Grid
       {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
      >
        <SeparatorGrid
          container
          justifyContent={"center"}
          alignItems={"center"}
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <SeparatorLeftGrid>
            <UpDownButton
              onClick={() => moveScroll("topField")}
            >
              <VerticalAlignTop></VerticalAlignTop>
            </UpDownButton>
          </SeparatorLeftGrid>
          <SeparatorLeftGrid>
            <UpDownButton
              onClick={() => moveScroll("bottomField")}
            >
              <VerticalAlignBottom></VerticalAlignBottom>
            </UpDownButton>
          </SeparatorLeftGrid>
        </SeparatorGrid>
      </Grid>
    </Grid>
    {/* ダイアログ */}
    <FactoryCheckDialog
      msg={dialogMessage}
      isOpen={dialogFlg}
      doOK={dialogClose}
      doNo={dialogClose}
      isAlert={true}
    />
    {/* コンテンツフッター↑ここまで */}
    </>
    )}
    </>
  );
};
export default FactoryCheckTable;
import { FC } from "react";
import { DateTime } from "luxon";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { MobileTimePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';

const SejTimePicker: FC<{
  selectedTime: DateTime | null;
  timeFunc: any;
  format?: any;
  maxDate?: any;
  minDate?: any;
  backgroundColor?: string;
  mainColor?: string;
  width?: any
  }> = ({
    selectedTime,
    timeFunc,
    format,
    maxDate,
    minDate,
    backgroundColor,
    mainColor,
    width
    }) => {

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterLuxon}
        localeText={{
          previousMonth: "前月を表示", // < のツールチップ
          nextMonth: "次月を表示", // > のツールチップ
          cancelButtonLabel: "キャンセル", // スマホ画面のCANCELボタン
          okButtonLabel: "選択", // スマホ画面のOKボタン
        }}
      >
        <MobileTimePicker
          openTo="hours"
          views={['hours']}
          value={selectedTime}
          onChange={(date) => timeFunc(date)}
          inputFormat='HH時' //選択済みの日付の表示
          mask='__時'
          toolbarTitle="時間帯選択"
          renderInput={(params) => (
            <TextField
              variant="standard"
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "**時", //プレースホルダー（フォーカスを合わせたときに薄く表示される入力例）
              }}
            />
          )}
          DialogProps={{ sx:{ '& .PrivateMobileTimePickerToolbar-penIcon': {display: 'none'} } }}
        />
      </LocalizationProvider>
    </>
  );
};

export default SejTimePicker;

import { DateTime } from "luxon";
import {
  Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import {
  FACTORY_CHECK_BIG_RECORD,
  FACTORY_CHECK_ADD_BIG_RECORD,
  FACTORY_CHECK_RECORD,
  FACTORY_CHECK_COMMENTS,
} from "../../FactoryCheck/Register/FactoryCheckDataOperation";
import {
  convertCommentLine,
} from "../../organisms/table/FactoryCheckCorrectionsTable";
import {
  BaseGrid,
  SeparatorGrid,
  PrintContentsGrid,
  DataInputGrid,
  HeaderGrid,
  InputGrid,
  DataHeaderGrid,
} from "../../FactoryCheck/Styled/FactoryCheckStyled";
import { 
  EXISTING_FACTORY_RECORD,
} from "../../../data/existingFactory";
const Img = styled('img')({
  WebkitAppearance: "none",
  objectFit: "scale-down",
  // その他
  "@media (min-width: 300px)": {
    width: "40px",
  },
  // iPad Air縦
  "@media (min-width: 820px)": {
    width: "120px",
  },
  // iPad Pro縦, iPad Air横
  "@media (min-width: 1024px)": {
    width: "150px",
  },
  // 統合OA, iPad Pro横
  "@media (min-width: 1300px)": {
    width: "200px",
  },
  // 統合OA, iPad Pro横
  "@media (min-width: 1900px)": {
    width: "300px",
  },
});

const OTHER_VALUE: string = "99";
  
function isCheckImage(rowItemList: FACTORY_CHECK_BIG_RECORD[]) {
  let result = false;
  rowItemList.map((rowItem: any, indexRowItem: number) => {
    rowItem.rows.map((row: any, indexRowItem: number) => {
      if (existsPhoto(row) || existsComment(row) || existsCommentReason(row)) {
        result = true;
      }
    });
  });
  return result;
}

// 添付写真が存在するか判定
const existsPhoto = (row: FACTORY_CHECK_RECORD): boolean => {
  let isExists = false;
  if (!Array.isArray(row.comments) || row.comments.length === 0) return isExists;
  row.comments.map((comment: FACTORY_CHECK_COMMENTS, index: number) => {
    if(comment.photo_filepath_gcs !== undefined && comment.photo_filepath_gcs !== "") {
      isExists = true;
      return;
    }
  });
  return isExists;
}

// 添付コメントが存在するか判定
const existsComment = (row: FACTORY_CHECK_RECORD): boolean => {
  let isExists = false;
  if (!Array.isArray(row.comments) || row.comments.length === 0) return isExists;
  row.comments.map((comment: FACTORY_CHECK_COMMENTS, index: number) => {
    if (
    (comment.comment_handwrite_flag === "1" && comment.comment_filepath_gcs !== "")
    || comment.user_input_comment !== "") {
      isExists = true;
      return;
    }
  });
  return isExists;
}

function commentReason(row: FACTORY_CHECK_RECORD) {
  let comment = "";
  const addComment = (
    row: FACTORY_CHECK_RECORD,
    target: string,
    comment: string,
    targetFlg: boolean
  ) => {
    let workComment = "";
    if (row.reason_target_list && target && targetFlg) {
      for (let i = 0; i < row.reason_target_list.length; i++) {
        if (target === row.reason_target_list[i].value) {
          workComment += row.reason_target_list[i].name + " ";
        }
      }
    }
    if (OTHER_VALUE === target) {
      workComment += "その他 ";
    }
    if (
      row.reason_comment_map_list &&
      row.reason_comment_map_list.length > 0 &&
      comment
    ) {
      for (let i = 0; i < row.reason_comment_map_list.length; i++) {
        if (target === row.reason_comment_map_list[i].value) {
          let commentList = row.reason_comment_map_list[i].comment_list;
          for (let j = 0; j < commentList.length; j++) {
            if (comment === commentList[j].value) {
              workComment += commentList[j].name + " ";
            }
          }
        }
      }
    }
    return workComment;
  };

  comment += addComment(
    row,
    row.user_radio_target,
    row.user_radio_comment,
    true
  );
  if (row.user_ng_list) {
    let targetNo = "";
    for (let i = 0; i < row.user_ng_list.length; i++) {
      let targetFlg = false;
      if (row.user_ng_list[i].user_radio_target !== targetNo) {
        targetNo = row.user_ng_list[i].user_radio_target;
        targetFlg = true;
      }
      comment += addComment(
        row,
        row.user_ng_list[i].user_radio_target,
        row.user_ng_list[i].user_radio_comment,
        targetFlg
      );
    }
  }
  return comment;
}

// NG理由が存在するか判定
const existsCommentReason = (row: FACTORY_CHECK_RECORD): boolean => {
  const cReason = commentReason(row);
  return cReason !== undefined && cReason !== "";
}

function getImageFile(imagePath: string, imageToken: string) {
  if (imagePath === undefined || imagePath === "") {
    return "";
  }
  return (
    process.env.REACT_APP_CLOUD_STORAGE_URL +
    imagePath +
    "?access_token=" +
    imageToken
  );
}

function getPrintImageHeader(
  factoryRecordSearch: EXISTING_FACTORY_RECORD | undefined,
  witness: string,
  submissionDeadline: DateTime | null,
  auditorDepartmentName: string,
  auditorName: string,
) {
  return (
    <>
        <SeparatorGrid
          container
          justifyContent={"flex-start"}
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Typography variant="h4">指摘事項 改善報告</Typography>
        </SeparatorGrid>
        <Grid
          container 
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Grid
            container 
            {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
          >
            {/* 情報部 START */}
            <BaseGrid
              container 
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid
                container 
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <HeaderGrid
                  container 
                  {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                >
                  <Typography variant="h6">デイリーメーカー工場確認 改善報告書</Typography>
                </HeaderGrid>
              </Grid>
              <Grid
                container 
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <HeaderGrid
                  container 
                  {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
                >
                  <Typography variant="h6">メーカー名</Typography>
                </HeaderGrid>
                <PrintContentsGrid
                  container 
                  {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
                >
                  <Typography variant="h6">{factoryRecordSearch ? factoryRecordSearch.company_name : ""}</Typography>
                </PrintContentsGrid>
              </Grid>
              <Grid
                container 
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <HeaderGrid
                  container 
                  {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
                >
                  <Typography variant="h6">工場名</Typography>
                </HeaderGrid>
                <PrintContentsGrid
                  container
                  {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
                >
                  <Typography variant="h6">{factoryRecordSearch ? factoryRecordSearch.factory_name : ""}</Typography>
                </PrintContentsGrid>
              </Grid>
              <Grid
                container
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <HeaderGrid
                  container
                  {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
                >
                  <Typography variant="h6">工場責任者</Typography>
                </HeaderGrid>
                <PrintContentsGrid
                  container
                  {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
                >
                  <Typography variant="h6">{witness}</Typography>
                </PrintContentsGrid>
              </Grid>
              <Grid
                container 
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <HeaderGrid
                  container
                  {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
                >
                  <Typography variant="h6">工場確認日</Typography>
                </HeaderGrid>
                <InputGrid
                  container
                  {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
                >
                {submissionDeadline ? (
                  <Typography variant="h6">
                    {/* {submissionDeadline.toFormat("yyyy年MM月dd日") +
                      "(" +
                      getDayOfWeekText(submissionDeadline) +
                      ")"} */}
                  </Typography>
                ) : (
                  ""
                )}
                </InputGrid>
              </Grid>
              <Grid
                container 
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <HeaderGrid
                  container
                  {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
                >
                  <Typography variant="h6">改善報告提出日</Typography>
                </HeaderGrid>
                <InputGrid
                  container
                  {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
                >
                {submissionDeadline ? (
                  <Typography variant="h6">
                    {/* {submissionDeadline.toFormat("yyyy年MM月dd日") +
                      "(" +
                      getDayOfWeekText(submissionDeadline) +
                      ")"} */}
                  </Typography>
                ) : (
                  ""
                )}
                </InputGrid>
              </Grid>
            </BaseGrid>
          </Grid>
          <Grid
            container 
            {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
          >
            <Grid
              container 
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              {/* 情報部 END */}
              <Grid
                container
                {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
              >
              </Grid>
              {/* 評価部 START */}
              <Grid
                container 
                justifyContent="center"
                {...{ xs: 10, sm: 10, md: 10, lg: 10 }}
              >
                <SeparatorGrid
                  container
                  justifyContent={"center"}
                  alignItems="center"
                  {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                >
                  <BaseGrid
                    container 
                    {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  >
                    <HeaderGrid
                      container
                      {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
                    >
                      <Typography variant="h6">SEJ工場確認者</Typography>
                    </HeaderGrid>
                    <PrintContentsGrid
                      container
                      {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
                    >
                      <Typography variant="h6">{auditorDepartmentName} {auditorName}</Typography>
                    </PrintContentsGrid>
                  </BaseGrid>
                </SeparatorGrid>
                {/* 評価部 END */}
                <SeparatorGrid
                  container
                  justifyContent={"center"}
                  alignItems="center"
                  {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                >
                    <Grid
                      container 
                      justifyContent={"center"}
                      {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                    >
                    SEJ使用欄
                    </Grid>
                    <BaseGrid
                      container
                      {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                    >
                        <PrintContentsGrid
                          minHeight={"10vh"}
                          {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
                        >
                        </PrintContentsGrid>
                        <PrintContentsGrid
                          minHeight={"10vh"}
                          {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
                        >
                        </PrintContentsGrid>
                    </BaseGrid>
                </SeparatorGrid>
                {/* 評価部 END */}
              </Grid>
            <Grid {...{ xs: 1, sm: 1, md: 1, lg: 1 }}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export function getPrintImage(
  factoryRecordSearch: EXISTING_FACTORY_RECORD | undefined,
  witness: string,
  submissionDeadline: DateTime | null,
  auditorDepartmentName: string,
  auditorName: string,
  rowItemList: FACTORY_CHECK_BIG_RECORD[],
  addRowItemList: FACTORY_CHECK_ADD_BIG_RECORD[],
  imageToken: string
) {
  return (
    <>
    {isCheckImage(rowItemList) || isCheckImage(addRowItemList) ? (
      <>
    {getPrintImageHeader(
      factoryRecordSearch,
      witness,
      submissionDeadline,
      auditorDepartmentName,
      auditorName
    )}
      <SeparatorGrid/>
      <BaseGrid
        container
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
      >
        {/* タイトル行↓ここから */}
        <Grid container {...{ xs: 12, sm: 12, md: 12, lg: 12 }}>
          <DataHeaderGrid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
          >
            <Typography variant="h6">要改善事項</Typography>
          </DataHeaderGrid>
          <DataHeaderGrid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
          >
            <Typography variant="h6">工場対応</Typography>
          </DataHeaderGrid>
        </Grid>
        <Grid container {...{ xs: 12, sm: 12, md: 12, lg: 12 }}>
          <DataHeaderGrid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <Typography variant="h6">確認事項</Typography>
          </DataHeaderGrid>
          <DataHeaderGrid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <Typography variant="h6">内容</Typography>
          </DataHeaderGrid>
          <DataHeaderGrid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <Typography variant="h6">写真</Typography>
          </DataHeaderGrid>
          <DataHeaderGrid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 3, sm: 3, md: 3, lg: 3 }}
          >
            <Typography variant="h6">改善方法</Typography>
          </DataHeaderGrid>
          <DataHeaderGrid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <Typography variant="h6">改善後写真</Typography>
          </DataHeaderGrid>
          <DataHeaderGrid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
          >
            <Typography variant="h6">改善日</Typography>
          </DataHeaderGrid>
        </Grid>
        {/* タイトル行↑ここまで */}
        <Grid
          container
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
        {rowItemList.map((rowItem: any, indexRowItem: number) => (
          <>
          {Object.keys(rowItem).length !== 0 ? (
            <>
              {rowItem.rows.map((row: any, indexRowItem: number) => (
                <>
                  {existsPhoto(row) || existsComment(row) || existsCommentReason(row) ? (
                    <>
                    {row.comments.map((comment: any, indexComment: number) => (
                      <>
                      {/* 確認内容↓ここから */}
                      <PrintContentsGrid
                        {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Typography>{row.small_title}</Typography>
                      </PrintContentsGrid>
                      {/* 確認内容↑ここまで */}
                      {/* 問題点・助言等↓ここから */}
                      {existsComment(row) || existsCommentReason(row) ? (
                        <>
                          {comment.comment_handwrite_flag === "1" ? (
                            getImageFile(comment.comment_filepath_gcs, imageToken) ? (
                              <DataInputGrid
                                container
                                justifyContent={"center"}
                                alignItems={"center"}
                                {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                              >
                                <Img
                                  src={getImageFile(comment.comment_filepath_gcs, imageToken)}
                                  alt={row.small_title}
                                  height="180"
                                />
                              </DataInputGrid>
                            ) : (
                            <PrintContentsGrid
                              container
                              alignItems={"flex-start"}
                              {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                            >
                              <Typography></Typography>
                            </PrintContentsGrid>
                            )
                          ) : (
                            <>
                            <PrintContentsGrid
                              container
                              alignItems={"flex-start"}
                              {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                            >
                              <Typography>{commentReason(row)}</Typography>
                              {convertCommentLine(comment.user_input_comment)}
                            </PrintContentsGrid>
                            </>
                          )}
                        </>
                      ) : (
                            <PrintContentsGrid
                              container
                              alignItems={"flex-start"}
                              {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                            >
                              <Typography></Typography>
                            </PrintContentsGrid>

                      )}
                      {/* 問題点・助言等↑ここまで */}
                      {/* 添付画像↓ここから */}
                      <DataInputGrid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        {getImageFile(comment.photo_filepath_gcs, imageToken) ? (
                          <Img
                            src={getImageFile(comment.photo_filepath_gcs, imageToken)}
                            alt={row.small_title}
                            height="180"
                          />
                        ) : (
                          ""
                        )}
                      </DataInputGrid>
                      {/* 添付画像↑ここまで */}
                      {/* 改善方法↓ここから */}
                      <PrintContentsGrid
                        {...{ xs: 3, sm: 3, md: 3, lg: 3 }}
                      >
                        <Typography>{""}</Typography>
                      </PrintContentsGrid>
                      {/* 改善方法↑ここまで */}
                      {/* 改善後写真↓ここから */}
                      <PrintContentsGrid
                        {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Typography>{""}</Typography>
                      </PrintContentsGrid>
                      {/* 改善後写真↑ここまで */}
                      {/* 改善日↓ここから */}
                      <PrintContentsGrid
                        {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
                      >
                        <Typography>{""}</Typography>
                      </PrintContentsGrid>
                      {/* 改善日↑ここまで */}
                      {/* データ行↑ここまで */}
                      </>
                    ))}
                  </>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </>
          ) : (
            ""
          )}
        </>
        ))}
        {addRowItemList.map((rowItem: any, indexRowItem: number) => (
          <>
          {Object.keys(rowItem).length !== 0 ? (
            <>
              {rowItem.rows.map((row: any, indexRowItem: number) => (
                <>
                  {existsPhoto(row) || existsComment(row) || existsCommentReason(row) ? (
                    <>
                    {row.comments.map((comment: any, indexComment: number) => (
                      <>
                      {/* 確認内容↓ここから */}
                      <PrintContentsGrid
                        {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Typography>{row.small_title}</Typography>
                      </PrintContentsGrid>
                      {/* 確認内容↑ここまで */}
                      {/* 問題点・助言等↓ここから */}
                      {existsComment(row) || existsCommentReason(row) ? (
                        <>
                          {comment.comment_handwrite_flag === "1" ? (
                            getImageFile(comment.comment_filepath_gcs, imageToken) ? (
                              <DataInputGrid
                                container
                                justifyContent={"center"}
                                alignItems={"center"}
                                {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                              >
                                <Img
                                  src={getImageFile(comment.comment_filepath_gcs, imageToken)}
                                  alt={row.small_title}
                                  height="180"
                                />
                              </DataInputGrid>
                            ) : (
                            <PrintContentsGrid
                              container
                              alignItems={"flex-start"}
                              {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                            >
                              <Typography></Typography>
                            </PrintContentsGrid>
                            )
                          ) : (
                            <>
                            <PrintContentsGrid
                              container
                              alignItems={"flex-start"}
                              {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                            >
                              <Typography>{commentReason(row)}</Typography>
                              {convertCommentLine(comment.user_input_comment)}
                            </PrintContentsGrid>
                            </>
                          )}
                        </>
                      ) : (
                            <PrintContentsGrid
                              container
                              alignItems={"flex-start"}
                              {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                            >
                              <Typography></Typography>
                            </PrintContentsGrid>

                      )}
                      {/* 問題点・助言等↑ここまで */}
                      {/* 添付画像↓ここから */}
                      <DataInputGrid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        {getImageFile(comment.photo_filepath_gcs, imageToken) ? (
                          <Img
                            src={getImageFile(comment.photo_filepath_gcs, imageToken)}
                            alt={row.small_title}
                            height="180"
                          />
                        ) : (
                          ""
                        )}
                      </DataInputGrid>
                      {/* 添付画像↑ここまで */}
                      {/* 改善方法↓ここから */}
                      <PrintContentsGrid
                        {...{ xs: 3, sm: 3, md: 3, lg: 3 }}
                      >
                        <Typography>{""}</Typography>
                      </PrintContentsGrid>
                      {/* 改善方法↑ここまで */}
                      {/* 改善後写真↓ここから */}
                      <PrintContentsGrid
                        {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Typography>{""}</Typography>
                      </PrintContentsGrid>
                      {/* 改善後写真↑ここまで */}
                      {/* 改善日↓ここから */}
                      <PrintContentsGrid
                        {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
                      >
                        <Typography>{""}</Typography>
                      </PrintContentsGrid>
                      {/* 改善日↑ここまで */}
                      {/* データ行↑ここまで */}
                      </>
                    ))}
                  </>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </>
          ) : (
            ""
          )}
        </>
        ))}
        </Grid>
      </BaseGrid>
      </>
    ) : ("")}
    </>
  );
}

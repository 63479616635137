import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Route, BrowserRouter } from "react-router-dom";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import Auth from "./components/Auth/Auth";
import Home from "./components/Home/Home";
import FactoryCheck from "./components/FactoryCheck/Register/FactoryCheck";
import FactoryCheckSearch from "./components/FactoryCheck/Search/FactoryCheckSearch";
import FactoryCheckFilingStatusConfirm from "./components/FactoryCheck/FilingStatusConfirm/FactoryCheckFilingStatusConfirm";
import FactoryCheckManagerAnalysis from "./components/FactoryCheck/ManagerAnalysis/FactoryCheckManagerAnalysis";


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <>
          <Route exact path="/login" component={Auth} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/factory-check" component={FactoryCheck} />
          <Route
            exact
            path="/factory-check-search"
            component={FactoryCheckSearch}
          />
          <Route
            exact
            path="/factory-check-filing-status-confirm"
            component={FactoryCheckFilingStatusConfirm}
          />
          <Route
            exact
            path="/factory-check-manager-analysis"
            component={FactoryCheckManagerAnalysis}
          />
          <Route path="/" component={App} />
        </>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();

import axios from "axios";
import { getAPIToken, getFirebaseAuthToken } from "../firebase";

// GETリクエスト
export const getRequest = async (
  url: string
  // params: any,
  // isLoading: boolean = true
) => {
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + (await getAPIToken()),
      },
      // params,
    });
    // ステータス200番台のエラーハンドリング
    if (response.data.success !== true) {
      console.log("status 2XX Error");
      console.log(response.data.dataList);
      throw Error;
    }
    return response.data.dataList;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
  // return response.data.dataList;
};

// POSTリクエスト
export const postRequest = async (
  url: string,
  params: any,
  isLoading: boolean = true,
  isAuthed: boolean = true,
  isAuthUpdate: boolean = true
) => {
  try {
    const response = await axios.post(url, params, {
      headers: isAuthed
        // APIの向き先がWorkstationではない場合
        ? process.env.REACT_APP_API_URL?.indexOf("cloudworkstations") === -1 ? 
        {
          Authorization: "Bearer " + (await getAPIToken(isAuthUpdate)),
          "Application-Name": "ofc-counseling",
        }
        : 
        // APIの向き先がWorkstationの場合
        {
          // 開発Workstation用のアクセストークン作成
          Authorization2: "Bearer " + (await getAPIToken(isAuthUpdate)),
          Authorization: "Bearer " + (await getFirebaseAuthToken(isAuthUpdate)),
          "Application-Name": "ofc-counseling",
        }
        : {},
    });
    // Todo暫定
    // ステータス200番台のエラーハンドリング
    // if (response.data.success !== true) {
    //   console.log("status 2XX Error");
    //   throw Error;
    // }
    return response;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

// POSTリクエスト
export const postCommonRequest = async (
  url: string,
  params: any,
  isLoading: boolean = true,
  isAuthed: boolean = true,
  isAuthUpdate: boolean = true
) => {
  try {
    const response = await axios.post(url, params, {
      headers: isAuthed
        // APIの向き先がWorkstationではない場合
        ? process.env.REACT_APP_API_URL?.indexOf("cloudworkstations") === -1 ? 
        {
          Authorization: "Bearer " + (await getAPIToken(isAuthUpdate)),
          "Application-Name": "ofc-counseling",
        }
        :
        // APIの向き先がWorkstationの場合
        {
          // 開発Workstation用のアクセストークン作成
            Authorization2: "Bearer " + (await getAPIToken(isAuthUpdate)),
            Authorization: "Bearer " + (await getFirebaseAuthToken(isAuthUpdate)),
            "Application-Name": "ofc-counseling",
        }
        : {},
      });
      return response;
  } catch (error) {
    console.log("postCommonRequest error", error);
    throw error;
  }
};

// PATCHリクエスト
export const patchRequest = async (
  url: string,
  params: string,
  isLoading: boolean = true
) => {
  try {
    const response = await axios.patch(url, params, {
      headers: { Authorization: "Bearer " + (await getAPIToken()) },
    });
    // ステータス200番台のエラーハンドリング
    if (response.data.success !== true) {
      console.log("status 2XX Error");
      throw Error;
    }
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

// DELETEリクエスト
export const deleteRequest = async (
  url: string,
  params: string,
  isLoading: boolean = true
) => {
  try {
    const response = await axios.delete(url, {
      data: params,
      headers: { Authorization: "Bearer " + (await getAPIToken()) },
    });
    // ステータス200番台のエラーハンドリング
    if (response.data.success !== true) {
      console.log("status 2XX Error");
      throw Error;
    }
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

/**
 * GoogleCloudStorageからトークンを用いてファイルダウンロードを行う
 *
 * @param url GoogleCloudStorageのファイル場所URL
 * @param token GoogleCloudStorageのトークン
 */
export const getGcsFile = async (gcsPath: string, token: string) => {
  try {
    const response = await axios.get(gcsPath + "?access_token=" + token);
    return response;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

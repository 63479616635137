  //  店舗確認票権限設定
  export interface AuthFactoryCheck {
    loadTenpo: number,
    loadZo: number,
    loadDo: number,
    createNew: number;
    edit: number;
    reference: number;
    search: number;
    share: number;
    printing: number;
    status: number;
  }
  
  //  利用不可
  export const notAvailable: number = 0;
  //  担当のみ
  export const onlyInCharge: number = 1;
  //  担当エリアのみ
  export const onlyArea: number = 2;
  //  全国
  export const onlyAll: number = 3;

  //  店舗確認票ＯＦＣ権限
  export const initialForOFC: AuthFactoryCheck = {
    loadTenpo: onlyInCharge,
    loadZo: onlyInCharge,
    loadDo: onlyInCharge,
    createNew: onlyInCharge,
    edit: onlyInCharge,
    reference: onlyArea,
    search: onlyInCharge,
    share: onlyArea,
    printing: onlyInCharge,
    status: notAvailable,
  };

  //  店舗確認票ＤＭ権限
  export const initialForDM: AuthFactoryCheck = {
    loadTenpo: onlyInCharge,
    loadZo: onlyInCharge,
    loadDo: onlyInCharge,
    createNew: onlyArea,
    edit: onlyArea,
    reference: onlyArea,
    search: onlyArea,
    share: onlyArea,
    printing: onlyArea,
    status: onlyArea,
  };

  //  店舗確認票ＺＭ権限
  export const initialForZM: AuthFactoryCheck = {
    loadTenpo: onlyAll,
    loadZo: onlyAll,
    loadDo: onlyAll,
    createNew: onlyInCharge,
    edit: onlyInCharge,
    reference: onlyAll,
    search: onlyArea,
    share: notAvailable,
    printing: notAvailable,
    status: onlyAll,
  };

  //  店舗確認票ＯＰＳ権限
  export const initialForOPS: AuthFactoryCheck = {
    loadTenpo: onlyAll,
    loadZo: onlyAll,
    loadDo: onlyAll,
    createNew: notAvailable,
    edit: notAvailable,
    reference: onlyAll,
    search: onlyAll,
    share: notAvailable,
    printing: notAvailable,
    status: onlyAll,
  };

  //  店舗確認票Admin-Group権限
  export const initialForAdminGroup: AuthFactoryCheck = {
    loadTenpo: onlyAll,
    loadZo: onlyAll,
    loadDo: onlyAll,
    createNew: notAvailable,
    edit: notAvailable,
    reference: onlyAll,
    search: onlyAll,
    share: notAvailable,
    printing: notAvailable,
    status: onlyAll,
  };

  //  許可職種判定
  export const getAuthFactoryCheck = (jobCategory: string) => {
    if (jobCategory === "dm"){
      return initialForDM;
    }
    else if (jobCategory === "zm"){
      return initialForZM;
    }
    else if (jobCategory === "gm"){
      return initialForAdminGroup;
    }
    else if (jobCategory === "ops" || jobCategory === "admin-group"){
      return initialForAdminGroup;
    }
    return initialForOFC;
  }

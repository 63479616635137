import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { TENPOSTATUS } from "./interface";

const initialTenpoStatus: TENPOSTATUS = {
  tenpoCode: "",
  tenpoName: "",
  tenpoCheckComment: "",
  tenpoCheckPhoroUrl: "",
  tenpoGraphFirstItem: "",
  tenpoGraphFirstName: "",
  tenpoGraphSecondItem: "",
  tenpoGraphSecondName: "",
};

export const tenpoStatusSlice = createSlice({
  name: "tenpoStatus",
  initialState: {
    tenpoStatus: initialTenpoStatus,
  },
  reducers: {
    updateFactoryCheckComment: (state, action) => {
      state.tenpoStatus.tenpoCheckComment = action.payload.tenpoCheckComment;
    },
    updateFactoryCheckPhoroUrl: (state, action) => {
      state.tenpoStatus.tenpoCheckPhoroUrl = action.payload.tenpoCheckPhoroUrl;
    },
    updateTenpoGraphFirstItem: (state, action) => {
      state.tenpoStatus.tenpoGraphFirstItem =
        action.payload.tenpoGraphFirstItem;
    },
    updateTenpoGraphFirstName: (state, action) => {
      state.tenpoStatus.tenpoGraphFirstName =
        action.payload.tenpoGraphFirstName;
    },
    updateTenpoGraphSecondItem: (state, action) => {
      state.tenpoStatus.tenpoGraphSecondItem =
        action.payload.tenpoGraphSecondItem;
    },
    updateTenpoGraphSecondName: (state, action) => {
      state.tenpoStatus.tenpoGraphSecondName =
        action.payload.tenpoGraphSecondName;
    },
    clearTenpoStatus: (state) => {
      state.tenpoStatus = initialTenpoStatus;
    },
  },
});

export const selectTenpoStatus = (state: RootState) => state.tenpoStatus;
export const selectTenpoCode = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.tenpoCode;
export const selectTenpoGraphFirstItem = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.tenpoGraphFirstItem;
export const selectTenpoGraphFirstName = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.tenpoGraphFirstName;
export const selectTenpoGraphSecondItem = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.tenpoGraphSecondItem;
export const selectTenpoGraphSecondName = (state: RootState) =>
  state.tenpoStatus.tenpoStatus.tenpoGraphSecondName;
export default tenpoStatusSlice.reducer;
export const {
  updateFactoryCheckComment,
  updateFactoryCheckPhoroUrl,
  updateTenpoGraphFirstItem,
  updateTenpoGraphFirstName,
  updateTenpoGraphSecondItem,
  updateTenpoGraphSecondName,
  clearTenpoStatus,
} = tenpoStatusSlice.actions;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
import {
  Button,
  OutlinedInput,
  styled,
  IconButton,
} from "@mui/material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Grid from '@mui/material/Unstable_Grid2';
import Header from "../../Header/Header";
import SejDatePicker from "../../common/SejDatePicker";
import FactoryCheckSearchResult from "./FactoryCheckSearchResult";
import FactoryCheckSearchData from "./FactoryCheckSearchData";
import { selectUser } from "../../../../src/features/userSlice";
import {
  updateFactoryId,
  updateFactoryFullName,
  updateAreaCode,
  updateAreaName,
  updateQCMailAddress,
  updateQCName,
  updateCheckDataList,
  selectCheckDataList,
  updateLocationKey,
  selectLocationKey,
  updateCreateDateFrom,
  updateCreateDateTo,
  selectCreateDateFrom,
  selectCreateDateTo,
} from "../../../components/organisms/search/FactoryCheckSearchSlice";
import {
  getAllExistingSejStores,
  getAllZone,
  getAllDo,
  EXISTING_SEJ_STORES_RECORD,
} from "../../../data/existingSejStores";
import { postRequest } from "../../../apis/axiosAction";
import SearchFactory from "../../organisms/search/searchFactory/SearchFactory";
import SearchFactoryArea from "../../organisms/search/searchFactory/SearchFactoryArea";
import { userLogging } from "../../../apis/userLog";
import FactoryCheckDialog from "../FactoryCheckDialog";
import { isFirebaseAuth } from "../../../firebase";
import { useLocation } from "react-router-dom";
import {
  OverLayer,
  LoadingCircule,
  SeparatorGrid,
  ModalPaneModal,
  SearchAreaGrid,
  SearchHeaderGrid,
  SearchSectionGrid,
  SearchItemGrid,
  SearchButton,
  SeparatorLeftGrid,
} from "../Styled/FactoryCheckStyled";
import { getAllExistingFactory, EXISTING_FACTORY_RECORD } from "../../../data/existingFactory";
import { isExecuteLogicalDelete } from "../../../apis/privilege";

// 曜日の取得
const getDayOfWeekText = (datetime: DateTime | null) => {
  if (!datetime) {
    return;
  }
  let weekDayArray: string[] = [];
  weekDayArray = ["月", "火", "水", "木", "金", "土", "日"];
  const dayOfWeek = datetime.weekday;
  return weekDayArray[dayOfWeek - 1];
};

const FactorySearch: React.FC = (props: any) => {
  // const classes = useStyles();
  const user = useSelector(selectUser);
  const searchResultList = useSelector(selectCheckDataList);
  const [click, setClick] = React.useState(false);
  const [jitan, setJitan] = React.useState(false);
  const [lowerPoint, setLowerPoint] = React.useState("");
  const [upperPoint, setUpperPoint] = React.useState("");
  const [areaCode, setAreaCode] = React.useState("");
  const [areaName, setAreaName] = React.useState("");
  const [qcMailAddress, setQCMailAddress] = React.useState("");
  const [factoryId, setFactoryId] = React.useState([""]);
  const [searchFlg, setSearchFlg] = React.useState("");
  const [resultList, setResultList] = React.useState<any[]>([]);
  const [searchButtonFlg, setSearchButtonFlg] = React.useState(true);
  const zoneMaster = getAllZone().zone_data;
  const doMaster = getAllDo().do_data;
  const dispatch = useDispatch();
  const storeMaster: Map<String, EXISTING_SEJ_STORES_RECORD> = getAllExistingSejStores().store_data;
  const [createdDateFrom, setCreatedDateFrom] = React.useState<DateTime | null>(
    null
  );
  const [createdDateTo, setCreatedDateTo] = React.useState<DateTime | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);
  const [factoryCheckId, setFactoryCheckId] = React.useState("");
  const [searchResultRecord, setSearchResultRecord] = React.useState({});
  const locationKeys: any = useSelector(selectLocationKey);
  const initCreateDateFrom = useSelector(selectCreateDateFrom);
  const initCreateDateTo = useSelector(selectCreateDateTo);
  let thumbUp = "";
  const [logicalDelete, setLogicalDelete] = React.useState("0");
  const JitanButton = styled(Button)(({ theme }) => (
    !jitan ? 
    {
      color: "black",
      backgroundColor: "#F2F2F2",
      "&:hover": {
        backgroundColor: "#d5d5d5",
      },
    }
      :
    {
      color: "#ffffff",
      outlineColor: "#3e3e3e",
      backgroundColor: "#4dc0b2",
      "&:hover": {
        backgroundColor: "#4ab5a1",
      },
    }
  ));

  const SubeteButton = styled(Button)(({ theme }) => (
    jitan ? 
    {
      color: "black",
      backgroundColor: "#F2F2F2",
      "&:hover": {
        backgroundColor: "#d5d5d5",
      },
    }
      :
    {
      color: "#ffffff",
      outlineColor: "#3e3e3e",
      backgroundColor: "#4dc0b2",
      "&:hover": {
        backgroundColor: "#4ab5a1",
      },
    }
  ));

  const dialogClose = () => {
    setDialogFlg(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleBaseStartDate = (date: DateTime | null) => {
    setCreatedDateFrom(date);
    // date !== null && setConCreatedFromValue(date.toFormat("yyyyMMdd"));
  };

  const handleBaseEndDate = (date: DateTime | null) => {
    setCreatedDateTo(date);
    // date !== null && setConCreatedToValue(date.toFormat("yyyyMMdd"));
  };

  const jitanCheck = (flag: boolean) => {
    setJitan(flag);
  };

  const handleClick = () => {
    setClick((prev) => !click);
  };

  const location = useLocation<{
    qcAddress: string;
    createdateFrom: DateTime;
    createdateTo: DateTime;
  }>();

  // // 検索ボタン押下時処理
  const doSearch = (initFlag?: boolean) => {
    // zocodeを複数持っている場合は初期検索を行わない（他画面から条件を指定しての画面遷移の場合は除く）
    // if (initFlag && user.areaCode.length > 1 && !location.state) return;
    // if (!location.state) return;
    // 当年客数をAPIから取得
    // let apiParams = {
    //   "api-name": "search",
    //   jitan_flag: jitan ? "1" : "0",
    //   // factory_id: factoryId,
    //   tenpo_code: ["102829"],
    //   area_name: areaName,
    //   // do_code: doCode,
    //   // qc_address: qcMailAddress,
    //   createdate_from: createdDateFrom,
    //   // createdate_from: "2022-10-23T00:00:00.000+09:00",
    //   createdate_to: createdDateTo,
    //   // createdate_to: "2023-10-30T13:47:32.640+09:00",
    //   lower_point: lowerPoint,
    //   upper_point: upperPoint,
    //   transition_qc_address: "",
    // };
    let nowDate: DateTime = DateTime.local();
    let lastDate: DateTime = nowDate.minus({ day: 7 });
    const factoryMaster: Map<String, EXISTING_FACTORY_RECORD> = getAllExistingFactory().factory_data;
  
    // 初期検索
    // if (initFlag === true) {
    //   // 初期検索
    //   if (user.jobCategory === "qc") {
    //     jitanCheck(true);
    //     apiParams.createdate_from = lastDate;
    //     apiParams.createdate_to = nowDate;
    //     apiParams.jitan_flag = "1";
    //     apiParams.factory_id = user.factoryId;
    //   } else if (user.jobCategory === "dm") {
    //     apiParams.createdate_from = lastDate;
    //     apiParams.createdate_to = nowDate;
    //     apiParams.jitan_flag = "0";
    //     apiParams.factory_id = user.factoryId;
    //     saveStatusBottun("", "", "", "", user.doCode[0], doMaster[user.doCode[0]].do_name, "", "");
    //   } else if (user.jobCategory === "zm") {
    //     apiParams.createdate_from = lastDate;
    //     apiParams.createdate_to = nowDate;
    //     apiParams.jitan_flag = "0";
    //     apiParams.area_code = user.areaCode[0];
    //     saveStatusBottun("", "", user.areaCode[0], zoneMaster[user.areaCode[0]].zone_name, "", "", "", "");
    //   }
    //   // 前画面から検索パラメータが渡された場合
    //   if (location.state !== undefined && location.state !== null) {
    //     apiParams.transition_qc_address = location.state.qcAddress;
    //     apiParams.createdate_from = location.state.createdateFrom;
    //     apiParams.createdate_to = location.state.createdateTo;
    //   }
    // }
    // if (qcMailAddress) {
    //   if (user.jobCategory === "dm"){
    //     apiParams.do_code = user.doCode[0];
    //   }
    //   else {
    //     apiParams.area_code = getEmployee(qcMailAddress).zocode;
    //   }
    // }
    if (!initFlag) {
      dispatch(updateCreateDateFrom({ createDateFrom: createdDateFrom }));
      dispatch(updateCreateDateTo({ createDateTo: createdDateTo }));
    }
    let apiStrings = 
    'query { ' + 
      'factoryCheckSearchList (' +
      'input: {';
      if (jitan) {
        apiStrings = apiStrings + 'jtanFlag: "' + jitan + '", ';
      }
      if (thumbUp) {
        apiStrings = apiStrings + 'thumbUp: "' + thumbUp + '", ';
      }
      if (logicalDelete) {
        apiStrings = apiStrings + 'deletedFlag: "' + logicalDelete + '", ';
      }
      if (lowerPoint) {
        apiStrings = apiStrings + 'scoreFrom: ' + lowerPoint + ', ';
      }
      if (upperPoint) {
        apiStrings = apiStrings + 'scoreTo: ' + upperPoint + ', ';
      }
      if (areaCode) {
        apiStrings = apiStrings + 'areaCode: "' + areaCode + '", ';
      }
      if (factoryId[0] !== "") {
        let factoryRecordSearch = factoryMaster.get(factoryId[0]);
        apiStrings = apiStrings + 'vendorCode: "' + factoryRecordSearch?.vender_code + '", ';
        apiStrings = apiStrings + 'factoryCode: "' + factoryRecordSearch?.factory_code + '", ';
      }
      apiStrings = apiStrings + 
          'checkDateFrom: "' + createdDateFrom?.toFormat("yyyy/MM/dd") + '", ' +
          'checkDateTo: "' + createdDateTo?.toFormat("yyyy/MM/dd") + '"' +
        '}' +
      ')' +
      '{id docLastId areaCode areaName factoryCode factoryName factoryFullName vendorCode companyName qcName managerName filingDate jtanFlag thumbUp deletedFlag}' +
      // '{id docLastId areaCode areaName factoryCode factoryName factoryFullName vendorCode companyName qcName managerName filingDate jtanFlag thumbUp}' +
    '}';
    const apiParams = 
    {
      "query": apiStrings
    };
    userLogging("ＱＣ工場チェック", "データ検索#検索", JSON.stringify(apiParams));
    setSearchFlg("2");
    const url = process.env.REACT_APP_API_URL + "/graphql";
    postRequest(url, apiParams)
      .then((res) => {
        let dataList: any[] = res.data.data.factoryCheckSearchList;
        let searchResultRecords = [];
        if (dataList) {
          for (let i = 0; i < dataList.length; i++) {
            let date: string = DateTime.fromFormat(
              dataList[i].filingDate,
              "yyyyMMdd hh:mm:ss"
            ).toFormat("yyyy/MM/dd");
            searchResultRecords.push({
              docId: dataList[i].id,
              docLastId: dataList[i].docLastId,
              areaName: dataList[i].areaName,
              companyName: dataList[i].companyName,
              factoryName: dataList[i].factoryName,
              factoryFullName: dataList[i].vendorCode + dataList[i].factoryFullName,
              factoryId: dataList[i].factoryCode,
              qcName: dataList[i].qcName,
              managerName: dataList[i].managerName,
              filingDate: dataList[i].filingDate,
              jtanFlag: dataList[i].jtanFlag,
              thumbUp: dataList[i].thumbUp ? dataList[i].thumbUp : "",
              deletedFlag: dataList[i].deletedFlag ? dataList[i].deletedFlag : "",
            });
          }
        }
        dispatch(updateCheckDataList({ checkDataList: searchResultRecords }));
        setResultList(sortResultRecords(searchResultRecords));
        setSearchFlg("1");
        handleClick();
      })
      .catch((r) => {
        setDialogMessage([String(r)]);
        setDialogFlg(true);
      });

    handleClose();
  };
    
  // 初期表示処理
  useEffect(() => {
    // ブラウザバックではなく通常の遷移かの判定用にkeyを保持
    dispatch(
      updateLocationKey({ locationKey: { "factory-check-search": location.key } })
    );

    userLogging("ＱＣ工場チェック", "データ検索#初期表示", "");
    //検索条件を残すなら検索結果がある時はリセットしない
    //TODO 店舗、DO、ZOは保存されるが自担当、好事例、すべて、期間は
    //     保存されていないので追加実装が必要
    //if (searchResultList === undefined || searchResultList.length === 0) {
    dispatch(
      updateFactoryFullName({
        factoryFullName: "",
      })
    );
    dispatch(
      updateAreaName({
        areaName: "",
      })
    );
    dispatch(
      updateQCMailAddress({
        qcMailAddress: "",
      })
    );
    dispatch(
      updateQCName({
        qcName: "",
      })
    );

    let nowDate: DateTime = DateTime.local();
    let lastDate: DateTime = nowDate.minus({ day: 7 });
    setCreatedDateFrom(lastDate);
    setCreatedDateTo(nowDate);
    if (initCreateDateFrom) {
      setCreatedDateFrom(initCreateDateFrom);
    }
    if (initCreateDateTo) {
      setCreatedDateTo(initCreateDateTo);
    }

    // 初期検索
    // if (
    //   location.state !== undefined &&
    //   location.state !== null &&
    //   location.key !== locationKeys["factory-check-search"]
    // ) {
    //   doSearch(true);
    // } else if (searchResultList.length === 0) {
    //   if (
    //     user.jobCategory === "qc" ||
    //     user.jobCategory === "dm" ||
    //     user.jobCategory === "zm"
    //   ) {
    //     doSearch(true);
    //   }
    // } else {
      setResultList([]);
      setSearchFlg("");
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // // 検索ボタンの制御
  // useEffect(() => {
  //   // if (factoryId[0] !== "" || areaCode !== "" || doCode !== "" || ofcMailAddress !== "") {
  //   if (factoryId[0] !== "" || qcMailAddress !== "") {
  //     setSearchButtonFlg(true);
  //   } else {
  //     setSearchButtonFlg(false);
  //   }
  // }, [factoryId, qcMailAddress]);
  // }, [factoryId, areaCode, doCode, ofcMailAddress]);

  if (!isFirebaseAuth()) {
    return <></>;
  }
  function sortResultRecords(records: any[]) {
    let mapList: any[] = [];
    for (let i = 0;i< records.length;i++) {
      mapList.push(records[i]);
    }
    if (mapList) {
      return mapList.sort(function (first, second) {
        if (first.create_date > second.create_date) {
          return -1;
        } else if (first.create_date < second.create_date) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      return mapList;
    }
  }

  // 検索ボタン押下時処理
  function saveStatusBottun(
    argFactoryId: string,
    argFactoryFullName: string,
    argAreaCode: string,
    argAreaName: string,
    // argDoCode: string,
    // argDoName: string,
    argQCMailAddress: string,
    argQCName: string
  ) {
    dispatch(
      updateFactoryId({
        factoryId: argFactoryId,
      })
    );
    dispatch(
      updateFactoryFullName({
        factoryFullName: argFactoryFullName,
      })
    );
    dispatch(
      updateAreaCode({
        areaCode: argAreaCode,
      })
    );
    dispatch(
      updateAreaName({
        areaName: argAreaName,
      })
    );
    dispatch(
      updateQCMailAddress({
        qcMailAddress: argQCMailAddress,
      })
    );
    dispatch(
      updateQCName({
        qcName: argQCName,
      })
    );
    setFactoryId([argFactoryId]);
    setAreaCode(argAreaCode);
    setAreaName(argAreaName);
    setQCMailAddress(argQCMailAddress);
  }

  const changeLowerPoint = (event: React.ChangeEvent<{ value: unknown }>) => {
    let numValue = event.target.value as number;
    if (!numValue && numValue !== 0) {
      setLowerPoint("");
      return;
    } else if (numValue < 0) {
      setLowerPoint("0");
      return;
    } else if (numValue > 100) {
      setLowerPoint("100");
      return;
    }
    setLowerPoint(String(numValue));
  };
  const changeUpperPoint = (event: React.ChangeEvent<{ value: unknown }>) => {
    let numValue = event.target.value as number;
    if (!numValue && numValue !== 0) {
      setUpperPoint("");
      return;
    } else if (numValue < 0) {
      setUpperPoint("0");
      return;
    } else if (numValue > 100) {
      setUpperPoint("100");
      return;
    }
    setUpperPoint(String(numValue));
  };

  function clickSearch(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    handleOpen();
  }

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter" && searchButtonFlg) {
      thumbUp = "";
      doSearch();
    }
  }

  function updateResultList(id: string, thumbUp: string, deletedFlag: string){
    let searchResultRecords = [];
    if (resultList) {
      for (let i = 0; i < resultList.length; i++) {
        searchResultRecords.push({
          docId: resultList[i].docId,
          docLastId: resultList[i].docLastId,
          areaName: resultList[i].areaName,
          companyName: resultList[i].companyName,
          factoryName: resultList[i].factoryName,
          factoryFullName: resultList[i].factoryFullName,
          factoryId: resultList[i].factoryId,
          qcName: resultList[i].qcName,
          managerName: resultList[i].managerName,
          filingDate: resultList[i].filingDate,
          jtanFlag: resultList[i].jtanFlag,
          thumbUp: resultList[i].docId === id ? thumbUp : resultList[i].thumbUp,
          deletedFlag: resultList[i].docId === id ? deletedFlag : resultList[i].deletedFlag,
        });
        if (resultList[i].docId === id) {
          setSearchResultRecord(searchResultRecords[i]);
        }
      }
      setResultList(searchResultRecords);
    }
  }

  const generateCSV = function() {

    const factoryMaster: Map<String, EXISTING_FACTORY_RECORD> = getAllExistingFactory().factory_data;
    let apiStrings = 
    'query { ' +
      'factoryCheckDetailCSV(' +
      'input: {';
      if (thumbUp) {
        apiStrings = apiStrings + 'thumbUp: "' + thumbUp + '", ';
      }
      if (lowerPoint) {
        apiStrings = apiStrings + 'scoreFrom: ' + lowerPoint + ', ';
      }
      if (upperPoint) {
        apiStrings = apiStrings + 'scoreTo: ' + upperPoint + ', ';
      }
      if (areaCode) {
        apiStrings = apiStrings + 'areaCode: "' + areaCode + '", ';
      }
      if (factoryId[0] !== "") {
        let factoryRecordSearch = factoryMaster.get(factoryId[0]);
        apiStrings = apiStrings + 'vendorCode: "' + factoryRecordSearch?.vender_code + '", ';
        apiStrings = apiStrings + 'factoryCode: "' + factoryRecordSearch?.factory_code + '", ';
      }
      apiStrings = apiStrings + 
          'checkDateFrom: "' + createdDateFrom?.toFormat("yyyy/MM/dd") + '", ' +
          'checkDateTo: "' + createdDateTo?.toFormat("yyyy/MM/dd") + '"' +
        '}' +
      ')' +
      '{ formatUpdateDate header subHeader percentHeader pointsHeader rows }' +
    '}';
    const apiParams = 
    {
      "query": apiStrings
    };
    userLogging("ＱＣ工場チェック", "データ検索#ＣＳＶダウンロード", JSON.stringify(apiParams));
    const url = process.env.REACT_APP_API_URL + "/graphql";
    postRequest(url, apiParams)
      .then((res) => {
        let dataList: any[] = res.data.data.factoryCheckDetailCSV;
        if (dataList) {
          for (let i = 0; i < dataList.length; i++) {
            let csvData = "";
            let csvRows = "";
            csvData = dataList[i].header
            + "\r\n"
            + dataList[i].subHeader
            + "\r\n"
            + dataList[i].percentHeader
            + "\r\n"
            + dataList[i].pointsHeader
            + "\r\n";
            for (let j = 0; j < dataList[i].rows.length; j++) {
              csvRows += dataList[i].rows[j] + "\r\n";
            }
            let filename = "工場チェックデータ検索結果_" + dataList[i].formatUpdateDate.replaceAll("/", "") + "版";
            downloadCSV(filename, csvData + csvRows);
          }
        }
      }
    )
      .catch((r) => {
        setDialogMessage([String(r)]);
        setDialogFlg(true);
      }
    );
  }
  // ---------------------------------
  // CSVダウンロード処理
  // data : csvの中身(カンマ区切り、改行はセルフで)
  // filaname : ダウンロードするファイル名
  // ---------------------------------
  const downloadCSV = function(filename: string, data: string) {
    // UTF BOM
    let bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
    // リンククリエイト
    let downloadLink = document.createElement("a");
    downloadLink.download = filename + ".csv";
    // ファイル情報設定
    downloadLink.href = URL.createObjectURL(new Blob([bom, data], { type: "text/csv" }));
    downloadLink.dataset.downloadurl = ["text/csv", downloadLink.download, downloadLink.href].join(":");
    // イベント実行
    downloadLink.click();
  }

  return (
    <>
      <ModalPaneModal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        // className={classes.modalPane}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyUp(e)}
      >
        <SearchAreaGrid
          container 
          alignItems="flex-start"
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        // className={classes.searchArea}
        >
          {/*検索欄*/}
          <SearchHeaderGrid
            container
            justifyContent="center"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            条件変更
          </SearchHeaderGrid>
          {/*L1*/}
          {/* <SearchSectionGrid
            container
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            絞込み
          </SearchSectionGrid>
          <SearchItemGrid
            container
            // className={classes.searchItem}
            justifyContent="space-evenly"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
              <JitanButton
                variant="contained"
                // className={
                //   jitan ? classes.checkedButton : classes.unCheckButton
                // }
                onClick={() => jitanCheck(true)}
              >
                自担当
              </JitanButton>
              <SubeteButton
                variant="contained"
                // className={
                //   !jitan ? classes.checkedButton : classes.unCheckButton
                // }
                onClick={() => jitanCheck(false)}
              >
                すべて
              </SubeteButton>
          </SearchItemGrid> */}

          <SearchSectionGrid
            container
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            得点率
          </SearchSectionGrid>
          <SearchItemGrid
            container
            // className={classes.searchItem}
            justifyContent="space-evenly"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <OutlinedInput
              type="number"
              multiline={false}
              endAdornment={
                <span style={{ width: "80px", fontSize: "12px" }}>％以上</span>
              }
              style={{ width: "150px" }}
              value={lowerPoint}
              onChange={(e) => changeLowerPoint(e)}
            />
            <OutlinedInput
              type="number"
              multiline={false}
              endAdornment={
                <span style={{ width: "80px", fontSize: "12px" }}>％以下</span>
              }
              style={{ width: "150px" }}
              value={upperPoint}
              onChange={(e) => changeUpperPoint(e)}
            />
          </SearchItemGrid>

          <SearchSectionGrid
            container
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            地区名を選択
          </SearchSectionGrid>
          <SearchItemGrid
            container
            justifyContent="center"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <SearchFactoryArea
              disabled={false}
              firstView={false}
              initialDisplay={true}
              onClickFunc={(areaCode: string, areaName: string) => {
                saveStatusBottun("", "", areaCode, areaName, "", "");
              }}
            />
          </SearchItemGrid>
          {/*L2*/}
          <SearchSectionGrid
            container
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            工場ID・工場名
          </SearchSectionGrid>
          <SearchItemGrid
            container
            // className={classes.searchItem}
            justifyContent="space-evenly"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <SearchFactory
              // useStyles={stylesSearchTenpo}
              disabled={false}
              firstView={false}
              initialDisplay={true}
              onClickFunc={(factoryId: string, factoryFullName: string) => {
                // saveStatusBottun(factoryId, tenpoName, "", "", "", "", "", "");
                saveStatusBottun(factoryId, factoryFullName, "", "", "", "");
              }}
            />
          </SearchItemGrid>
          {/*L3*/}
      {/* {getAuthFactoryCheck(user.jobCategory).search < onlyArea &&
        !isTRDept(user.departmentCodeOA) ? "" : (
        <>
          <Grid
            container
            style={{
              borderTop: "1px solid #aaa",
            }}
            justifyContent="flex-start"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <Grid
              style={{
                paddingTop: "15px",
                paddingLeft: "15px",
                fontWeight: "bolder",
              }}
            >
              ZOを選択
            </Grid>
          </Grid>
          <SearchItemGrid
            container
            // className={classes.searchItem}
            // justify="center"
            // alignItems="center"
            justifyContent="center"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <SearchZO
              // useStyles={stylesSearchZO}
              disabled={getAuthFactoryCheck(user.jobCategory).search < onlyArea && !isTRDept(user.departmentCodeOA)}
              firstView={false}
              toggleStatus={false}
              searchMode={0}
              onClickFunc={(areaCode: string, zoName: string) => {
                // saveStatusBottun(factoryId, tenpoName, "", "", "", "", "", "");
                saveStatusBottun(factoryId, tenpoName, "", "", "", "");
              }}
            />
          </SearchItemGrid>
        </>
      )} */}
          {/*L4*/}
      {/* {getAuthFactoryCheck(user.jobCategory).search < onlyArea &&
        !isTRDept(user.departmentCodeOA)? "" : (
        <>
          <Grid
            container
            style={{
              borderTop: "1px solid #aaa",
            }}
            justifyContent="flex-start"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <Grid
              style={{
                paddingTop: "15px",
                paddingLeft: "15px",
                fontWeight: "bolder",
              }}
            >
              DOを選択
            </Grid>
          </Grid>
          <SearchItemGrid
            container
            // className={classes.searchItem}
            // justify="center"
            // alignItems="center"
            justifyContent="center"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <SearchDO
              // useStyles={stylesSearchDO}
              disabled={getAuthFactoryCheck(user.jobCategory).search < onlyArea && !isTRDept(user.departmentCodeOA)}
              firstView={false}
              toggleStatus={false}
              searchMode={getAuthFactoryCheck(user.jobCategory).search}
              onClickFunc={(doCode: string, doName: string) => {
                // saveStatusBottun(factoryId, tenpoName, "", "", "", "", "", "");
                saveStatusBottun(factoryId, tenpoName, "", "", "", "");
              }}
            />
          </SearchItemGrid>
        </>
      )} */}
      {/* {getAuthFactoryCheck(user.jobCategory).search < onlyArea &&
        !isTRDept(user.departmentCodeOA) ? "" : (
        <>
          <Grid
            container
            style={{
              borderTop: "1px solid #aaa",
            }}
            justifyContent="center"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <Grid
              style={{
                paddingTop: "15px",
                paddingLeft: "15px",
                fontWeight: "bolder",
              }}
            >
              {user.jobCategory === "dm" ? 
              "ＯＦＣ、ＤＭを選択" : "ＯＦＣ、ＤＭ、ＺＭを選択"
              }
            </Grid>
          </Grid>
          <SearchItemGrid
            container
            // className={classes.searchItem}
            // justify="center"
            // alignItems="center"
            justifyContent="center"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <SearchQC
              // useStyles={stylesSearchQC}
              disabled={getAuthFactoryCheck(user.jobCategory).search < onlyArea && !isTRDept(user.departmentCodeOA)}
              firstView={false}
              toggleStatus={false}
              searchMode={getAuthFactoryCheck(user.jobCategory).search}
              onClickFunc={(doCode: string, doName: string, qcMailAddress: string, qcName: string) => {
                // saveStatusBottun("", "", "", "", doCode, doName, qcMailAddress, qcName);
                saveStatusBottun("", "", doCode, doName, qcMailAddress, qcName);
              }}
              searchDocode={doCode}
            />
          </SearchItemGrid>
        </>
      )} */}
          {/*L5*/}
          <SearchSectionGrid
            container
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            期間を選択
          </SearchSectionGrid>
          <SearchItemGrid
            container
            // className={classes.searchItem}
            justifyContent="space-evenly"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <SejDatePicker
              selectedDate={createdDateFrom}
              dateFunc={(date: DateTime | null) => {
                handleBaseStartDate(date);
              }}
              format={
                "yyyy年MM月dd日" +
                "(" +
                getDayOfWeekText(createdDateFrom) +
                ")"
              }
              maxDate={DateTime.local()}
              // minDate={DateTime.local().minus(0)}
              mainColor={"#4dc0b2"}
            />
              ～
            <SejDatePicker
              selectedDate={createdDateTo}
              dateFunc={(date: DateTime | null) => {
                handleBaseEndDate(date);
              }}
              format={
                "yyyy年MM月dd日" +
                "(" +
                getDayOfWeekText(createdDateTo) +
                ")"
              }
              maxDate={DateTime.local()}
              mainColor={"#4dc0b2"}
            />
          </SearchItemGrid>
          <SearchItemGrid
            container
            // className={classes.searchItem}
            justifyContent="center"
            alignItems="center"
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <SearchButton
              // className={classes.searchButton}
              variant="contained"
              color="primary"
              disabled={!searchButtonFlg}
              onClick={() => {
                thumbUp = "";
                doSearch();
              }}
            >
              検索
            </SearchButton>
            <SeparatorLeftGrid />
            <SearchButton
              // className={classes.excuteButton}
              variant="contained"
              color="primary"
              disabled={!searchButtonFlg}
              onClick={() => {
                thumbUp = "1";
                doSearch();
              }}
            >
              好事例を検索
            </SearchButton>
            {isExecuteLogicalDelete(user) ?
            <>
            <SeparatorLeftGrid />
            <IconButton size="small" onClick={() => setLogicalDelete(logicalDelete === "1" ? "0" : "1")}>
              {logicalDelete === "1" ? 
                (<CheckBoxIcon/>)
               : 
               (<CheckBoxOutlineBlankIcon/>)
              }
              削除データを表示する
            </IconButton>
            </>
            :
            ""}
          </SearchItemGrid>
        </SearchAreaGrid>
      </ModalPaneModal>

      {/* メインコンテンツ */}
      <Grid>
        <Grid
          container 
          justifyContent={"center"}
          alignItems={"flex-start"}
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Header
            titleName={"工場チェック検索"}
            modalCondition={(e) => clickSearch(e)}
          />
          {searchFlg === "1" && resultList.length === 0 ? (
            <SeparatorGrid
              container 
              justifyContent="center"
              alignItems="center"
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              検索条件に一致するデータがありません。条件を変更して再度検索してください。
            </SeparatorGrid>
          ) : searchFlg === "1" ? (
            <FactoryCheckSearchResult
              resultList={resultList}
              updateResultList={updateResultList}
              setFactoryCheckId={setFactoryCheckId}
              setSearchResultRecord={setSearchResultRecord}
              setSearchFlg={setSearchFlg}
              generateCSV={generateCSV}
            />
          ) : searchFlg === "2" ? (
            <>
            <OverLayer/>
            <LoadingCircule
              size={"40vh"}
            />
            </>
          ) : searchFlg === "3" ? (
            <FactoryCheckSearchData
              factoryCheckId={factoryCheckId}
              updateResultList={updateResultList}
              searchResultRecord={searchResultRecord}
              setSearchFlg={setSearchFlg}
            />
          ) : (
            <SeparatorGrid
              container 
              justifyContent="center"
              alignItems="center"
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              右上の虫眼鏡アイコンから検索条件を指定してください。
            </SeparatorGrid>
          )}
        </Grid>
      </Grid>
      {/* ダイアログ */}
      <FactoryCheckDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogClose}
        doNo={dialogClose}
        isAlert={true}
      />
    </>
  );
};

export default FactorySearch;

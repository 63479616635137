import { FC } from "react";
import {
  VerticalAlignTop,
  VerticalAlignBottom,
} from "@mui/icons-material";
import Grid from '@mui/material/Unstable_Grid2';
import DownloadIcon from "@mui/icons-material/CloudDownload";

import {
  ViewOverFlowGrid,
  SeparatorGrid,
  SeparatorLeftGrid,
  UpDownButton,
  RegistrationButton,
} from "../Styled/FactoryCheckStyled";
import FactoryCheckSearchResultTable from "../../organisms/table/FactoryCheckSearchResultTable"

const moveScroll = (fieldName: string, bottomFlg?: boolean) => {
  let element = document?.getElementById(fieldName);
  if (element !== null) {
    if (bottomFlg) {
      element?.scrollIntoView(false);
    } else {
      element.scrollIntoView();
    }
  }
};

const FactoryCheckSearchResult: FC<{
  resultList: any;
  updateResultList: any;
  setFactoryCheckId: any;
  setSearchResultRecord: any;
  setSearchFlg: any;
  generateCSV: any;
}> = ({ resultList, updateResultList, setFactoryCheckId, setSearchResultRecord, setSearchFlg, generateCSV }) => {

  if (resultList && resultList.length > 0) {
    return (
      <>
      <ViewOverFlowGrid
        container 
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
      >
        <FactoryCheckSearchResultTable
          resultList={resultList}
          updateResultList={updateResultList}
          setFactoryCheckId={setFactoryCheckId}
          setSearchResultRecord={setSearchResultRecord}
          setSearchFlg={setSearchFlg}
          isReference={false}
        />
      </ViewOverFlowGrid>
      {/* コンテンツフッター↓ここから */}
      <Grid
        container
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
      >
        <SeparatorGrid
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
          >
             <RegistrationButton
              variant="contained"
              // className={classes.button}
              onClick={() => {
                generateCSV();
              }}
            >
              ＣＳＶダウンロード<DownloadIcon />
            </RegistrationButton>
          </Grid>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
          >
          </Grid>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <SeparatorLeftGrid>
              <UpDownButton
                onClick={() => moveScroll("topField")}
              >
                <VerticalAlignTop/>
              </UpDownButton>
            </SeparatorLeftGrid>
            <SeparatorLeftGrid>
              <UpDownButton
                onClick={() => moveScroll("bottomField")}
              >
                <VerticalAlignBottom/>
              </UpDownButton>
            </SeparatorLeftGrid>
          </Grid>
        </SeparatorGrid>
      </Grid>
      {/* コンテンツフッター↑ここまで */}
      </>
    );
  } else {
    return (
      <>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <SeparatorGrid>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            右上の虫眼鏡アイコンから検索条件を指定してください。
          </Grid>
          </SeparatorGrid>
        </Grid>
      </>
    );
  }
};
export default FactoryCheckSearchResult;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect } from "react";
// import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     greenButton: {
//       backgroundColor: "#00CC5B",
//       color: "#fff",
//       "&:hover": {
//         backgroundColor: "#00CC5B",
//       },
//     },
//   })
// );
const GreenButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#00CC5B",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#00CC5B",
  }
}));

const FactoryCheckDialog: React.FunctionComponent<{
  msg: string[];
  isOpen: boolean;
  doOK: any;
  doNo: any;
  isAlert: boolean;
  isClose?: boolean;
  boldList?: any[];
}> = ({ msg, isOpen, doOK, doNo, isAlert, isClose, boldList }) => {
  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const doNoAction = () => {
    if (isClose) {
      doNo();
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={() => doNoAction()}
        aria-labelledby="common-dialog-title"
        aria-describedby="common-dialog-description"
      >
        <DialogContent>
          {msg.map((row: string, i) =>
          　row ? 
            boldList && boldList.indexOf(i) !== -1 ? (
              <Typography style={{ fontWeight: "bold" }}>{row}</Typography>
            ) : (
              <Typography>{row}</Typography>
            )
            :<br />
          )}
        </DialogContent>
        <DialogActions>
          <GreenButton
            onClick={() => doOK()}
            variant={"contained"}
            // className={classes.greenButton}
          >
            OK
          </GreenButton>
          {isAlert ? (
            ""
          ) : (
            <Button onClick={() => doNo()} variant={"contained"}>
              キャンセル
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default FactoryCheckDialog;

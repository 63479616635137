import React, { useEffect, FC } from "react";
import {  useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";

// import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Modal,
  TextareaAutosize,
  Typography,
  styled,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

import { selectImageToken } from "../../../features/userSlice";
import { getFixedFlg } from "../../Header/Header";
import {
  FACTORY_CHECK_COMMENTS,
} from "./FactoryCheckDataOperation";

export const UploadGrid = styled(Grid)(({ theme }) => ({
  height: "7vh",
}));

export const PaneModal = styled(Modal)(({ theme }) => ({
  position: "absolute",
  paddingTop: "2%",
  paddingLeft: "5%",
  width: "84%",
  height: "80%",
}));

export const PaperGrid = styled(Grid)(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: "auto",
}));

export const TitleGrid = styled(Grid)(({ theme }) => ({
  height: "7vh",
  color: "white",
  backgroundColor: "#4dc0b2",
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
}));

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     uploadGrid: {
//       height: "9vh",
//     },
//     noUpload: {
//       color: "#f2f2f2",
//     },
//     uploaded: {
//       color: "#37ab9d",
//     },
//     modalPane: {
//       position: "absolute",
//       paddingTop: "2%",
//       paddingLeft: "5%",
//       width: "84%",
//       height: "80%",
//     },
//     paper: {
//       height: "100%",
//       backgroundColor: theme.palette.background.paper,
//       border: "2px solid #000",
//       boxShadow: theme.shadows[5],
//       padding: theme.spacing(2, 4, 3),
//     },
//     titleGrid: {
//       height: "7vh",
//       color: "white",
//       backgroundColor: "#4dc0b2",
//     },
//     titleTypography: {
//       fontWeight: "bold",
//     },
//   })
// );
const FactoryCheckModalLastScore: FC<{
  title: string;
  comments: Array<FACTORY_CHECK_COMMENTS>;
}> = ({
  title,
  comments,
}) => {
  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const imageToken = useSelector(selectImageToken);
  function getImageFile(imagePath: string) {
    if (imagePath === undefined || imagePath === "") {
      return `${window.location.origin}/image/noimage.png`;
    }
    return (
      process.env.REACT_APP_CLOUD_STORAGE_URL +
      imagePath +
      "?access_token=" +
      imageToken
    );
  }
  return (
    <>
      <UploadGrid
        container
        justifyContent={"center"}
        alignItems={"center"}
        // className={classes.uploadGrid}
      >
        <Grid
          onClick={handleOpen}
        >
          <CloseIcon style={{ color: "red" }} />
        </Grid>
      </UploadGrid>
      {/*  モーダルここから↓*/}
      <PaneModal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        // className={classes.modalPane}
      >
        <PaperGrid
          container 
          justifyContent={"center"}
          alignItems={"flex-start"}
        >
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
          >
          {/*  タイトルここから↓*/}
          <TitleGrid
            container
            justifyContent={"center"}
            alignItems={"center"}
            // className={classes.titleGrid}
            {...{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
          >
            <TitleTypography variant="h6" 
            // className={classes.titleTypography}
            >
              {title}
            </TitleTypography>
          </TitleGrid>
          {/*  タイトルここまで↑*/}
          <Grid
            container
            justifyContent={"center"}
            alignItems={"flex-start"}
            style={{ paddingTop: "10px" }}
            {...{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
          >
          {comments.map((comment: FACTORY_CHECK_COMMENTS, index: number) => (
            <>
            {/*  写真ここから↓*/}
            <Grid
              {...{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
            >
              <Grid
                container 
                justifyContent={"center"}
                alignItems={"center"}
                style={{ paddingTop: "10px" }}
              >
                <img
                  alt=""
                  src={getImageFile(comment.photo_filepath_gcs)}
                  height="200"
                  style={{
                    width: "200px",
                    WebkitAppearance: "none",
                  }}
                />
              </Grid>
            </Grid>
            {/*  写真ここまで↑*/}
            {/*  その他コメントここから↓*/}
            <Grid
              {...{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
            >
              {comment.comment_filepath_gcs ? (
                <img
                  alt=""
                  src={getImageFile(comment.comment_filepath_gcs)}
                  height="200"
                  style={{
                    width: "200px",
                    WebkitAppearance: "none",
                  }}
                />
                ):(
                <TextareaAutosize
                  // rowsMax={16}
                  aria-label="store-input-comment-area"
                  minRows={11}
                  placeholder={
                      "コメントがありません。"
                  }
                  value={comment.user_input_comment}
                  readOnly
                  style={{
                    width: "200px",
                    fontSize: "16px",
                    borderRadius: "8px",
                    margin: "10px",
                  }}
                />
              )}
            </Grid>
            {/*  その他コメントここまで↑*/}
            </>
          ))}
          </Grid>
          </Grid>
        </PaperGrid>
      </PaneModal>
      {/*  モーダル↑ここまで*/}
    </>
  );
};
export default FactoryCheckModalLastScore;

/**
 * FactoryCheckTable.tsxのソースコード量が多くなっていたので
 * データ処理に関して別ファイルに分割した
 *
 */
import {
  deleteFirestoreDoc,
  getFirestoreDataWhereArrayAny,
  getFirestoreDocData,
  writeFirestoreDoc,
} from "../../../apis/firestoreAction";
import { v4 as uuidv4 } from "uuid";
import { firestore, getFirebaseUserInfo } from "../../../firebase";
import { zonedTimeToUtc } from "date-fns-tz";
import { userLogging } from "../../../apis/userLog";

// 追加項目番号
export const FACTORY_CHECK_ADD_NUMBER = 90;

// 工場チェック個別項目の型
export interface FACTORY_CHECK_DATA {
  factory_id: string;
  id: string;
  last_time_id: string;
  zone_code: string;
  do_code: string;
  check_date: string;
  complete_date: string;
  last_check_date: string;
  update_user: string;
  check_hour:string;
  // 基本項目
  base_value: Array<FACTORY_CHECK_BIG_RECORD>;
  // 追加項目
  add_value: Array<FACTORY_CHECK_ADD_BIG_RECORD>;
  // （フォーマット）作成日
  format_create_date: string;
  // （フォーマット）更新日
  format_update_date: string;
  // 工場チェック選択
  factory_check_select: boolean[];
  // 回数
  report_count: string;
  // 立会人
  witness: string;
  // カテゴリーチェック
  category_check_value: boolean[];
  // カテゴリー記入項目
  manufactur_category: string;
  //  改善報告書提出期限
  submission_deadline: string;
  // 是正要請
  corrections: Array<REQUEST_FOR_CORRECTION>;
  // 総評・助言
  review_advice: string;
  // 監査者部署名
  auditor_department_name: string;
  // 監査者氏名
  auditor_name: string;
  // 編集可否
  is_fixed: boolean;
}

// 工場チェックの大項目単位の値の型
export interface FACTORY_CHECK_BIG_RECORD {
  big_title: string;
  big_item_id: number;
  primary_category: string;
  primary_category_short: string;
  rows: Array<FACTORY_CHECK_RECORD>;
}

// 工場チェックの追加項目の大項目単位の値の型(拡張)
export interface FACTORY_CHECK_ADD_BIG_RECORD extends FACTORY_CHECK_BIG_RECORD {
  maximum_small_item_id: number;
  rows: Array<FACTORY_CHECK_ADD_RECORD>;
}

// 工場チェックの小項目単位の値の型
export interface FACTORY_CHECK_RECORD {
  key: string; // 「2-3」のような「{大項目}-{小項目}」の形式
  big_item_id: number;
  small_title: string;
  small_item_id: number;
  basic_flag: boolean;
  secondary_category: string;
  secondary_start: boolean;
  secondary_end: boolean;
  tertiary_category: string;
  score: string;
  button_value: string;
  last_score: string;
  last_button_value: string;
  manual: string[];
  user_radio_comment: string;
  user_radio_target: string;
  reason_target_list: SELECTMAP[];
  reason_comment_map_list: REASONMAP[];
  user_ng_list: any[];
  title_link_list: any[];
  comments: FACTORY_CHECK_COMMENTS[];
  last_comments: FACTORY_CHECK_COMMENTS[];
}
// 工場チェックの詳細項目の値の型
export interface FACTORY_CHECK_COMMENTS {
  user_input_comment: string;
  photo_filepath_gcs: string;
  photo_file_data: string;
  comment_filepath_gcs: string;
  comment_file_data: string;
  comment_handwrite_flag: string;
}
// 工場チェックの詳細項目の値の型
export interface REASONMAP {
  value: string;
  comment_list: SELECTMAP[];
}
// 工場チェックの選択項目の値の型
export interface SELECTMAP {
  name: string;
  value: string;
  task_id?: string;
}
// 工場チェックのNG項目の値の型
export interface USERNGMAP {
  user_radio_comment: string;
  user_radio_target: string;
  task_id?: string;
}
// 工場チェックの追加項目の大項目単位の値の型(拡張)
export interface FACTORY_CHECK_ADD_RECORD extends FACTORY_CHECK_RECORD {
  uuid: string;
}

// 工場チェックのテンプレートの型
export interface FACTORY_CHECK_ITEM_TEMPLATE {
  id: string;
  doc_id: string;
  template_name: string;
  conditions: string[];
  big_item_id: number;
  big_item_name: string;
  small_items: Array<TEMPLATE_ITEM>;
  info: {
    create_date: any;
    create_user: string;
    update_date: any;
    update_user: string;
  };
}
// テンプレート内の追加項目の型
export interface TEMPLATE_ITEM {
  id: number;
  name: string;
  uuid: string;
  comments: FACTORY_CHECK_COMMENTS[];
  // photo_filepath_gcs: string[];
  // user_input_comment: string;
  // comment_filepath_gcs: string[];
  // comment_handwrite_flag: string;
}

// 工場チェックの大項目単位の値の型
export interface REQUEST_FOR_CORRECTION {
  correction_no: number;
  // 重篤性判断
  judgement_of_severity: string;
  // 基準
  standard: string;
  // 記録
  evaluation: string;
  // 連単
  follow_up: string;
  // コメント・助言
  comment_advice: string;
}

const defaultCorrections: Array<REQUEST_FOR_CORRECTION> = [
  {
    correction_no: 1,
    judgement_of_severity: "",
    standard: "",
    evaluation: "",
    follow_up: "",
    comment_advice: "",
  },
  {
    correction_no: 2,
    judgement_of_severity: "",
    standard: "",
    evaluation: "",
    follow_up: "",
    comment_advice: "",
  },
  {
    correction_no: 3,
    judgement_of_severity: "",
    standard: "",
    evaluation: "",
    follow_up: "",
    comment_advice: "",
  },
  {
    correction_no: 4,
    judgement_of_severity: "",
    standard: "",
    evaluation: "",
    follow_up: "",
    comment_advice: "",
  },
  {
    correction_no: 5,
    judgement_of_severity: "",
    standard: "",
    evaluation: "",
    follow_up: "",
    comment_advice: "",
  },
];

export const defaultComments: FACTORY_CHECK_COMMENTS = {
  user_input_comment:  "",
  photo_filepath_gcs:  "",
  photo_file_data:  "",
  comment_filepath_gcs:  "",
  comment_file_data:  "",
  comment_handwrite_flag:  "",
}

//Todo 追加項目
const addItemBigRecordId: string = "090";
const addItemBigRecordDmId: string = "090_dm";
const addItemBigRecordZmId: string = "090_zm";

// Firestoreより3レコード取得して結果をマージし、UIに表示するデータを作る
export const getFactoryCheckData = async (
  factoryCode: string,
  docId: string,
  lastDocId: string,
  fixedFlag: boolean,
  userPrivilege: string,
  retDocId: any
) => {
  // 1:3レコード取得する
  // 大項目番号1～7の共通大項目の場合
  const baseItemValues = await getFirestoreDocData(
    "factory_check_items",
    "000001_basedata"
  );
  const addItemValues = await getFirestoreDocData(
    "factory_check_add_items",
    factoryCode
  );
  // let retDocId = null;
  if (docId) {
    // retDocId = await getFirestoreDocData("factory_check_records", docId);
    if (!retDocId) {
      // firestoreからデータが取得できないとき（初回でデータがない場合）必要な情報を以下作成
      const baseItemValue: any = baseItemValues;
      let smallValueArray: Array<FACTORY_CHECK_RECORD> = [];
      let bigValueArray: Array<FACTORY_CHECK_BIG_RECORD> = [];
      Object.keys(baseItemValue.attribution)
        .sort()
        .forEach(function (key) {
          const bigValue: FACTORY_CHECK_BIG_RECORD = {
            big_title: baseItemValue.attribution[key].big_item_name,
            big_item_id: baseItemValue.attribution[key].big_item_id,
            primary_category: baseItemValue.attribution[key].primary_category,
            primary_category_short: baseItemValue.attribution[key].primary_category_short,
            // 前で作成した小項目の配列より同じ大項目の項目の値を設定する
            rows: makeBigRecordArray(
              baseItemValue.attribution[key].big_item_id,
              baseItemValue.attribution[key].small_items,
              smallValueArray,
              false
            ),
          };
          bigValueArray.push(bigValue);
        });
      let records: any = {};
      for (const bigRecord of bigValueArray) {
        for (const smallRecord of bigRecord.rows) {
          const keyValue: string = "records-" + smallRecord.key;
          records[keyValue] = smallRecord;
        }
      }
      let attribution: any = {
        check_date: "",
        last_check_date: "",
      };
      let update_date: any = {
        update_user: "",
      };
      retDocId = {
        records: records,
        attribution: attribution,
        update_date: update_date,
      };
    }
  }
  const docIdData: any = retDocId;
  let lastWeek: any = null;
  if (docIdData) {
    lastWeek = await getJsonlastWeeks(lastDocId);
  }
  const lastWeekRecords = lastWeek;
  return await editData(
    baseItemValues,
    addItemValues,
    docIdData,
    factoryCode,
    docId,
    lastDocId,
    fixedFlag,
    lastWeekRecords,
    userPrivilege || ""
  );
};

//前回の追加項目のuuidを検索して返す
function getLastAddItem(lastRecords: any, currentRecords: any) {
  let lastWeekRecord: any = null;
  const keys = lastRecords ? sortObject(lastRecords) : [];
  Object.keys(keys)
  .sort()
  .forEach(function (key: any) {
    //追加項目のuuidを検索
    if (keys[key]["uuid"] === currentRecords["uuid"]) {
      lastWeekRecord = keys[key];
    }
  });
  return lastWeekRecord;
}

const editData = (
  baseItemValues: any,
  addItemValues: any,
  docIdData: any,
  factoryId: string,
  docId: string,
  lastDocId: string,
  fixedFlag: boolean,
  lastWeekRecords: any,
  userPrivilege: string
): FACTORY_CHECK_DATA => {
  let completeFlg = false;
  if (docIdData && docIdData.attribution.complete_date) {
    completeFlg = true;
  }
  const data: FACTORY_CHECK_DATA = {
    factory_id: factoryId,
    id: docId,
    last_time_id: lastDocId,
    zone_code: "",
    do_code: "",
    check_date: docIdData ? docIdData.attribution.check_date : "",
    complete_date: docIdData ? docIdData.attribution.complete_date : "",
    last_check_date: docIdData ? docIdData.attribution.last_check_date : "",
    update_user: docIdData ? docIdData.update_date.update_user : "",
    check_hour:docIdData ? docIdData.attribution.check_hour : "",
    base_value: [],
    add_value: [],
    format_update_date: formatDate(baseItemValues.updatedate.update_date.toDate()),
    format_create_date: formatDate(baseItemValues.updatedate.create_date.toDate()),
    report_count: docIdData ? docIdData.attribution.reportCount ? docIdData.attribution.reportCount : "" : "",
    witness: docIdData ? docIdData.attribution.witness ? docIdData.attribution.witness : "" : "",
    category_check_value: docIdData ? docIdData.attribution.categoryCheckValue ? docIdData.attribution.categoryCheckValue : [false, false, false, false, false, false, false, false, false] : [false, false, false, false, false, false, false, false, false],
    factory_check_select: docIdData ? docIdData.attribution.factoryCheckSelect ? docIdData.attribution.factoryCheckSelect : [false, false, false, false, false, false, false, false, false] : [false, false, false, false, false, false, false, false, false],
    manufactur_category: docIdData ? docIdData.attribution.manufacturCategory ? docIdData.attribution.manufacturCategory : "" : "",
    submission_deadline: docIdData ? docIdData.attribution.submissionDeadline ? docIdData.attribution.submissionDeadline : "" : "",
    corrections: docIdData ? docIdData.attribution.corrections ? docIdData.attribution.corrections : defaultCorrections : defaultCorrections,
    // judgementOfSeverity: docIdData ? docIdData.attribution.judgementOfSeverity ? docIdData.attribution.judgementOfSeverity : ["", "", "", "", ""] : ["", "", "", "", ""],
    // standard: docIdData ? docIdData.attribution.standard ? docIdData.attribution.standard : ["", "", "", "", ""] : ["", "", "", "", ""],
    // evaluation: docIdData ? docIdData.attribution.evaluation ? docIdData.attribution.evaluation : ["", "", "", "", ""] : ["", "", "", "", ""],
    // followUp: docIdData ? docIdData.attribution.followUp ? docIdData.attribution.followUp : ["", "", "", "", ""] : ["", "", "", "", ""],
    // commentAdvice: docIdData ? docIdData.attribution.commentAdvice ? docIdData.attribution.commentAdvice : ["", "", "", "", ""] : ["", "", "", "", ""],
    review_advice: docIdData ? docIdData.attribution.reviewAdvice ? docIdData.attribution.reviewAdvice : "" : "",
    auditor_department_name: docIdData ? docIdData.attribution.auditorDepartmentName ? docIdData.attribution.auditorDepartmentName : "" : "",
    auditor_name: docIdData ? docIdData.attribution.auditorName ? docIdData.attribution.auditorName : "" : "",
    is_fixed: docIdData ? docIdData.attribution.isFixed ? docIdData.attribution.isFixed : false : false,
  };
  let smallValueArray: Array<FACTORY_CHECK_RECORD> = [];
  let smallAddValueArray: Array<FACTORY_CHECK_ADD_RECORD> = [];
  const keys = docIdData ? sortObject(docIdData["records"]) : [];
  let indexSmallValueArray = 0;
  let currentSecondaryCategory = "";
  Object.keys(keys)
    .sort()
    .forEach(function (key: any) {
      let recordKey = "records-" + keys[key]["key"];
      let lastWeekScore: string = "";
      let lastWeekButton: string = "";
      let lastComments: Array<FACTORY_CHECK_COMMENTS> = [];
      if (lastWeekRecords[recordKey]) {
        lastWeekScore = lastWeekRecords[recordKey]["score"];
        lastWeekButton = lastWeekRecords[recordKey]["button_value"];
        lastComments = lastWeekRecords[recordKey]["comments"];
      }
      let secondaryStart = false;
      if (currentSecondaryCategory !== keys[key]["secondary_category"]) {
        currentSecondaryCategory = keys[key]["secondary_category"];
        secondaryStart = true;
      }
      else if (indexSmallValueArray !== 0 && smallValueArray[indexSmallValueArray - 1]) {
        smallValueArray[indexSmallValueArray - 1].secondary_end = false;
      }
      if (keys[key]["big_item_id"] !== FACTORY_CHECK_ADD_NUMBER) {
        const smallValue: FACTORY_CHECK_RECORD = {
          key: keys[key]["key"],
          big_item_id: keys[key]["big_item_id"],
          small_title: keys[key]["small_title"],
          small_item_id: keys[key]["small_item_id"],
          basic_flag: keys[key]["basic_flag"],
          secondary_category: keys[key]["secondary_category"],
          secondary_start: secondaryStart,
          secondary_end: true,
          tertiary_category: keys[key]["tertiary_category"],
          score: keys[key]["score"],
          button_value: keys[key]["button_value"],
          last_score: lastWeekScore,
          last_button_value: lastWeekButton,
          manual: [],
          user_radio_comment: keys[key]["user_radio_comment"],
          user_radio_target: keys[key]["user_radio_target"] || "",
          reason_target_list: keys[key]["reason_target_list"] || [],
          reason_comment_map_list: keys[key]["reason_comment_map_list"] || [],
          user_ng_list: keys[key]["user_ng_list"] || [],
          title_link_list:[],
          comments: keys[key]["comments"] || [defaultComments],
          last_comments: lastComments || [defaultComments],
        };
        smallValueArray.push(smallValue);
      } else {
        // 前回の追加項目のuuidを検索して返す
        let lastWeekRecord = getLastAddItem(lastWeekRecords, keys[key]);
        //追加項目のuuidが合致した場合、同じbigItem-SmallItemとしてデータを保存
        if (lastWeekRecord !== null) {
          lastWeekScore = lastWeekRecord["score"];
          lastWeekButton = lastWeekRecord["button_value"];
          lastComments = lastWeekRecord["comments"];
        }
        //uuidが合致しなかったものはないものとして扱う
        else {
          lastWeekScore = "";
          lastWeekButton = "";
          lastComments = [];
        }
        const smallAddValue: FACTORY_CHECK_ADD_RECORD = {
          uuid: keys[key]["uuid"],
          key: keys[key]["key"],
          big_item_id: keys[key]["big_item_id"],
          small_title: keys[key]["small_title"],
          small_item_id: keys[key]["small_item_id"],
          basic_flag: keys[key]["basic_flag"],
          secondary_category: "その他",
          secondary_start: secondaryStart,
          secondary_end: true,
          tertiary_category: "その他",
          score: keys[key]["score"],
          button_value: keys[key]["button_value"],
          last_score: lastWeekScore,
          last_button_value: lastWeekButton,
          manual: [],
          user_radio_comment: keys[key]["user_radio_comment"],
          user_radio_target: keys[key]["user_radio_target"] || "",
          reason_target_list: keys[key]["reason_target_list"] || [],
          reason_comment_map_list: keys[key]["reason_comment_map_list"] || [],
          user_ng_list: keys[key]["user_ng_list"] || [],
          title_link_list:[],
          comments: keys[key]["comments"] || [defaultComments],
          last_comments: lastComments || [defaultComments],
        };
        smallAddValueArray.push(smallAddValue);
      }
      indexSmallValueArray++;
    });

  let bigValueArray: Array<FACTORY_CHECK_BIG_RECORD> = [];
  // 大項目(共通大項目が10を超えるとソートが崩れるので注意!)
  Object.keys(baseItemValues.attribution)
  .sort()
  .forEach(function (key) {
    const bigValue: FACTORY_CHECK_BIG_RECORD = {
      big_title: baseItemValues.attribution[key].big_item_name,
      big_item_id: baseItemValues.attribution[key].big_item_id,
      primary_category: baseItemValues.attribution[key].primary_category,
      primary_category_short: baseItemValues.attribution[key].primary_category_short,
  // 前で作成した小項目の配列より同じ大項目の項目の値を設定する
      rows: makeBigRecordArray(
        baseItemValues.attribution[key].big_item_id,
        baseItemValues.attribution[key].small_items,
        smallValueArray,
        completeFlg
      ),
    };
    bigValueArray.push(bigValue);
  });
  // 追加項目
  let bigValueAddArray: Array<FACTORY_CHECK_ADD_BIG_RECORD> = [];
  const bigAddValue = (): FACTORY_CHECK_ADD_BIG_RECORD => {
    let addItemUserPrivilege = userPrivilege;
    if (fixedFlag) {
      addItemUserPrivilege = docIdData
        ? docIdData.attribution.user_privilege
        : "";
    }
    let itemBigRecordId = addItemBigRecordId;
    if (addItemUserPrivilege === "dm") {
      itemBigRecordId = addItemBigRecordDmId;
    } else if (addItemUserPrivilege === "zm") {
      itemBigRecordId = addItemBigRecordZmId;
    }
    if (fixedFlag) {
      return {
        big_title: addItemValues.attribution[itemBigRecordId]["big_item_name"],
        big_item_id: addItemValues.attribution[itemBigRecordId]["big_item_id"],
        primary_category: addItemValues.attribution[itemBigRecordId]["primary_category"],
        primary_category_short: addItemValues.attribution[itemBigRecordId]["primary_category_short"],
        maximum_small_item_id: getAddValuesMaxId(
          addItemValues.attribution[itemBigRecordId]["small_items"]
        ),
        // 前で作成した小項目の配列より同じ大項目の項目の値を設定する
        rows: smallAddValueArray,
      };
    } else if (
      addItemValues &&
      addItemValues.attribution[itemBigRecordId] &&
      addItemValues.attribution[itemBigRecordId]["big_item_id"]
    ) {
      return {
        big_title: addItemValues.attribution[itemBigRecordId]["big_item_name"],
        big_item_id: addItemValues.attribution[itemBigRecordId]["big_item_id"],
        primary_category: addItemValues.attribution[itemBigRecordId]["primary_category"],
        primary_category_short: addItemValues.attribution[itemBigRecordId]["primary_category_short"],
        maximum_small_item_id: getAddValuesMaxId(
          addItemValues.attribution[itemBigRecordId]["small_items"]
        ),
        // 前で作成した小項目の配列より同じ大項目の項目の値を設定する
        rows: linkItemArray(smallAddValueArray, itemBigRecordId, addItemValues),
      };
    } else {
      const temp: FACTORY_CHECK_ADD_BIG_RECORD = {
        big_title: "その他",
        big_item_id: FACTORY_CHECK_ADD_NUMBER,
        primary_category: "その他",
        primary_category_short: "その他",
        maximum_small_item_id: 0,
        rows: [],
      };
      const addItem = {
        big_item_name: "その他",
        big_item_id: FACTORY_CHECK_ADD_NUMBER,
        primary_category: "その他",
        primary_category_short: "その他",
        maximum_small_item_id: 0,
        small_items: linkItemArray([], itemBigRecordId, addItemValues),
      };
      const objects: any = {};
      let recordItem = {};
      let recordItemDm = {};
      let recordItemZm = {};
      if (addItemValues) {
        recordItem = addItemValues["attribution"][addItemBigRecordId] || {};
        recordItemDm = addItemValues["attribution"][addItemBigRecordDmId] || {};
        recordItemZm = addItemValues["attribution"][addItemBigRecordZmId] || {};
      }
      if (userPrivilege === "dm") {
        objects["attribution"] = {
          "090": recordItem,
          "090_dm": addItem,
          "090_zm": recordItemZm,
        };
      } else if (userPrivilege === "zm") {
        objects["attribution"] = {
          "090": recordItem,
          "090_dm": recordItemDm,
          "090_zm": addItem,
        };
      } else {
        objects["attribution"] = {
          "090": addItem,
          "090_dm": recordItemDm,
          "090_zm": recordItemZm,
        };
      }
      if (!fixedFlag) {
        userLogging("ＱＣ工場チェック", "新規作成#factory_check_itemsテーブル追加", JSON.stringify(objects));
        writeFirestoreDoc("factory_check_add_items", factoryId, objects);
      }
      return temp;
    }
  };
  bigValueAddArray.push(bigAddValue());
  data["base_value"] = bigValueArray;
  data["add_value"] = bigValueAddArray;
  return data;
};

// 追加項目の項目連携
const linkItemArray = (
  smallAddValueArray: Array<FACTORY_CHECK_ADD_RECORD>,
  itemBigRecordId: string,
  addItemValues: any
) => {
  if (!addItemValues || !addItemValues["attribution"][itemBigRecordId]) {
    return [];
  }
  let resultArray: Array<FACTORY_CHECK_ADD_RECORD> = [];
  let smallItems = addItemValues["attribution"][itemBigRecordId]["small_items"];
  if (smallItems) {
    for (let i = 0; i < smallItems.length; i++) {
      let recordFlg = false;
      let keyNum = Number(i) + 1;
      for (const val of smallAddValueArray) {
        if (smallItems[i].uuid === val.uuid) {
          recordFlg = true;
          val.key = FACTORY_CHECK_ADD_NUMBER + "-" + keyNum;
          val.small_item_id = keyNum;
          resultArray.push(val);
          break;
        }
      }
      if (!recordFlg) {
        const smallValue: FACTORY_CHECK_ADD_RECORD = {
          uuid: smallItems[i]["uuid"],
          key: FACTORY_CHECK_ADD_NUMBER + "-" + keyNum,
          big_item_id: FACTORY_CHECK_ADD_NUMBER,
          small_title: smallItems[i].name,
          small_item_id: keyNum,
          basic_flag: false,
          secondary_category: "",
          secondary_start: false,
          secondary_end: false,
          tertiary_category: "",
          score: "0",
          button_value: "0",
          last_score: "0",
          last_button_value: "0",
          manual: [],
          user_radio_comment: "",
          user_radio_target: "",
          reason_target_list: [],
          reason_comment_map_list: [],
          user_ng_list: [],
          title_link_list:[],
          comments:[defaultComments],
          last_comments:[defaultComments],
        };
        resultArray.push(smallValue);
      }
    }
  }
  return resultArray;
};

// レコードを作る関数
const makeBigRecordArray = (
  bigItemId: string,
  smallItems: any,
  smallValueArray: Array<FACTORY_CHECK_RECORD>,
  completeFlg: boolean
): Array<FACTORY_CHECK_RECORD> => {
  const searchRecord = (
    bigItemId: string,
    smallItemId: string,
    smallItemTitle: string,
    basic_flag: boolean,
    secondary_category: string,
    tertiary_category: string,
    manual?: any[],
    reason_target_list?: any[],
    reason_comment_map_list?: any[],
    title_link_list?: any[]
  ) => {
    const key = bigItemId + "-" + smallItemId;
    for (const row of smallValueArray) {
      if (row["key"] === key) {
        row["small_title"] = smallItemTitle;
        row["basic_flag"] = basic_flag;
        if (secondary_category) {
          row["secondary_category"] = secondary_category;
        }
        if (tertiary_category) {
          row["tertiary_category"] = tertiary_category;
        }
        if (manual) {
          row["manual"] = manual;
        }
        if (!completeFlg && reason_comment_map_list) {
          row["reason_comment_map_list"] = reason_comment_map_list;
        }
        if (reason_target_list) {
          row["reason_target_list"] = reason_target_list;
        }
        if (title_link_list) {
          row["title_link_list"] = title_link_list;
        }
        return row;
      }
    }
    const tmp: FACTORY_CHECK_RECORD = {
      key,
      big_item_id: Number(bigItemId),
      small_title: smallItemTitle,
      small_item_id: Number(smallItemId),
      basic_flag: false,
      secondary_category: "",
      secondary_start: false,
      secondary_end: false,
      tertiary_category: "",
      score: "0",
      button_value: "0",
      last_score: "0",
      last_button_value: "0",
      manual: [],
      user_radio_comment: "",
      user_radio_target: "",
      reason_target_list: [],
      reason_comment_map_list: [],
      user_ng_list: [],
      title_link_list:[],
      comments:[defaultComments],
      last_comments:[defaultComments],
  };
    return tmp;
  };
  let array: Array<FACTORY_CHECK_RECORD> = [];
  for (const row of smallItems) {
    const val = searchRecord(
      bigItemId,
      row.id,
      row.name,
      row.basic_flag,
      row.secondary_category,
      row.tertiary_category,
      row.manual,
      row.reason_target_list,
      row.reason_comment_map_list,
      row.title_link_list
    );
    if (val !== undefined) {
      array.push(val);
    }
  }
  return array;
};

// レコードを作る関数
const makeAddBigRecordArray = (
  bigItemId: string,
  smallItems: any,
  smallValueArray: Array<FACTORY_CHECK_ADD_RECORD>
): Array<FACTORY_CHECK_ADD_RECORD> => {
  const searchRecord = (
    bigItemId: string,
    smallItemId: string,
    smallItemTitle: string,
    uuid: string
  ) => {
    const key = bigItemId + "-" + smallItemId;
    for (const row of smallValueArray) {
      if (row["uuid"] === uuid) {
        row["small_title"] = smallItemTitle;
        row["uuid"] = uuid;
        return row;
      }
    }
    const tmp: FACTORY_CHECK_ADD_RECORD = {
      uuid: uuid,
      key,
      big_item_id: Number(bigItemId),
      small_title: smallItemTitle,
      small_item_id: Number(smallItemId),
      basic_flag: false,
      secondary_category: "",
      secondary_start: false,
      secondary_end: false,
      tertiary_category: "",
      score: "0",
      button_value: "0",
      last_score: "0",
      last_button_value: "0",
      manual: [],
      user_radio_comment: "",
      user_radio_target: "",
      reason_target_list: [],
      reason_comment_map_list: [],
      user_ng_list: [],
      title_link_list:[],
      comments:[defaultComments],
      last_comments:[defaultComments],
    };
    return tmp;
  };
  let array: Array<FACTORY_CHECK_ADD_RECORD> = [];
  for (const row of smallItems) {
    const val = searchRecord(bigItemId, row.id, row.name, row.uuid);
    if (val !== undefined) {
      array.push(val);
    }
  }
  return array;
};

// 追加項目の小項目の最大の値を取得する
const getAddValuesMaxId = (smallItems: any): number => {
  let maxNumber = 0;
  if (smallItems) {
    for (const row of smallItems) {
      if (row.id > maxNumber) maxNumber = row.id;
    }
  }
  return maxNumber;
};

// 配列の順番を整えるため、ここだけ連想配列キーを「xx-yy(例:02-11)」のような形にする
const sortObject = (objects: any) => {
  let newObj: any = {};
  Object.keys(objects).forEach(function (key) {
    const tempKey =
      ("00" + objects[key]["big_item_id"]).slice(-2) +
      ("00" + objects[key]["small_item_id"]).slice(-2);
    newObj[tempKey] = objects[key];
  });
  return newObj;
};

/**
 * 工場チェックの変更項目を差分更新し、最終的にReactHooksに保存する
 * @param factoryCheckData 工場チェック(基本項目)の全てのデータ
 * @returns
 */
export const updateFactoryCheck = (
  factoryCheckData: FACTORY_CHECK_BIG_RECORD[],
  newData: FACTORY_CHECK_RECORD
): FACTORY_CHECK_BIG_RECORD[] => {
  const newFactoryCheckData: FACTORY_CHECK_BIG_RECORD[] = [];
  for (const bigRecord of factoryCheckData) {
    let newArray: FACTORY_CHECK_RECORD[] = [];
    for (const record of bigRecord.rows) {
      if (record.key === newData.key) {
        newArray.push(newData);
      } else {
        newArray.push(record);
      }
    }
    bigRecord.rows = newArray;
    newFactoryCheckData.push(bigRecord);
  }
  return newFactoryCheckData;
};

/**
 * 工場チェックの変更項目を差分更新し、最終的にReactHooksに保存する
 * @param factoryCheckData 工場チェック(追加項目)の全てのデータ
 * @param newNumber -1の場合は新規追加して最後尾にpushする
 * @returns
 */
export const updateFactoryCheckAddValue = (
  factoryCheckData: FACTORY_CHECK_ADD_BIG_RECORD[],
  newData: FACTORY_CHECK_ADD_RECORD,
  isNew: boolean = false
) => {
  const newFactoryCheckData: FACTORY_CHECK_ADD_BIG_RECORD[] = [];
  for (const bigRecord of factoryCheckData) {
    let newArray: FACTORY_CHECK_ADD_RECORD[] = [];
    for (const addRecord of bigRecord.rows) {
      if (addRecord.key === newData.key && !isNew) {
        newArray.push(newData);
      } else {
        newArray.push(addRecord);
      }
    }
    if (isNew) {
      newArray.push(newData);
    }
    bigRecord.rows = newArray;
    newFactoryCheckData.push(bigRecord);
  }
  return newFactoryCheckData;
};

/**
 * 工場チェックの値を受け取り、Firestoreの更新を行う
 * @param basicItemList 基本項目の配列
 * @param addRowItemList 追加項目の配列
 *
 */
export const updateFactoryCheckFirestore = async (
  rowItemList: Array<FACTORY_CHECK_BIG_RECORD>,
  addRowItemList: Array<FACTORY_CHECK_ADD_BIG_RECORD>,
  factoryCheckId: string,
  totalScore: number,
  factoryCheckLastTimeId: string,
  selectedTenpoCode: string,
  checkDate: string,
  lastCheckDate: string,
  userPrivilege: string,
  checkHour: string,
  factoryCheckSelect: boolean[],
  reportCount: string,
  witness: string,
  categoryCheckValue: boolean[],
  manufacturCategory: string,
  submissionDeadline: string,
  corrections: Array<REQUEST_FOR_CORRECTION>,
  reviewAdvice: string,
  auditorDepartmentName: string,
  auditorName: string,
  isFixed: boolean,
  complete_date?: string
) => {
  if (
    (Array.isArray(rowItemList) && rowItemList.length === 0) ||
    factoryCheckId === ""
  ) {
    // 初回読み込みで呼ばれてしまうので空であれば終了する
    return;
  }
  const objects: any = {};
    // let corrections: Array<REQUEST_FOR_CORRECTION> = []
    // Object.keys(baseItemValues.attribution)
    // .sort()
    // .forEach(function (key) {
    //   corrections.push(baseItemValues.attribution[key].corrections);
    // });
    // data["corrections"] = corrections;
    
  objects["attribution"] = {
    conditions: ["ALL"],
    last_time_id: factoryCheckLastTimeId,
    factory_id: selectedTenpoCode,
    total_score: String(totalScore),
    check_date: checkDate,
    last_check_date: lastCheckDate,
    user_privilege: userPrivilege,
    check_hour: checkHour,
    factoryCheckSelect: factoryCheckSelect,
    reportCount: reportCount,
    witness: witness,
    categoryCheckValue: categoryCheckValue,
    manufacturCategory: manufacturCategory,
    submissionDeadline: submissionDeadline,
    corrections: corrections,
    reviewAdvice: reviewAdvice,
    auditorDepartmentName: auditorDepartmentName,
    auditorName: auditorName,
    isFixed: isFixed,
  };
  if (complete_date) {
    objects["attribution"] = {
      conditions: ["ALL"],
      last_time_id: factoryCheckLastTimeId,
      factory_id: selectedTenpoCode,
      total_score: String(totalScore),
      check_date: checkDate,
      last_check_date: lastCheckDate,
      user_privilege: userPrivilege,
      check_hour: checkHour,
      factoryCheckSelect: factoryCheckSelect,
      reportCount: reportCount,
      witness: witness,
      categoryCheckValue: categoryCheckValue,
      manufacturCategory: manufacturCategory,
      submissionDeadline: submissionDeadline,
      corrections: corrections,
      reviewAdvice: reviewAdvice,
      auditorDepartmentName: auditorDepartmentName,
      auditorName: auditorName,
      isFixed: isFixed,
      complete_date: complete_date,
    };
  }
  objects["id"] = factoryCheckId;
  objects["docId"] = factoryCheckId;
  objects["update_date"] = {
    update_date: firestore.FieldValue.serverTimestamp(),
    update_user: getFirebaseUserInfo().uid,
  };
  let records: any = {};
  for (const bigRecord of rowItemList) {
    for (const smallRecord of bigRecord.rows) {
      const keyValue: string = "records-" + smallRecord.key;
      records[keyValue] = smallRecord;
    }
  }
  for (const bigRecord of addRowItemList) {
    for (const smallRecord of bigRecord.rows) {
      const keyValue: string = "records-" + smallRecord.key;
      records[keyValue] = smallRecord;
    }
  }
  objects["records"] = records;
  userLogging(
    "工場チェック", "新規作成#factory_check_recordsテーブル更新",
    JSON.stringify(objects)
  );
  await writeFirestoreDoc("factory_check_records", factoryCheckId, objects);
};

// 追加ボタン押下時の新規レコード追加処理
export const createNewRecord = (
  small_title: string,
  addRowItemLArray: FACTORY_CHECK_ADD_BIG_RECORD,
  comments: FACTORY_CHECK_COMMENTS[]
): FACTORY_CHECK_ADD_RECORD => {
  const big_item_id = FACTORY_CHECK_ADD_NUMBER;
  let maxSmallId = 0;
  if (addRowItemLArray) {
    for (const row of addRowItemLArray.rows) {
      if (Number(row.key.split("-")[1]) > maxSmallId) {
        maxSmallId = Number(row.key.split("-")[1]);
      }
    }
  }
  const new_small_item_id = maxSmallId + 1;
  const val: FACTORY_CHECK_ADD_RECORD = {
    uuid: uuidv4(),
    key: big_item_id + "-" + new_small_item_id,
    big_item_id: big_item_id,
    small_title: small_title,
    small_item_id: Number(new_small_item_id),
    basic_flag: false,
    secondary_category: "",
    secondary_start: false,
    secondary_end: false,
    tertiary_category: "",
    score: "0",
    button_value: "0",
    last_score: "0",
    last_button_value: "0",
    manual: [],
    user_radio_comment: "",
    user_radio_target: "",
    reason_target_list: [],
    reason_comment_map_list: [],
    user_ng_list: [],
    title_link_list:[],
    comments: comments,
    last_comments:[defaultComments],
  };
  return val;
};

// 削除ボタン押下時
export const deleteAddItems = (
  deleteClickList: string[],
  addRowItemList: FACTORY_CHECK_ADD_BIG_RECORD[]
) => {
  let resultList = [];
  for (const addRecord of addRowItemList[0].rows) {
    if (!deleteClickList.includes(addRecord.key)) {
      resultList.push(addRecord);
    }
  }
  addRowItemList[0].rows = resultList;
  return addRowItemList;
};

// ソートボタン押下時
export const sortAddItems = (
  addRowItemList: FACTORY_CHECK_ADD_BIG_RECORD[],
  isUp: boolean,
  key: string
) => {
  // 上から順番を取得する
  let array: FACTORY_CHECK_ADD_RECORD[] = addRowItemList[0].rows;
  let order: number = 0;
  for (const addRecord of array) {
    if (key === addRecord.key) {
      // 0始まりの順番
      break;
    }
    order++;
  }
  let nextOrderKey: string = key;
  // 前に移動
  if (isUp) {
    let x = order,
      y = order - 1;
    if (y < 0) {
      y = order;
    }
    let temp = array[order];
    const xKey = array[x].key;
    const yKey = array[y].key;
    nextOrderKey = yKey;
    array[x] = array[y];
    array[y] = temp;
    array[x].key = xKey;
    array[y].key = yKey;
  } else if (isUp === false) {
    let x = order,
      y = order + 1;
    if (array.length === y) {
      y = order;
    }
    let temp = array[order];
    const xKey = array[x].key;
    const yKey = array[y].key;
    nextOrderKey = yKey;
    array[x] = array[y];
    array[y] = temp;
    array[x].key = xKey;
    array[y].key = yKey;
  }
  addRowItemList[0].rows = array;
  return { addRowItemList, nextKey: nextOrderKey };
};

export const getTenpoFirestoreData = async (factoryId: string) => {
  const data = await getFirestoreDocData("existing_sej_stores", factoryId).then(
    (response: any) => {
      return response;
    }
  );
  return data;
};

// 追加項目のFirestoreデータ更新を行う
export const updateFactoryCheckAddItem = async (
  addRowItemList: FACTORY_CHECK_ADD_BIG_RECORD[],
  factoryId: string,
  userPrivilege: string
) => {
  let addItem = [];
  for (const row of addRowItemList[0].rows) {
    addItem.push({
      id: row.key.split("-")[1],
      name: row.small_title,
      uuid: row.uuid,
    });
  }
  // 工場チェック項目の取得を行う
  const addItemValues = await getFirestoreDocData(
    "factory_check_add_items",
    factoryId
  ).then((response: any) => {
    return response;
  });

  let itemBigRecordId = addItemBigRecordId;
  if (userPrivilege === "dm") {
    itemBigRecordId = addItemBigRecordDmId;
  } else if (userPrivilege === "zm") {
    itemBigRecordId = addItemBigRecordZmId;
  }
  addItemValues["attribution"][itemBigRecordId]["small_items"] = addItem;
  userLogging("ＱＣ工場チェック", "新規作成#factory_check_itemsテーブル更新", JSON.stringify(addItemValues));

  writeFirestoreDoc("factory_check_add_items", factoryId, addItemValues);
};

const FORMAT = "YYYY/MM/DD HH:mm:ss";

export function getFirstDayOfWeek(target_date: string, addDate: number) {
  // let targetYear = target_date.substring(0, 4);
  // let targetMonth = target_date.substring(4, 6);
  // let targetDate = target_date.substring(6, 8);
  // let today = new Date(targetYear + "/" + targetMonth + "/" + targetDate + " 00:00:00");
  // 日付取得
  let today = zonedTimeToUtc(target_date, "Asia/Tokyo");
  let this_year = today.getFullYear();
  let this_month = today.getMonth();
  let date = today.getDate();
  let day_num = today.getDay();
  // Sunday - Saturday : 0 - 6
  if (day_num === 0) {
    day_num = 7;
  }
  let this_monday = date - day_num + 1 + addDate;

  //月曜日の年月日
  let firstDayOfWeek = new Date(this_year, this_month, this_monday);
  let year = firstDayOfWeek.getFullYear() + "";
  let month =
    firstDayOfWeek.getMonth() + 1 < 10
      ? "0" + (firstDayOfWeek.getMonth() + 1)
      : firstDayOfWeek.getMonth() + 1 + "";
  let day =
    firstDayOfWeek.getDate() < 10
      ? "0" + firstDayOfWeek.getDate()
      : firstDayOfWeek.getDate();
  //文字列を作成
  return year + month + day;
}

async function getJsonlastWeeks(last_time_id: string) {
  let retDocItem: any = null;
  let retRecords: any = {};
  let base_week: string = "";
  // 過去1週分取得する
  // let n: number = 1;
  // for (let i: number = 0; i < n; ) {
  //   try {
  //     if (last_time_id) {
  //       retDocItem = await getFirestoreDocData(
  //         "factory_check_records",
  //         last_time_id
  //       );
  //       if (retDocItem) {
  //         if (base_week === "") {
  //           base_week = getFirstDayOfWeek(
  //             retDocItem.attribution.check_date,
  //             0
  //           );
  //         }
  //         let target_week = getFirstDayOfWeek(
  //           retDocItem.attribution.check_date,
  //           0
  //         );
  //         let last_weeks = getFirstDayOfWeek(base_week, -7 * i);
  //         let complete_date = retDocItem.attribution.complete_date;
  //         last_time_id = retDocItem.attribution.last_time_id;
  //         if (complete_date && last_weeks !== target_week) {
  //           retRecords = retDocItem.records;
  //           i++;
  //         }
  //       }
  //     } else {
  //       break;
  //     }
  //   } catch {
  //     break;
  //   }
  // }
  if (last_time_id) {
    retDocItem = await getFirestoreDocData(
      "factory_check_records",
      last_time_id
    );
    if (retDocItem) {
      // let complete_date = retDocItem.attribution.complete_date;
      // if (complete_date) {
        retRecords = retDocItem.records;
      // }
    }
  }
  return retRecords;
}

// テンプレート一覧取得
export const getTemplateData = async (conditions: string[]) => {
  let templateArray: FACTORY_CHECK_ITEM_TEMPLATE[] = [];
  if (conditions.length === 0) {
    return templateArray;
  }
  const getTmpTempData = async (con: string[]) => {
    await getFirestoreDataWhereArrayAny(
      "factory_check_items_template",
      "attribution.conditions",
      con
    ).then((response: any) => {
      response.forEach((res: any) => {
        let data = res.data();
        let docID = data.doc_id;
        let skipFlg = false;
        for (let i = 0; i < templateArray.length; i++) {
          if (docID === templateArray[i].doc_id) {
            skipFlg = true;
          }
        }
        if (skipFlg) {
          return;
        }
        const templateValue: FACTORY_CHECK_ITEM_TEMPLATE = {
          id: data.id,
          doc_id: data.doc_id,
          template_name: data.attribution.template_name,
          big_item_id: data.attribution.big_item_id,
          big_item_name: data.attribution.big_item_name,
          conditions: data.attribution.conditions,
          small_items: data.attribution.small_items,
          info: data.info,
        };
        templateArray.push(templateValue);
      });
      return templateArray;
    });
  };

  if (conditions && conditions.length > 10) {
    let workConditions = [];
    for (let i = 0; i < conditions.length; i++) {
      workConditions.push(conditions[i]);
      if (workConditions.length % 10 === 0 || i + 1 === conditions.length) {
        await getTmpTempData(workConditions);
        workConditions = [];
      }
    }
  } else {
    await getTmpTempData(conditions);
  }
  return templateArray;
};

// テンプレートのFirestoreデータ更新を行う
export const updateFactoryCheckTemplate = async (
  templateItem: FACTORY_CHECK_ITEM_TEMPLATE,
  newFlg?: boolean
) => {
  const objects: any = {};
  objects["attribution"] = {
    big_item_id: templateItem.big_item_id,
    big_item_name: templateItem.big_item_name,
    template_name: templateItem.template_name,
    conditions: templateItem.conditions,
    small_items: templateItem.small_items,
  };
  objects["id"] = templateItem.id;
  objects["doc_id"] = templateItem.doc_id;
  objects["info"] = {
    create_date: newFlg
      ? firestore.FieldValue.serverTimestamp()
      : templateItem.info
      ? templateItem.info.create_date
      : null,
    create_user: newFlg
      ? getFirebaseUserInfo().uid
      : templateItem.info
      ? templateItem.info.create_user
      : "",
    update_date: firestore.FieldValue.serverTimestamp(),
    update_user: getFirebaseUserInfo().uid,
  };

  userLogging("ＱＣ工場チェック", "新規作成#factory_check_items_templateテーブル更新", JSON.stringify(objects));
  await writeFirestoreDoc("factory_check_items_template", templateItem.doc_id, objects);
};

// テンプレートのFirestoreデータ削除を行う
export const deleteFactoryCheckTemplate = async (doc_id: string) => {
  deleteFirestoreDoc("factory_check_items_template", doc_id);
};

// factory_check_recordsのデータを取得
export const getFactoryCheckRecordsFirestoreData = async (factoryCheckId: string) => {
  const data = await getFirestoreDocData("factory_check_records", factoryCheckId).then(
    (response: any) => {
      return response;
    }
  );
  return data;
};

/**
 * Firestoreの編集可否の値を更新を行う
 * @param docId ドキュメントID
 * @param isFixed 編集可否
 *
 */
export const writeIsFixed = async (
  docId: string,
  isFixed: boolean
) => {
  await getFirestoreDocData(
    "factory_check_records",
    docId
  ).then((response: any) => {
    response["attribution"]["isFixed"] = isFixed;
    writeFirestoreDoc("factory_check_records", docId, response);
  });
};

export const formatDate = (date: Date) => {
  const year = date.getFullYear().toString().padStart(4, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return year + '/' + month + '/' + day;
}
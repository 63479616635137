import { DateTime } from "luxon";
import {
  Checkbox,
  Typography,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';

import { getDayOfWeekText } from "../../../apis/util";
import {
  FACTORY_CHECK_BIG_RECORD,
  FACTORY_CHECK_ADD_BIG_RECORD,
  FACTORY_CHECK_RECORD,
} from "../../FactoryCheck/Register/FactoryCheckDataOperation";
import {
  FACTORY_CHECK_SCORES,
  calculationUnitScore,
  updateScore,
} from "../../FactoryCheck/Register/FactoryCheckCalculation";
import { 
  EXISTING_FACTORY_RECORD,
} from "../../../data/existingFactory";

import {
  getCategoryScoreTable,
} from "../../organisms/table/FactoryCheckCategoryScoreTable";
import {
  convertCommentLine,
} from "../../organisms/table/FactoryCheckCorrectionsTable";

import {
  BaseGrid,
  SeparatorGrid,
  SeparatorLeftGrid,
  HeaderGrid,
  MiddleHeaderGrid,
  PrintMiddleContentsGrid,
  PrintContentsGrid,
  PrintDataContentsGrid,
  DataHeaderGrid,
  InputGrid,
  DataInputGrid,
  DataMiddleGrid,
} from "../../FactoryCheck/Styled/FactoryCheckStyled";

const OTHER_VALUE: string = "99";

export function commentReason(row: FACTORY_CHECK_RECORD) {
  let comment = "";
  const addComment = (
    row: FACTORY_CHECK_RECORD,
    target: string,
    comment: string,
    targetFlg: boolean
  ) => {
    let workComment = "";
    if (row.reason_target_list && target && targetFlg) {
      for (let i = 0; i < row.reason_target_list.length; i++) {
        if (target === row.reason_target_list[i].value) {
          workComment += row.reason_target_list[i].name + " ";
        }
      }
    }
    if (OTHER_VALUE === target) {
      workComment += "その他 ";
    }
    if (
      row.reason_comment_map_list &&
      row.reason_comment_map_list.length > 0 &&
      comment
    ) {
      for (let i = 0; i < row.reason_comment_map_list.length; i++) {
        if (target === row.reason_comment_map_list[i].value) {
          let commentList = row.reason_comment_map_list[i].comment_list;
          for (let j = 0; j < commentList.length; j++) {
            if (comment === commentList[j].value) {
              workComment += commentList[j].name + " ";
            }
          }
        }
      }
    }
    return workComment;
  };

  comment += addComment(
    row,
    row.user_radio_target,
    row.user_radio_comment,
    true
  );
  if (row.user_ng_list) {
    let targetNo = "";
    for (let i = 0; i < row.user_ng_list.length; i++) {
      let targetFlg = false;
      if (row.user_ng_list[i].user_radio_target !== targetNo) {
        targetNo = row.user_ng_list[i].user_radio_target;
        targetFlg = true;
      }
      comment += addComment(
        row,
        row.user_ng_list[i].user_radio_target,
        row.user_ng_list[i].user_radio_comment,
        targetFlg
      );
    }
  }
  return comment;
}

const isEnd = (currentSubCategory: string, rows: any, index: number) => {
  if (rows.length === index + 1 || rows[index + 1].tertiary_category !== currentSubCategory) {
    return true;
  }
  return false;
}
const isTop = (currentSubCategory: string, rows: any, index: number) => {
  if (index === 0 || rows[index - 1].tertiary_category !== currentSubCategory) {
    return true;
  }
  return false;
}

export function getCheckRecord(
  factoryCheckSelect: boolean[],
  rowItemList: FACTORY_CHECK_BIG_RECORD[],
  addRowItemList: FACTORY_CHECK_ADD_BIG_RECORD[],
  formatCreateDate: string,
  formatUpdateDate: string,
  factoryRecordSearch: EXISTING_FACTORY_RECORD | undefined,
  checkDate: DateTime | null,
  inputHour: string,
  witness: string,
  categoryCheckValue: boolean[],
  reportCount: string,
  manufacturCategory: string,
  submissionDeadline: DateTime | null,
  imageToken: string,
  auditorDepartmentName: string,
  auditorName: string,
  // isHeaderCheckImage: boolean,
) {
  let scoresList: Array<FACTORY_CHECK_SCORES> = updateScore(rowItemList, factoryCheckSelect);
  return (
    <>
          <SeparatorGrid></SeparatorGrid>
        {/* ヘッダー部 START */}
        <Grid
          container 
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid {...{ xs: 6, sm: 6, md: 6, lg: 6 }}>
            <Typography variant="h4">デイリーメーカー 工場確認報告書</Typography>
          </Grid>
          <Grid {...{ xs: 3, sm: 3, md: 3, lg: 3 }}>
            <Typography variant="h4">マスタ項目</Typography>
          </Grid>    
          <Grid {...{ xs: 3, sm: 3, md: 3, lg: 3 }}>
            <Grid container 
            // justify="flex-start" alignItems="center"
            >
              <Grid {...{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                【作成】{DateTime.fromFormat(formatCreateDate, "yyyy/MM/dd").toFormat("yyyy年MM月dd日")}
              </Grid>
            </Grid>
            <Grid container 
            // justify="flex-start" alignItems="center"
            >
              <Grid {...{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                【改訂】{DateTime.fromFormat(formatUpdateDate, "yyyy/MM/dd").toFormat("yyyy年MM月dd日")}
              </Grid>
            </Grid>
          </Grid>    
        </Grid>
        {/* ヘッダー部 END */}
        <SeparatorGrid/>
        <Grid
          container 
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          {/* 情報部 START */}
          <BaseGrid
            container 
            {...{ xs: 5, sm: 5, md: 5, lg: 5 }}
            justifyContent="space-evenly"
            alignItems="center"
          >
          <Grid
            container 
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
              <Typography variant="h6">地区名</Typography>
            </HeaderGrid>
            <PrintContentsGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }}>
              <Typography variant="h6">{factoryRecordSearch ? factoryRecordSearch.factory_area : ""}</Typography>
            </PrintContentsGrid>
          </Grid>
          <Grid
            container 
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
              <Typography variant="h6">工場名</Typography>
            </HeaderGrid>
            <PrintContentsGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }}>
              <Typography variant="h6">{factoryRecordSearch ? factoryRecordSearch.factory_full_name : ""}</Typography>
            </PrintContentsGrid>
          </Grid>
          <Grid
            container 
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
              <Typography variant="h6">監査日時</Typography>
            </HeaderGrid>
            <InputGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            {checkDate ? (
              <Typography variant="h6">
                {checkDate.toFormat("yyyy年MM月dd日") +
                  "(" +
                  getDayOfWeekText(checkDate) +
                  ")"}
              </Typography>
            ) : (
              ""
            )}
            </InputGrid>
          </Grid>
          <Grid
            container 
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
              <Typography variant="h6">監査時間帯</Typography>
            </HeaderGrid>
            <InputGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }}>
              <Typography variant="h6">
                {inputHour}時頃
              </Typography>
            </InputGrid>
          </Grid>
          <Grid
            container 
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
              <Typography variant="h6">回数</Typography>
            </HeaderGrid>
            <InputGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }}>
              <Typography variant="h6">{reportCount}{reportCount ? "回目" : ""}</Typography>
            </InputGrid>
          </Grid>
          <Grid
            container 
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
              <Typography variant="h6">立会い者</Typography>
            </HeaderGrid>
            <PrintContentsGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }}>
              <Typography variant="h6">{witness}</Typography>
            </PrintContentsGrid>
          </Grid>
          <Grid
            container 
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
              <Typography variant="h6">製造カテゴリー</Typography>
            </HeaderGrid>
            <InputGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }}>
              <Grid container>
                <Grid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
                  <Grid>
                    <Checkbox
                      style={{ color: "black" }}
                      checked={categoryCheckValue[0]}
                      readOnly={true}
                    />米飯
                  </Grid>
                  <Grid>
                    <Checkbox
                      style={{ color: "black" }}
                      checked={categoryCheckValue[1]}
                      readOnly={true}
                    />麺類
                  </Grid>
                  <Grid>
                    <Checkbox
                      style={{ color: "black" }}
                      checked={categoryCheckValue[2]}
                      readOnly={true}
                    />焼成パン
                  </Grid>
                </Grid>
                <Grid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
                  <Grid>
                    <Checkbox
                      style={{ color: "black" }}
                      checked={categoryCheckValue[3]}
                      readOnly={true}
                    />チルド米飯
                  </Grid>
                  <Grid>
                    <Checkbox
                      style={{ color: "black" }}
                      checked={categoryCheckValue[4]}
                      readOnly={true}
                    />デリカ
                  </Grid>
                  <Grid>
                    <Checkbox
                      style={{ color: "black" }}
                      checked={categoryCheckValue[5]}
                      readOnly={true}
                    />７Ｐ
                  </Grid>
                </Grid>
                <Grid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
                  <Grid>
                    <Checkbox
                      style={{ color: "black" }}
                      checked={categoryCheckValue[6]}
                      readOnly={true}
                    />調理パン
                  </Grid>
                  <Grid>
                    <Checkbox
                      style={{ color: "black" }}
                      checked={categoryCheckValue[7]}
                      readOnly={true}
                    />スイーツ
                  </Grid>
                </Grid>
                <Grid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
                  <Grid>
                    <Checkbox
                      style={{ color: "black" }}
                      checked={categoryCheckValue[8]}
                      readOnly={true}
                    />（{manufacturCategory ? manufacturCategory : "　　　　"}）
                  </Grid>
                </Grid>
              </Grid>
            </InputGrid>
          </Grid>
          <Grid
            container 
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
              <Typography variant="h6">監査者</Typography>
            </HeaderGrid>
            <PrintContentsGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }}>
              <Typography variant="h6">{auditorDepartmentName} {auditorName}</Typography>
            </PrintContentsGrid>
          </Grid>
          <Grid
            container 
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }}>
              <Typography variant="h6">改善報告書提出期限</Typography>
            </HeaderGrid>
            <InputGrid {...{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            {submissionDeadline ? (
              <Typography variant="h6">
                {submissionDeadline.toFormat("yyyy年MM月dd日") +
                  "(" +
                  getDayOfWeekText(submissionDeadline) +
                  ")"}
              </Typography>
            ) : (
              ""
            )}
            </InputGrid>
          </Grid>
          </BaseGrid>
          {/* 情報部 END */}
          <Grid {...{ xs: 1, sm: 1, md: 1, lg: 1 }}></Grid>
          {/* 評価部 START */}
          <BaseGrid
            container 
            maxHeight={"28vh"}
            {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
          >
          <Grid
            container 
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <HeaderGrid {...{ xs: 4, sm: 4, md: 4, lg: 4 }} minHeight={37}>
              <Typography variant="h6">項目</Typography>
            </HeaderGrid>
            <MiddleHeaderGrid {...{ xs: 5, sm: 5, md: 5, lg: 5 }} minHeight={37}>
              <Typography variant="h6">評価</Typography>
            </MiddleHeaderGrid>
            <HeaderGrid {...{ xs: 3, sm: 3, md: 3, lg: 3 }} minHeight={37}>
              
            </HeaderGrid>
          </Grid>
            {getCategoryScoreTable(scoresList)}
          </BaseGrid>
          {/* 評価部 END */}
          <SeparatorGrid
            container
            justifyContent={"center"}
            {...{ xs: 5, sm: 5, md: 5, lg: 5 }}
          >
            <Grid
              container
              {...{ xs: 12, sm: 12, md: 5, lg: 5 }}
            >
              <BaseGrid
                container
                maxHeight={"16vh"}
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <DataHeaderGrid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  {...{ xs: 7, sm: 7, md: 7, lg: 7 }}
                >
                  <Typography variant="h6">ランク評価</Typography>
                </DataHeaderGrid>
                <PrintDataContentsGrid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  {...{ xs: 5, sm: 5, md: 5, lg: 5 }}
                >
                  <Typography variant="h3">{scoresList[scoresList.length-1].rank}</Typography>
                </PrintDataContentsGrid>
              </BaseGrid>
            </Grid>
            <Grid
              {...{ xs: 12, sm: 12, md: 1, lg: 1 }}
            />
            <Grid
              {...{ xs: 12, sm: 12, md: 6, lg: 6 }}
            >
              <BaseGrid
                {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <PrintContentsGrid
                  {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                >
                  <Grid
                    container
                    {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  >
                    <Grid
                      {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
                    >
                      Ａ：９０％以上
                    </Grid>
                    <Grid
                      {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
                    >
                      　
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  >
                    <Grid
                      {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
                    >
                      Ｂ：８０％以上
                    </Grid>
                    <Grid
                      {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
                    >
                      　
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  >
                    <Grid
                      {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
                    >
                      Ｃ：７０％以上
                    </Grid>
                    <Grid
                      {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
                    >
                      　※再監査要
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
                  >
                    <Grid
                      {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
                    >
                      Ｄ：７０％未満
                    </Grid>
                    <Grid
                      {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
                    >
                      　※再監査＋面談
                    </Grid>
                  </Grid>
                </PrintContentsGrid>
              </BaseGrid>
            </Grid>
          </SeparatorGrid>
          <SeparatorGrid
            container
            justifyContent={"flex-end"}
            {...{ xs: 7, sm: 7, md: 7, lg: 7 }}
          >
            <BaseGrid
            >
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                minWidth={100}
                maxWidth={100}
              >
                <Grid>
                  <Typography>工場立会者</Typography>
                </Grid>
              </DataHeaderGrid>
              <PrintContentsGrid
                minHeight={"10vh"}
              >
              </PrintContentsGrid>
            </BaseGrid>
            <SeparatorLeftGrid/>
            <BaseGrid
              container
            >
              <Grid
              >
                <DataHeaderGrid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  minWidth={100}
                  maxWidth={100}
                >
                  <Grid>
                    <Typography>担当ＱＣ</Typography>
                  </Grid>
                </DataHeaderGrid>
                <PrintContentsGrid
                  minHeight={"10vh"}
                >
                </PrintContentsGrid>
              </Grid>
              <Grid
              >
                <DataHeaderGrid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  minWidth={100}
                  maxWidth={100}
                >
                  <Grid>
                    <Typography>担当ＭＧＲ</Typography>
                  </Grid>
                </DataHeaderGrid>
                <PrintContentsGrid
                  minHeight={"10vh"}
                >
                </PrintContentsGrid>
              </Grid>
              <Grid
              >
                <DataHeaderGrid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  minWidth={100}
                  maxWidth={100}
                >
                  <Grid>
                    <Typography>担当ＡＧＭ</Typography>
                  </Grid>
                </DataHeaderGrid>
                <PrintContentsGrid
                  minHeight={"10vh"}
                >
                </PrintContentsGrid>
              </Grid>
            </BaseGrid>
            {/* 評価部 END */}
          </SeparatorGrid>
        </Grid>
        {/* 情報部 END */}
        {getCheckRow(rowItemList, scoresList)}
        {getCheckAddRow(addRowItemList)}
    </>
  )
}

function getCheckRow(
  rowItemList: FACTORY_CHECK_BIG_RECORD[],
  scoresList: FACTORY_CHECK_SCORES[]
) {
  let secondaryCount = 0;
  return (
    <>
      <Grid container
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
      >
        {/* チェック表↓ここから */}
        <SeparatorGrid/>
        <Grid container  {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        // justify="center"
        >
          <BaseGrid
            container
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            {/* タイトル行↓ここから */}
            <Grid container {...{ xs: 12, sm: 12, md: 12, lg: 12 }}>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                xs={3}
              >
                <Typography variant="h6">項目</Typography>
              </DataHeaderGrid>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                xs={3.5}
              >
                <Typography variant="h6">確認内容</Typography>
              </DataHeaderGrid>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                xs={1}
              >
                <Typography variant="h6">管理分類</Typography>
              </DataHeaderGrid>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                xs={0.5}
              >
                <Typography variant="h6">基本</Typography>
              </DataHeaderGrid>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                xs={0.5}
              >
                <Typography variant="h6">評価</Typography>
              </DataHeaderGrid>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                xs={0.5}
              >
                <Typography variant="h6">配点</Typography>
              </DataHeaderGrid>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                xs={3}
              >
                <Typography variant="h6">問題点・助言等</Typography>
              </DataHeaderGrid>
            </Grid>
            {/* タイトル行↑ここまで */}
            {rowItemList.map((rowItem: any, indexRowItem: number) => (
              <>
              {Object.keys(rowItem.rows).length !== 0 ? (
                <>
                {/* データ行↓ここから */}
                <Grid container {...{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                  {rowItem.rows.map((row: any, indexRow: number) => (
                    <>
                      {/* 項目↓ここから */}
                      {row.secondary_start && row.secondary_end ? 
                        (
                      <PrintContentsGrid {...{ xs: 1, sm: 1, md: 1, lg: 1 }}>
                        <Typography>{row.secondary_category}</Typography>
                      </PrintContentsGrid>
                        )
                        :
                        row.secondary_start ?
                        (
                      <PrintMiddleContentsGrid {...{ xs: 1, sm: 1, md: 1, lg: 1 }}>
                        <Typography>{row.secondary_category}</Typography>
                      </PrintMiddleContentsGrid>
                        )
                        :
                        row.secondary_end ?
                        (
                      <PrintContentsGrid {...{ xs: 1, sm: 1, md: 1, lg: 1 }}>
                        <Typography></Typography>
                      </PrintContentsGrid>
                        )
                        :
                        (
                      <PrintMiddleContentsGrid {...{ xs: 1, sm: 1, md: 1, lg: 1 }}>
                        <Typography></Typography>
                      </PrintMiddleContentsGrid>
                        )
                      }
                      {isEnd(row.tertiary_category, rowItem.rows, indexRow) ? 
                        (
                      <PrintContentsGrid {...{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                        <Typography>{isTop(row.tertiary_category, rowItem.rows, indexRow) ? (row.tertiary_category) : ("")}</Typography>
                      </PrintContentsGrid>
                        )
                        :
                        isTop(row.tertiary_category, rowItem.rows, indexRow) ?
                        (
                      <PrintMiddleContentsGrid {...{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                        <Typography>{row.tertiary_category}</Typography>
                      </PrintMiddleContentsGrid>
                        )
                        :
                        (
                      <PrintMiddleContentsGrid {...{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                        <Typography></Typography>
                      </PrintMiddleContentsGrid>
                        )
                      }
                      {/* 項目↑ここまで */}
                      {/* 確認内容↓ここから */}
                      <PrintContentsGrid xs={3.5} 
                        // key={row.key + "_caption"}
                      >
                        <Typography>{row.small_title}</Typography>
                      </PrintContentsGrid>
                      {/* 確認内容↑ここまで */}
                      {/* 管理分類↓ここから */}
                      <PrintDataContentsGrid
                        container
                        xs={1}
                        justifyContent={"center"}
                        alignItems="center"
                      >
                        {rowItem.primary_category_short}
                      </PrintDataContentsGrid>
                      {/* 管理分類↑ここまで */}
                      {/* 基本↓ここから */}
                      <PrintDataContentsGrid
                        container
                        xs={0.5}
                        justifyContent={"center"}
                        alignItems="center"
                      >
                        {row.basic_flag ? "K" : ""}
                      </PrintDataContentsGrid>
                      {/* 基本↑ここまで */}
                      {/* 評価↓ここから */}
                      <DataInputGrid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        xs={0.5}
                        // key={row.key + "_check"}
                      >
                      {row.button_value === "1" ? (
                        <RadioButtonUnchecked />
                      ) : row.button_value === "2" ? (
                          <CloseIcon />
                      ) : row.button_value === "3" ? (
                          <ChangeHistoryIcon />
                      ) : row.button_value === "0" ? (
                          <RemoveIcon />
                      ) : (
                        ""
                      )}
                      </DataInputGrid>
                      {/* 評価↑ここまで */}
                      {/* 配点↓ここから */}
                      {row.secondary_start && row.secondary_end ? 
                        (
                      <DataInputGrid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        {...{ xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5 }}
                      >
                        <Typography variant="h6">{scoresList[indexRowItem].secondary_score[secondaryCount++]}</Typography>
                      </DataInputGrid>
                        )
                        :
                        row.secondary_start ?
                        (
                      <DataMiddleGrid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        {...{ xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5 }}
                      >
                        <Typography variant="h6">{scoresList[indexRowItem].secondary_score[secondaryCount++]}</Typography>
                      </DataMiddleGrid>
                        )
                        :
                        row.secondary_end ?
                        (
                      <PrintContentsGrid {...{ xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5 }}>
                        <Typography></Typography>
                      </PrintContentsGrid>
                        )
                        :
                        (
                      <PrintMiddleContentsGrid {...{ xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5 }}>
                        <Typography></Typography>
                      </PrintMiddleContentsGrid>
                        )
                      }
                      {/* 配点↑ここまで */}
                      {/* 問題点・助言等↓ここから */}
                      <PrintContentsGrid
                        container
                        alignItems={"center"}
                        xs={3}
                      >
                        <Typography>{commentReason(row)}</Typography>
                        {convertCommentLine(row.comments[0].user_input_comment)}
                      </PrintContentsGrid>
                      {/* 問題点・助言等↑ここまで */}
                    </>
                  ))}
                </Grid>
                {/* データ行↑ここまで */}
            </>
          ) : (
            ""
          )}
      </>
      ))}
              </BaseGrid>
        </Grid>
      </Grid>
    </>
  );
}
function printAddRecordsEmpty(notEmptyRecords: number) {
  const list = [];
  for (let i = 0; i < 20 - notEmptyRecords; i++) {
    list.push(
    <>
      {/* 確認内容↓ここから */}
      <PrintContentsGrid
        {...{ xs: 6.5, sm: 6.5, md: 6.5, lg: 6.5 }}
        // key={row.key + "_caption"}
      >
        <Typography></Typography>
      </PrintContentsGrid>
      {/* 確認内容↑ここまで */}
      {/* 管理分類↓ここから */}
      <PrintDataContentsGrid
        container
        {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
        justifyContent={"center"}
        alignItems="center"
      >
      </PrintDataContentsGrid>
      {/* 管理分類↑ここまで */}
      {/* 基本↓ここから */}
      {/* <PrintDataContentsGrid
        container
        {...{ xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5 }}
        justifyContent={"center"}
        alignItems="center"
      >
        {row.basic_flag ? "K" : ""}
      </PrintDataContentsGrid> */}
      {/* 基本↑ここまで */}
      {/* 評価↓ここから */}
      <DataInputGrid
        container
        justifyContent={"center"}
        alignItems={"center"}
        {...{ xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5 }}
        // key={row.key + "_check"}
      >
      </DataInputGrid>
      {/* 評価↑ここまで */}
      {/* 問題点・助言等↓ここから */}
      <PrintContentsGrid
        container
        alignItems={"center"}
        {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
      >
        <Typography></Typography>
      </PrintContentsGrid>
      {/* 問題点・助言等↑ここまで */}
    </>
    );
  }
  return list;
}

function getCheckAddRow(
  addRowItemList: FACTORY_CHECK_ADD_BIG_RECORD[],
) {
  if (!addRowItemList || addRowItemList.length === 0) return "";
  return (
    <>
      <Grid container
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
      >
        {/* チェック表↓ここから */}
        <SeparatorGrid/>
        <Grid
          container 
          justifyContent="flex-start"
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Typography variant="h4">
            {addRowItemList[0].big_title}
          </Typography>
        </Grid>
        <Grid container  {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        // justify="center"
        >
          <BaseGrid
            container
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            {/* タイトル行↓ここから */}
            <Grid container {...{ xs: 12, sm: 12, md: 12, lg: 12 }}>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 6.5, sm: 6.5, md: 6.5, lg: 6.5 }}
              >
                <Typography variant="h6">確認内容</Typography>
              </DataHeaderGrid>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
              >
                <Typography variant="h6">管理分類</Typography>
              </DataHeaderGrid>
              {/* <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5 }}
              >
                <Typography variant="h6">基本</Typography>
              </DataHeaderGrid> */}
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5 }}
              >
                <Typography variant="h6">評価</Typography>
              </DataHeaderGrid>
              <DataHeaderGrid
                container
                justifyContent={"center"}
                alignItems={"center"}
                {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
              >
                <Typography variant="h6">問題点・助言等</Typography>
              </DataHeaderGrid>
            </Grid>
            {/* タイトル行↑ここまで */}
            {addRowItemList.map((rowItem: any, indexRowItem: number) => (
              <>
              {Object.keys(rowItem.rows).length !== 0 ? (
                <>
                {/* データ行↓ここから */}
                <Grid container {...{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                  {rowItem.rows.map((row: any, indexRow: number) => (
                    <>
                      {/* 確認内容↓ここから */}
                      <PrintContentsGrid
                        {...{ xs: 6.5, sm: 6.5, md: 6.5, lg: 6.5 }}
                        // key={row.key + "_caption"}
                      >
                        <Typography>{row.small_title}</Typography>
                      </PrintContentsGrid>
                      {/* 確認内容↑ここまで */}
                      {/* 管理分類↓ここから */}
                      <PrintDataContentsGrid
                        container
                        {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
                        justifyContent={"center"}
                        alignItems="center"
                      >
                        {rowItem.primary_category_short}
                      </PrintDataContentsGrid>
                      {/* 管理分類↑ここまで */}
                      {/* 基本↓ここから */}
                      {/* <PrintDataContentsGrid
                        container
                        {...{ xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5 }}
                        justifyContent={"center"}
                        alignItems="center"
                      >
                        {row.basic_flag ? "K" : ""}
                      </PrintDataContentsGrid> */}
                      {/* 基本↑ここまで */}
                      {/* 評価↓ここから */}
                      <DataInputGrid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        {...{ xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5 }}
                        // key={row.key + "_check"}
                      >
                      {row.button_value === "1" ? (
                        <RadioButtonUnchecked />
                      ) : row.button_value === "2" ? (
                          <CloseIcon />
                      ) : row.button_value === "3" ? (
                          <ChangeHistoryIcon />
                      ) : row.button_value === "0" ? (
                          <RemoveIcon />
                      ) : (
                        ""
                      )}
                      </DataInputGrid>
                      {/* 評価↑ここまで */}
                      {/* 問題点・助言等↓ここから */}
                      <PrintContentsGrid
                        container
                        alignItems={"center"}
                        {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
                      >
                        <Typography>{commentReason(row)}</Typography>
                        {convertCommentLine(row.comments[0].user_input_comment)}
                      </PrintContentsGrid>
                      {/* 問題点・助言等↑ここまで */}
                    </>
                  ))}
                  {printAddRecordsEmpty(Object.keys(rowItem.rows).length)}
                </Grid>
                {/* データ行↑ここまで */}
            </>
          ) : (
            <>
            {/* データ行↓ここから */}
            <Grid container {...{ xs: 12, sm: 12, md: 12, lg: 12 }}>
              {printAddRecordsEmpty(0)}
            </Grid>
            {/* データ行↑ここまで */}
        </>
      )}
      </>
      ))}
              </BaseGrid>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useEffect, FC } from "react";
import { useSelector } from "react-redux";

import {
  Modal,
  Button,
  Typography,
  IconButton,
  styled,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import CreateIcon from "@mui/icons-material/Create";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import LensIcon from "@mui/icons-material/Lens";

import { selectImageToken } from "../../../features/userSlice";
import { getFixedFlg } from "../../Header/Header";

import Avatar from "@mui/material/Avatar";

const ModalPane = styled(Modal)(({ theme }) => ({
  position: "absolute",
  paddingTop: "0%",
  paddingLeft: "0%",
  width: "100%",
  height: "100%",
}));

const PaperGrid = styled(Grid)(({ theme }) => ({
  height: "100%",
  backgroundColor: "#333333",
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
}));

const TitleGrid = styled(Grid)(({ theme }) => ({
  color: "white",
  backgroundColor: "#4dc0b2",
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
}));

const EditToolAreaGrid = styled(Grid)(({ theme }) => ({
  height: "400px",
  width: "40px",
}));

const PhotoEdit: FC<{
  baseFilePath: string;
  baseFileData: string;
  updateFilePath: (path: string) => void;
  fileNameGenerator: () => string;
  functionPath: string;
}> = ({ baseFilePath, baseFileData, updateFilePath, fileNameGenerator, functionPath }) => {
  // 各種定数
  const defaultLineWidth: number = 2; // デフォルトの線の太さ
  const thickLineWidth: number = 6; // 太いの線の太さ
  const defaultLineColor: string = "black"; // デフォルトの線の色
  const defaultTransParency: number = 1; // デフォルトの線の透過度
  const halfTransParency: number = 0.3; // ぼかし時の線の透過度
  const cropHandleLength: number = 12; // トリミング用ドラッグポイント辺長
  const cropHandleRangeMagnification: number = 2; // トリミング用ドラッグポイント範囲倍率
  const lineColorKinds: any = {
    // 線の色設定
    black: "black",
    red: "red",
    orange: "orange",
    blue: "blue",
    green: "green",
  };
  const [filePath, setFilePath] = React.useState("");
  const [fileData, setFileData] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  interface Point {
    x: number;
    y: number;
  }
  interface CropRect {
    x: number;
    y: number;
    width: number;
    height: number;
  }
  const canvasRef = React.useRef<HTMLCanvasElement>(null); // 画像用
  const lineCanvasRef = React.useRef<HTMLCanvasElement>(null); // 線引き用
  const cropCanvasRef = React.useRef<HTMLCanvasElement>(null); // トリミング用
  const [drawing, setDrawing] = React.useState(false); // マウスをラッグ中であること
  const [lastPoint, setLastPoint] = React.useState<Point>({ x: 0, y: 0 }); // クリック、ドラッグ時のマウスポイント。線を引くときに使用
  const [canvasContext, setCanvasContext] =
    React.useState<CanvasRenderingContext2D | null>(null); // 画像用canvasのcontext
  const [lineCanvasContext, setLineCanvasContext] =
    React.useState<CanvasRenderingContext2D | null>(null); // ライン用canvasのcontext
  const [cropCanvasContext, setCropCanvasContext] =
    React.useState<CanvasRenderingContext2D | null>(null); // 切取り用canvasのcontext
  const [lineWidth, setLineWidth] = React.useState<number>(defaultLineWidth); // 線の太さ
  const [lineColor, setLineColor] = React.useState<string>(
    lineColorKinds.black
  ); // 線の色
  const [createMode, setCreateMode] = React.useState<boolean>(false); // 線を引くモード
  const [eraserMode, setEraserMode] = React.useState<boolean>(false); // 線を消すモード
  const [cropMode, setCropMode] = React.useState<boolean>(false); // 切り取りモード
  const [readyCrop, setReadyCrop] = React.useState<boolean>(false); // 切り取り準備完了有無。切り取り範囲を指定し終わったらtrueとなる
  const [transParency, setTransParency] =
    React.useState<number>(defaultTransParency); // 透過度。線を引くときの線の透過度を管理
  const [initImage, setInitImage] = React.useState<ImageData | null>(null); // 初期画像
  const [cropRect, setCropRect] = React.useState<CropRect>({
    x: 0,
    y: 0,
    width: 200,
    height: 200,
  }); // 切取り範囲
  const [dragHandle, setDragHandle] = React.useState<number | null>(null); // 切取り範囲変更点対象

  const handleOpen = () => {
    setOpen(true);
  };

  // モーダル開閉時のファイルパス設定
  useEffect(() => {
    if (open) {
      setFilePath(baseFilePath);
      setFileData(baseFileData);
      resetCrop();
      resetLine();
      resetCreate();
      setCreateMode(true);
      refreshCanvas();
      if (!lineCanvasContext) return;
      lineCanvasContext.setLineDash([]);
    } else {
      setCropRect({ x: 0, y: 0, width: 0, height: 0 });
    }
  }, [open]);

  // トリミング範囲変更時
  useEffect(() => {
    if (!cropCanvasContext) return;
    drawOverlay(cropCanvasContext, cropRect);
    if (cropMode) {
      drawCropRect(cropCanvasContext, cropRect);
    }
  }, [cropRect]);

  // 画像変更時。canvasへの画像表示、各種設定初期化。
  useEffect(() => {
    refreshCanvas();
  }, [filePath, fileData]);

  // 線の太さ変更
  useEffect(() => {
    if (!lineCanvasContext) return;
    lineCanvasContext.lineWidth = lineWidth;
  }, [lineWidth]);

  // 消しゴムモード設定
  useEffect(() => {
    if (!lineCanvasContext) return;
    lineCanvasContext.globalCompositeOperation = eraserMode
      ? "destination-out"
      : "source-over";
  }, [eraserMode]);

  // 線の透過度設定
  useEffect(() => {
    if (!lineCanvasContext) return;
    lineCanvasContext.globalAlpha = transParency;
  }, [transParency]);

  // 線のカラー
  useEffect(() => {
    if (!lineCanvasContext) return;
    lineCanvasContext.strokeStyle = lineColor;
  }, [lineColor]);

  // トリミングモードOFF時トリミング範囲表示初期化
  useEffect(() => {
    if (!cropCanvasContext) return;
    if (cropMode) {
      drawOverlay(cropCanvasContext, cropRect);
      drawCropRect(cropCanvasContext, cropRect);
    } else {
      cropCanvasContext.clearRect(
        0,
        0,
        cropCanvasContext.canvas.width,
        cropCanvasContext.canvas.height
      );
      drawOverlay(cropCanvasContext, cropRect);
    }
  }, [cropMode]);

  // canvas更新
  const refreshCanvas = (): void => {
    const img = new Image();
    if (fileData) {
      img.src = fileData;
    }
    else {
      img.src = getImageFile(filePath);
    }
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      // imageCanvas
      const canvas: any = canvasRef.current;
      if (!canvas) return;
      canvas.width = img.width;
      canvas.height = img.height;
      const context: CanvasRenderingContext2D = canvas.getContext("2d");
      context.drawImage(img, 0, 0, img.width, img.height);

      // lineCanvas
      const lineCanvas: HTMLCanvasElement | null = lineCanvasRef.current;
      if (!lineCanvas) return;
      lineCanvas.width = img.width;
      lineCanvas.height = img.height;
      const lineContext: CanvasRenderingContext2D | null =
        lineCanvas.getContext("2d");
      setLineCanvasContext(lineContext);

      // cropCanvas
      const cropCanvas: HTMLCanvasElement | null = cropCanvasRef.current;
      if (!cropCanvas) return;
      cropCanvas.width = img.width;
      cropCanvas.height = img.height;
      const cropContext: CanvasRenderingContext2D | null =
        cropCanvas.getContext("2d");
      setCropCanvasContext(cropContext);

      // if (!filePath[0] && !fileData[0]) return;
      if (!filePath && !fileData) return;
      setDrawing(false);
      setCanvasContext(context);
      setInitImage(context.getImageData(0, 0, img.width, img.height));
      setCropRect({ x: 0, y: 0, width: img.width, height: img.height });
    };
  };

  // 編集アイコンの選択状態CSS取得
  const selectToolCss = (item: string): string => {
    let selected: boolean = false;
    if (item === "crop") {
      selected = cropMode;
    } else if (item === "line") {
      selected = createMode && transParency === defaultTransParency;
    } else if (item === "lineHalf") {
      selected = createMode && transParency !== defaultTransParency;
    } else if (item === "eraser") {
      selected = eraserMode;
    } else if (item.startsWith("lineColor")) {
      selected = item === "lineColor_" + lineColor;
    } else if (item === "lineWidthDefault") {
      selected = lineWidth === defaultLineWidth;
    } else if (item === "lineWidthThick") {
      selected = lineWidth === thickLineWidth;
    }
    return selected ? "photo-edit-li-selected" : "photo-edit-li";
  };

  // タッチの始め
  const handleTouchStart = (e: React.TouchEvent<HTMLCanvasElement>) => {
    if (!canvasContext) return;
    const offset = canvasContext.canvas.getBoundingClientRect();
    const offsetX = e.touches[0].clientX - offset.left;
    const offsetY = e.touches[0].clientY - offset.top;
    startOperation(offsetX, offsetY);
  };

  // タッチの終わり
  const handleTouchEnd = (e: React.TouchEvent<HTMLCanvasElement>) => {
    endOperation();
    e.preventDefault();
  };

  // タッチで線を引く、クラップ範囲の指定等を行う。
  const handleTouchMove = (e: React.TouchEvent<HTMLCanvasElement>) => {
    // 座標位置取得が必要なモードになっていないとき
    if (!drawing || (!createMode && !eraserMode && !cropMode)) {
      return;
    }

    if (!canvasContext) return;
    const canvas: HTMLCanvasElement = canvasContext.canvas;
    const offset = canvas.getBoundingClientRect();
    let offsetX = e.changedTouches[0].clientX - offset.left;
    let offsetY = e.changedTouches[0].clientY - offset.top;
    moveOperation(offsetX, offsetY);
  };

  // ドラッグの始め
  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const { offsetX, offsetY } = e.nativeEvent;
    startOperation(offsetX, offsetY);
  };

  // ドラッグの終わり
  const handleMouseUp = (e: React.MouseEvent<HTMLCanvasElement>) => {
    endOperation();
  };

  // ドラッグ中。線を引く、クラップ範囲の指定等を行う。
  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    // 座標位置取得が必要なモードになっていないとき
    if (!drawing || (!createMode && !eraserMode && !cropMode)) {
      return;
    }

    moveOperation(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
  };

  // 操作開始時（マウスクリック、タッチ）
  const startOperation = (offsetX: number, offsetY: number): void => {
    const { x, y, width, height } = cropRect;
    const operationLength = cropHandleLength * cropHandleRangeMagnification;

    if (cropMode) {
      if (
        offsetX >= x - cropHandleLength &&
        offsetX <= x + operationLength &&
        offsetY >= y - cropHandleLength &&
        offsetY <= y + operationLength
      ) {
        // 左上のドラッグ
        setDragHandle(0);
      } else if (
        offsetX >= x + width - operationLength &&
        offsetX <= x + width + cropHandleLength &&
        offsetY >= y - cropHandleLength &&
        offsetY <= y + operationLength
      ) {
        // 右上のドラッグ
        setDragHandle(1);
      } else if (
        offsetX >= x + width - operationLength &&
        offsetX <= x + width + cropHandleLength &&
        offsetY >= y + height - operationLength &&
        offsetY <= y + height + cropHandleLength
      ) {
        // 右下のドラッグ
        setDragHandle(2);
      } else if (
        offsetX >= x - cropHandleLength &&
        offsetX <= x + operationLength &&
        offsetY >= y + height - operationLength &&
        offsetY <= y + height + cropHandleLength
      ) {
        // 左下のドラッグ
        setDragHandle(3);
      } else if (
        offsetX >= x + width / 2 - cropHandleLength &&
        offsetX <= x + width / 2 + cropHandleLength &&
        offsetY >= y - cropHandleLength &&
        offsetY <= y + operationLength
      ) {
        // 上のドラッグ
        setDragHandle(4);
      } else if (
        offsetX >= x + width - operationLength &&
        offsetX <= x + width + cropHandleLength &&
        offsetY >= y + height / 2 - cropHandleLength &&
        offsetY <= y + height / 2 + cropHandleLength
      ) {
        // 右のドラッグ
        setDragHandle(5);
      } else if (
        offsetX >= x + width / 2 - cropHandleLength &&
        offsetX <= x + width / 2 + cropHandleLength &&
        offsetY >= y + height - operationLength &&
        offsetY <= y + height + cropHandleLength
      ) {
        // 下のドラッグ
        setDragHandle(6);
      } else if (
        offsetX >= x - cropHandleLength &&
        offsetX <= x + operationLength &&
        offsetY >= y + height / 2 - cropHandleLength &&
        offsetY <= y + height / 2 + cropHandleLength
      ) {
        // 左のドラッグ
        setDragHandle(7);
      }
    }
    setLastPoint({ x: offsetX, y: offsetY });
    setDrawing(true);
  };

  // 操作終了時（マウスクリック、タッチ）
  const endOperation = (): void => {
    setDrawing(false);
    if (cropMode) {
      setReadyCrop(true);
      setDragHandle(null);
    }
  };

  // 操作時（マウスクリック、タッチ）
  const moveOperation = (offsetX: number, offsetY: number): void => {
    if (cropMode) {
      moveCropOperation(offsetX, offsetY);
    } else {
      if (!canvasContext || !lineCanvasContext) return;
      const canvas: HTMLCanvasElement = canvasContext.canvas;
      // 座標補正
      if (offsetX < 0) {
        offsetX = 0;
      } else if (canvas.width < offsetX) {
        offsetX = canvas.width;
      }
      if (offsetY < 0) {
        offsetY = 0;
      } else if (canvas.height < offsetY) {
        offsetY = canvas.height;
      }
      const currentPoint = {
        x: offsetX,
        y: offsetY,
      };

      lineCanvasContext.beginPath();
      lineCanvasContext.moveTo(lastPoint.x, lastPoint.y);
      lineCanvasContext.lineTo(currentPoint.x, currentPoint.y);
      lineCanvasContext.stroke();
      setLastPoint(currentPoint);
    }
  };

  // トリミング操作時（マウスクリック、タッチ）
  const moveCropOperation = (offsetX: number, offsetY: number): void => {
    let currentPoint = {
      x: offsetX,
      y: offsetY,
    };

    if (!cropCanvasContext || dragHandle === null) return;
    const xDiff = currentPoint.x - lastPoint.x;
    const yDiff = currentPoint.y - lastPoint.y;
    let updateCropRect = { ...cropRect };

    switch (dragHandle) {
      case 0: // 左上
        updateCropRect.x += xDiff;
        updateCropRect.y += yDiff;
        updateCropRect.width -= xDiff;
        updateCropRect.height -= yDiff;
        break;
      case 1: // 右上
        updateCropRect.y += yDiff;
        updateCropRect.width += xDiff;
        updateCropRect.height -= yDiff;
        break;
      case 2: // 右下
        updateCropRect.width += xDiff;
        updateCropRect.height += yDiff;
        break;
      case 3: // 左下
        updateCropRect.x += xDiff;
        updateCropRect.width -= xDiff;
        updateCropRect.height += yDiff;
        break;
      case 4: // 上
        updateCropRect.y += yDiff;
        updateCropRect.height -= yDiff;
        break;
      case 5: // 右
        updateCropRect.width += xDiff;
        break;
      case 6: // 下
        updateCropRect.height += yDiff;
        break;
      case 7: // 左
        updateCropRect.x += xDiff;
        updateCropRect.width -= xDiff;
        break;
    }

    // 範囲外かを判定
    const range = {
      x: 0,
      y: 0,
      width: cropCanvasContext.canvas.width,
      height: cropCanvasContext.canvas.height,
    };
    const within = isWithin(updateCropRect, range);

    // 異常値回避、範囲外に出た場合の対応
    if (updateCropRect.x < 0) {
      updateCropRect.x = 0;
    } else if (updateCropRect.x > cropCanvasContext.canvas.width) {
      updateCropRect.x = cropCanvasContext.canvas.width;
    } else if (updateCropRect.y < 0) {
      updateCropRect.y = 0;
    } else if (updateCropRect.y > cropCanvasContext.canvas.height) {
      updateCropRect.y = cropCanvasContext.canvas.height;
    } else if (updateCropRect.width < 0) {
      updateCropRect.width = 0;
    } else if (updateCropRect.width > cropCanvasContext.canvas.width) {
      updateCropRect.width = cropCanvasContext.canvas.width;
    } else if (updateCropRect.height < 0) {
      updateCropRect.height = 0;
    } else if (updateCropRect.height > cropCanvasContext.canvas.height) {
      updateCropRect.height = cropCanvasContext.canvas.height;
    }

    setCropRect(updateCropRect);
    setLastPoint(currentPoint);

    // 範囲外に出た場合は終了
    if (!within) {
      setDrawing(false);
      setReadyCrop(true);
      setDragHandle(null);
    }
  };

  // 対象が範囲内か判定
  const isWithin = (target: CropRect, range: CropRect): boolean => {
    let ret = true;
    if (
      target.x < range.x ||
      target.x > range.width ||
      target.y < range.y ||
      target.y > range.height ||
      target.width < 0 ||
      target.width > range.width ||
      target.height < 0 ||
      target.height > range.height
    ) {
      ret = false;
    }
    return ret;
  };

  // トリミング範囲描画
  const drawCropRect = (
    ctx: CanvasRenderingContext2D,
    rect: CropRect
  ): void => {
    const storeStrokeStyle = ctx.strokeStyle;
    const storeLineWidth = ctx.lineWidth;
    const storeFillStyle = ctx.fillStyle;

    // 対象範囲囲み線
    ctx.strokeStyle = "deepskyblue";
    ctx.lineWidth = 1;
    const { x, y, width, height } = rect;
    ctx.strokeRect(x, y, width, height);

    // 対象範囲移動点_塗りつぶし
    const half = cropHandleLength / 2;
    ctx.fillStyle = "white";
    ctx.lineWidth = 2;
    // 左上
    ctx.fillRect(x, y, cropHandleLength, cropHandleLength);
    // 右上
    ctx.fillRect(
      x + width - cropHandleLength,
      y,
      cropHandleLength,
      cropHandleLength
    );
    // 右下
    ctx.fillRect(
      x + width - cropHandleLength,
      y + height - cropHandleLength,
      cropHandleLength,
      cropHandleLength
    );
    // 左下
    ctx.fillRect(
      x,
      y + height - cropHandleLength,
      cropHandleLength,
      cropHandleLength
    );
    // 上
    ctx.fillRect(x + width / 2 - half, y, cropHandleLength, cropHandleLength);
    // 右
    ctx.fillRect(
      x + width - cropHandleLength,
      y + height / 2 - half,
      cropHandleLength,
      cropHandleLength
    );
    // 下
    ctx.fillRect(
      x + width / 2 - half,
      y + height - cropHandleLength,
      cropHandleLength,
      cropHandleLength
    );
    // 左
    ctx.fillRect(x, y + height / 2 - half, cropHandleLength, cropHandleLength);

    // 対象範囲移動点_囲み
    ctx.strokeStyle = "deepskyblue";
    ctx.lineWidth = 1;
    // 左上
    ctx.strokeRect(x, y, cropHandleLength, cropHandleLength);
    // 右上
    ctx.strokeRect(
      x + width - cropHandleLength,
      y,
      cropHandleLength,
      cropHandleLength
    );
    // 右下
    ctx.strokeRect(
      x + width - cropHandleLength,
      y + height - cropHandleLength,
      cropHandleLength,
      cropHandleLength
    );
    // 左下
    ctx.strokeRect(
      x,
      y + height - cropHandleLength,
      cropHandleLength,
      cropHandleLength
    );
    // 上
    ctx.strokeRect(x + width / 2 - half, y, cropHandleLength, cropHandleLength);
    // 右
    ctx.strokeRect(
      x + width - cropHandleLength,
      y + height / 2 - half,
      cropHandleLength,
      cropHandleLength
    );
    // 下
    ctx.strokeRect(
      x + width / 2 - half,
      y + height - cropHandleLength,
      cropHandleLength,
      cropHandleLength
    );
    // 左
    ctx.strokeRect(
      x,
      y + height / 2 - half,
      cropHandleLength,
      cropHandleLength
    );

    // 設定を戻す
    ctx.strokeStyle = storeStrokeStyle;
    ctx.lineWidth = storeLineWidth;
    ctx.fillStyle = storeFillStyle;
  };

  // トリミング範囲外部分覆う
  const drawOverlay = (ctx: CanvasRenderingContext2D, rect: CropRect): void => {
    const { x, y, width, height } = rect;
    ctx.clearRect(x, y, width, height);
    const storeFillStyle = ctx.fillStyle;
    ctx.fillStyle = "#333333";
    ctx.fillRect(0, 0, ctx.canvas.width, y);
    ctx.fillRect(0, y, x, height);
    ctx.fillRect(x + width, y, ctx.canvas.width - (x + width), height);
    ctx.fillRect(
      0,
      y + height,
      ctx.canvas.width,
      ctx.canvas.height - (y + height)
    );
    ctx.fillStyle = storeFillStyle;
  };

  // create系リセット
  const resetCreate = (): void => {
    setCreateMode(false);
    setCropMode(false);
    setEraserMode(false);
    setTransParency(defaultTransParency);
  };

  const handleCreateMode = (isMode: boolean) => {
    if (isMode) {
      resetCreate();
    }
    setCreateMode(isMode);
  };

  const handleEraserMode = (isMode: boolean) => {
    if (isMode) {
      resetCreate();
    }
    setEraserMode(isMode);
  };

  const handleCropMode = (isMode: boolean) => {
    if (isMode) {
      resetCreate();
    }
    setCropMode(isMode);
  };

  // 保存処理。canvasで編集した内容で画像をアップロードし直す
  const handleSave = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (getFixedFlg()) {
      setOpen(false);
      return;
    }

    if (!canvasContext || !lineCanvasContext) return;
    // トリミング実施
    await cropImage();

    // モーダルを閉じる
    setOpen(false);

    // 画像レイヤーに描画した線を合成
    canvasContext.drawImage(lineCanvasContext.canvas, 0, 0);

    // 保存時に画像をアップロード
    const canvas: HTMLCanvasElement = canvasContext.canvas;
    const data = canvas.toDataURL();
    const loFilePath = data || filePath || "";

    updateFilePath(loFilePath);
  };

  // キャンセル時の動作
  const handleClose = () => {
    if (loading) {
      return;
    }
    setOpen(false);
  };

  // 元の画像状態に戻す
  const replyAll = () => {
    if (!canvasContext || !initImage || !lineCanvasContext) return;
    canvasContext.putImageData(initImage, 0, 0);
    lineCanvasContext.clearRect(
      0,
      0,
      lineCanvasContext.canvas.clientWidth,
      lineCanvasContext.canvas.clientHeight
    );
    if (!cropCanvasContext) return;
    const { width, height } = cropCanvasContext.canvas;
    setCropRect({ x: 0, y: 0, width: width, height: height });
  };

  const imageToken = useSelector(selectImageToken);
  function getImageFile(imagePath: string) {
    if (imagePath === undefined || imagePath === "") {
      return `${window.location.origin}/image/noimage.png`;
    }
    return (
      process.env.REACT_APP_CLOUD_STORAGE_URL +
      imagePath +
      "?access_token=" +
      imageToken
    );
  }

  // 画像を切り取り
  const cropImage = async () => {
    if (
      !canvasContext ||
      !lineCanvasContext ||
      !cropCanvasContext ||
      !readyCrop
    )
      return;

    const { x, y, width, height } = cropRect;
    // 画像レイヤー切取り
    canvasContext.drawImage(
      canvasContext.canvas,
      x,
      y,
      width,
      height,
      0,
      0,
      canvasContext.canvas.width,
      canvasContext.canvas.height
    );

    // ラインレイヤー切取り
    var img = await loadImage(lineCanvasContext.canvas.toDataURL());
    lineCanvasContext.clearRect(
      0,
      0,
      lineCanvasContext.canvas.clientWidth,
      lineCanvasContext.canvas.clientHeight
    );
    lineCanvasContext.drawImage(
      img,
      x,
      y,
      width,
      height,
      0,
      0,
      lineCanvasContext.canvas.width,
      lineCanvasContext.canvas.height
    );
  };

  // 画像読み込み
  const loadImage = async (src: string): Promise<HTMLImageElement> => {
    return new Promise(function (resolve, reject) {
      var img = new Image();
      img.onload = function () {
        resolve(img);
      };
      img.onerror = function () {
        reject(new Error("画像のロードに失敗しました"));
      };
      img.src = src;
    });
  };

  // crop関連reset
  const resetCrop = (): void => {
    setCropMode(false);
    setReadyCrop(false);
  };

  // 線描画関連reset
  const resetLine = (): void => {
    setLineWidth(defaultLineWidth);
    setLineColor(defaultLineColor);
    setTransParency(defaultTransParency);
  };

  return (
    <>
      {/*  編集ボタンここから↓*/}
      <IconButton
        component="span"
        onClick={handleOpen}
        size={"small"}
        style={{ marginLeft: "8px" }}
      >
        <CreateIcon/>
      </IconButton>
      {/*  編集ボタンここまで↑*/}
      {/*  モーダルここから↓*/}
      <ModalPane
        open={open}
        onClose={handleClose}
        aria-labelledby="photo-edit-title"
        aria-describedby="photo-edit-description"
      >
        <PaperGrid
          container
          direction="column"
          style={{ overflowY: "auto" }}
        >
          <div style={{ height: "100%", minHeight: "500px" }}>
            {/*  タイトルここから↓*/}
            <TitleGrid
              justifyContent="center"
              alignItems="center"
              style={{ flexBasis: "7%" }}
            >
              <Grid
                container
                  justifyContent="center"
                  alignItems="center"
                style={{ height: "100%" }}
              >
                <TitleTypography variant="h6" 
                >
                  画像編集
                </TitleTypography>
              </Grid>
            </TitleGrid>
            {/*  タイトルここまで↑*/}
            {/*  画像編集エリアここから↓*/}
            <Grid
              style={{ flexBasis: "85%", height: "85%" }}
            >
              <Grid
                container
                style={{ paddingTop: "10px", height: "100%" }}
              >
                {/*  画像編集ツールここから↓*/}
                <Grid
                  xs={1}
                >
                  <EditToolAreaGrid
                    id="tool-area"
                  >
                    <ul
                      style={{
                        width: "100%",
                        listStyle: "none",
                        margin: 0,
                        padding: 0,
                        paddingTop: 10,
                        paddingBottom: 10,
                        backgroundColor: "#666666",
                        borderRadius: "100vh",
                      }}
                    >
                      <li
                        className={selectToolCss("crop")}
                        style={{
                          position: "relative",
                          paddingLeft: "10%",
                          marginTop: "1px",
                        }}
                        >
                        <IconButton
                          onClick={() => handleCropMode(true)}
                          size={"small"}
                        >
                          <Avatar
                            alt=""
                            src={`${window.location.origin}/image/icons/crop.svg`}
                            style={{ width: "25px", height: "25px" }}
                          />
                        </IconButton>
                      </li>
                    </ul>
                    <div
                      style={{height: "20px"}}
                    >
                    </div>
                    <ul
                      style={{
                        width: "100%",
                        listStyle: "none",
                        margin: 0,
                        padding: 0,
                        paddingTop: 10,
                        paddingBottom: 10,
                        backgroundColor: "#666666",
                        borderRadius: "100vh",
                      }}
                    >
                      <li
                        className={selectToolCss("line")}
                        style={{
                          position: "relative",
                          paddingLeft: "10%",
                          marginTop: "1px",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            handleCreateMode(true);
                            setTransParency(defaultTransParency);
                          }}
                          size={"small"}
                        >
                          <Avatar
                            alt=""
                            src={`${window.location.origin}/image/icons/thin_pen.svg`}
                            style={{ width: "25px", height: "25px" }}
                          />
                        </IconButton>
                      </li>
                      <li
                        className={selectToolCss("lineHalf")}
                        style={{
                          position: "relative",
                          paddingLeft: "10%",
                          marginTop: "1px",
                        }}
                      >
                        <IconButton
                          onClick={(e) => {
                            handleCreateMode(true);
                            setTransParency(halfTransParency);
                          }}
                          size={"small"}
                        >
                          <Avatar
                            alt=""
                            src={`${window.location.origin}/image/icons/thick_pen.svg`}
                            style={{ width: "25px", height: "25px" }}
                          />
                        </IconButton>
                      </li>
                      <li
                        className={selectToolCss("eraser")}
                        style={{
                          position: "relative",
                          paddingLeft: "10%",
                          marginTop: "1px",
                        }}
                      >
                        <IconButton
                          onClick={() => handleEraserMode(true)}
                          size={"small"}
                        >
                          <Avatar
                            alt=""
                            src={`${window.location.origin}/image/icons/eraser.svg`}
                            style={{ width: "25px", height: "25px" }}
                          />
                        </IconButton>
                      </li>
                      <li
                        style={{
                          position: "relative",
                          paddingLeft: "-5px",
                          marginTop: "1px",
                        }}
                      >
                        <hr style={{ width: "70%", height: "1px", background: "#fff" }}></hr>
                      </li>
                      <li
                        className={selectToolCss("lineWidthDefault")}
                        style={{
                          position: "relative",
                          paddingLeft: "10%",
                          marginTop: "1px",
                        }}
                      >
                        <IconButton
                          onClick={() => setLineWidth(defaultLineWidth)}
                          size={"small"}
                        >
                          <Avatar
                            alt=""
                            src={`${window.location.origin}/image/icons/thin_pencil.svg`}
                            style={{ width: "25px", height: "25px" }}
                          />
                        </IconButton>
                      </li>
                      <li
                        className={selectToolCss("lineWidthThick")}
                        style={{
                          position: "relative",
                          paddingLeft: "10%",
                          marginTop: "1px",
                        }}
                      >
                        <IconButton
                          onClick={() => setLineWidth(thickLineWidth)}
                          size={"small"}
                        >
                          <Avatar
                            alt=""
                            src={`${window.location.origin}/image/icons/thick_pencil.svg`}
                            style={{ width: "25px", height: "25px" }}
                          />
                        </IconButton>
                      </li>
                      <li
                        style={{ marginLeft: "-5px" }}
                      >
                        <hr style={{ width: "70%", height: "1px", background: "#fff" }}></hr>
                      </li>
                      <li
                        className={selectToolCss(
                          "lineColor_" + lineColorKinds.black
                        )}
                        style={{
                          position: "relative",
                          paddingLeft: "10%",
                          marginTop: "1px",
                        }}
                      >
                        <IconButton
                          onClick={(e) => {
                            setLineColor(lineColorKinds.black);
                          }}
                          size={"small"}
                          style={{
                            color: "black",
                          }}
                        >
                          <LensIcon/>
                        </IconButton>
                      </li>
                      <li
                        className={selectToolCss(
                          "lineColor_" + lineColorKinds.red
                        )}
                        style={{
                          position: "relative",
                          paddingLeft: "10%",
                          marginTop: "1px",
                        }}
                      >
                        <IconButton
                          onClick={(e) => {
                            setLineColor(lineColorKinds.red);
                          }}
                          size={"small"}
                          style={{
                            color: "red",
                          }}
                        >
                          <LensIcon/>
                        </IconButton>
                      </li>
                      <li
                        className={selectToolCss(
                          "lineColor_" + lineColorKinds.orange
                        )}
                        style={{
                          position: "relative",
                          paddingLeft: "10%",
                          marginTop: "1px",
                        }}
                      >
                        <IconButton
                          onClick={(e) => {
                            setLineColor(lineColorKinds.orange);
                          }}
                          size={"small"}
                          style={{
                            color: "orange",
                          }}
                        >
                          <LensIcon/>
                        </IconButton>
                      </li>
                      <li
                        className={selectToolCss(
                          "lineColor_" + lineColorKinds.blue
                        )}
                        style={{
                          position: "relative",
                          paddingLeft: "10%",
                          marginTop: "1px",
                        }}
                      >
                        <IconButton
                          onClick={(e) => {
                            setLineColor(lineColorKinds.blue);
                          }}
                          size={"small"}
                          style={{
                            color: "blue",
                          }}
                        >
                          <LensIcon/>
                        </IconButton>
                      </li>
                      <li
                        className={selectToolCss(
                          "lineColor_" + lineColorKinds.green
                        )}
                        style={{
                          position: "relative",
                          paddingLeft: "10%",
                          marginTop: "1px",
                        }}
                      >
                        <IconButton
                          onClick={(e) => {
                            setLineColor(lineColorKinds.green);
                          }}
                          size={"small"}
                          style={{
                            color: "gree",
                          }}
                        >
                          <LensIcon/>
                        </IconButton>
                      </li>
                      <li
                        className={selectToolCss("")}
                        style={{
                          position: "relative",
                          paddingLeft: "10%",
                          marginTop: "1px",
                        }}
                      >
                        <IconButton
                          onClick={(e) => {
                            replyAll();
                          }}
                          size={"small"}
                        >
                          <ReplyAllIcon/>
                        </IconButton>
                      </li>
                    </ul>
                  </EditToolAreaGrid>
                </Grid>
                {/*  画像編集ツールここまで↑*/}
                {/*  画像編集canvasここから↓*/}
                <Grid
                  // item
                  xs={11}
                >
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <canvas
                      className="canvas"
                      ref={canvasRef}
                      style={{ position: "absolute", zIndex: 1 }}
                    />
                    <canvas
                      className="canvas"
                      ref={lineCanvasRef}
                      style={{ position: "absolute", zIndex: 2 }}
                    />
                    <canvas
                      className="canvas"
                      onMouseDown={handleMouseDown}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                      onTouchMove={handleTouchMove}
                      onTouchStart={handleTouchStart}
                      onTouchEnd={handleTouchEnd}
                      ref={cropCanvasRef}
                      style={{ position: "absolute", zIndex: 3, userSelect: "none", WebkitTouchCallout: "none", WebkitUserSelect: "none" }}
                    />
                  </div>
                </Grid>
                {/*  画像編集canvasここまで↑*/}
              </Grid>
            </Grid>
            {/*  画像編集エリアここまで↑*/}
            {/*  ボタン↓ここから*/}
            <Grid
              style={{ flexBasis: "8%" }}
            >
              <Grid
                container 
                justifyContent="flex-end"
              >
                <Grid
                  xs={6}
                >
                  <Grid
                    container
                    justifyContent="center"
                  >
                    <Button
                      style={{ width: "30vw" }}
                      variant="contained"
                      fullWidth={true}
                      onClick={handleClose}
                      disabled={loading}
                    >
                      キャンセル
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  xs={6}
                >
                  <Grid container 
                    justifyContent="center"
                  >
                    {getFixedFlg() ? (
                      ""
                    ) : (
                      <Button
                        style={{ width: "30vw" }}
                        variant="contained"
                        fullWidth={true}
                        onClick={(e) => handleSave(e)}
                        color="primary"
                        disabled={loading}
                      >
                        保存
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/*  ボタン↑ここまで*/}
          </div>
        </PaperGrid>
      </ModalPane>
      {/*  モーダル↑ここまで*/}
    </>
  );
};
export default PhotoEdit;

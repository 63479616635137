import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";

import {
  getAllArea
} from "../../../../data/existingFactory";
import {
  selectAreaName
} from "../FactoryCheckSearchSlice";
import {
  Button,
  TextField,
  Modal,
  Theme,
  styled,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

const PaperGrid = styled(Grid)(({ theme }) => ({
//     paper: {
      position: "absolute",
      top: "5%",
      left: "10%",
      height: "90%",
      maxHeight: "780px",
      width: "80%",
      maxWidth: "280px",
      minWidth: "280px",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
//     },
}));

const ListPaperGrid = styled(Grid)(({ theme }) => ({
//     listPaper: {
      height: "85%",
      maxWidth: "220px",
      minWidth: "220px",
      overflow: "auto",
      padding: "0px 0px",
      marginTop: "25px",
}));

const SearchButton = styled(Button)(({ theme }) => ({
//     },
// searchButton: {
  width: "90%",
  marginLeft: "15px",
  backgroundColor: theme.palette.background.paper,
  border: "1px solid #D5D5D5",
  borderRadius: "5px",
//     },
}));

const SelectButtonGrid = styled(Grid)(({ theme }) => ({
//     selectButton: {
  height: "38px",
  textAlign: "left",
  marginLeft: "10px",
  marginTop: "2px",
  fontSize: "22px",
//     },
}));

const SearchFactoryArea: React.FC<{
  // useStyles: any;
  disabled: boolean;
  firstView: boolean;
  initialDisplay: boolean;
  onClickFunc: any;
}> = ({
  // useStyles,
  disabled,
  firstView,
  initialDisplay,
  onClickFunc,
}) => {
  // const classes = useStyles({});
  const [open, setOpen] = React.useState(false);
  // 検索文字列
  const [searchWord, setSearchWord] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const stateAreaName = useSelector(selectAreaName);

  const MAX_SEARCH_LIST = 150;
  const areaMaster: any = getAllArea().area_data;
  const [areaList, setAreaList] = React.useState(allAreaButton());

  // 初期表示処理
  useLayoutEffect(() => {
      // 選択メニューを開く
      if (firstView) {
        setOpen(true);
      }
      if (initialDisplay) {
        setAreaList(allAreaButton());
      }
  }, []);
  // 検索文字列が更新された際に動く
  useLayoutEffect(() => {
    setAreaList(allAreaButton());
  }, [searchWord]);

  function allAreaButton() {
    let areaList: any[] = [""];
    let hitCount: number = 0;
    for (let [key] of areaMaster) {
      // 店舗レコードを内部データから取得
      let areaRecord = areaMaster.get(key);
      if (
        (!initialDisplay && searchWord === "") ||
        key === undefined ||
        areaRecord === undefined ||
        areaRecord.area_name === undefined
      ) continue;
      // 検索文字列にマッチしたものだけリストに入れる
      if (areaRecord.area_name.match(searchWord)) {
        areaList.push(
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              onClickFunc(
                areaRecord.area_code,
                areaRecord.area_name
              );
              setOpen(false);
            }}
          >
            <SelectButtonGrid
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              {areaRecord.area_name}
            </SelectButtonGrid>
          </Button>
        );
        hitCount++;
      }
      if (hitCount > MAX_SEARCH_LIST) break;
    }
    areaList = areaList.sort();
    return areaList;
  }

  return (
    <>
      <SearchButton
        onClick={() => {
          handleOpen();
          onClickFunc("", "");
        }}
        disabled = {disabled}
        style={{ WebkitAppearance: "none" }}
      >
        <SelectButtonGrid 
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          { stateAreaName === "" ? "地区名" : stateAreaName}
        </SelectButtonGrid>
      </SearchButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <PaperGrid 
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <TextField
            // className={classes.searchTextField}
            value={searchWord}
            label="地区名で探す"
            variant="outlined"
            size="medium"
            fullWidth
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchWord(e.target.value);
            }}
          />
          <ListPaperGrid 
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            {areaList}
          </ListPaperGrid>
        </PaperGrid>
      </Modal>
    </>
  );
};

export default SearchFactoryArea;
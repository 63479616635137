/**
 * 共通で使用可能なデータをここに配置します
 * (SEJ店舗関係)
 *
 */
import { getFromGcsZip } from "../apis/depressAction";

// 既存店個店別の型
export interface EXISTING_FACTORY_RECORD {
  // 会社名
  company_name: string;
  // 会社よみ
  company_yomi: string;
  // 工場名
  factory_name: string;
  // 会社工場名
  factory_full_name: string;
  // QCDB文字寄せ
  qcdb_name_collection: string;
  // NDFエリア
  ndf_area: string;
  // NDFベンダーコード
  vender_code: string;
  // NDF工場コード
  factory_code: string;
  // 地区コード
  factory_area_code: string;
  // 地区
  factory_area: string;
  // 地区分科会
  district_subcommittee: string;
  // QCDB工場コード
  qcdb_factory_code: string;
  // 担当QC
  qc_name: string;
  // 担当M
  m_name: string;
  // システム内部の工場ID（＝NDFベンダーコード+NDF工場コード）
  factory_id: string;
}

// 既存店個店別の型のオブジェクトの集まり
export interface INTERFACE_STORE_DATA {
  [index: string]: EXISTING_FACTORY_RECORD;
}

// 既存店の型
export interface INTERFACE_EXISTING_FACTORY {
  factory_data: Map<String, EXISTING_FACTORY_RECORD>;
  count: number;
}

// 既存店の初期値
const initialExistingFactory: INTERFACE_EXISTING_FACTORY = {
  factory_data: new Map<string, EXISTING_FACTORY_RECORD>(),
  count: 0,
};

const existingFactoryData = {
  // SEJ既存店舗
  existingFactory: initialExistingFactory,
};

// SEJ既存店舗情報の全量の情報を返す
export const getAllExistingFactory = () => {
  return existingFactoryData.existingFactory;
};

// SEJ既存店舗のある1店舗のデータを返す
export const getExistingFactory = (
  factoryCode: string
): EXISTING_FACTORY_RECORD | undefined => {
  return existingFactoryData.existingFactory.factory_data.get(factoryCode);
};

// AREA別の型
export interface AREA_RECORD {
  // 各アプリケーション共通項目
  area_code: string;
  area_name: string;
}

// AREAの型のオブジェクトの集まり
export interface INTERFACE_AREA_DATA {
  [index: string]: AREA_RECORD;
}

// AREAの型
export interface INTERFACE_AREA {
  area_data: Map<String, AREA_RECORD>;
  count: number;
}

// AREAの初期値
const initialArea: INTERFACE_AREA = {
  area_data: new Map<string, AREA_RECORD>(),
  count: 0,
};

const areaData = {
  // AREA
  area: initialArea,
};

// ZONE情報の全量の情報を返す
export const getAllArea = () => {
  return areaData.area;
};

// areaCodeのあるAREAのデータを返す
export const getArea = (
    areaCode: string
  ): AREA_RECORD | undefined => {
  return areaData.area.area_data.get("0" + areaCode);
};

// SEJ既存店舗全量を保存する
export const setExistingFactory = (data: any) => {
  const recordData = []; //配列を用意
  const csvRecords = data.split(/\r\n|\r|\n/); //改行で分割
  const factoryData: Map<String, EXISTING_FACTORY_RECORD> = new Map();
  const areaRecordData: Map<String, AREA_RECORD> = new Map();
  let count = 0;
  let countArea = 0;
  let tempArea = "";
  for (let i = 0; i < csvRecords.length; i++) {
    recordData[i] = csvRecords[i].split(",");
    if (recordData[i][0] === "") {
      continue;
    }
    const factoryRecord: EXISTING_FACTORY_RECORD = {
      // 会社名
      company_name: recordData[i][0],
      // 会社よみ
      company_yomi: recordData[i][1],
      // 工場名
      factory_name: recordData[i][2],
      // 会社工場名
      factory_full_name: recordData[i][3],
      // QCDB文字寄せ
      qcdb_name_collection: recordData[i][4],
      // NDFエリア
      ndf_area: recordData[i][5],
      // NDFベンダーコード
      vender_code: recordData[i][6],
      // NDF工場コード
      factory_code: recordData[i][7],
      // 地区分科会
      district_subcommittee: recordData[i][8],
      // QCDB工場コード
      qcdb_factory_code: recordData[i][9],
      // 地区コード
      factory_area_code: recordData[i][10],
      // 地区
      factory_area: recordData[i][11],
      // 担当QC
      qc_name: recordData[i][12],
      // 担当M
      m_name: recordData[i][13],
      // システム内部の工場ID（＝NDFベンダーコード+NDF工場コード）
      factory_id: recordData[i][6] + recordData[i][7],
    };
    factoryData.set(recordData[i][6] + recordData[i][7],factoryRecord);
    count++;
    if (tempArea !== recordData[i][11]) {
      tempArea = recordData[i][11];
      countArea++;
    }
    const areaRecord: AREA_RECORD = {
      area_code: recordData[i][10],
      area_name: recordData[i][11],
    };
    areaRecordData.set("0" + recordData[i][10], areaRecord);
  }

  const result: INTERFACE_EXISTING_FACTORY = {
    factory_data: factoryData,
    count,
  };
  existingFactoryData.existingFactory = result;

  const count_area: number = Object.keys(areaRecordData).length;
  const area: INTERFACE_AREA = {
    area_data: areaRecordData,
    count: count_area,
  };
  areaData.area = area;

};

export const initializeExistingFactory = (token: string) => {
  getFromGcsZip(token, "/master/qc-factory-list_0_2_0.zip", setExistingFactory);
};

import {
  REQUEST_FOR_CORRECTION,
} from "../../FactoryCheck/Register/FactoryCheckDataOperation";
import {
  Typography,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import CloseIcon from "@mui/icons-material/Close";
import {
  BaseGrid,
  SeparatorGrid,
  DataHeaderGrid,
  PrintDataGrid,
  PrintObjectGrid,
  PrintReviewAdviceGrid,
} from "../../FactoryCheck/Styled/FactoryCheckStyled";

export function convertCommentLine(comment: string){
  return (
    <Grid {...{ xs: 12, sm: 12, md: 12, lg: 12 }}>
    {comment.split("\n").map((line, index) => (
      <Typography>{line}</Typography>
    ))}
    </Grid>
  );
}

export const getCorrectionsTable = (corrections: REQUEST_FOR_CORRECTION[], reviewAdvice: string) => {
  if (Array.isArray(corrections) && corrections.length === 0) {
    return "";
  }
  else if (corrections.length !== 20){
    for (let i = corrections.length; i < 20; i++) {
      corrections.push(
        {
          correction_no: i,
          judgement_of_severity: "",
          standard: "",
          evaluation: "",
          follow_up: "",
          comment_advice: "",
        }
      )
    }
  }
  return (
  <>
  <SeparatorGrid />
  <Grid
    container
    justifyContent={"flex-start"}
    alignItems={"center"}
    {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
  >
    <Typography variant="h4">是正要請</Typography>
  </Grid>
  <BaseGrid
    container
    {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
  >
    {/* 是正要請/総評・助言 */}
    {/* ヘッダー部 */}
    <Grid
      container
      {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
    >
      <Grid
        container
        {...{ xs: 6, sm: 6, md: 4, lg: 4 }}
      >
        {/* 重篤性判断 */}
        <Grid
          container
          {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
        >
          <DataHeaderGrid
          container
          justifyContent={"center"}
          alignItems={"center"}
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
          <Typography variant="h6">重篤性判断</Typography>
          </DataHeaderGrid>
        </Grid>
        <Grid
          container
          {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
        >
          {/* 基準 */}
          <DataHeaderGrid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
          >
            <Typography variant="h6">基準</Typography>
          </DataHeaderGrid>
          {/* 記録 */}
          <DataHeaderGrid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
            >
            <Typography variant="h6">記録</Typography>
          </DataHeaderGrid>
        </Grid>
      </Grid>
      {/* 連単 */}
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
      >
        <DataHeaderGrid
          container
          justifyContent={"center"}
          alignItems={"center"}
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Typography variant="h6">連単</Typography>
        </DataHeaderGrid>
      </Grid>
      {/* コメント・助言 */}
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        {...{ xs: 5, sm: 5, md: 7, lg: 7 }}
      >
        <DataHeaderGrid
          container
          justifyContent={"center"}
          alignItems={"center"}
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Typography variant="h6">コメント・助言</Typography>
        </DataHeaderGrid>
      </Grid>
    </Grid>
    <Grid
      container
      {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
    >
    {/* データ部 */}
    {corrections.map((correctionRecord, index) => (
      <Grid
        container
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
      >
        <Grid
          container
          {...{ xs: 6, sm: 6, md: 4, lg: 4 }}
        >
          <Grid
            container
            {...{ xs: 8, sm: 8, md: 8, lg: 8 }}
          >
            {/* 重篤性判断 */}
            <PrintDataGrid
              container
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              {convertCommentLine(correctionRecord["judgement_of_severity"])}
            </PrintDataGrid>
          </Grid>
          <Grid
            container
            {...{ xs: 4, sm: 4, md: 4, lg: 4 }}
          >
            <PrintObjectGrid
              container
              justifyContent={"center"}
              alignItems={"center"}
              {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
            >
              {correctionRecord["standard"] === "1" ? (
                <RadioButtonUnchecked />
              ) : correctionRecord["standard"] === "0" ? (
                <CloseIcon />
              ) : (
                ""
              )}
            </PrintObjectGrid>
            {/* 記録 */}
            <PrintObjectGrid
              container
              justifyContent={"center"}
              alignItems={"center"}
              {...{ xs: 6, sm: 6, md: 6, lg: 6 }}
            >
              {correctionRecord["evaluation"] === "1" ? (
                <RadioButtonUnchecked />
              ) : correctionRecord["evaluation"] === "0" ? (
                <CloseIcon />
              ) : (
                ""
              )}
            </PrintObjectGrid>
          </Grid>
        </Grid>
        <Grid
          container
          {...{ xs: 1, sm: 1, md: 1, lg: 1 }}
        >
          {/* 連単 */}
          <PrintObjectGrid
            container
            justifyContent={"center"}
            alignItems={"center"}
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            {correctionRecord["follow_up"] === "1" ? (
              "継続"
            ) : correctionRecord["follow_up"] === "0" ? (
              "単発"
            ) : (
              ""
            )}
          </PrintObjectGrid>
        </Grid>
        <Grid
          container
          {...{ xs: 5, sm: 5, md: 7, lg: 7 }}
        >
          {/* コメント・助言 */}
          <PrintDataGrid
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
          {convertCommentLine(correctionRecord["comment_advice"])}
          </PrintDataGrid>
        </Grid>
      </Grid>
    ))}
    </Grid>
  </BaseGrid>
  <SeparatorGrid />
  {/* 総評 ・ 助言 */}
  <Grid
    container
    justifyContent={"flex-start"}
    alignItems={"center"}
    {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
  >
    <Typography variant="h4">総評 ・ 助言</Typography>
  </Grid>
  <BaseGrid
    container
    {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
  >
    <PrintReviewAdviceGrid
      container
      {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
    >
      {convertCommentLine(reviewAdvice)}
    </PrintReviewAdviceGrid>
  </BaseGrid>
  </>
  );
};
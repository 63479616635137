import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { FACTORY_STATUS } from "./interface";

const initialFactoryStatus: FACTORY_STATUS = {
  factoryCode: "",
  factoryFullName: "",
  factoryCheckComment: "",
  factoryCheckPhoroUrl: "",
  factoryGraphFirstItem: "",
  factoryGraphFirstName: "",
  factoryGraphSecondItem: "",
  factoryGraphSecondName: "",
};

export const factoryStatusSlice = createSlice({
  name: "factoryStatus",
  initialState: {
    factoryStatus: initialFactoryStatus,
  },
  reducers: {
    updateFactoryCheckComment: (state, action) => {
      state.factoryStatus.factoryCheckComment = action.payload.factoryCheckComment;
    },
    updateFactoryCheckPhoroUrl: (state, action) => {
      state.factoryStatus.factoryCheckPhoroUrl = action.payload.factoryCheckPhoroUrl;
    },
    updateFactoryGraphFirstItem: (state, action) => {
      state.factoryStatus.factoryGraphFirstItem =
        action.payload.factoryGraphFirstItem;
    },
    updateFactoryGraphFirstName: (state, action) => {
      state.factoryStatus.factoryGraphFirstName =
        action.payload.factoryGraphFirstName;
    },
    updateFactoryGraphSecondItem: (state, action) => {
      state.factoryStatus.factoryGraphSecondItem =
        action.payload.factoryGraphSecondItem;
    },
    updateFactoryGraphSecondName: (state, action) => {
      state.factoryStatus.factoryGraphSecondName =
        action.payload.factoryGraphSecondName;
    },
    clearFactoryStatus: (state) => {
      state.factoryStatus = initialFactoryStatus;
    },
  },
});

export const selectFactoryStatus = (state: RootState) => state.factoryStatus;
export const selectFactoryCode = (state: RootState) =>
  state.factoryStatus.factoryStatus.factoryCode;
export const selectFactoryGraphFirstItem = (state: RootState) =>
  state.factoryStatus.factoryStatus.factoryGraphFirstItem;
export const selectFactoryGraphFirstName = (state: RootState) =>
  state.factoryStatus.factoryStatus.factoryGraphFirstName;
export const selectFactoryGraphSecondItem = (state: RootState) =>
  state.factoryStatus.factoryStatus.factoryGraphSecondItem;
export const selectFactoryGraphSecondName = (state: RootState) =>
  state.factoryStatus.factoryStatus.factoryGraphSecondName;
export default factoryStatusSlice.reducer;
export const {
  updateFactoryCheckComment,
  updateFactoryCheckPhoroUrl,
  updateFactoryGraphFirstItem,
  updateFactoryGraphFirstName,
  updateFactoryGraphSecondItem,
  updateFactoryGraphSecondName,
  clearFactoryStatus,
} = factoryStatusSlice.actions;

//=============================================================================
//  コード定義
//=============================================================================
  // job_category {"101": "ＧＭ", "102": "ＡＧＭ", "230": "Ｍgr", "550": "担当"}
  // hq_department_code_OA 965000
  // hq_department_name_OA ＱＣ室
  // department_code_OA ["965000", "965001",  "965002",    "965003",           "965004"]
  // department_name_OA ["ＱＣ室", "デイリー", "加食・雑貨", "ＦＦ・店舗品質管理", "ＱＣサポート"]
/** 管理者 */
export const JOB_CATEGORY_CODE_ADMIN = "admin-group";
/** ＧＭ */
export const JOB_CATEGORY_CODE_GM = "101";
/** ＡＧＭ */
export const JOB_CATEGORY_CODE_AGM = "102";
/** Ｍgr */
export const JOB_CATEGORY_CODE_MGR = "230";
/** 担当 */
export const JOB_CATEGORY_CODE_IN_CHARGE = "550";
/** ＱＣ・物流管理本部。ＱＣ部, デイリー, 加食・雑貨, ＦＦ・店舗品質管理, ＱＣサポート */
export const qcDeptCodes = ["952000", "952100", "952101", "952102", "952103", "952104"];
/** システム企画、オペレーション本部 */
export const kikakuDeptCodes = ["955500", "900A50", "900A60"];
/** 個人 */
export const kojinMailAddresses = ["kikushima-hirotaka@sej.7andi.co.jp", "kudou-toshiyuki@sej.7andi.co.jp"]

//=============================================================================
//  権限
//=============================================================================
//  提出可能判定
const isAcceptJob = (jobCategory: string) => {
  return jobCategory === JOB_CATEGORY_CODE_IN_CHARGE;
};

//  管理職判定
const isMagager = (jobCategory: string) => {
  return (
    jobCategory === JOB_CATEGORY_CODE_GM ||
    jobCategory === JOB_CATEGORY_CODE_AGM ||
    jobCategory === JOB_CATEGORY_CODE_MGR
  );
};

/**
 * 登録画面表示可否判定。 
*/
export const isOpenRegistPage = (user: any) => {
  if (!qcDeptCodes.includes(user.departmentCodeOA)) {
    return false;
  }
  else if (isAcceptJob(user.jobCategory)) {
    return true;
  }
  return false;
};

/**
 * 提出状況確認画面表示可否判定。 
*/
export const isOpenStatusConfirmPage = (user: any) => {
  if (
    user.jobCategory === JOB_CATEGORY_CODE_ADMIN ||
    kikakuDeptCodes.includes(user.departmentCodeOA) ||
    kojinMailAddresses.includes(user.mailAddress)||
    (qcDeptCodes.includes(user.departmentCodeOA) &&
    isMagager(user.jobCategory))
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * 管理者分析画面表示可否判定。 
*/
export const isOpenManagerAnalysisPage = (user: any) => {
  if (
    user.jobCategory === JOB_CATEGORY_CODE_ADMIN ||
    kikakuDeptCodes.includes(user.departmentCodeOA) ||
    kojinMailAddresses.includes(user.mailAddress)||
    (qcDeptCodes.includes(user.departmentCodeOA) &&
    isMagager(user.jobCategory))
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * 論理削除可否判定。 
*/
export const isExecuteLogicalDelete = (user: any) => {
  if (
    user.jobCategory === JOB_CATEGORY_CODE_ADMIN ||
    kikakuDeptCodes.includes(user.departmentCodeOA) ||
    kojinMailAddresses.includes(user.mailAddress)||
    (qcDeptCodes.includes(user.departmentCodeOA) &&
    isMagager(user.jobCategory))
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * 開発環境判定。 
*/
export const isLocal = () => {
  return window.location.origin.indexOf("localhost") !== -1
  || window.location.origin.indexOf("3000-workstation") !== -1;
};

/**
 * ダウンロード可否判定。 
*/
export const isDownloadPointData = (user: any) => {
  if (
    user.jobCategory === JOB_CATEGORY_CODE_ADMIN ||
    kikakuDeptCodes.includes(user.departmentCodeOA) ||
    kojinMailAddresses.includes(user.mailAddress)||
    (qcDeptCodes.includes(user.departmentCodeOA) &&
    isMagager(user.jobCategory))
  ) {
      return true;
  } else {
    return false;
  }
};
import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";

import { getAllExistingFactory } from "../../../../data/existingFactory";
import {
  selectFactoryFullName,
} from "../FactoryCheckSearchSlice";
import {
  Button,
  TextField,
  Modal,
  styled,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { getChangeFlg, setChangeFlg } from "../../../FactoryCheck/Register/FactoryCheckTable";
import FactoryCheckDialog from "../../../FactoryCheck/FactoryCheckDialog";

const PaperGrid = styled(Grid)(({ theme }) => ({
      position: "absolute",
      top: "5%",
      left: "10%",
      width: "80%",
      maxWidth: "640px",
      minWidth: "640px",
      height: "90%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
//     },
}));

const ListPaperGrid = styled(Grid)(({ theme }) => ({
      height: "85%",
      maxWidth: "580px",
      minWidth: "580px",
      overflow: "auto",
      padding: "0px 0px",
      marginTop: "25px",
}));

const SearchButton = styled(Button)(({ theme }) => ({
  width: "90%",
  marginLeft: "15px",
  backgroundColor: theme.palette.background.paper,
  border: "1px solid #D5D5D5",
  borderRadius: "5px",
//     },
}));

const SelectButtonGrid = styled(Grid)(({ theme }) => ({
  height: "38px",
  textAlign: "left",
  marginLeft: "10px",
  marginTop: "2px",
  fontSize: "22px",
//     },
}));

const SearchFactory: React.FC<{
  disabled: boolean;
  firstView: boolean;
  initialDisplay: boolean;
  onClickFunc: any;
}> = ({
  firstView,
  disabled,
  initialDisplay,
  onClickFunc,
}) => {
  const [open, setOpen] = React.useState(false);
  // 検索文字列
  const [searchWord, setSearchWord] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const stateFactoryFullName = useSelector(selectFactoryFullName);
  
  const MAX_SEARCH_LIST = 150;
  const factory: any = getAllExistingFactory().factory_data;
  const [factoryList, setFactoryList] = React.useState([""]);
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);
  const dialogNo = () => {
    setDialogFlg(false);
    handleClose();
  };
  const dialogYes = () => {
    setDialogFlg(false);
    onClickFunc("", "");
    setChangeFlg(false);
    handleOpen();
  };

  // 初期表示処理
  useLayoutEffect(() => {
    // 選択メニューを開く
    if (firstView) {
      setOpen(true);
    }
    if (initialDisplay) {
      allFactoryButton();
    }
  }, []);
  // 検索文字列が更新された際に動く
  useLayoutEffect(() => {
    allFactoryButton();
  }, [searchWord, open]);

  function allFactoryButton() {
    let factoryList: any[] = [""];
    let hitCount: number = 0;
    for (let [key] of factory) {
      // 店舗レコードを内部データから取得
      let factoryRecord = factory.get(key);
      if (
        (!initialDisplay && searchWord === "") ||
        key === undefined ||
        factoryRecord === undefined ||
        factoryRecord.factory_full_name === undefined
      ) continue;
      // 検索文字列にマッチしたものだけリストに入れる
      if (factoryRecord.factory_full_name.match(searchWord)) {
        factoryList.push(
          <Button
            key={key}
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              onClickFunc(
                factoryRecord.factory_id, factoryRecord.factory_full_name
              );
              handleClose();
            }}
          >
            <SelectButtonGrid
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              {factoryRecord.factory_full_name}
            </SelectButtonGrid>
          </Button>
        );
        hitCount++;
      }
      if (hitCount > MAX_SEARCH_LIST) break;
    }
    setFactoryList(factoryList);
  }

  return (
    <>
      <SearchButton
        onClick={() => {
          if (getChangeFlg()) {
            setDialogMessage(["保存されていません。編集内容を破棄して遷移しますか？"]);
            setDialogFlg(true);
            return;
          }
          onClickFunc("", "");
          handleOpen();
        }}
        disabled = {disabled}
        style={{ WebkitAppearance: "none" }}
      >
        <SelectButtonGrid
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          { stateFactoryFullName === "" ? "工場名" : stateFactoryFullName}
        </SelectButtonGrid>
      </SearchButton>

      {/* ダイアログ */}
      <FactoryCheckDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogYes}
        doNo={dialogNo}
        isAlert={false}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <PaperGrid
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }} 
        >
          <TextField
            value={searchWord}
            label="メーカー名・工場名で探す"
            variant="outlined"
            size="medium"
            fullWidth
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchWord(e.target.value);
            }}
          />
          <ListPaperGrid
            {...{ xs: 12, sm: 12, md: 12, lg: 12 }} 
          >
            {factoryList}
          </ListPaperGrid>
          {factoryList.length > 150 ? "※最大150件まで表示しています。検索で絞り込みしてください。" : ""}
        </PaperGrid>
      </Modal>
    </>
  );
};

export default SearchFactory;
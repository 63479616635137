import React, { useEffect, FC } from "react";
import { useSelector } from "react-redux";

import { v4 as uuidv4 } from "uuid";

import {
  Button,
  Grid,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import Close from "@mui/icons-material/Close";

import { limitLengthAndLine } from "../../../apis/util"
import { convertToFile } from "../../../apis/photoAction";
import { selectImageToken } from "../../../features/userSlice";
import { getFixedFlg } from "../../Header/Header";
import { userLogging } from "../../../apis/userLog";
import PhotoEdit from "../../organisms/photo/PhotoEdit";
import SwitchTextArea, { HandwriteUpdate } from "../../organisms/text/SwitchTextArea";
import {
  FACTORY_CHECK_COMMENTS,
} from "../../FactoryCheck/Register/FactoryCheckDataOperation";
import FactoryCheckDialog from "../../FactoryCheck/FactoryCheckDialog";

const PhotoAndComment: FC<{
  // コメント
  comment: FACTORY_CHECK_COMMENTS;
  handwriteFlag: string;
  index: number;
  length: number;
  factoryId?: string;
  handleDelete: (val: number) => void;
}> = ({ comment, handwriteFlag, index, length, factoryId, handleDelete }) => {
  const [inputComment, setInputComment] = React.useState("");
  const [photoPath, setPhotoPath] = React.useState("");
  const [photoData, setPhotoData] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);
  const [commentFilepathGcs, setCommentFilepathGcs] = React.useState<string>("");
  const [commentFileData, setCommentFileData] = React.useState<string>("");
  const [commentHandwriteFlag, setCommentHandwriteFlag] = React.useState<string>("");

  // GCSアクセストークン
  let imageToken = useSelector(selectImageToken);

  useEffect(() => {
    setInputComment(comment.user_input_comment);
    setPhotoPath(comment.photo_filepath_gcs);
    setPhotoData(comment.photo_file_data);
    setCommentFilepathGcs(comment.comment_filepath_gcs);
    setCommentFileData(comment.comment_file_data);
    setCommentHandwriteFlag(handwriteFlag);
  }, [comment]);

  // 引き渡し用canvas更新関数
  const updateCavasContext = (context: CanvasRenderingContext2D): void => {
    if (context) {
      const canvasElement: HTMLCanvasElement = context.canvas;
      const data = canvasElement.toDataURL();
      setCommentFileData(data);
      setCommentFilepathGcs("");
      setCommentHandwriteFlag("1");
      comment.comment_file_data = data;
      comment.comment_filepath_gcs = "";
      comment.comment_handwrite_flag = "1";
    }
  }

  // 引き渡し用手書きコメント変更有無
  const updateHandwriteUpdateClass = (val: string): void => {
    setCommentFilepathGcs("");
    setCommentFileData("");
    comment.comment_filepath_gcs = "";
    comment.comment_file_data = "";
    if (val === HandwriteUpdate.Off) {
      setCommentHandwriteFlag("");
      comment.comment_handwrite_flag = "";
    }
  }

  // コメント入力監視
  const changeComment = (event: React.ChangeEvent<{ value: unknown }>) => {
    // 閲覧モード判定
    if (getFixedFlg()) return;
    let commentValue = event.target.value as string;
    commentValue = limitLengthAndLine(commentValue, 132, 12);
    setInputComment(commentValue);
    comment.user_input_comment = commentValue;
  };

  // 写真保存処理
  const handleUploadPhoto = (e: any, index: number) => {
    setLoading(true);
    if (getFixedFlg()) return;
    if (!e.target.files[0].name.match('.(jpeg|jpg|png|bmp|gif|jfif|heic)$')) {
      setDialogMessage(["画像ファイル以外はアップロードできません。"]);
      setDialogFlg(true);
      setLoading(false);
      return;
    }
    convertToFile(e.target.files[0]).then((res) => {
      setPhotoPath("");
      setPhotoData("" + res);
      comment.photo_filepath_gcs = "";
      comment.photo_file_data = "" + res;
      setLoading(false);
    }).catch((e) => {
      setLoading(false);
    });
  };

 
  // ローディングプログレス
  // const loadingGif = `${window.location.origin}/image/icon_loader.gif`;
  
  // GCSファイルパス作成
  function getImageFile(imagePath: string) {
    if (imagePath === undefined || imagePath === "") {
      return `${window.location.origin}/image/noimage.png?` + uuidv4();
    }
    return (
      process.env.REACT_APP_CLOUD_STORAGE_URL +
      imagePath +
      "?access_token=" +
      imageToken
      + "nocash=" + uuidv4()
    );
  }
  
  // 写真削除
  function deleteFile(filePath: any) {
    userLogging("ＱＣ工場チェック", "チェック#写真削除", filePath);
    setPhotoPath("");
    setPhotoData("");
    comment.photo_filepath_gcs = "";
    comment.photo_file_data = "";
  }

  // 画像編集での画像ファイルパス設定用関数
  const updateFilePath = (data: string): void => {
    setPhotoPath("");
    setPhotoData(data);
    comment.photo_filepath_gcs = "";
    comment.photo_file_data = data;
  };

  // ファイル名作成関数
  const fileNameGenerator = (): string => { return (factoryId + "-" + uuidv4()) };

  const dialogClose = () => {
    setDialogFlg(false);
  };

  return (
    <>
      <Grid
        container
        style={{
          marginBottom: "5px",
          display: "flex",
          alignSelf: "flex-start",
        }}
      >
        {
        // 写真をアップロードここから↓
        }
        <Grid
          item
          lg={3} md={4} sm={5}
        >
          <Grid
            container 
            justifyContent={"center"}
            alignItems={"center"} 
            style={{ paddingTop: "10px" }}
          >
              {loading ? (
                <Grid
                  container
                  justifyContent={"center"}
                  alignItems={"center"} 
                  sx={{
                    display: 'flex',
                    minHeight: "200px",
                    maxWidth: "200px",
                  }}
                >
                  <CircularProgress
                    size={"10vh"}
                    sx={{
                      color: "#ccc",
                    }}
                  />
                </Grid>
              ) : (
                <img
                  alt=""
                  src={
                    photoPath ? getImageFile(photoPath) : 
                    photoData ? photoData : 
                    `${window.location.origin}/image/noimage.png?` + uuidv4()
                  }
                  height="200"
                  style={{
                    width: "200px",
                    WebkitAppearance: "none",
                  }}
                />
              )}
          </Grid>
          <Grid container 
            justifyContent={"center"}
            alignItems={"center"} 
            style={{ paddingTop: "10px" }}>
            <label htmlFor={"upload-photo" + index}>
              <input
                style={{ display: "none" }}
                id={"upload-photo" + index}
                type="file"
                onChange={(e) => {
                  handleUploadPhoto(e, index);
                  e.target.value = "";
                }}
                disabled={getFixedFlg()}
              />
              {
                // 閲覧モード、オンライン判定
                getFixedFlg() ? (
                  ""
                ) : (
                  <>
                    <Button variant="contained" component="span">
                      写真をアップロード
                    </Button>
                  </>
                )
              }
            </label>

            {
              // 閲覧モード、オンライン判定
              !getFixedFlg() && (photoPath || photoData) ? (
                <>
                  <IconButton
                    onClick={(e) => {
                      deleteFile(photoPath);
                    }}
                    size={"small"}
                    style={{ marginLeft: "8px" }}
                  >
                    <Delete 
                    // fontSize={"default"} 
                    />
                  </IconButton>

                  <PhotoEdit
                    baseFilePath={photoPath}
                    baseFileData={photoData}
                    updateFilePath={updateFilePath}
                    fileNameGenerator={fileNameGenerator}
                    functionPath="factory-check-photo/"
                  />
                </>
              ) : (
                ""
              )
            }
          </Grid>
        </Grid>
        {
        // 写真をアップロードここまで↑
        }
        {
        // コメントここから↓
        }
        <Grid item lg={8} md={7} sm={6}>
          <Grid
            container 
            justifyContent={"center"}
            alignItems={"center"} 
          >
            <Grid item>
              <SwitchTextArea
                comment={inputComment}
                commentFilepathGcs={commentFilepathGcs}
                commentFileData={commentFileData}
                commentHandwriteFlag={commentHandwriteFlag}
                changeComment={changeComment}
                updateCavasContext={updateCavasContext}
                // updateCommentHandwriteFlag={updateCommentHandwriteFlag}
                updateHandwriteUpdateClass={updateHandwriteUpdateClass}
                />
            </Grid>
            {/* <Grid item>
              <Typography>※印刷時</Typography>
              <Typography
                style={countText(modalComment) > 30 ? { color: "red" } : {}}
              >
                1頁目の表示は全角30文字で切れます
              </Typography>
              <Typography>2～3頁目の表示は全角144文字で切れます</Typography>
              <Typography>手書き入力した場合、印刷結果に理由等は表示されません</Typography>
              <Typography>手書き入力で誤った内容で保存した場合はクリアボタンを押下することで新規に作成することができます</Typography>
            </Grid> */}
          </Grid>
        </Grid>
        {
        // コメントここまで↑
        }
        <Grid item lg={1} md={1} sm={1}>
          {length !== 1 ? (
          <Grid container 
            justifyContent={"center"}
            alignItems={"flex-start"} 
            style={{ paddingTop: "10px" }}>
            <IconButton
              onClick={(e) => {
                handleDelete(index);
              }}
              size={"small"}
              style={{ marginLeft: "8px" }}
            >
              <Close/>
            </IconButton>
          </Grid>
          ) : <></>}
        </Grid>
      </Grid>
      {
      // ダイアログ
      }
      <FactoryCheckDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogClose}
        doNo={dialogClose}
        isAlert={true}
      />
    </>
  );
};
export default PhotoAndComment;

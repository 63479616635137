import React, { forwardRef, useImperativeHandle } from "react";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import FactoryIcon from '@mui/icons-material/Factory';
import HelpIcon from '@mui/icons-material/Help';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {
  SwipeableDrawer,
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { teal } from '@mui/material/colors';
import {
  clickNewFactory,
  URL_MANUAL
} from "../Home/Home";
import {
  isOpenRegistPage,
  isOpenStatusConfirmPage,
  isOpenManagerAnalysisPage,
  isDownloadPointData,
} from "../../apis/privilege";
import { selectUser } from "../../../src/features/userSlice";
import { auth } from "../../firebase";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid } from "@mui/material";
import { userLogging } from "../../apis/userLog";
import { getChangeFlg, setChangeFlg } from "../FactoryCheck/Register/FactoryCheckTable";
import FactoryCheckDialog from "../FactoryCheck/FactoryCheckDialog";

type Anchor = "left";
const imgDir = `${window.location.origin}/image/icons/`;

const factoryNewItem = {
  title: "新規登録",
  id: "factory-check",
  icon: "new_icon.png",
};
const factorySearchItem = {
  title: "データ検索",
  id: "factory-check-search",
  icon: "search_icon.png",
};
const factoryStatusConfirmItem = {
  title: "提出状況確認",
  id: "factory-check-filing-status-confirm",
  icon: "confirm_icon.png",
};
const factoryManagerAnalysisItem = {
  title: "管理者分析",
  id: "factory-check-manager-analysis",
  icon: "analysis_icon.png",
};

let factoryCheckItems = [
  factoryNewItem,
  factorySearchItem,
  factoryStatusConfirmItem,
  // tenpoManagerAnalysisItem,
];

export interface SwipTempDrawerHandles {
  openDrawer(): void;
}

export const SwipeableTemporaryDrawer = forwardRef<SwipTempDrawerHandles>(
  (props, ref) => {
    const history = useHistory();
    const [state, setState] = React.useState({
      left: false,
    });
    const user: any = useSelector(selectUser);
    const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
    const [dialogFlg, setDialogFlg] = React.useState(false);
    const [currentItem, setCurrentItem] = React.useState({
      title: "",
      id: "",
      icon: "",
    });
    const dialogNo = () => {
      setDialogFlg(false);
    };
    const dialogYes = () => {
      setDialogFlg(false);
      setChangeFlg(false);
      if (currentItem.id === "factory-check") {
        clickNewFactory();
      }
      history.push(currentItem.id);
      userLogging("工場確認表", "サイドメニュー", currentItem.title);
    };

    factoryCheckItems = [];
    // 新規登録
    if (isOpenRegistPage(user)) {
      factoryCheckItems.push(factoryNewItem);
    }
    // 検索
    factoryCheckItems.push(factorySearchItem);
    // 提出状況確認
    if (isOpenStatusConfirmPage(user)) {
      factoryCheckItems.push(factoryStatusConfirmItem);
    }
    // 管理者分析
    if (isOpenManagerAnalysisPage(user)) {
      factoryCheckItems.push(factoryManagerAnalysisItem);
    }

    useImperativeHandle(ref, () => ({
      openDrawer() {
        setState({ ...state, left: true });
      },
    }));
    const signOut = () => {
      auth
        .signOut()
        .then(() => {})
        .catch((error: any) => {
          console.log(`logout error (${error})`);
        });
    };

    const toggleDrawer =
      (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event &&
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };
    const list = (anchor: Anchor) => (
      <div
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          <ListItemButton>
            <ListItemIcon>
              <FactoryIcon/>
            </ListItemIcon>
            <ListItemText
              primary={"工場確認表"}
              primaryTypographyProps={{
                variant: "inherit",
              }}
            />
          </ListItemButton>
          {factoryCheckItems.map((item) => (
            <ListItemButton
              key={item.id}
              onClick={() => {
                if (getChangeFlg()) {
                  setDialogMessage(["保存されていません。編集内容を破棄して遷移しますか？"]);
                  setDialogFlg(true);
                  setCurrentItem(item);
                  return;
                }
                if (item.id === "factory-check") {
                  clickNewFactory();
                }
                history.push(item.id);
                userLogging("工場確認表", "サイドメニュー", item.title);
              }}
            >
              <ListItemIcon>
                <img
                  src={imgDir + item.icon}
                  alt=""
                  style={{ height: "20px", paddingRight: "10px" }}
                />
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
          ))}
        <Divider />
          {/* <ListItem
            button
            key="manual1"
            onClick={() => {
              window.open(URL_Q_A, "_blank");
              userLogging("その他", "サイドメニュー", "Q＆A");
            }}
          >
            <ListItemIcon>
              <img
                // src={imgDir + "logout_icon.png"}
                alt=""
                style={{ height: "20px", paddingRight: "10px" }}
              />
            </ListItemIcon>
            <ListItemText primary={"Q＆A"} />
          </ListItem> */}
          <ListItemButton>
            <ListItemIcon>
              <HelpIcon/>
            </ListItemIcon>
            <ListItemText
              primary={"マニュアル"}
              primaryTypographyProps={{
                variant: "inherit",
              }}
            />
          </ListItemButton>
          <ListItemButton
            key="manual"
            onClick={() => {
              window.open(URL_MANUAL, "_blank");
              userLogging("その他", "サイドメニュー", "マニュアル：操作手順");
            }}
          >
            <ListItemIcon>
              <MenuBookIcon sx={{ color: teal["A700"] }}/>
            </ListItemIcon>
            <ListItemText primary={"操作手順"}/>
          </ListItemButton>

          {process.env.REACT_APP_MODE === "develop" ||
           process.env.REACT_APP_MODE === "test-factory-check" ? (
            <>
            <Divider />
            <ListItemButton
              key="logout"
              onClick={() => {
                signOut();
                history.push("login");
                userLogging("共通", "サイドメニュー", "ログアウト");
              }}
            >
              <ListItemIcon>
                <img
                  src={imgDir + "logout_icon.png"}
                  alt=""
                  style={{ height: "20px", paddingRight: "10px" }}
                />
              </ListItemIcon>
              <ListItemText primary={"ログアウト"} />
            </ListItemButton>
            <ListItemButton>
              <ListItemText
                primary={user.mailAddress}
                primaryTypographyProps={{
                  variant: "inherit",
                }}
              />
            </ListItemButton>
            <ListItemButton>
              <ListItemText
                primary={user.uid}
                primaryTypographyProps={{
                  variant: "inherit",
                }}
              />
            </ListItemButton>
            <ListItemButton>
              <ListItemText
                primary={user.jobCategory}
                primaryTypographyProps={{
                  variant: "inherit",
                }}
              />
            </ListItemButton>
            <ListItemButton>
              <ListItemText
                primary={JSON.stringify(user.zoCode)}
                primaryTypographyProps={{
                  variant: "inherit",
                }}
              />
            </ListItemButton>
            <ListItemButton>
              <ListItemText
                primary={JSON.stringify(user.doCode)}
                primaryTypographyProps={{
                  variant: "inherit",
                }}
              />
          </ListItemButton>
          </>
        ) : (
            ""
          )}
        </List>
      </div>
    );

    return (
      <React.Fragment>
        <Grid
          container 
          justifyContent="flex-start"
          alignItems="center"
          direction="row"
        >
          {/* ダイアログ */}
          <FactoryCheckDialog
            msg={dialogMessage}
            isOpen={dialogFlg}
            doOK={dialogYes}
            doNo={dialogNo}
            isAlert={false}
          />
          <Button onClick={toggleDrawer("left", true)} style={{ color: "#000000", padding: "0" }}>
            <MenuIcon></MenuIcon>
          </Button>
          <img
            src={`${window.location.origin}/image/sejlogo.png`}
            alt={"SEJアイコン"}
            height={"60px"}
            object-fit={"contain"}
          />
        </Grid>
        <SwipeableDrawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          {list("left")}
        </SwipeableDrawer>
      </React.Fragment>
    );
  }
);

import React, { FC, useRef, forwardRef } from "react";

import Encoding from "encoding-japanese";
import { useSelector } from "react-redux";
import { selectIsDeviceType } from "../../../features/userSlice";

import { DataGrid, GridToolbarContainer, GridToolbarExport, jaJP, GridColumns } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';

// import MaterialTable from "material-table";
// import { localizationJapanese } from "../../common/MatelialTableLocalizationJapanese";
// import AddBox from "@mui/icons-material/AddBox";
// import ArrowDownward from "@mui/icons-material/ArrowDownward";
// import Check from "@mui/icons-material/Check";
// import ChevronLeft from "@mui/icons-material/ChevronLeft";
// import ChevronRight from "@mui/icons-material/ChevronRight";
// import Clear from "@mui/icons-material/Clear";
// import DeleteOutline from "@mui/icons-material/DeleteOutline";
// import Edit from "@mui/icons-material/Edit";
// import FilterList from "@mui/icons-material/FilterList";
// import FirstPage from "@mui/icons-material/FirstPage";
// import LastPage from "@mui/icons-material/LastPage";
// import Remove from "@mui/icons-material/Remove";
// import SaveAlt from "@mui/icons-material/SaveAlt";
// import Search from "@mui/icons-material/Search";
// import ViewColumn from "@mui/icons-material/ViewColumn";
import { userLogging } from "../../../apis/userLog";
import { TablePagination, Link } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import { getFirstDayOfWeek } from "../Register/FactoryCheckDataOperation";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Grid
        container
        justifyContent="flex-end"
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
      >
        <GridToolbarExport 
          printOptions={{
            disableToolbarButton: true,
          }}
          csvOptions={{
            utf8WithBom: true,
          }}
        />
      </Grid>
    </GridToolbarContainer>
  );
}


const FactoryCheckFilingStatusConfirmResult: FC<{
  record: any[];
}> = ({ record }) => {
  // デバイスタイプの取得
  const deviceType = useSelector(selectIsDeviceType);
  const tableRef: any = useRef();
  const history = useHistory();
  const [pageSize, setPageSize] = React.useState<number>(15);
  const columns: GridColumns = 
  [
    // "initialState":{
    //     "columns":{
    //       "columnVisibilityModel":{
    //         "id":false
    //     }
  
    //   }
  
    // },
    // {
    //   "field":"id",
    //   "hide":true
    // },

    {
      field: 'qc_area_name',
      headerName: '地区',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      // "width":110,
    },
    {
      field: 'vendor_name',
      headerName: 'メーカー名',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      minWidth: 180,
      editable: false,
    },
    {
      field: 'factory_name',
      headerName: '工場名',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      minWidth: 180,
      editable: false,
    },
    {
      field: 'qc_name',
      headerName: 'ＱＣ',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      editable: false,
    },
    {
      field: 'manager_name',
      headerName: 'Ｍ',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      editable: false,
    },
    {
      field: 'result1',
      headerName: '１月',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      maxWidth: 60,
      editable: false,
    },
    {
      field: 'result2',
      headerName: '２月',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      maxWidth: 60,
      editable: false,
    },
    {
      field: 'result3',
      headerName: '３月',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      maxWidth: 60,
      editable: false,
    },
    {
      field: 'result4',
      headerName: '４月',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      maxWidth: 60,
      editable: false,
    },
    {
      field: 'result5',
      headerName: '５月',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      maxWidth: 60,
      editable: false,
    },
    {
      field: 'result6',
      headerName: '６月',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      maxWidth: 60,
      editable: false,
    },
    {
      field: 'result7',
      headerName: '７月',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      maxWidth: 60,
      editable: false,
    },
    {
      field: 'result8',
      headerName: '８月',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      maxWidth: 60,
      editable: false,
    },
    {
      field: 'result9',
      headerName: '９月',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      maxWidth: 60,
      editable: false,
    },
    {
      field: 'result10',
      headerName: '１０月',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      maxWidth: 60,
      editable: false,
    },
    {
      field: 'result11',
      headerName: '１１月',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      maxWidth: 60,
      editable: false,
    },
    {
      field: 'result12',
      headerName: '１２月',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      maxWidth: 60,
      editable: false,
    },
  ];

  const rows: GridColumns = record;

  const customColumns = (baseColumns: any[]) => {
    let resultColumns: any[] = [];
    for (let column of baseColumns) {
      if (column.field === "name") {
        resultColumns.push({
          title: column.title,
          field: column.field,
          cellStyle: column.cellStyle,
          // customSort: (a: any, b: any) => {
          //   if (a.docode > b.docode) {
          //     return 1;
          //   } else if (a.docode < b.docode) {
          //     return -1;
          //   } else {
          //     if (a.code > b.code) {
          //       return 1;
          //     } else if (a.code < b.code) {
          //       return -1;
          //     } else {
          //       return 0;
          //     }
          //   }
          // },
        });
      } else if (column.field === "tenpo_count") {
        resultColumns.push(column);
      } else {
        resultColumns.push({
          title: convertDateTitle(column.title),
          field: column.field,
          align: column.align,
          type: column.type,
          sorting: column.sorting,
          render: (rowData: any) => {
            return rowData[column.field] !== "0" ? (
              <Link
                style={{ cursor: "pointer" }}
                onClick={() =>
                  clickSubmissionsCount(
                    column.title,
                    rowData.ofc_mailaddress
                  )
                }
                color="primary"
              >
                {rowData[column.field]}
              </Link>
            ) : (
              rowData[column.field]
            );
          },
        });
      }
    }
    return resultColumns;
  };
  const downloadCsv = (record: any, fileName: string) => {
    userLogging("ＱＣ工場チェック", "提出状況確認#CSVダウンロード", fileName);
    const exportFileName = `${fileName}.csv`;

    let convertData: any = new Uint8Array(
      Encoding.convert(Encoding.stringToCode(record), "SJIS", "UNICODE")
    );

    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([convertData], { type: "text/csv" }));
    a.setAttribute("download", exportFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // 対象OFCの店舗の検索に遷移
  const clickSubmissionsCount = (dateString: any, ofcAddress: string) => {
    const targetDateTime = DateTime.fromFormat(dateString, "yyyy/MM/dd");
    const jsDate = targetDateTime.toJSDate();
    const fromDate: DateTime = DateTime.fromFormat(
      getFirstDayOfWeek(jsDate.toISOString(), 0),
      "yyyyMMdd"
    );
    const toDate: DateTime = DateTime.fromFormat(
      getFirstDayOfWeek(jsDate.toISOString(), 6),
      "yyyyMMdd"
    );

    const paramState = {
      ofcAddress: ofcAddress,
      createdateFrom: fromDate,
      createdateTo: toDate,
    };
    history.push({
      pathname: "factory-check-search",
      state: paramState,
    });
    userLogging("ＱＣ工場チェック", "提出状況確認#検索画面へ遷移", "データ検索");
  };

  // 対象年月タイトル表示文字列変換
  const convertDateTitle = (dateString: string): string => {
    const datetime = DateTime.fromFormat(dateString, "yyyy/MM/dd");
    return datetime.toFormat("MM/dd");
  }

  return (
    <>
    <Grid
      container
      justifyContent="center"
      // alignItems="flex-start"
      sx={{
        '& .super-app-theme--header': {
          backgroundColor: '#00CC99',
          color: "white",
        },
      }}
      height={{ xs: '95vh', sm: '93vh', md: '90vh', lg: '90vh', xl: '90vh' }}
      {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
    >
      <Grid
        container
        justifyContent="center"
        // alignItems="flex-start"
        width={{ xs: '95%', sm: '95%', md: '95%', lg: '95%', xl: '1400px' }}
        sx={{ 
          paddingTop: '20px',
        }}
      >
        <DataGrid
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          disableSelectionOnClick
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15]}
          pagination
          rows={rows}
          columns={columns}
          loading={false}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </Grid>
    </Grid>
    </>
  );
};

export default FactoryCheckFilingStatusConfirmResult;

/**
 * Web Crypto APIの共通処理です
 * @class cryptoUtils.ts
 * @constructor
 */


/**
 * この関数は、RSA-OAEPアルゴリズムを使用して暗号キーペアを生成します。
 * 
 * RSA-OAEP（RSA最適非対称暗号パディング）は、公開鍵暗号化の一種であり、高度なセキュリティを提供します。
 * 
 * この関数で生成されたキーペアは、公開鍵でデータを暗号化し、それに対応する秘密鍵でデータを復号化するために使用することができます。
 * これによりデータの機密性を確保することができます。
 * 
 * @returns 生成された暗号キーペアのPromiseを返します。
 */
export const generateKeyPairRsaOaep = (): Promise<CryptoKeyPair> => {
    return window.crypto.subtle.generateKey(
        {
            name: "RSA-OAEP",
            modulusLength: 2048,
            publicExponent: new Uint8Array([1, 0, 1]),
            hash: { name: "SHA-256" }
        },
        true,
        ["encrypt", "decrypt"]
    );
};

/**
 * この関数は、Base64形式の文字列をRSA-OAEPアルゴリズムを使用して復号化します。
 * 
 * @param base64String - 復号化するBase64形式の文字列。
 * @param keyPair - 秘密鍵と公開鍵を含むキーペア。
 * @returns 復号化された文字列を解決するPromise。
 */
export const decryptRsaOaepData = async (base64String: string, keyPair: CryptoKeyPair): Promise<string> => {
    try {
        const binaryString = atob(base64String);
        let bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const decryptedData = await window.crypto.subtle.decrypt(
            {
                name: "RSA-OAEP",
                //@ts-ignore
                hash: { name: "SHA-256" }
            },
            keyPair.privateKey,
            bytes.buffer
        );
        const decryptedString = new TextDecoder().decode(new Uint8Array(decryptedData));
        return decryptedString;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

/**
 * この関数は、平文をRSA-OAEPアルゴリズムを使用して暗号化し、結果をBase64形式の文字列として返します。
 * 
 * @param plainText - 暗号化する平文。
 * @param keyPair - 秘密鍵と公開鍵を含むキーペア。
 * @returns 暗号化され、Base64形式にエンコードされた文字列を解決するPromise。
 */
export const encryptRsaOaepData = async (plainText: string, keyPair: CryptoKeyPair): Promise<string> => {
    try {
        const encoder: TextEncoder = new TextEncoder();
        const encrypted = await window.crypto.subtle.encrypt(
            {
                name: "RSA-OAEP",
                //@ts-ignore
                hash: { name: "SHA-256" }
            },
            keyPair.publicKey,
            encoder.encode(plainText)
        );
        const encryptedData = new Uint8Array(encrypted);
        const base64String: string = arrayBufferToBase64(encryptedData.buffer);
        return base64String;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

/**
 * この関数は、公開鍵をエクスポートします。
 * 
 * エクスポート形式は"spki"（SubjectPublicKeyInfo）で、公開鍵のみを含む標準的な形式です。
 * 
 * @param keyPair - 公開鍵と秘密鍵を含むキーペア。
 * @returns 公開鍵のArrayBufferを解決するPromise。
 */
export const exportPublicKey = (keyPair: CryptoKeyPair): Promise<ArrayBuffer> => {
    return window.crypto.subtle.exportKey("spki", keyPair.publicKey);
};

/**
 * この関数は、ArrayBufferをBase64文字列に変換します。
 * 
 * ArrayBufferはバイナリデータを扱うためのデータ型で、Base64はバイナリデータをASCII文字列で表現するエンコーディング方法です。
 * この関数は、バイナリデータをテキスト形式で安全に送信または保存するために使用できます。
 * 
 * @param buffer - Base64文字列に変換するArrayBuffer。
 * @returns 変換されたBase64文字列。
 */
export const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

/**
 * この関数は、公開鍵で暗号化された複数のBase64形式の文字列を含む配列を受け取り、
 * 各文字列を復号化し、それらを結合します。
 *
 * 各文字列は、提供されたキーペアを使用して復号化されます。
 * 復号化された各文字列は、結果の文字列に追加されます。
 *
 * @param base64Strings - 復号化および結合するBase64形式の暗号化データの配列
 * @param keyPair - 秘密鍵と公開鍵を含むキーペア
 * @returns 復号化され、結合された文字列を返すPromise
 * @throws 復号化に失敗した場合、エラーを投げます
 */
export const decryptRsaOaepAndConcatenate = async (base64Strings: string[], keyPair: CryptoKeyPair): Promise<string> => {
    try {
        let result = '';
        for (const base64String of base64Strings) {
            const decryptedString = await decryptRsaOaepData(base64String, keyPair);
            result += decryptedString;
        }
        return result;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

import React, { useEffect } from "react";
import {
  styled,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Header, {
  getFirstFlg,
  getFixedFlg,
  getInitFactoryId,
  setFirstFlg,
} from "../../Header/Header";
import FactoryCheckTab from "./FactoryCheckTab";
import { Redirect } from "react-router-dom";
import { isFirebaseAuth } from "../../../firebase";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";
import { isOpenRegistPage } from "../../../apis/privilege";
import { postRequest } from "../../../apis/axiosAction";
import { getAllExistingFactory, EXISTING_FACTORY_RECORD } from "../../../data/existingFactory";

const MainContentsGrid = styled(Grid)(({ theme }) => ({
  padding: "1%",
}));

// 対象店舗確認ID
let initFactoryCheckId: string = "";
let initLastFactoryCheckId: string = "";
/** データセット */
export function setFactoryCheckId(id: any) {
  initFactoryCheckId = id;
}
export function getFactoryCheckId() {
  return initFactoryCheckId;
}
/** データセット */
export function setLastFactoryCheckId(id: any) {
  initLastFactoryCheckId = id;
}
export function getLastFactoryCheckId() {
  return initLastFactoryCheckId;
}

const FactoryCheck: React.FC = (props: any) => {

  const [factoryId, setFactoryId] = React.useState(getInitFactoryId());
  const [factoryCheckId, setFactoryCheckId] = React.useState("");
  const [lastFactoryCheckId, setLastFactoryCheckId] = React.useState("");
  const factoryChange = (argFactoryId: string) => {
    setFactoryId(argFactoryId);
    setFactoryCheckId("");
    setLastFactoryCheckId("");
  };
  const [updateFlg, setUpdateFlg] = React.useState("");
  const user = useSelector(selectUser);

  useEffect(() => {
      if (factoryId === "" || getFirstFlg()) {
      setFirstFlg(false);
      setFactoryId("");
      setFactoryCheckId("");
      setLastFactoryCheckId("");
      return;
    }
    const factoryMaster: Map<String, EXISTING_FACTORY_RECORD> = getAllExistingFactory().factory_data;
    let factoryRecordSearch = factoryMaster.get(factoryId);
    if (initFactoryCheckId === "") {
      // 次回の工場チェックのデータを作成
      const apiParams = {
        "query": 
          'mutation {' +
            ' factoryCheckCheckInfo(' +
              'input: {' +
                'createUser: "' + user.mailAddress + '", ' +
                'updateUser: "' + user.mailAddress + '", ' +
                'createEmployeeNo: "' + user.employeeNo + '", ' +
                'updateEmployeeNo: "' + user.employeeNo + '", ' +
                'vendorCode: "' + factoryRecordSearch?.vender_code + '", ' +
                'vendorName: "' + factoryRecordSearch?.company_name + '", ' +
                'factoryCode: "' + factoryRecordSearch?.factory_code + '", ' +
                'factoryName: "' + factoryRecordSearch?.factory_name + '", ' +
                'qcAreaCode: "' + factoryRecordSearch?.factory_area_code + '", ' +
                'qcAreaName: "' + factoryRecordSearch?.factory_area + '"' +
              '}' +
            ')' +
            '{' +
              'id ' +
              'docLastId ' +
            '}' +
          '}'
      };
      const url = process.env.REACT_APP_API_URL + "/graphql";
      postRequest(url, apiParams)
        .then(async (res: any) => {
          setFactoryCheckId(res.data.data.factoryCheckCheckInfo.id);
          setLastFactoryCheckId(res.data.data.factoryCheckCheckInfo.docLastId);
      });

    } else {
      setFactoryCheckId(initFactoryCheckId);
      setLastFactoryCheckId(initLastFactoryCheckId);
    }
    setFirstFlg(false);
  }, [factoryId, updateFlg, getFirstFlg()]);

  // 画面表示可否
  if (!isFirebaseAuth()) {
    return <></>;
  }

  // うまくいかないのでコメントアウト
  // if (
  //   (!isOpenRegistPage(user) && !getFixedFlg()) ||
  //   (factoryId === "" && getFixedFlg())
  // ) {
  //   return <Redirect to="/home" />;
  // }

  return (
    <Grid
      container 
    >
      <Header titleName={"工場確認表"} factoryChange={(argTc:string) => factoryChange(argTc)} />
      <MainContentsGrid
        {...{ xs: 12, sm: 12, md: 12, lg: 12 }} 
      >
      {factoryId === "" ? (
        <Grid
          container
          justifyContent="center"
        >
          右上の工場名より新規作成する工場を選択してください。
        </Grid>
      ) : (
        <FactoryCheckTab
          factoryId={factoryId}
          factoryCheckId={factoryCheckId}
          lastFactoryCheckId={lastFactoryCheckId}
          setUpdateFlg={setUpdateFlg}
        />
      )}
      </MainContentsGrid>
    </Grid>
  );
};

export default FactoryCheck;

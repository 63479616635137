import React from "react";
// import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Header, {
  setFirstFlg,
  setFixedFlg,
  setInitFactoryId,
} from "../Header/Header";
import {
  isOpenRegistPage,
  isOpenStatusConfirmPage,
  isOpenManagerAnalysisPage,
  isDownloadPointData,
} from "../../apis/privilege";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import FactoryIcon from '@mui/icons-material/Factory';
import HelpIcon from '@mui/icons-material/Help';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {
  Button,
  Link,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  styled,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { teal } from '@mui/material/colors';
import {
  setLastFactoryCheckId,
  setFactoryCheckId,
} from "../FactoryCheck/Register/FactoryCheck";
import { selectUser } from "../../../src/features/userSlice";
import { userLogging } from "../../apis/userLog";
import { isFirebaseAuth } from "../../firebase";

const SeparatorGrid = styled(Grid)(({ theme }) => ({
  height: "54px",
}));

const SeparatorLeftGrid = styled(Grid)(({ theme }) => ({
  width: "10px",
}));

const FactoryButtonTypography = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize:"17px",
  fontWeight: "bold",
}));

const FactoryButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  boxShadow: theme.shadows[3],
  width: "230px",
  height: "54px",
  color: "#FFFFFF",
  backgroundColor: "#00CC99",
  fontSize:"17px",
  fontWeight: "bold",
  borderRadius: "10px",
}));

const FactoryLink = styled(Link)(({ theme }) => ({
  marginLeft: "20px",
  underline: "hover",
  variant: "caption",
  fontSize: "20px",
  cursor: "pointer",
  color: "#404040",
}));

const FactoryAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  width: "230px",
  height: "54px",
  backgroundColor: "#00CC99",
  color: "white",
  borderRadius: "10px",
}));

const FactoryAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: theme.shadows[3],
  margin: theme.spacing(1),
  borderRadius: "10px",
}));

const AccordionSummaryGrid = styled(Grid)(({ theme }) => ({
  width: "230px",
  height: "54px",
}));

export function clickNewFactory() {
  setFixedFlg(false);
  setFactoryCheckId("");
  setLastFactoryCheckId("");
  setFirstFlg(true);
}

export const URL_MANUAL = "https://7andihd.sharepoint.com/:p:/t/msteams_86a7e0/EYpGu9txbipNoLMCbjgJDi4BIx8ohcikxLHf8A5GShMqQg?e=eamzrE";

const Home: React.FC = (props: any) => {
  // const classes = useStyles();
  const history = useHistory();
  const isFactoryCheck: boolean =
    process.env.REACT_APP_MODE === "store-check" ||
    process.env.REACT_APP_MODE === "test-factory-check" ||
    process.env.REACT_APP_MODE === "production-factory-check" ||
    process.env.REACT_APP_MODE === "production-stg-factory-check";
  const user: any = useSelector(selectUser);
  
  let titleName = "工場確認表";

  const iconDir = `${window.location.origin}/image/icons/`;
  // setInitTenpoCode("");
  if (!isFirebaseAuth()) {
    return <></>;
  }
  return (
    <>
      {/* ヘッダー部 START */}
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
      >
        <Header titleName={titleName} />
      </Grid>
      {/* ヘッダー部 END */}
      {/* コンテンツ部 START */}
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <SeparatorGrid/>
          <FactoryAccordion 
          // className={classes.homeAccordion}
          >
            <FactoryAccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
              <AccordionSummaryGrid
                container
                justifyContent="center"
                alignItems="center"
              >
                <FactoryIcon sx={{ color: "#ffffff" }}/>
                <SeparatorLeftGrid/>工場確認表
              </AccordionSummaryGrid>
            </FactoryAccordionSummary>
            {/* {isOpenRegistPage(user) ? ( */}
            {/* //TODO テスト用のユーザー情報修正待ち */}
            {true ? (
              <>
              <AccordionDetails>
                <FactoryLink
                  // className={classes.sectionItem}
                  onClick={() => {
                    clickNewFactory();
                    history.push("factory-check");
                    userLogging("工場確認表", "Homeメニュー", "新規登録");
                  }}
                >
                  <img
                    src={iconDir + "new_icon.png"}
                    alt=""
                    style={{
                      height: "20px",
                      paddingRight: "10px",
                    }}
                  />
                  新規登録
                </FactoryLink>
              </AccordionDetails>
              <AccordionDetails>
                <FactoryLink
                  // className={classes.sectionItem}
                  onClick={() => {
                    history.push("factory-check-search");
                    userLogging("工場確認表", "Homeメニュー", "データ検索");
                  }}
                >
                  <img
                    src={iconDir + "search_icon.png"}
                    alt=""
                    style={{
                      height: "20px",
                      paddingRight: "10px",
                    }}
                  />
                  データ検索
                </FactoryLink>
              </AccordionDetails>
              </>
            ) : (
              ""
            )}
            {isOpenStatusConfirmPage(user) ? (
            <>
              <AccordionDetails>
                <FactoryLink
                  // className={classes.sectionItem}
                  onClick={() => {
                    history.push("factory-check-filing-status-confirm");
                    userLogging(
                      "工場確認表",
                      "Homeメニュー",
                      "提出状況確認"
                    );
                  }}
                >
                  <img
                    src={iconDir + "confirm_icon.png"}
                    alt=""
                    style={{
                      height: "20px",
                      paddingRight: "10px",
                    }}
                  />
                  提出状況確認
                </FactoryLink>
              </AccordionDetails>
            {isOpenManagerAnalysisPage(user) ? (
              <AccordionDetails>
                <FactoryLink
                  // className={classes.sectionItem}
                  onClick={() => {
                    history.push("factory-check-manager-analysis");
                    userLogging(
                      "工場確認表",
                      "Homeメニュー",
                      "管理者分析"
                    );
                  }}
                >
                  <img
                    src={iconDir + "analysis_icon.png"}
                    alt=""
                    style={{
                      height: "20px",
                      paddingRight: "10px",
                    }}
                    // className={classes.homeMenuIcon}
                  />
                  管理者分析
                </FactoryLink>
              </AccordionDetails>
            ) : (
              ""
            )}
            </>
          ) : (
            ""
          )}
          </FactoryAccordion>
        {/* <Grid container 
          justifyContent={"center"}
        >
          <FactoryButton
            variant="contained"
            onClick={() => {
              window.open(
                URL_Q_A,
                "_blank"
              );
              userLogging("その他", "Homeメニュー", "Q＆A");
            }}
          >
            Q＆A
          </FactoryButton>
        </Grid> */}
          <FactoryAccordion>
            <FactoryAccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
              <AccordionSummaryGrid
                container
                justifyContent="center"
                alignItems="center"
              >
                <HelpIcon sx={{ color: "#ffffff" }}/>
                <SeparatorLeftGrid/>マニュアル
              </AccordionSummaryGrid>
            </FactoryAccordionSummary>
            <AccordionDetails>
              <FactoryLink
                onClick={() => {
                  window.open(
                    URL_MANUAL,
                    "_blank"
                  );
                  userLogging("その他", "Homeメニュー", "マニュアル：操作手順");
                }}
              >
                <MenuBookIcon
                  sx={{ color: teal["A700"] }}
                  style={{
                    height: "20px",
                    paddingRight: "10px",
                  }}/>操作手順
              </FactoryLink>
            </AccordionDetails>
          </FactoryAccordion>
      </Grid>
      {/* コンテンツ部 END */}
    </>
  );
};

export default Home;
